import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";

const ManageRewardCoupons = () => {
  const [couponCode, setCouponCode] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");
  const [duration, setduration] = useState("");
  const [actualPrice, setactualPrice] = useState("");
  const [discountPrice, setdiscountPrice] = useState("");
  const [summary, setsummary] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [allData, setAllData] = useState([]);

  const [service_type_id, setservice_type_id] = useState("");

  const [totalService_type, settotalService_type] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);
  const [totalSubcategory, settotalSubcategory] = useState([]);

  const [singledata, setsingleData] = useState({});
  const [totalServices, setTotalServices] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [chkVal, setChkVal] = useState(1);

  // console.log("2data", subcategory_id);

  // const [image, setimage] = useState("")

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    getAllService_type();
    setservice_type_id("64d60270203bf4c1d37b6a1e");
    getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
    fetchAllData();
    // setExpiryDate(moment().format("YYYY-MM-DD"));
  }, []);

  const generateCouponCode = (length) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let couponCode = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      couponCode += charset[randomIndex];
    }

    return couponCode;
  };

  const handleChecked = (e) => {
    if (e.target.value == 1) {
      setIsActive(true);
      setChkVal(0);
    } else {
      setIsActive(false);
      setChkVal(1);
    }
    console.log("CHK", chkVal, isActive);
  };

  const resetState = () => {
    setNumberOfBookings(0);
    setOrderFrequency("");
    setCouponCode("");
    setDiscountAmount(0);
    setExpiryDate(moment().format("YYYY-MM-DD"));
    setIsActive(false);
    setChkVal(1);
  };

  const onEdit = (item) => {
    window.scroll(0, 0);
    console.log("ITEM", item);

    setNumberOfBookings(item?.limit_service);
    setOrderFrequency(item?.service_timing);
    setCouponCode(item?.couponCode);
    setDiscountAmount(item?.discountAmount);
    setExpiryDate(moment(item?.expireDate).format("YYYY-MM-DD"));

    console.log("EXPDATE", expiryDate);

    setIsActive(item?.isActive);
    isActive ? setChkVal(0) : setChkVal(1);
    // setimgdata(item?.image);
    // setimage(item?.image);

    setId(item?._id);

    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        NewHomService.DeleteRewardCoupon(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              //   console.log("RESPONSEDELETE", res);
              fetchAllData();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // const organiseData = () => {
  //     let temarr = [...questio_ans]
  //     const tempobj = {
  //         question: question,
  //         answer: ans
  //     }
  //     temarr.push(tempobj);
  //     setquestio_ans(temarr)
  //     setquestion('')
  //     setans('')
  // }

  // const addQuestion = () => {
  //     setfquestions([...fquestions, { question: "", answer: "" }]);
  // };

  // const updateQuestion = (index, field, value) => {
  //     const updatedQuestions = [...fquestions];
  //     updatedQuestions[index][field] = value;
  //     setfquestions(updatedQuestions);
  // };
  // const deleteQuestion = (index) => {
  //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
  //     setfquestions(updatedQuestions);
  // };

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          settotalSubcategory(res?.data);
        } else {
          settotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllServicesByCategory = async (id) => {
    console.log("services:", id);
    NewHomService.FetchServicesByCategory(id)
      .then((res) => {
        console.log("FetchServicesByCategory", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("services:", res);
          setTotalServices(res?.data);
        } else {
          setTotalServices();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllData = () => {
    NewHomService.FetchRewardCoupon()
      .then((res) => {
        console.log("Response Data", res);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data
            ?.sort()
            .reverse()
            .map((item, index) => {
              return {
                sl: index + 1,
                NumberOfOrders: item?.limit_service,
                OrderFrequency: item?.service_timing,
                CouponCode: item?.couponCode,
                DiscountPercentage: item?.discountAmount,
                ExpiryDate: item?.expireDate,

                sId: item?.serviceTypeId,
                isActive: item?.isActive,
                // image: item?.image,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          console.log("RESPONSE", res);
          setAllData(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res?.doc?.url);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      console.log(res?.doc?.url);
      setimgdata(res?.doc?.url);
      setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const [numberOfBookings, setNumberOfBookings] = useState(0);
  const [orderFrequency, setOrderFrequency] = useState("");

  const addData = () => {
    let data = {
      limit_service: numberOfBookings,
      service_timing: orderFrequency,
      couponCode: couponCode,
      discountAmount: discountAmount,
      isActive: isActive,
      expireDate: expiryDate,
    };

    if (
      numberOfBookings &&
      orderFrequency &&
      couponCode &&
      discountAmount &&
      expiryDate
    ) {
      console.log("DATA", data);

      NewHomService.AddRewardCoupon(data)
        .then((res) => {
          // console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllData();
            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!numberOfBookings) {
        return toast.error("Enter numbner of booking");
      }
      if (!orderFrequency) {
        return toast.error("Choose order frequency");
      }
      if (!couponCode) {
        return toast.error("Coupon Code is mandatory");
      }
      if (!discountAmount) {
        return toast.error("Discount Amount is mandatory");
      }
      if (!expiryDate) {
        return toast.error("Expiry Date is mandatory");
      }
    }
    console.log("errorList", errorList);
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      wrap: true,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Number Of Order(s)
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {row.NumberOfOrders} ({row.OrderFrequency})
          </>
        );
      },
      wrap: true,
      width: "150px",
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Category
    //     </div>
    //   ),
    //   selector: (row) => row.Category,
    //   wrap: true,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Sub Category
    //     </div>
    //   ),
    //   selector: (row) => row.Subcategory,
    //   wrap: true,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Service
    //     </div>
    //   ),
    //   selector: (row) => row.Service,
    //   wrap: true,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Coupon Code
        </div>
      ),
      selector: (row) => {
        return row.isActive ? (
          <>
            <strong>{row.CouponCode}</strong>
          </>
        ) : (
          <>{row.CouponCode}</>
        );
      },
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Discount
        </div>
      ),
      selector: (row) => {
        return <>{row.DiscountPercentage}%</>;
      },
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Active
        </div>
      ),
      selector: (row) => {
        return row.isActive ? "Yes" : "No";
      },
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Expiry Date
        </div>
      ),
      selector: (row) => moment(row.ExpiryDate).format("DD-MM-YYYY"),
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const updateData = () => {
    let data = {
      limit_service: numberOfBookings,
      service_timing: orderFrequency,
      couponCode: couponCode,
      discountAmount: discountAmount,
      isActive: isActive,
      expireDate: expiryDate,
    };

    if (
      numberOfBookings &&
      orderFrequency &&
      couponCode &&
      discountAmount &&
      expiryDate
    ) {
      //   console.log("UPDATEDATA", data);
      NewHomService.UpdateRewardCoupon(id, data)
        .then((res) => {
          if (res && res.status) {
            toast.success("Updated Successfully");
            setHide(true);
            fetchAllData();
            resetState();
          } else {
            if (res.message == "Server error,please try again") {
              toast.error("Coupon code already used");
            } else {
              toast.error(res.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!couponCode) {
        toast.error("Coupon Code is mandatory");
      }
      if (!discountAmount) {
        toast.error("Discount Amount is mandatory");
      }
      if (!expiryDate) {
        toast.error("Expiry Date is mandatory");
      }
      // if (!serviceId) {
      //   toast.error("Choose Service");
      // }
      // if (!subcategory_id) {
      //   toast.error("Choose Sub Category");
      // }
      if (!category_id) {
        toast.error("Choose Category");
      }
    }
  };
  const createCode = () => {
    setCouponCode(generateCouponCode(7));
  };

  const [errorList, setErrorList] = useState({});

  const checkPercentage = (e) => {
    return e.target.value < 0 || e.target.value > 100
      ? (setErrorList({ discountAmount: "Invalid Input" }),
        setDiscountAmount(0))
      : setDiscountAmount(e.target.value);
  };

  const checkNumberOfOrder = (e) => {
    return e.target.value < 0 || e.target.value > 100
      ? setNumberOfBookings(0)
      : setNumberOfBookings(e.target.value);
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Reward Coupon
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Reward Coupon
              </div>
            )}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span>:
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  getAllCategoryinService_type(e.target.value);
                }}
                disabled
                value={service_type_id}
              >
                <option value="">Choose...</option>
                {totalService_type?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Category <span style={{ color: "red" }}>*</span>:
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  getAllSubCategory(e.target.value);
                }}
              >
                <option value="">Choose...</option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Sub Category:
              </label>
              <select
                onChange={(e) => {
                  setsubcategory_id(e.target.value);
                  getAllServicesByCategory(e.target.value);
                }}
              >
                <option value="">Choose...</option>
                {totalSubcategory?.map((ele, id) => {
                  // console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Service:
              </label>
              <select
                onChange={(e) => {
                  setServiceId(e.target.value);
                }}
              >
                <option value="">Choose...</option>
                {totalServices?.map((ele, id) => {
                  // console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == serviceId ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.title}
                    </option>
                  );
                })}
              </select>
            </div> */}

            <div className="row">
              <div className="col-3">
                {/* <div class="form-group">
                  <label for="exampleInputEmail1">
                    Number of Order(s) <span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    placeholder="0"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={discountAmount}
                    onChange={(e) => checkPercentage(e)}
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger _text-muted"
                  >
                    {errorList.discountAmount}
                  </small>
                </div> */}
                <label for="exampleInputEmail1">
                  Number of Order(s)<span style={{ color: "red" }}>*</span> :
                </label>
                <div class="input-group mb-3">
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    placeholder="0"
                    value={numberOfBookings}
                    onChange={(e) => checkNumberOfOrder(e)}
                  />
                  <div class="input-group-append">
                    <select
                      onChange={(e) => {
                        setOrderFrequency(e.target.value);
                        // getAllServicesByCategory(e.target.value);
                      }}
                    >
                      <option value="">--Choose--</option>
                      <option
                        value="monthly"
                        selected={orderFrequency === "monthly" ? true : false}
                      >
                        Monthly
                      </option>
                      <option
                        value="yearly"
                        selected={orderFrequency === "yearly" ? true : false}
                      >
                        Yearly
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                    Coupon Code<span style={{ color: "red" }}>*</span> :{" "}
                    <button
                      onClick={createCode}
                      className="btn btn-sm btn-secondary"
                      style={{ padding: "0 5px", fontSize: "10px" }}
                    >
                      Generate
                    </button>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger _text-muted"
                  >
                    {errorList.couponCode}
                  </small>
                </div>
              </div>

              <div className="col-3">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                    Discount Percentage<span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    placeholder="0"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={discountAmount}
                    onChange={(e) => checkPercentage(e)}
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger _text-muted"
                  >
                    {errorList.discountAmount}
                  </small>
                </div>
              </div>
              <div
                className="col-3
              "
              >
                <div class="form-group">
                  <label for="exampleInputEmail1">
                    Expiry Date<span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                  />
                  <small
                    id="emailHelp"
                    class="form-text text-danger _text-muted"
                  >
                    {errorList.expiryDate}
                  </small>
                </div>
              </div>
            </div>

            <div class="form-check mb-3">
              <input
                class="form-check-input"
                value={chkVal}
                type="checkbox"
                onClick={(e) => handleChecked(e)}
                checked={isActive}
              />
              <label class="form-check-label">
                {isActive ? "Uncheck and De-activate" : "Check and Activate"}
              </label>
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={addData}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={updateData}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Reward Coupon(s)
            </div>
            <DataTable
              columns={columns}
              data={allData}
              pagination
              noDataComponent="No Coupons are available"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageRewardCoupons;
