import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Loader from '../../Component/Loader';
import HttpClientXml from '../../Utils/HttpClientXml';
import toast from 'react-hot-toast';

const WorkTime = () => {
    const [filteredData, setFilteredData] = useState([]);
    const [Name, SetName] = useState('');
    const [Id, SetID] = useState('');

    const [loader, setLoader] = useState(false);
    const [hide, setHide] = useState(true);
    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            wrap: true,
            center: true,
            width: "125px",
        },

        {
            name: (
                <div style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder", margin: "auto" }}>
                    Name
                </div>
            ),
            selector: (row) => <h4 className='' style={{ textAlign: 'center' }}>{row.Name}</h4>,
            wrap: true,
            center: true,
            width: "45rem",
        },
        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Edit/Delete
                </div>
            ),
            selector: (row) => row.action,
            // width: "150px",
            wrap: true,
            center: true,
        },
    ];

    console.log(Id, "ID")
    useEffect(() => {
        fetchAllData()
    }, [])
    const fetchAllData = async () => {
        const res = await HttpClientXml.requestData(
            "view-work-timing",
            "GET"
        );
        console.log(res, "resdata")
        let apiData = [];
        if (res && res?.status) {
            console.log(res?.data, "sdefee");
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                Name: item?.name,

                action: <>
                    <div className=' d-flex gap-3'>
                        <div

                            onClick={() => { SetName(item?.name); setHide(false); SetID(item?._id) }}
                            type="button"
                        >
                            <i className="fa-solid fa-pen-to-square" style={{ color: '#0c43a1', fontSize: "20px" }}></i>


                        </div>
                        <div

                            // onClick={(e) => ApproveUpdate(e, item?.vendorId)}
                            type="button"
                            onClick={() => handleDelete(item?._id)}
                        >
                            <span className="fa-regular fa-trash-can" style={{
                                color: '#af2323', fontSize: "20px"
                            }}></span>

                        </div>
                    </div>
                </>





            }));
        }
        setFilteredData(apiData);

    };

    const handleSUbmit = async (e) => {
        e.preventDefault();
        if (!Name) {
            return toast.error("Empty Input Detected")
        }
        const res = await HttpClientXml.requestData("add-work-timing", { name: Name }, "POST")
        if (res && res.status) {
            toast.success(res.message)
            fetchAllData()
            SetName("")
        }
        else {
            toast.error(res.message)
        }
    }
    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!Name) {
            return toast.error("Empty Input Detected")
        }
        const res = await HttpClientXml.requestData("update-work-timing/" + Id, { name: Name }, "PUT")
        if (res && res.status) {
            toast.success(res.message)
            fetchAllData()
            SetName("")
            setHide(true)
        }
        else {
            toast.error(res.message)
        }
    }
    const handleDelete = async (id) => {

        const res = await HttpClientXml.requestData("delete-work-timing/" + id, {}, "DELETE")
        if (res && res.status) {
            toast.success(res.message)
            fetchAllData()
            SetName("")
            setHide(true)
        }
        else {
            toast.error(res.message)
        }
    }

    return (
        <div component="div" className="TabsAnimation appear-done enter-done">
            <div className="main-card mb-3 card">
                <div className="card-body">
                    {hide ? (
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Add Work Time
                        </div>
                    ) : (
                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Edit Work Time
                        </div>
                    )}


                    <div class="form-group">
                        <label for="exampleInputEmail1">
                            Working Time<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={Name}
                            onChange={(e) => {
                                SetName(e.target.value);
                            }}
                        />

                    </div>

                    {hide ? (
                        <button class="btn btn-primary mb-4" type="submit" onClick={(e) => handleSUbmit(e)}>
                            Submit
                        </button>
                    ) : (
                        <button class="btn btn-warning mb-4" type="submit" onClick={(e) => handleUpdate(e)}>
                            Update
                        </button>
                    )}
                    {loader ? (
                        <Loader />
                    ) : (
                        <>
                            {/* <CSVLink
                    className="btn btn-sm btn-info"
                    filename={`subcategory-list${moment().format("YYYYMMDD")}.csv`}
                    data={csvData}
                  >
                    Export to CSV
                  </CSVLink> */}
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 100]}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default WorkTime