import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link, useParams } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const HelpAndSupport = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allState, setAllState] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCountry();
    FetchData();
  }, []);

  const FetchData = async () => {
    // const res = await NewHomService.FetchData();
    const res = await NewHomService.FetchHelpAndSupport();

    if (res && res?.status) {
      // setLoader(false)

      const pageData = res?.data
      console.log("DATA39", pageData);
      setPhoneNumber(pageData?.phoneNumber);
      setEmail(pageData?.email);
    }
  };



  const manageData = () => {
    let data = {
      Type: "vendor",
      phoneNumber: phoneNumber,
      email: email,
      // image: image
    };

    if (phoneNumber && email) {
      console.log();
      NewHomService.UpdateHelpAndSupport(data)
        .then((res) => {
          // console.log("Response Add Blog Category", res);
          if (res && res.status) {
            toast.success(res.message);
            FetchData();
          } else {
            toast.error(res.message);
            // toast.error("L195")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!phoneNumber) {
        toast.error("Phone number is mandatory");
      }
      if (!email) {
        toast.error("Email address is mandatory");
      }
    }
  };


  //         phoneNumber: phoneNumber,
  //         description: description,
  //         imageurl: image

  //     }
  //     HomeService.UpdateOfferserviceimage(id, data)
  //         .then((res) => {
  //             console.log("updated res", res);
  //             if (res && res.status) {
  //                 toast.success("Updated Successfully");
  //                 setHide(true);
  //                 console.log("RESPONSEDELETE", res);
  //                 setPhoneNumber("");
  //                 //   getAllCountry();
  //             } else {
  //                 toast.error("Blog Category Name Already Exist");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-phoneNumber"
            >
              Help and Support
            </div>

            <div className="row">

            <div class="form-group col-6">
              <label for="exampleInputEmail1">
                Phone Number <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div class="form-group col-6">
              <label for="exampleInputEmail1">
              Email Address <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            </div>

            

            

            {/* <div class="form-group">
                            <label for="formFile" class="form-label">
                                Image<span style={{ color: "red" }}>*</span> :
                            </label>
                            {image && (
                                <>
                                    <img
                                        style={{
                                            // height: "50%",
                                            width: "50%",
                                            marginTop: "12px",
                                            borderRadius: "9px",
                                            marginBottom: "1em"
                                        }}
                                        src={image}
                                    />
                                </>
                            )}
                            <input
                                id="images"
                                onChange={imageHandler}
                                class="form-control"
                                type="file"
                                accept="image/*"
                            />

                            
                        </div> */}

            {/* {hide ? ( */}
            <button class="btn btn-primary" onClick={manageData}>
              Update
            </button>
            {/* ) : (
                            <button class="btn btn-primary" onClick={UpdateCountryCategory}>
                                Update
                            </button>
                        )} */}

            {/* <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-phoneNumber"
            >
              MANAGE  Service Offer 
            </div> */}
            {/* <DataTable columns={columns} data={allState} pagination /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpAndSupport;
