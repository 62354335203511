import React from 'react'
import AddAMCprice from '../View/AMCprice/AddAMCprice'
import CleaningPrice from '../View/ModelType/CleaningPrice'
import HomeAppliences from '../View/ModelType/HomeAppliences'
import ServiceBrand from '../View/ModelType/ServiceBrand'
import CCTVTechnology from '../View/ModelType/CCTVTechnology'
import PieChart from '../View/ModelType/PieChart'
import PriceDescription from '../View/ModelType/PriceDescription'

function Testing() {
  return (
    // <AddAMCprice/>
    // <CleaningPrice/>
    // <HomeAppliences/>
    // <ServiceBrand/>
    // <CCTVTechnology/>
    // <PieChart />
    <PriceDescription/>
  )
}

export default Testing