import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import ImageLoader from "../Loader/ImageLoader";
import NewHomService from "../../Service/NewHomService";
import Loader from "../../Component/Loader";
import { CSVLink } from "react-csv";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import HomeService from "../../Service/HomeService";

const Service = () => {
  const [name, setName] = useState("");
  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");
  const [duration, setduration] = useState("");
  const [actualPrice, setactualPrice] = useState("");
  const [discountPrice, setdiscountPrice] = useState("");
  const [summary, setsummary] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [allData, setAllData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [searchCategory, setSearchCategory] = useState("")

  const [searchSubCategory, setSearchSubCategory] = useState("")
  const [searchService, setSearchService] = useState("")

  const [service_type_id, setservice_type_id] = useState("");

  const [totalService_type, settotalService_type] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);
  const [totalSubcategory, settotalSubcategory] = useState([]);

  const [singledata, setsingleData] = useState({})

  console.log("2data", subcategory_id);
  const [fquestions, setfquestions] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  // const [image, setimage] = useState("")

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCategory();
    // getAllSubCategory()
    fetchAllService();
    getAllService_type();
    getAllServiceTags()
    setservice_type_id("64d60270203bf4c1d37b6a1e");
    getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
    onCustomFilter("64d60270203bf4c1d37b6a1e", "", "")

    // if (category_id) {
    //     getAllSubCategory(category_id)
    // }
  }, []);

  useEffect(() => {
    fetchCSVData();
  }, [filteredData])

  // useEffect(()=>{  
  //    service_type_id && getAllCategoryinService_type(service_type_id)
  //   },[service_type_id,])

  const resetState = () => {
    setName("");
    setactualPrice("");
    setdiscountPrice("");
    setsummary("");
    setcategory_id("");
    setsubcategory_id("");
    setAllData("");
    setduration("");
    setNoteValues([initialNote])
    setHowToServiceWork([initialHowToServiceWork])
    setIncludesValues([initialIncludes])
    setExcludesValues([initialExcludes])
    setSpecialTagArrayDetail([])
    setimage("")
    let file = document.querySelector("#images")
    file.value = ""

  };

  const [specialTagArrayDetailEdit, setSpecialTagArrayDetailEdit] = useState([])

  const onEdit = (item) => {
    // getAllService_type();
    // getAllCategoryinService_type(item?.serviceTypeId)
    window.scroll(0, 0)
    // console.log("itemee", item);
    setSpecialTagArrayDetail(item?.specialTagArray)

    getAllCategoryinService_type(item?.serviceTypeId);
    getAllSubCategory(item.categoryID)
    setsingleData(item)
    setservice_type_id(item?.serviceTypeId);



    setName(item?.title);
    setactualPrice(item?.actualPrice);
    setdiscountPrice(item?.discountPrice);
    setsummary(item?.summary);

    setId(item?._id);
    setcategory_id(item.categoryID);

    setsubcategory_id(item.subCategoryID);
    // setimage(IMAMGE_URL + item.serviceImage[0]);
    setimage(item.serviceImage[0]);
    setimgdata(item.serviceImage[0]);
    setduration(item?.duration);

    // setcategory_id(item.countryId._id);
    setfquestions(item?.frequentlyQuestions);

    item?.note && setNoteValues(item?.note)

    item?.howToServiceWork && setHowToServiceWork(item?.howToServiceWork)
    item?.includes && setIncludesValues(item?.includes)
    item?.excludes && setExcludesValues(item?.excludes)

    setHide(false);

    // console.log("TDFRS", item?.specialTagArrayDetail);
  };

  // console.log("TDFRS", specialTagArrayDetailEdit);

  const onDelete = (id) => {

    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.Delete_Service(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllService();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // const organiseData = () => {
  //     let temarr = [...questio_ans]
  //     const tempobj = {
  //         question: question,
  //         answer: ans
  //     }
  //     temarr.push(tempobj);
  //     setquestio_ans(temarr)
  //     setquestion('')
  //     setans('')
  // }

  const addQuestion = () => {
    setfquestions([...fquestions, { question: "", answer: "" }]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...fquestions];
    updatedQuestions[index][field] = value;
    setfquestions(updatedQuestions);
  };
  const deleteQuestion = (index) => {
    const updatedQuestions = fquestions.filter((_, i) => i !== index);
    setfquestions(updatedQuestions);
  };

  const [loader, setLoader] = useState(false)
  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          settotalSubcategory(res?.data);
        } else {
          settotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllService = () => {
    HomeService.Manage_Service()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.sort().reverse().map((item, index) => {
            return {
              sl: index + 1,
              Name: item?.title,
              // actualPrice: item?.actualPrice,
              // discountPrice: item?.discountPrice,
              duration: item?.duration,
              sId: item?.serviceTypeId,
              icon: item?.serviceImage,
              ServiceType: item?.serviceTypeDatas,
              Category: item?.CategoryDatas,
              Subcategory: item?.subCategoryDatas,
              SpecialTags: item?.specialTagArrayDetail,


              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res?.data);
      setimage(res?.doc?.url);
      setimgdata(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const AddService = () => {
    const temparr = [];
    temparr.push(imgdata);
    let data = {
      serviceTypeId: service_type_id,
      categoryID: category_id,
      // subCategoryID: subcategory_id,
      title: name,
      // actualPrice: actualPrice,
      // discountPrice: discountPrice,
      summary: summary,
      specialTagArray: specialTagArrayDetail,

      serviceProcess: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      frequentlyQuestions: fquestions.slice(0, fquestions.length - 1),
      serviceImage: temparr,
      duration: duration,
      note: noteValues,
      howToServiceWork: howToServiceWork,
      includes: includesValues,
      excludes: excludesValues,
    };

    if (subcategory_id) {
      data['subCategoryID'] = subcategory_id;
    }
    // console.log("LINE399", data);

    if (
      name &&
      service_type_id &&
      category_id
    ) {
      HomeService.add_Service(data)
        .then((res) => {
          if (res && res.status) {
            toast.success(res.message);
            fetchAllService();
            // onCustomFilter(service_type_id, category_id, subcategory_id)
            resetState();
          } else {
            toast.error(res.message);
            // fetchAllService();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err && err.status) {
            toast.success(err.message);
            fetchAllService();
            // onCustomFilter(service_type_id, category_id, subcategory_id)
            resetState();
          } else {
            toast.error(err.message);
            // fetchAllService();
          }
        });
    } else {

      if (!service_type_id) {
        return toast.error("Service Type Name Field Is Required");
      }

      if (!category_id) {
        return toast.error("Category Name Field Is Required");
      }
      // if (!actualPrice) {
      //   return toast.error("ActualPrice Name Field Is Required");
      // }
      // if (!subcategory_id) {
      //   return toast.error("SubCategory Name Field Is Required");
      // }
      if (!name) {
        return toast.error("Service Name Field Is Required");
      }
      if (!imgdata) {
        return toast.error("Service Image Field Is Required");
      }
      // if (!discountPrice) {
      //   return toast.error("Price  Field Is Required");
      // }
      if (!summary) {
        return toast.error("Summary  Field Is Required");
      }
      if (!fquestions) {
        return toast.error("FrequentlyQuestions  Field Is Required");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width: "75px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.ServiceType,
      wrap: true,
      width: "150px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.Category,
      wrap: true,
      width: "150px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Sub Category
        </div>
      ),
      selector: (row) => row.Subcategory,
      wrap: true,
      width: "150px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Icon
        </div>
      ),
      selector: (row) => <img style={{ width: "100%" }} src={row.icon} />,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Name
        </div>
      ),
      selector: (row) => row.Name,
      wrap: true,
      width: "150px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Duration
        </div>
      ),
      selector: (row) => row.duration,
      wrap: true,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       ActualPrice
    //     </div>
    //   ),
    //   selector: (row) => row.actualPrice,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Actual Price
    //     </div>
    //   ),
    //   selector: (row) => row.discountPrice,
    //   wrap: true,
    // },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Special Tags
        </div>
      ),
      selector: (row) => {
        return (
          row?.SpecialTags.length > 0 ? (
            <>
              <ul className="list-group my-2">
                {row.SpecialTags.map((item, key) => {
                  return (
                    <>
                      <li className="list-group-item">{item.name}</li>
                    </>
                  );
                })}
              </ul>
            </>) : (
            <>
              n/a
            </>
          )
        );
      },
      width: "200px",
      wrap: true,
      alignItems: "top",
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const UpdateStateService = () => {
    const temparr = [];
    temparr.push(imgdata);

    console.log("ID", id);
    let data = {
      serviceTypeId: service_type_id,
      categoryID: category_id,
      // subCategoryID: subcategory_id,
      title: name,
      // actualPrice: actualPrice,
      // discountPrice: discountPrice,
      summary: summary,
      specialTagArray: specialTagArrayDetail,

      serviceProcess: [
        {
          title: "",
          description: "",
          image: "",
        },
      ],
      frequentlyQuestions: fquestions,
      serviceImage: temparr,
      duration: duration,
      note: noteValues,
      howToServiceWork: howToServiceWork,
      includes: includesValues,
      excludes: excludesValues,
    };
    if (subcategory_id) {
      data['subCategoryID'] = subcategory_id;
    }
    console.log("data582", data);
    // return
    HomeService.Update_Service(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          // console.log("RESPONSEDELETE", res);
          fetchAllService();
          onCustomFilter(service_type_id, category_id, subcategory_id)
          resetState();
        } else {
          toast.error("Error occured");
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const [onEditing, setOnEditing] = useState(false)
  const initialHowToServiceWork = {
    title: "",
    description: "",
    image: ""
  };

  const [howToServiceWork, setHowToServiceWork] = useState([initialHowToServiceWork]);
  const handleChange = (i, e) => {
    let newFormValues = [...howToServiceWork];
    newFormValues[i][e.target.name] = e.target.value;
    setHowToServiceWork(newFormValues);
  };


  const [serviceWorkImageLoader, setServiceWorkImageLoader] = useState(false)
  const handleServiceWorkImage = async (i, e) => {
    setServiceWorkImageLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log('L86:', data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);

    if (res && res.status) {
      console.log("UploadImageRes", res);
      // setBannerImage(res?.url);
      let newFormValues = [...howToServiceWork];
      newFormValues[i].image = res?.doc?.url;;
      setHowToServiceWork(newFormValues);
      // console.log("L60: ",howToServiceWork[0].image);
    } else {
      toast.error(res?.message);
    }
    setServiceWorkImageLoader(false);
  };
  const addServiceWork = () => {
    // alert(howToServiceWork.length)
    if (howToServiceWork?.length < 5000) {
      setHowToServiceWork([...howToServiceWork, initialHowToServiceWork]);
    } else {
      Swal("")
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeServiceWork = (i) => {
    let newFormValues = [...howToServiceWork];
    newFormValues.splice(i, 1);
    setHowToServiceWork(newFormValues);
  };


  // Notes ///////////////
  const initialNote = [""];

  const [noteValues, setNoteValues] = useState(initialNote);

  const handleNoteChange = (i, e) => {
    let newFormValues = [...noteValues];
    newFormValues[i] = e.target.value;
    setNoteValues(newFormValues);
  };


  const addNotes = () => {
    if (noteValues.length < 5000) {
      setNoteValues([...noteValues, initialNote]);
    } else {
      Swal("")
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeNotes = (i) => {
    let newFormValues = [...noteValues];
    newFormValues.splice(i, 1);
    setNoteValues(newFormValues);
  };

  // //Notes ///////////////

  // Includes ///////////////
  const initialIncludes = [""];

  const [includesValues, setIncludesValues] = useState(initialIncludes);

  const handleIncludesChange = (i, e) => {
    let newFormValues = [...includesValues];
    newFormValues[i] = e.target.value;
    setIncludesValues(newFormValues);
  };


  const addIncludes = () => {
    if (includesValues.length < 5000) {
      setIncludesValues([...includesValues, initialIncludes]);
    } else {
      Swal("")
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeIncludes = (i) => {
    let newFormValues = [...includesValues];
    newFormValues.splice(i, 1);
    setIncludesValues(newFormValues);
  };

  // //Includes ///////////////

  // Excludes ///////////////
  const initialExcludes = [""];

  const [excludesValues, setExcludesValues] = useState(initialExcludes);

  const handleExcludesChange = (i, e) => {
    let newFormValues = [...excludesValues];
    newFormValues[i] = e.target.value;
    setExcludesValues(newFormValues);
  };


  const addExcludes = () => {
    if (excludesValues.length < 5000) {
      setExcludesValues([...excludesValues, initialExcludes]);
    } else {
      Swal("")
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeExcludes = (i) => {
    let newFormValues = [...excludesValues];
    newFormValues.splice(i, 1);
    setExcludesValues(newFormValues);
  };

  // //Excludes ///////////////

  const onFilter = () => {
    let searchData = allData;
    console.log("GSGS796", allData);
    //Service Type
    if (searchText !== "") {
      searchData = searchData.filter((item) => {
        if (item?.ServiceType?.toLowerCase().includes(searchText.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //subcategory
    if (searchSubCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Subcategory?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //service
    if (searchService !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Name?.toLowerCase().includes(searchService.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  }

  const viewAllData = () => {
    setSearchText("")
    setSearchCategory("")
    setSearchSubCategory("")
    setSearchService("")
    setFilteredData(allData)
  }

  const checkPrice = (e) => {

    e.target.value < 0 ? (toast.error("Price should not be negative")) : (
      setdiscountPrice(e.target.value)
    )
  }

  const getAllServiceTags = async () => {
    NewHomService.FetchServiceTags()
      .then((res) => {
        console.log("XXDES", res);
        if (res && res?.status) {
          setServiceCategories(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [serviceCategories, setServiceCategories] = useState([])
  const [specialTagArrayDetail, setSpecialTagArrayDetail] = useState([])

  const dataTypes = ["1", "3"]



  // let specialcategoryTypes = []
  const handleCheckboxChange = (e) => {
    // console.log("DDDDD", e.target.value);
    // Destructuring
    const { id, value, checked } = e.target;
    if (checked) {
      // specialcategoryTypes.push(value)
      setSpecialTagArrayDetail([...specialTagArrayDetail, value]);
    }
    else {
      // specialcategoryTypes = specialcategoryTypes.filter((item) => item != value)
      let newFormValues = [...specialTagArrayDetail];
      console.log("SERDS", id);
      const key = specialTagArrayDetail.indexOf(value);
      newFormValues.splice(key, 1);
      setSpecialTagArrayDetail(newFormValues);
    }
    // setSpecialTagArrayDetail(specialcategoryTypes)
    // console.log("SERSER", specialcategoryTypes);
  };

  const onCustomFilter = (s_id, c_id, sc_id) => {
    setLoader(true)
    HomeService.Manage_Service()
      .then((res) => {
        console.log("SERE862", res.data);
        if (res && res?.status) {
          setLoader(false)
          let arr = []
          if (s_id && c_id && sc_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.serviceTypeId
                  .includes(s_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.categoryID
                  .includes(c_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.subCategoryID
                  .includes(sc_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  Name: item?.title,
                  actualPrice: item?.actualPrice,
                  discountPrice: item?.discountPrice,
                  duration: item?.duration,
                  sId: item?.serviceTypeId,
                  icon: item?.serviceImage,
                  ServiceType: item?.serviceTypeDatas,
                  Category: item?.CategoryDatas,
                  Subcategory: item?.subCategoryDatas,
                  SpecialTags: item?.specialTagArrayDetail,


                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })

          } else if (s_id && c_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.serviceTypeId
                  .includes(s_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.categoryID
                  .includes(c_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  Name: item?.title,
                  actualPrice: item?.actualPrice,
                  discountPrice: item?.discountPrice,
                  duration: item?.duration,
                  sId: item?.serviceTypeId,
                  icon: item?.serviceImage,
                  ServiceType: item?.serviceTypeDatas,
                  Category: item?.CategoryDatas,
                  Subcategory: item?.subCategoryDatas,
                  SpecialTags: item?.specialTagArrayDetail,


                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else if (s_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.serviceTypeId
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  Name: item?.title,
                  actualPrice: item?.actualPrice,
                  discountPrice: item?.discountPrice,
                  duration: item?.duration,
                  sId: item?.serviceTypeId,
                  icon: item?.serviceImage,
                  ServiceType: item?.serviceTypeDatas,
                  Category: item?.CategoryDatas,
                  Subcategory: item?.subCategoryDatas,
                  SpecialTags: item?.specialTagArrayDetail,


                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else {
            arr = res?.data?.sort().reverse().map((item, index) => {
              return {
                sl: index + 1,
                Name: item?.title,
                actualPrice: item?.actualPrice,
                discountPrice: item?.discountPrice,
                duration: item?.duration,
                sId: item?.serviceTypeId,
                icon: item?.serviceImage,
                ServiceType: item?.serviceTypeDatas,
                Category: item?.CategoryDatas,
                Subcategory: item?.subCategoryDatas,
                SpecialTags: item?.specialTagArrayDetail,


                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          }




          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  ////CSV Download//////////////

  const [csvData, setCsvData] = useState([]);


  const fetchCSVData = () => {
    // NewHomService.FetchAllUsers()
    //   .then((res) => {
    //     if (res && res?.status) {
    // setLoader(false);
    console.log("DDDR1188", filteredData);
    let arr = filteredData
      ?.map((item, index) => {

        return {
          SLNO: index + 1,
          ServiceType: item?.ServiceType ? item?.ServiceType : "N/A",
          Category: item?.Category ? item?.Category : "N/A",
          SubCategory: item?.Subcategory ? item?.Subcategory : "N/A",
          ServiceName: item?.Name ? item?.Name : "N/A",
          Price: item?.discountPrice ? item?.discountPrice : "N/A",
          Duration: item?.duration ? item?.duration : "N/A",
          Category: item?.Category ? item?.Category : "N/A",
          // SpecialTags: item?.SpecialTags? (<>
          // {
          //   item?.SpecialTags.map((item,index)=>{
          //     return (
          //       <>
          //       {item?.name}
          //       </>
          //     )
          //   })
          // }
          // </>) : (<></>),

        };
      });
    console.log("DATASSSS1215", arr);
    setCsvData(arr);
    //   }
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });
  };

  ////CSV Download//////////////

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Service
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Service
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  getAllCategoryinService_type(e.target.value);
                  onCustomFilter(e.target.value, "", "")

                }}
              // value={service_type_id}
              >
                <option value="" >
                  Choose...
                </option>
                {totalService_type?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                      disabled={ele._id !== "64d60270203bf4c1d37b6a1e" && true}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  getAllSubCategory(e.target.value);
                  onCustomFilter(service_type_id, e.target.value, "")
                }}
              >
                <option value="" >
                  Choose...
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Sub Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {
                setsubcategory_id(e.target.value)
                onCustomFilter(service_type_id, category_id, e.target.value)
              }}>
                <option value="" >
                  Choose...
                </option>
                {totalSubcategory?.map((ele, id) => {
                  console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Duration<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={duration}
                onChange={(e) => setduration(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="formFile" class="form-label">
                Service Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />

              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div>


            {/* <div class="form-group" style={{ display: "none" }}>
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={actualPrice}
                onChange={(e) => checkPrice(e)}
              />
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                placeholder="0"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={discountPrice}
                onChange={(e) => checkPrice(e)}
              />
            </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Summary<span style={{ color: "red" }}>*</span> :
              </label>
              {/* <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summary}
                onChange={(e) => setsummary(e.target.value)}
              /> */}

              <div style={{ marginBottom: '21px' }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    placeholder: "Enter content here",
                    toolbar: [
                      "undo",
                      "redo",
                      "bold",
                      "blockQuote",
                      "heading",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                    ],
                  }}
                  data={summary}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor);
                  }}

                  onChange={(event, editor) => {

                    let data = editor.getData();

                    setsummary(data);

                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
            </div>


            {/* Note */}

            <div className="row" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
              <div className="col-lg-12">
                {/* <form> */}
                <label for="exampleInputEmail1">
                  Note :
                </label>

                {noteValues?.map((element, index) => (

                  <div
                    // style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} 
                    className="form-inline" key={index}
                  >
                    <div className="form-group mb-2 mt-1">
                      {/* <label for="inputEmail4">
                        Note:
                      </label> */}
                      <input
                        type="text"
                        class="form-control"
                        // name="noteline"
                        placeholder={`Note ${index + 1}`}
                        value={element || ""}
                        onChange={(e) => handleNoteChange(index, e)}
                      />
                    </div>

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeNotes(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}


                <div className="button-section mt-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addNotes()}
                  >
                    <i class="fas fa-plus"></i> Note
                  </button>

                </div>
                {/* </form> */}
              </div>
            </div>

            {/* //Note */}



            {/* Multiple Service Works */}

            <div className="row mt-3" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
              <div className="col-lg-12">
                {/* <form> */}
                <label for="exampleInputEmail1">
                  How Service Works :
                </label>


                {howToServiceWork?.map((element, index) => (

                  <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline" key={index}>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Title:
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="title"
                        placeholder={`Title ${index + 1}`}
                        value={element.title || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>

                    <div className="form-group mb-2 mt-1">
                      <label for="inputEmail4">
                        Description:
                      </label>
                      <textarea class="form-control"
                        name="description"
                        placeholder={`Description ${index + 1}`}
                        value={element.description || ""}
                        onChange={(e) => handleChange(index, e)}
                        rows="3"
                      ></textarea>

                    </div>

                    {/* Image Upload */}
                    <label for="exampleInputEmail1" style={{ marginTop: "1rem" }}>
                      Image :
                    </label>
                    <input
                      class="form-control"
                      onChange={(e) => handleServiceWorkImage(index, e)}
                      type="file"
                      id="thumbnail"
                      accept="image/*"
                    />
                    {serviceWorkImageLoader ? (
                      <>
                        <ImageLoader />
                      </>
                    ) : null}
                    {element.image && (
                      <>
                        <div>
                          <img
                            style={{
                              height: "10%",
                              width: "10%",
                              marginTop: "12px",
                              borderRadius: "5px",
                            }}
                            src={element.image}
                          />

                        </div>
                      </>
                    )}
                    {/* //Image Upload */}

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeServiceWork(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}


                <div className="button-section my-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addServiceWork()}
                  >
                    <i class="fas fa-plus"></i> Service Work
                  </button>

                </div>
                {/* </form> */}
              </div>
            </div>

            {/* //Multiple Service Works */}

            {/* Includes */}

            <div className="row" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
              <div className="col-lg-12">
                {/* <form> */}
                <label for="exampleInputEmail1">
                  Includes :
                </label>

                {includesValues?.map((element, index) => (

                  <div
                    // style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} 
                    className="_form-inline" key={index}>
                    <div className="form-group mb-2 mt-1">
                      {/* <label for="inputEmail4">
                        Includes:
                      </label> */}
                      <input
                        type="text"
                        class="form-control"
                        // name="noteline"
                        placeholder={`Includes ${index + 1}`}
                        value={element || ""}
                        onChange={(e) => handleIncludesChange(index, e)}
                      />
                    </div>

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeIncludes(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}


                <div className="button-section mt-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addIncludes()}
                  >
                    <i class="fas fa-plus"></i> Includes
                  </button>

                </div>
                {/* </form> */}
              </div>
            </div>

            {/* //Includes */}

            {/* Excludes */}

            <div className="row mt-3" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
              <div className="col-lg-12">
                {/* <form> */}
                <label for="exampleInputEmail1">
                  Excludes :
                </label>


                {excludesValues?.map((element, index) => (

                  <div
                    // style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} 
                    className="_form-inline" key={index}>
                    <div className="form-group mb-2 mt-1">
                      {/* <label for="inputEmail4">
                        Excludes:
                      </label> */}
                      <input
                        type="text"
                        class="form-control"
                        // name="noteline"
                        placeholder={`Excludes ${index + 1}`}
                        value={element || ""}
                        onChange={(e) => handleExcludesChange(index, e)}
                      />
                    </div>

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeExcludes(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>

                ))}


                <div className="button-section my-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addExcludes()}
                  >
                    <i class="fas fa-plus"></i> Excludes
                  </button>

                </div>
                {/* </form> */}
              </div>
            </div>

            {/* //Excludes */}

            {/* Service Special Types */}

            <label for="exampleInputEmail1">
              Special Tags :
            </label>
            <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">
              {
                serviceCategories.map((item, index) => {
                  return (
                    <>
                      <div class="form-check-inline" key={index}>
                        <input class="form-check-input" type="checkbox" value={item?._id} id={index}
                          onChange={(e) => handleCheckboxChange(e)}
                          checked={specialTagArrayDetail.includes(item?._id)}
                        />
                        <label class="form-check-label" for="flexCheckDefault">
                          {item?.name}
                        </label>
                      </div>
                    </>
                  )
                })
              }
            </div>



            {/* //Service Special Types */}
            <div className="clearfix mb-2"></div>
            {hide ? (
              <button class="btn btn-primary" onClick={AddService}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateService}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Service
            </div>
            <div class="form-inline my-2">
              <input class="form-control mr-sm-2" type="search" placeholder="Service Type" aria-label="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Sub Category" aria-label="Search"
                value={searchSubCategory}
                onChange={(e) => {
                  setSearchSubCategory(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Service Name" aria-label="Search"
                value={searchService}
                onChange={(e) => {
                  setSearchService(e.target.value)
                }}
              />

              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                onClick={onFilter}
              >Filter</button>
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                onClick={viewAllData}
              >View All</button>
            </div>
            {
              loader ? (<>
                <Loader />
              </>) : (<>
                <CSVLink
                  className="btn btn-sm btn-info"
                  filename={`servive-list${moment().format("YYYYMMDD")}.csv`}
                  data={csvData}
                >
                  Export to CSV
                </CSVLink>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  paginationPerPage={25}
                  paginationRowsPerPageOptions={[25, 50, 100]}
                />
              </>)
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
