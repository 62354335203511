import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import { parseDays } from "../../Utils/Helpers";
import Loader from "../../Component/Loader";

const ManageUserSubscriptionPlans = () => {
    const [subscriptionName, setSubscriptionName] = useState("");
    const [subscriptionPrice, setSubscriptionPrice] = useState("");
    const [activationDate, setActivationDate] = useState(moment().format("YYYY-MM-DD"));
    const [expiryDate, setExpiryDate] = useState(moment().format("YYYY-MM-DD"));
    const [duration, setDuration] = useState("");
    const [offerPercentage, setOfferPercentage] = useState("");

    const [imgdata, setimgdata] = useState("");
    const [image, setimage] = useState("");

    const [category_id, setcategory_id] = useState("");
    const [subcategory_id, setsubcategory_id] = useState("");
    const [serviceId, setServiceId] = useState("")
    const [allData, setAllData] = useState([]);

    const [service_type_id, setservice_type_id] = useState("");

    const [totalService_type, settotalService_type] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);
    const [totalSubcategory, settotalSubcategory] = useState([]);

    const [singledata, setsingleData] = useState({})
    const [totalServices, setTotalServices] = useState([])

    const [isHomePage, setIsHomePage] = useState(false)
    const [chkVal, setChkVal] = useState(1)

    // console.log("2data", subcategory_id);


    // const [image, setimage] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    const [loader, setLoader] = useState(false)

    const [filteredData, setFilteredData] = useState([])
    const [searchCategory, setSearchCategory] = useState("")
    const [searchSubCategory, setSearchSubCategory] = useState("")
    const [searchName, setSearchName] = useState("")


    useEffect(() => {
        getAllService_type();
        setservice_type_id("64d60270203bf4c1d37b6a1e")
        getAllCategoryinService_type("64d60270203bf4c1d37b6a1e")
        fetchAllService();
    }, []);

    // useEffect(()=>{  
    //    service_type_id && getAllCategoryinService_type(service_type_id)
    //   },[service_type_id,])



    const handleChecked = (e) => {
        if (e.target.value == 1) {
            setIsHomePage(true)
            setChkVal(0)
        } else {
            setIsHomePage(false)
            setChkVal(1)
        }
        console.log("CHK", chkVal, isHomePage);
    }

    const resetState = () => {
        setcategory_id()
        setsubcategory_id()
        setSubscriptionName("")
        setSubscriptionPrice(0)
        setOfferPercentage(0)
        setActivationDate(moment().format("YYYY-MM-DD"))
        setExpiryDate(moment().format("YYYY-MM-DD"))
        setDuration(0)
        document.querySelector("#images").value = "";
        setimage("")
    }

    const onEdit = (item) => {

        window.scroll(0, 0)
        console.log("itemee", item);

        getAllService_type();
        setservice_type_id(item?.serviceTypeID);
        getAllCategoryinService_type(item?.serviceTypeID);

        // setcategory_id(item?.serviceCategoryID);
        // getAllSubCategory(item?.serviceCategoryID);
        setMultipleCategories(item?.serviceCategoryID);

        setsubcategory_id(item?.subcategoryID)

        setSubscriptionName(item?.name)
        setSubscriptionPrice(item?.price)
        setOfferPercentage(item?.offerPercentage)
        // setActivationDate(moment(item?.subcategoryName._id).format("YYYY-MM-DD"))
        // setExpiryDate(moment(item?.subcategoryName._id).format("YYYY-MM-DD"))
        setDuration(item?.duration)

        setimgdata(item?.image);
        setimage(item?.image);

        setId(item?._id);

        setHide(false);
    };

    const onDelete = (id) => {

        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteUserSubscriptionPlans(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllService();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    // const organiseData = () => {
    //     let temarr = [...questio_ans]
    //     const tempobj = {
    //         question: question,
    //         answer: ans
    //     }
    //     temarr.push(tempobj);
    //     setquestio_ans(temarr)
    //     setquestion('')
    //     setans('')
    // }

    // const addQuestion = () => {
    //     setfquestions([...fquestions, { question: "", answer: "" }]);
    // };

    // const updateQuestion = (index, field, value) => {
    //     const updatedQuestions = [...fquestions];
    //     updatedQuestions[index][field] = value;
    //     setfquestions(updatedQuestions);
    // };
    // const deleteQuestion = (index) => {
    //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
    //     setfquestions(updatedQuestions);
    // };

    const getAllService_type = async () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalService_type(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllCategoryinService_type = async (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    // console.log(res?.data);
                    settotalCategory(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const getAllCategory = async () => {
    //   HomeService.ManageCategory()
    //     .then((res) => {
    //       console.log("ResAllBlog", res.data);

    //       if (res && res?.status) {
    //         // setLoader(false)
    //         console.log(res?.data);
    //         settotalCategory(res?.data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const getAllSubCategory = async (id) => {
        setLoader(true)
        HomeService.SubCategoriesviacategory(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    setLoader(false)
                    console.log("sub_cat", res?.data);
                    settotalSubcategory(res?.data);
                } else {
                    settotalSubcategory([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const FiterWtR_Category_subCategory = (Catid, subCatid) => {
        console.log(id, filteredData, allData, "ppwwqqkkk")
        if (Catid) {
            const searchData = allData.filter((item) => {
                if (item?.CategoryID === Catid) {
                    return item;
                }
            });
            // console.log("SRCDATA257", searchData);
            setFilteredData(searchData);
        }
        else {
            if (subCatid) {
                const searchData1 = allData.filter((item) => {
                    if (item?.SubcategoryID === subCatid) {
                        return item;
                    }
                });
                // console.log("SRCDATA257", searchData);
                setFilteredData(searchData1);
            }
        }
    }

    const onFilter = () => {
        let searchData = allData;
        console.log("ALLSDATA249", searchCategory);
        //Category
        if (searchCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
                    return item;
                }
            });
            // console.log("SRCDATA257", searchData);
            setFilteredData(searchData);
        }
        //SubCategory
        if (searchSubCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Subcategory?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //Name
        if (searchName !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Name?.toLowerCase().includes(searchName.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
    };


    const getAllServicesByCategory = async (id) => {
        console.log("services:", id);
        NewHomService.FetchServicesByCategory(id)
            .then((res) => {
                console.log("FetchServicesByCategory", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("services:", res);
                    setTotalServices(res?.data);
                } else {
                    setTotalServices();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchAllService = () => {
        setLoader(true)
        NewHomService.FetchUserSubscriptionPlans()
            .then((res) => {
                console.log("Response Data", res.data);
                if (res && res?.status) {
                    setLoader(false)

                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        return {
                            sl: index + 1,
                            ID: item?._id,
                            Status: item?.status,
                            Category: item?.categoryData?.name,
                            Subcategory: item?.subCategoryData?.name,
                            Name: item?.name,
                            Price: item?.price,
                            Duration: item?.duration,
                            Percentage: item?.offerPercentage,
                            // Image: item?.image,
                            CategoryID: item?.categoryData?._id,
                            SubcategoryID: item?.subCategoryData?._id,
                            MultipleCategories: item?.categoryData,
                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    // console.log("RESPONSE", res);
                    setFilteredData(arr)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const imageHandler = async (e) => {
        // setImgLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        console.log("imgdata", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);
        console.log("resImage", res?.doc?.url);
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            console.log(res?.doc?.url);
            setimgdata(res?.doc?.url);
            setimage(res?.doc?.url);
            // setImgLoader(false);
        } else {
            toast.error("Failed to upload Image");
            // setImgLoader(false);
        }
    };

    const addData = () => {
        let data = {
            serviceTypeID: service_type_id,
            // serviceCategoryID: category_id,
            // subcategoryID: subcategory_id,
            serviceCategoryID: multipleCategories,
            name: subscriptionName,
            price: subscriptionPrice,
            duration: duration,
            duration_unit: "day",
            offerPercentage: offerPercentage,
            // activationDate: activationDate,
            // expiryDate: expiryDate,
            // image: imgdata,
        };
        subcategory_id !== "" && (data.subcategoryID = subcategory_id)
        console.log("ADD_DATA", data);

        // return

        if (subscriptionName && subscriptionPrice && offerPercentage && duration) {
            console.log("ADD_DATA", data);

            NewHomService.AddUserSubscriptionPlans(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            if (!category_id) {
                return toast.error("Choose Category");
            }
            if (!subscriptionName) {
                return toast.error("Subscription Name is mandatory");
            }
            if (!subscriptionPrice) {
                return toast.error("Subscription Price is mandatory");
            }
            if (!offerPercentage) {
                return toast.error("Offer Percentage is mandatory");
            }
            if (!duration) {
                return toast.error("Duration is mandatory");
            }
        }
    };

    const changeStatus = (id, val) => {
        let data = {
            status: val
        }
        NewHomService.UpdateUserSubscriptionPlans(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    fetchAllService();
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Categories
                </div>
            ),
            // selector: (row) => row.Category,
            // wrap: true,
            selector: (row) => {
                return (
                    <>
                    <ul>
                    {
                        row.MultipleCategories.map((item, index) => {
                            return (
                                <>
                                    <li>{item.name}</li>
                                </>
                            )
                        })
                    }
                    </ul>
                    </>
                    

                )
            },
            wrap:true,
            width:"200px"
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Sub Category
        //         </div>
        //     ),
        //         selector: (row) => row.Subcategory,
        //             wrap: true,
        //     },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Name
                </div>
            ),
            selector: (row) => row.Name,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Price,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Duration
                </div>
            ),
            selector: (row) => {
                return parseDays(row.Duration)
            },
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Offer %
                </div>
            ),
            selector: (row) => {
                return `${row.Percentage}`
            },
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Status
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                        {
                            row.Status ? (
                                <>
                                    <button
                                        className="btn btn-sm btn-success"
                                        onClick={() => changeStatus(row.ID, false)}
                                    >
                                        Active
                                        <span
                                            style={{ fontSize: "70%", display: "block" }}
                                        >
                                            Click to Deactivate
                                        </span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() => changeStatus(row.ID, true)}
                                    >
                                        Deactive
                                        <span
                                            style={{ fontSize: "70%", display: "block" }}
                                        >
                                            Click to Active
                                        </span>
                                    </button>

                                </>
                            )
                        }


                    </>
                )
            },
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Banner
        //         </div>
        //     ),
        //     selector: (row) => <img style={{ width: "72px", margin: "5px", padding: " 3px" }} src={row.image} alt={""} />,
        // },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const updateData = () => {
        let data = {
            serviceTypeID: service_type_id,
            // serviceCategoryID: category_id,
            // subcategoryID: subcategory_id,
            serviceCategoryID: multipleCategories,
            name: subscriptionName,
            price: subscriptionPrice,
            duration: duration,
            duration_unit: "day",
            offerPercentage: offerPercentage,
            // activationDate: activationDate,
            // expiryDate: expiryDate,
            // image: imgdata,
        };
        subcategory_id !== "" && (data.subcategoryID = subcategory_id)
        console.log("UPDATE_DATA", data);

        if ( subscriptionName && subscriptionPrice && offerPercentage && duration) {
            console.log("DATA", data);
            NewHomService.UpdateUserSubscriptionPlans(id, data)
                .then((res) => {
                    // console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        setHide(true);
                        // console.log("RESPONSEDELETE", res);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error("Error Occured!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!category_id) {
                return toast.error("Choose Category");
            }
            if (!subscriptionName) {
                return toast.error("Subscription Name is mandatory");
            }
            if (!subscriptionPrice) {
                return toast.error("Subscription Price is mandatory");
            }
            if (!offerPercentage) {
                return toast.error("Offer Percentage is mandatory");
            }
            if (!duration) {
                return toast.error("Duration is mandatory");
            }
        }
    };

    const checkPrice = (e) => {

        e.target.value < 0 ? (toast.error("Price should not be negative")) : (
            setSubscriptionPrice(e.target.value)
        )
    }

    const checkDate = (e) => {

        e.target.value < 0 ? (toast.error("Day(s) should not be negative")) : (
            setDuration(e.target.value)
        )
    }

    const checkPercentage = (e) => {
        e.target.value < 0 || e.target.value > 100
            ? toast.error("Invalid percentage value")
            : setOfferPercentage(e.target.value);
    };

    const viewAllData = () => {
        setSearchCategory("")
        setSearchSubCategory("")
        setSearchName("")
        setFilteredData(allData)
    }

    const [multipleCategories, setMultipleCategories] = useState([])

    const handleCheckboxChange = (e) => {
        console.log("DDDDD", e.target.value);
        // Destructuring
        const { id, value, checked } = e.target;
        if (checked) {
            // specialcategoryTypes.push(value)
            setMultipleCategories([...multipleCategories, value]);
        }
        else {
            // specialcategoryTypes = specialcategoryTypes.filter((item) => item != value)
            let newFormValues = [...multipleCategories];
            console.log("SERDS", id);
            const key = multipleCategories.indexOf(value);
            newFormValues.splice(key, 1);
            setMultipleCategories(newFormValues);
        }
        // setMultipleCategories(specialcategoryTypes)
        // console.log("SERSER", specialcategoryTypes);
    };

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add User Subscription
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit User Subscription
                            </div>
                        )}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Type<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_type_id(e.target.value);
                                    getAllCategoryinService_type(e.target.value);
                                }}
                                disabled
                            // value={service_type_id}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalService_type?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == service_type_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    FiterWtR_Category_subCategory(e.target.value, "")

                                    setcategory_id(e.target.value);
                                    e.target.value !== "" && getAllSubCategory(e.target.value);
                                }}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalCategory?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == category_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

                        {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Sub Category :
                            </label>
                            <select onChange={(e) => {
                                FiterWtR_Category_subCategory("", e.target.value)
                                setsubcategory_id(e.target.value)
                                // getAllServicesByCategory(e.target.value);

                            }}>
                                <option value="" >
                                    All Sub Category...
                                </option>
                                {totalSubcategory?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == subcategory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

                        {/* Service Special Types */}

                        <label for="exampleInputEmail1">
                            Categories :
                        </label>
                        <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">
                            {
                                totalCategory.map((item, index) => {
                                    return (
                                        <>
                                            <div class="form-check-inline" key={index}>
                                                <input class="form-check-input" type="checkbox" value={item?._id} id={index}
                                                    onChange={(e) => handleCheckboxChange(e)}
                                                    checked={multipleCategories.includes(item?._id)}
                                                />
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    {item?.name}
                                                </label>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>

                        {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setServiceId(e.target.value)


                            }}>
                                <option value="" >Choose...</option>
                                {totalServices?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == serviceId ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

                        {/* <div class="form-group mb-3">
                            <label for="formFile" class="form-label">
                                Subscription Banner Image:
                            </label>
                            <input
                                id="images"
                                onChange={imageHandler}
                                class="form-control"
                                type="file"
                                accept="image/*"
                            />

                            {image && (
                                <>
                                    <img
                                        style={{
                                            height: "10%",
                                            width: "10%",
                                            marginTop: "12px",
                                            // borderRadius: "9px",
                                        }}
                                        src={image}
                                    />
                                </>
                            )}
                        </div> */}

                        <div className="row">
                            <div className="col-3">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Subscription Name<span style={{ color: "red" }}>*</span> :
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={subscriptionName}
                                        onChange={(e) => setSubscriptionName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <label for="exampleInputEmail1">
                                    Subscription Price<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={subscriptionPrice}
                                    placeholder="0"
                                    onChange={(e) => checkPrice(e)}
                                />
                            </div>
                            <div className="col-3">
                                <label for="exampleInputEmail1">
                                    Offer Percentage<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={offerPercentage}
                                    placeholder="0"
                                    onChange={(e) => checkPercentage(e)}
                                />
                            </div>

                            <div className="col-3">
                                <label for="exampleInputEmail1">
                                    Duration (in Days)<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="number"
                                    min={0}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={duration}
                                    placeholder="0"
                                    onChange={(e) => checkDate(e)}
                                />
                            </div>

                        </div>

                        {/* <div className="row mb-3">
                            <div className="col-6">
                                <label for="exampleInputEmail1">
                                    Activation Date<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="date"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={activationDate}
                                    onChange={(e) => setActivationDate(e.target.value)}
                                />
                            </div>
                            <div className="col-6">
                                <label for="exampleInputEmail1">
                                    Expiry Date<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="date"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={expiryDate}
                                    onChange={(e) => setExpiryDate(e.target.value)}
                                />
                            </div>                           
                        </div> */}

                        {hide ? (
                            <button class="btn btn-primary" onClick={addData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={updateData}>
                                Update
                            </button>
                        )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            User Subscription(s)
                        </div>
                        <div class="form-inline my-2">

                            <input
                                class="form-control mr-sm-2"
                                type="text"
                                placeholder="Category"
                                aria-label="Search"
                                value={searchCategory}
                                onChange={(e) => {
                                    setSearchCategory(e.target.value);
                                }}
                            />

                            <input
                                class="form-control mr-sm-2"
                                type="text"
                                placeholder="Sub Category"
                                aria-label="Search"
                                value={searchSubCategory}
                                onChange={(e) => {
                                    setSearchSubCategory(e.target.value);
                                }}
                            />

                            <input
                                class="form-control mr-sm-2"
                                type="text"
                                placeholder="Name"
                                aria-label="Search"
                                value={searchName}
                                onChange={(e) => {
                                    setSearchName(e.target.value);
                                }}
                            />

                            <button
                                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                                type="submit"
                                onClick={onFilter}
                            >
                                Filter
                            </button>
                            <button
                                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                                type="submit"
                                onClick={viewAllData}
                            >
                                View All
                            </button>
                        </div>
                        {
                            loader ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    noDataComponent="No User Subscription(s) are available"
                                />
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );



};

export default ManageUserSubscriptionPlans;
