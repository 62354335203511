import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import Loader from "../../Component/Loader";
import { useParams } from "react-router-dom";

const SpareParts = () => {
  const { paramservice, pcategory } = useParams()
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const [allCity, setAllCity] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  const [searchServiceType,setSearchServiceType]=useState("")
  const [searchCategory, setSearchCategory] = useState("")
  const [searchSubCategory, setSearchSubCategory] = useState("")
  const [searchService, setSearchService] = useState("")

  const [totalService_type, settotalService_type] = useState([]);
  const [service_type_id, setservice_type_id] = useState([]);
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [totalCategory, settotalCategory] = useState([]);
  const [totalSubcategory, settotalSubcategory] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    fetchAllData();
    getAllService_type()
    setservice_type_id("64d60270203bf4c1d37b6a1e")
    getAllCategory("64d60270203bf4c1d37b6a1e");
    onCustomFilter("64d60270203bf4c1d37b6a1e", "", "")

    if (paramservice) {
      setservice_type_id(paramservice)
      getAllCategory(paramservice)
    } else {
      setservice_type_id("64d60270203bf4c1d37b6a1e")
      getAllCategory();
    }

    if (pcategory) {
      setcategory_id(pcategory);
      setsubcategory_id(pcategory);
    }
  }, []);

  const onEdit = (item) => {
    getAllSubCategory(item.categoryID)
    window.scrollTo(0, 0);
    console.log("item", item);
    setName(item?.name);
    setId(item?._id);




    setservice_type_id("64d60270203bf4c1d37b6a1e")
    getAllCategory("64d60270203bf4c1d37b6a1e")


    setcategory_id(item.categoryID);
    setsubcategory_id(item.subcategoryID);
    setPrice(item.price);
    setHide(false);
  };


  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("kiu", res?.data);
          // paramservice ? (
          //   settotalService_type(res?.data.filter(item => item._id === paramservice))
          // ) : (
          //   settotalService_type(res?.data)
          // )

          settotalService_type(res?.data);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllCategory = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          settotalSubcategory(res?.data);
        } else {
          settotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteSpareparts(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllState();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllState = () => {
    HomeService.ManageState()
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          // setAllState(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const changeStatus = (id, val) => {
    let data = {
      status: val
    }
    console.log("DATAX", data);
    // return
    HomeService.Update_SpareParts(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllData = () => {
    HomeService.ManageSpareparts()
      .then((res) => {
        console.log("AER183", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data?.sort().reverse().map((item, index) => {
            // console.log("item222", item);
            return {
              sl: index + 1,
              Name: item?.name,
              price: item?.price,
              subCategoryName: item?.subCategoryDetail?.name ? item?.subCategoryDetail?.name : "n/a",
              CategoryName: item?.categoryName ? item?.categoryName : "n/a",
              ID: item?._id,
              Status: item?.status,
              ServiceTypeName: item?.categoryDetail.serviceTypeName,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllCity(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddCityCategory = () => {
    let data = {
      categoryID: category_id,
      subcategoryID: subcategory_id,
      name: name,
      price: price,
    };

    if (name && category_id && subcategory_id && price) {
      // alert(name + country_id)
      HomeService.AddSpareparts(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllData();
            onCustomFilter(service_type_id, category_id, subcategory_id)
            setName("");

            setcategory_id("");
            setsubcategory_id("");
            setPrice("");
            // setHide(false);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("SpareParts name Field Is Required");
      }
      if (!category_id) {
        toast.error("Category Name Field Is Required");
      }
      if (!subcategory_id) {
        toast.error("SubCategory name Field Is Required");
      }
      if (!price) {
        toast.error("SpareParts Price Field Is Required");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width:"75px",
      wrap:true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.ServiceTypeName,
      wrap:true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.CategoryName,
      width:"150px",
      wrap:true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Sub Category
        </div>
      ),
      selector: (row) => row.subCategoryName,
      wrap:true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Spare Parts
        </div>
      ),
      selector: (row) => row.Name,
      wrap:true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Price
        </div>
      ),
      selector: (row) =>  row.price,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {
              row.Status ? (
                <>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => changeStatus(row.ID, false)}
                  >
                    Active
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Deactivate
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => changeStatus(row.ID, true)}
                  >
                    Deactive
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Active
                    </span>
                  </button>

                </>
              )
            }


          </>
        )
      },
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const UpdateStateCategory = () => {
    console.log("ID", id);
    let data = {
      categoryID: category_id,
      subcategoryID: subcategory_id,
      name: name,
      price: price,
    };
    HomeService.Update_SpareParts(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          // onCustomFilter(service_type_id, category_id, subcategory_id)
          setName("");
          setcategory_id("");
          setsubcategory_id("");
          setPrice("");

          fetchAllData();
        } else {
          toast.error("Error occured");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkPrice = (e) => {

    e.target.value < 0 ? (toast.error("Price should not be negative")) : (
      setPrice(e.target.value)
    )
  }
  const [loader, setLoader] = useState(false)
  const onCustomFilter = (s_id, c_id, sc_id) => {
    setLoader(true)
    HomeService.ManageSpareparts()
      .then((res) => {
        
        if (res && res?.status) {
          setLoader(false)
          let arr = []
          if (s_id && c_id && sc_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.categoryDetail?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.categoryID
                  .includes(c_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.subcategoryID
                  .includes(sc_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  Name: item?.name,
                  price: item?.price,
                  subCategoryName: item?.subCategoryDetail?.name ? item?.subCategoryDetail?.name : "n/a",
                  CategoryName: item?.categoryName ? item?.categoryName : "n/a",
                  ID: item?._id,
                  Status: item?.status,
                  ServiceTypeName: item?.categoryDetail.serviceTypeName,
    
                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else if (s_id && c_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.categoryDetail?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.categoryID
                  .includes(c_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  Name: item?.name,
                  price: item?.price,
                  subCategoryName: item?.subCategoryDetail?.name ? item?.subCategoryDetail?.name : "n/a",
                  CategoryName: item?.categoryName ? item?.categoryName : "n/a",
                  ID: item?._id,
                  Status: item?.status,
                  ServiceTypeName: item?.categoryDetail.serviceTypeName,
    
                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else if (s_id) {
            // console.log("AER183", s_id);
            arr = res?.data?.filter((item) => {
              if (
                item?.categoryDetail?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                console.log("item672", item);
                return {
                  sl: index + 1,
                  Name: item?.name,
                  price: item?.price,
                  subCategoryName: item?.subCategoryDetail?.name ? item?.subCategoryDetail?.name : "n/a",
                  CategoryName: item?.categoryName ? item?.categoryName : "n/a",
                  ID: item?._id,
                  Status: item?.status,
                  ServiceTypeName: item?.categoryDetail.serviceTypeName,
    
                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else {
            arr = res?.data?.sort().reverse().map((item, index) => {
              // console.log("item", item);
              return {
                sl: index + 1,
                Name: item?.name,
                price: item?.price,
                subCategoryName: item?.subCategoryDetail?.name ? item?.subCategoryDetail?.name : "n/a",
                CategoryName: item?.categoryName ? item?.categoryName : "n/a",
                ID: item?._id,
                Status: item?.status,
                ServiceTypeName: item?.categoryDetail.serviceTypeName,
  
                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          }
          setFilteredData(arr);
        }
        // console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onFilter = () => {
    let searchData = allCity;
    console.log("GSGS751", allCity);

    //service type
    if (searchServiceType !== "") {
      searchData = searchData.filter((item) => {
        if (item?.ServiceTypeName?.toLowerCase().includes(searchServiceType.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }

    //category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.CategoryName?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //subcategory
    if (searchSubCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.subCategoryName?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //service
    if (searchService !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Name?.toLowerCase().includes(searchService.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  }

  const viewAllData = () => {
    setSearchServiceType("")
    setSearchCategory("")
    setSearchSubCategory("")
    setSearchService("")
    setFilteredData(allCity)
  }

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Spare Parts
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Spare Parts
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  e.target.value && setservice_type_id(e.target.value);
                  e.target.value && getAllCategory(e.target.value);
                  e.target.value && onCustomFilter(e.target.value, "", "")

                }}
              // value={service_type_id}
              >
                <option value="" >
                  Select{" "}
                </option>
                {totalService_type.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  e.target.value && setcategory_id(e.target.value);
                  e.target.value && getAllSubCategory(e.target.value);
                  e.target.value && onCustomFilter(service_type_id, e.target.value, "")
                }}
              >
                <option value="" >
                  Select{" "}
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group" style={{ display: totalSubcategory?.length > 0 ? "block" : "none" }}>
              <label for="exampleInputEmail1">
                Sub Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {
                e.target.value && setsubcategory_id(e.target.value)
                e.target.value && onCustomFilter(service_type_id, category_id, e.target.value)
              }}>
                <option value="">
                  Select{" "}
                </option>
                {totalSubcategory?.map((ele, id) => {
                  console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                placeholder="0"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={price}
                onChange={(e) => checkPrice(e)}
              />
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={AddCityCategory}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Spare Parts
            </div>
            <div class="form-inline my-2">
            <input class="form-control mr-sm-2" type="search" placeholder="Service Type" aria-label="Search"
                value={searchServiceType}
                onChange={(e) => {
                  setSearchServiceType(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Sub Category" aria-label="Search"
                value={searchSubCategory}
                onChange={(e) => {
                  setSearchSubCategory(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="search" placeholder="Spare Parts" aria-label="Search"
                value={searchService}
                onChange={(e) => {
                  setSearchService(e.target.value)
                }}
              />

              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                onClick={onFilter}
              >Filter</button>
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                onClick={viewAllData}
              >View All</button>
            </div>
            {
              loader ? (<Loader />) : (<DataTable columns={columns} data={filteredData} pagination />)
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default SpareParts;
