import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AboutCompany = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allState, setAllState] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCountry();
    FetchAbout();
  }, []);

  // const onEdit = (item) => {
  //     window.scrollTo(0, 0);
  //     console.log("item", item);
  //     setTitle(item?.text);
  //     setimgdata(item.imageurl);
  //     // setimage(IMAMGE_URL + item.imageurl);
  //     setimage(item.imageurl);
  //     setId(item?._id);
  //     setHide(false);
  // };

  // addOfferserviceimage,

  // MannageOfferserviceimage,
  // UpdateOfferserviceimage,
  // DeleteOfferserviceimage,

  const FetchAbout = async () => {
    // const res = await NewHomService.FetchAbout();
    const res = await NewHomService.FetchAboutCompany();

    if (res && res?.status) {
      // setLoader(false)
      console.log("resss", res);
      setTitle(res?.data?.title)
      setDescription(res?.data?.description);
      // setimage(res?.data.image)
    }
  };

  //   const getAllCountry = async () => {
  //      const res = await HomeService.MannageOfferserviceimage();
  //     // const res = await HttpClientXml.requestData("view-all-country", {}, "GET");

  //     console.log("ResAllBlog", res.data);
  //     if (res && res?.status) {
  //       // setLoader(false)
  //       console.log("resss",res)
  //       let arr = res?.data?.image?.map((item, index) => {
  //         return {
  //           sl: index + 1,
  //           Name: item?.text,
  //           // imgurl:IMAMGE_URL+item.imageurl,
  //           imageurl:item.imageurl,

  //         //   action: (
  //         //     <div style={{ display: "flex", flexDirection: "coloum" }}>
  //         //       <svg
  //         //         onClick={() => onEdit(item)}
  //         //         style={{
  //         //           height: "20px",
  //         //           width: "20px",
  //         //           cursor: "pointer",
  //         //           marginRight: "20px",
  //         //         }}
  //         //         xmlns="http://www.w3.org/2000/svg"
  //         //         width="16"
  //         //         height="16"
  //         //         fill="currentColor"
  //         //         class="bi bi-pencil-square"
  //         //         viewBox="0 0 16 16"
  //         //       >
  //         //         <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
  //         //         <path
  //         //           fill-rule="evenodd"
  //         //           d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
  //         //         />
  //         //       </svg>
  //         //       <svg
  //         //         onClick={() => onDelete(item?._id)}
  //         //         style={{
  //         //           color: "red",
  //         //           height: "20px",
  //         //           cursor: "pointer",
  //         //           width: "20px",
  //         //         }}
  //         //         xmlns="http://www.w3.org/2000/svg"
  //         //         width="16"
  //         //         height="16"
  //         //         fill="currentColor"
  //         //         class="bi bi-trash3"
  //         //         viewBox="0 0 16 16"
  //         //       >
  //         //         <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
  //         //       </svg>
  //         //     </div>
  //         //   ),
  //         };
  //       });
  //       setAllState(arr);
  //     }
  //   };

  // const onDelete = (id) => {
  //     Swal.fire({
  //         title: "Are you sure?",
  //         // text: "You won't  to delete this!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //         if (result.isConfirmed) {
  //             HomeService.DeleteOfferserviceimage(id)
  //                 .then((res) => {
  //                     console.log("DELETEBLOGCAT", res);
  //                     if (res && res.status) {
  //                         toast.success("Deleted Successfully");
  //                         console.log("RESPONSEDELETE", res);
  //                         //   getAllCountry();
  //                     } else {
  //                         toast.error("Failed to Delete the Item ");
  //                     }
  //                 })
  //                 .catch((err) => {
  //                     console.log(err);
  //                 });
  //         }
  //     });
  // };

  const imageHandler = async (e) => {
    // setImgLoader(true);

    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res?.data);
      console.log("Image Res", res);
      // setimgdata(res?.doc?.url);

      // setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const manageData = () => {
    let data = {
      title: title,
      description: description,
      // image: image
    };

    if (description) {
      // alert(title)
      // NewHomService.ManageWhyChoose(data)
      NewHomService.ManageAboutCompany(data)
        .then((res) => {
          // console.log("Response Add Blog Category", res);
          if (res && res.status) {
            toast.success(res.message);
            FetchAbout();
          } else {
            toast.error(res.message);
            // toast.error("L195")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!title) {
        toast.error("Heading is mandatory");
      }
      if (!description) {
        toast.error("Description is mandatory");
      }
    }
  };

  // const UpdateCountryCategory = () => {
  //     console.log("ID", id);
  //     let data = {

  //         title: title,
  //         description: description,
  //         imageurl: image

  //     }
  //     HomeService.UpdateOfferserviceimage(id, data)
  //         .then((res) => {
  //             console.log("updated res", res);
  //             if (res && res.status) {
  //                 toast.success("Updated Successfully");
  //                 setHide(true);
  //                 console.log("RESPONSEDELETE", res);
  //                 setTitle("");
  //                 //   getAllCountry();
  //             } else {
  //                 toast.error("Blog Category Name Already Exist");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                About Company
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                About Company
              </div>
            )}

            <div class="form-group">
                            <label for="exampleInputEmail1">
                                Heading <span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Content <span style={{ color: "red" }}>*</span> :
              </label>

              {/* <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="7"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea> */}

              <div style={{ marginBottom: "21px" }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    placeholder: "Enter content here",
                    toolbar: [
                      "undo",
                      "redo",
                      "bold",
                      "blockQuote",
                      "heading",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                    ],
                  }}
                  data={description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    let data = editor.getData();

                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>

            {/* <div class="form-group">
                            <label for="formFile" class="form-label">
                                Image<span style={{ color: "red" }}>*</span> :
                            </label>
                            {image && (
                                <>
                                    <img
                                        style={{
                                            // height: "50%",
                                            width: "50%",
                                            marginTop: "12px",
                                            borderRadius: "9px",
                                            marginBottom: "1em"
                                        }}
                                        src={image}
                                    />
                                </>
                            )}
                            <input
                                id="images"
                                onChange={imageHandler}
                                class="form-control"
                                type="file"
                                accept="image/*"
                            />

                            
                        </div> */}

            {/* {hide ? ( */}
            <button class="btn btn-primary" onClick={manageData}>
              Submit
            </button>
            {/* ) : (
                            <button class="btn btn-primary" onClick={UpdateCountryCategory}>
                                Update
                            </button>
                        )} */}

            {/* <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              MANAGE  Service Offer 
            </div> */}
            {/* <DataTable columns={columns} data={allState} pagination /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
