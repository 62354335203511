import React, { createContext, useState } from "react";
import "./App.css";
import PrivateRoutes from "./View/PrivateRoutes";
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  // redirect,
  // useNavigate,
} from "react-router-dom";
import Dashboard from "../src/View/Dashboard/Index";
import "../src/assets/base.css";
import Main from "./Layout/Main";

import Login from "./Athentication/Login";

import { Toaster } from "react-hot-toast";

import AddState from "./View/State/AddState";
import Addcountry from "./View/Country/Addcountry";
// import EditCountry from "./View/Country/EditCountry";
import AddCity from "./View/City/AddCity";
import AddArea from "./View/Area/AddArea";
// import AddLanguage from "./View/Language/AddLanguage";
import AddService from "./View/Service-type/AddService";
import AddCategory from "./View/Category/addCategory";
import SubCategory from "./View/sub-Category/SubCategory";
import Service from "./View/Service/Service";
import AddSlotTime from "./View/Slot/AddSlotTime";
import AddSlotDate from "./View/Slot/AddSlotDate";
import AddCoupon from "./View/Cupon/AddCupon";
import AddBooking from "./View/Booking/AddBooking";
import AddInterviewDate from "./View/InterviewDate/AddInterviewDate";
import AddInterviewTime from "./View/InterviewTime/AddInterviewTime";
import AddInterviewType from "./View/AddInterviewType/AddInterviewType";
import AddExperience from "./View/Experience/AddExperience";
import RegistrationFee from "./View/Registrationfee/RegistrationFee";
import WorkingTime from "./View/WorkingTime/WorkingTime";
import SpareParts from "./View/SpareParts/SpareParts";
import CategoryBanner from "./View/Banner/CategoryBanner";
import ServiceBanner from "./View/Banner/ServiceBanner";
import HomeBanner from "./View/Banner/HomeBanner";
import SubCategoryBanner from "./View/Banner/SubCategoryBanner";
import VendarDetails from "./View/Vendar/VendarDetails";
// import ViewVendor from "./View/Vendar/ViewVendor";
import AddHouseType from "./View/HouseType/AddHouseType";
import BuildingType from "./View/BuildingType/BuildingType";
import AddModelType from "./View/ModelType/AddModelType";
import AddPlaceType from "./View/PlaceType/AddPlaceType";
import AddMeterialType from "./View/MeterialType/AddMeterialType";
import Nopage from "./Loader/Nopage";
import AddHouseTypeDetails from "./View/HouseType/AddHouseTypeDetails";
import AreaIndivisual from "./View/AreaIndivisual/AreaIndivisual";
import AddPaintingBrands from "./View/Painting/AddPaintingBrands";
import AddPaintingQuality from "./View/Painting/AddPaintingQuality";
import AddPaintingVersion from "./View/Painting/AddPaintingVersion";
import AddVendorbanner from "./View/Vendar/AddVendorbanner";
import AddVendarInterviewQuestion from "./View/Vendar/AddVendarInterviewQuestion";
import AddServiceDays from "./View/Vendar/AddServiceDays";
import AddVendarQuestionAnswar from "./View/Vendar/AddVendarQuestionAnswar";
import ViewContuctUs from "./View/Contuctus/ViewContuctUs";
import VendarSubscription from "./View/Vendar/VendarSubscription";
import ServicePakages from "./View/ServicePakages/ServicePakages";
import AddDiscountPakeges from "./View/Discount Pakeges/AddDiscountPakeges";
import AddOrderService from "./View/OfferServiceImage/AddOrderService";
import AddAboutService from "./View/AboutService/AddAboutService";
import AddPaintingCost from "./View/PaintingCost/AddPaintingCost";
import AddModelCost from "./View/ModelType/AddModelCost";
import AddNoofBuilding from "./View/NoofBuilding/AddNoofBuilding";
import AddAMCprice from "./View/AMCprice/AddAMCprice";
import ADDCCtv from "./View/CCtv/ADDCCtv";
import ManageHouseType from "./View/AMC/HouseType/ManageHouseType";
import ManageFloorNumber from "./View/AMC/FloorNumber/ManageFloorNumber";
import ManageServicePackage from "./View/AMC/ServicePackage/ManageServicePackage";
import ManageServicePackagePrice from "./View/AMC/ServicePackagePrice/ManageServicePackagePrice";

import Testing from "./Component/Testing";

import AddHouseTypeCost from "./View/HouseType/AddHouseTypeCost";
import CleaningPrice from "./View/ModelType/CleaningPrice";
import HomeAppliences from "./View/ModelType/HomeAppliences";
import ServiceBrand from "./View/ModelType/ServiceBrand";
import RangeOfWork from "./View/ElectricalRangeOfWork/RangeOfWork";
import CCTVTechnology from "./View/ModelType/CCTVTechnology";
import PieChart from "./View/ModelType/PieChart";
import PriceDescription from "./View/ModelType/PriceDescription";
import WorkType from "./View/ContractService/WorkType";
import WorkTypePrice from "./View/ContractService/WorkTypePrice";
import ChannelAccories from "./View/ContractService/ChannelAccories";
import FloorPercentage from "./View/AMC/FloorPercentage";
import WorkArea from "./View/ContractService/WorkArea";
import Plywood from "./View/ContractService/Celling/Plywood";
import RequiredContract from "./View/ContractService/Plumbing-Senitary/RequiredContract";
import ServiceProblem from "./View/ContractService/ServiceProblem";
import ContractWorktype from "./View/ContractService/Plumbing-Senitary/ContractWorktype";
import Material from "./View/ContractService/Plumbing-Senitary/Material";
import Acceries from "./View/ContractService/Plumbing-Senitary/Acceries";
import SelectReason from "./View/ContractService/SelectReason";
import TypeOfWork from "./View/TypeOfWork/TypeOfWork";
import ChannelDVRModel from "./View/ContractService/CCTV/ChannelDVRModel";
import CameraVersion from "./View/ContractService/CCTV/CameraVersion";
import CameraType from "./View/ContractService/CCTV/CameraType";
import CameraResolution from "./View/ContractService/CCTV/CameraResolution";
import CameraModel from "./View/ContractService/CCTV/CameraModel";
import CameraBrand from "./View/ContractService/CCTV/CameraBrand";
import RequiredArea from "./View/ContractService/RequiredArea";
import CableWiring from "./View/ContractService/CCTV/CableWiring";
import Rack from "./View/ContractService/CCTV/Rack";
import CablePercentage from "./View/ContractService/CCTV/CablePercentage";
import PuttyHouseArea from "./View/ContractService/Painting/PuttyHouseArea";
import TypeOfPainting from "./View/ContractService/Painting/TypeOfPainting";
import RangeOfPainting from "./View/ContractService/Painting/RangeOfPainting";
import RangeOfAccessories from "./View/ContractService/Painting/RangeOfAccessories";
// import ViewSubmittedForms from "./View/FormData/AMCForms";
import AMCForms from "./View/FormData/AMCForms";
import ContractForms from "./View/FormData/ContractForms";
import PlywoodRange from "./View/ContractService/Celling/PlywoodRange";
import ChannelAccoriesRange from "./View/ContractService/Celling/ChannelAccoriesRange";
import HardiskCapacity from "./View/ContractService/CCTV/HardiskCapacity";
import ManageTestimonials from "./View/Testimonials/ManageTestimonials";
import ManageFAQs from "./View/FAQs/ManageFAQs";
import ManageFlashOffer from "./View/FlashOffer/ManageFlashOffer";
import ApplicationForms from "./View/FormData/ApplicationForms";
import ViewAllQueries from "./View/CustomerQueries/ViewAllQueries";
import ManageWarrenty from "./View/Warrenty/ManageWarrenty";
import ServiceFAQs from "./View/FAQs/ServiceFAQs";
import ManageWhyChoose from "./View/WhyChoose/ManageWhyChoose";
import ManageAbout from "./View/AboutService/ManageAbout";
import ManageLanguage from "./View/Language/ManageLanguage";
import ManageUserSubscriptionPlans from "./View/UserSubscription/ManageUserSubscriptionPlans";
import ManageUserSubscriptionBanner from "./View/UserSubscription/ManageUserSubscriptionBanner";
import ManageUserCoupon from "./View/UserCoupon/ManageUserCoupon";
import ManageAboutUs from "./View/AboutUs/ManageAboutUs";
import ManagePrivacyPolicy from "./View/PrivacyPolicy/ManagePrivacyPolicy";
import RegisteredVendors from "./View/Vendar/RegisteredVendors";
import UpdateProfile from "./View/Admin/UpdateProfile";
import ChangeAdminPassword from "./View/Admin/ChangeAdminPassword";
import VendorCommission from "./View/Vendar/VendorCommission";
// import TermsAndConditions from "./View/TermsAndConditions/VendorTermsAndConditions";
import VendorTermsAndConditions from "./View/TermsAndConditions/VendorTermsAndConditions";
import UserTermsAndConditions from "./View/TermsAndConditions/UserTermsAndConditions";
import ManageVendorBrand from "./View/Vendar/ManageVendorBrand";
import ManageToolkit from "./View/Vendar/ManageToolkit";
import ManageTraining from "./View/Vendar/ManageTraining";
import ManageReferral from "./View/Referral/ManageReferral";
import VendorQueries from "./View/Vendar/VendorQueries";
import ViewAllUser from "./View/User/ViewAllUser";
import VendorSubscriptionCommission from "./View/Vendar/VendorSubscriptionCommission";
import VendorMembers from "./View/Vendar/VendorMembers";
import FinanceDomesticServices from "./View/Finance/FinanceDomesticServices";
import FinanceAMCServices from "./View/Finance/FinanceAMCServices";
import FinanceContractServices from "./View/Finance/FinanceContractServices";
import ManageRewardCoupons from "./View/Rewards/ManageRewardCoupons";
import AboutCompany from "./View/AboutUs/AboutCompany";
import ViewAllReview from "./View/Customer/ViewAllReview";
import SpecialTags from "./View/Service/SpecialTags";
import VendorTasks from "./View/Vendar/VendorTasks";
import ManageHowToBook from "./View/HowToBook/ManageHowToBook";
import ManageThresholdValue from "./View/ThresholdValue/ManageThresholdValue";
import ManageDistrict from "./View/District/ManageDistrict";
import ManageDistrictWiseServicePrice from "./View/DistrictWisePrice/ManageDistrictWiseServicePrice";
import HelpAndSupport from "./View/TermsAndConditions/HelpAndSupport";
import ManageUserSubcription from "./View/UserSubscription/ManageUserSubcription";
import ZipCode from "./View/ZIP_code/ZipCode";
import WorkTime from "./View/WorkTime/WorkTime";
import UpdateRegisteredVendors from "./View/Vendar/UpdateRegisteredVendors";
import ManageRefundAndCancellation from "./View/RefundAndCancellation/ManageRefundAndCancellation";

const LOADINGDATA = createContext();
function App() {
  const [loader, setLoader] = useState(true);
  const [opensidebar, setopenSidebar] = useState(false);
  console.log("main" + opensidebar);

  return (
    <React.Fragment>
      <LOADINGDATA.Provider
        value={{
          loader: loader,
          setloader: setLoader,
          opensidebar: opensidebar,
          setopenSidebar: setopenSidebar,
        }}
      >
        <Toaster />
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Main />}>
              <Route path="/" element={<Dashboard />} />

              {/* <Route path="/add-blog-category" element={<AddBlogCategory/>}/>
              {/* <Route path="/add-blog-category" element={<AddBlogCategory/>}/>
              <Route path='/manage-blog-category' element={<ManageBlogCategory/>}/>
              <Route path='/edit-blog-category' element={<EditBlogCategory/>}/> */}

              <Route path="/add-country" element={<Addcountry />} />
              <Route path="/add-state" element={<AddState />} />
              <Route path="/add-city" element={<AddCity />} />
              <Route path="/district" element={<ManageDistrict />} />
              <Route path="/zipcode" element={<ZipCode />} />
              <Route path="/add-area" element={<AddArea />} />
              {/* <Route path="/add-language" element={<AddLanguage />} /> */}
              <Route path="/manage-language" element={<ManageLanguage />} />

              <Route path="/add-service" element={<AddService />} />
              <Route path="/add-category" element={<AddCategory />} />
              <Route path="/add-subcategory" element={<SubCategory />} />
              <Route path="/service" element={<Service />} />
              <Route path="/service-date" element={<AddSlotDate />} />
              <Route path="/service-time" element={<AddSlotTime />} />
              <Route path="/Add-coupon" element={<AddCoupon />} />
              <Route path="/Add-booking" element={<AddBooking />} />
              <Route path="/Add-booking/:bstatus" element={<AddBooking />} />
              <Route path="/Add-interviewdate" element={<AddInterviewDate />} />
              <Route path="/AddInterviewTime" element={<AddInterviewTime />} />
              <Route path="/AddInterviewType" element={<AddInterviewType />} />
              <Route path="/AddExperience" element={<AddExperience />} />
              <Route path="/RegistrationFee" element={<RegistrationFee />} />
              <Route path="/WorkingTime" element={<WorkingTime />} />
              <Route path="/SpareParts" element={<SpareParts />} />
              <Route path="/SpareParts/:paramservice" element={<SpareParts />} />
              <Route path="/SpareParts/:paramservice/:pcategory" element={<SpareParts />} />
              <Route path="/CategoryBanner" element={<CategoryBanner />} />
              <Route path="/ServiceBanner" element={<ServiceBanner />} />
              <Route path="/HomeBanner" element={<HomeBanner />} />
              <Route path="/Category_Banner" element={<CategoryBanner />} />

              <Route
                path="/SubCategory_Banner"
                element={<SubCategoryBanner />}
              />

              <Route path="/VendarDetails" element={<VendarDetails />} />
              {/* <Route path="/VendarView/:Vid" element={<ViewVendor />} />
              <Route path="/VendarView/:Vid" element={<ViewVendor />} /> */}

              <Route path="/AddHouseType" element={<AddHouseType />} />

              <Route
                path="/AddHouseType/:Sid/:Cid"
                element={<AddHouseTypeDetails />}
              />

              <Route path="/AddBuildingType" element={<BuildingType />} />
              <Route path="/AddPlaceType" element={<AddPlaceType />} />

              <Route path="/AddModelType" element={<AddModelType />} />
              <Route path="/AddModelType/:paramservice" element={<AddModelType />} />
              <Route path="/AddModelType/:paramservice/:pcategory" element={<AddModelType />} />

              <Route path="/AddMeterialType" element={<AddMeterialType />} />
              <Route path="/AreaIndivisual" element={<AreaIndivisual />} />

              <Route
                path="/AddPaintingBrands"
                element={<AddPaintingBrands />}
              />

              <Route
                path="/AddPaintingQuality"
                element={<AddPaintingQuality />}
              />
              <Route
                path="/AddPaintingVersion"
                element={<AddPaintingVersion />}
              />
              <Route path="/AddVendorbanner" element={<AddVendorbanner />} />
              <Route
                path="/AddVendarInterviewQuestion"
                element={<AddVendarInterviewQuestion />}
              />

              <Route path="/AddServiceDays" element={<AddServiceDays />} />
              <Route
                path="/AddVendarQuestionAnswar"
                element={<AddVendarQuestionAnswar />}
              />

              <Route path="/ViewContuctUs" element={<ViewContuctUs />} />
              <Route path="/AddSubscription" element={<VendarSubscription />} />
              <Route path="/ServicePakages" element={<ServicePakages />} />
              <Route
                path="/AddDiscountPakeges"
                element={<AddDiscountPakeges />}
              />
              <Route path="/AddOrderService" element={<AddOrderService />} />
              <Route path="/AddAboutService" element={<AddAboutService />} />
              <Route path="/AddPaintingCost" element={<AddPaintingCost />} />
              <Route path="/AddModelCost" element={<AddModelCost />} />
              <Route path="/AddModelCost/:paramservice" element={<AddModelCost />} />
              <Route path="/AddModelCost/:paramservice/:pcategory" element={<AddModelCost />} />

              <Route path="/AddNoofBuilding" element={<AddNoofBuilding />} />

              <Route path="/AddAMCprice" element={<AddAMCprice />} />
              <Route path="/ADDCCtv" element={<ADDCCtv />} />

              <Route path="/addHouseTypePrice" element={<AddHouseTypeCost />} />
              <Route path="/addHouseTypePrice/:paramservice" element={<AddHouseTypeCost />} />
              <Route path="/addHouseTypePrice/:paramservice/:pcategory" element={<AddHouseTypeCost />} />

              <Route
                path="/amc/housetype/manage"
                element={<ManageHouseType />}
              />

              <Route
                path="/amc/housetype/manage/:paramservice"
                element={<ManageHouseType />}
              />
              <Route
                path="/amc/housetype/manage/:paramservice/:pcategory"
                element={<ManageHouseType />}
              />

              <Route
                path="/amc/floornumber/manage"
                element={<ManageFloorNumber />}
              />
              <Route
                path="/amc/servicepackage/manage"
                element={<ManageServicePackage />}
              />
              <Route
                path="/amc/servicepackageprice/manage"
                element={<ManageServicePackagePrice />}
              />

              <Route path="/cleaning-price" element={<CleaningPrice />} />
              <Route path="/home-appliences" element={<HomeAppliences />} />

              <Route path="/service-brand" element={<ServiceBrand />} />
              <Route path="/service-brand/:paramservice" element={<ServiceBrand />} />
              <Route path="/service-brand/:paramservice/:pcategory" element={<ServiceBrand />} />

              <Route path="/rangeofwork" element={<RangeOfWork />} />


              <Route path="/AddAMCprice" element={<AddAMCprice />} />
              <Route path="/ADDCCtv" element={<ADDCCtv />} />

              <Route
                path="/amc/housetype/manage"
                element={<ManageHouseType />}
              />
              <Route
                path="/amc/floornumber/manage"
                element={<ManageFloorNumber />}
              />
              <Route
                path="/amc/servicepackage/manage"
                element={<ManageServicePackage />}
              />
              <Route
                path="/amc/servicepackageprice/manage"
                element={<ManageServicePackagePrice />}
              />
              <Route path="/cleaning-price" element={<CleaningPrice />} />
              {/* <Route path="/home-appliences" element={<HomeAppliences />} /> */}
              <Route path="/cctv-technology" element={<CCTVTechnology />} />
              <Route path="/piechart" element={<PieChart />} />
              <Route path="/price-description" element={<PriceDescription />} />

              <Route path="/work-type" element={<WorkType />} />
              <Route path="/work-type-price" element={<WorkTypePrice />} />
              <Route path="/channel-accories" element={<ChannelAccories />} />
              <Route path="/plywood" element={<Plywood />} />

              <Route path="/plywood-range" element={<PlywoodRange />} />
              <Route path="/channel-accories-range" element={<ChannelAccoriesRange />} />


              <Route path="/floor-percentage" element={<FloorPercentage />} />
              <Route path="/workarea" element={<WorkArea />} />
              <Route path="/required-contract" element={<RequiredContract />} />
              <Route path="/service-problem" element={<ServiceProblem />} />
              <Route path="/contract-worktype" element={<ContractWorktype />} />
              <Route path="/required-area" element={<RequiredArea />} />

              <Route path="/material" element={<Material />} />
              <Route path="/acceries" element={<Acceries />} />
              <Route path="/select-reason" element={<SelectReason />} />
              <Route path="/typeofwork" element={<TypeOfWork />} />
              <Route path="/camerachanneldvrmodel" element={<ChannelDVRModel />} />
              <Route path="/camera-version" element={<CameraVersion />} />
              <Route path="/camera-type" element={<CameraType />} />
              <Route path="/camera-resolution" element={<CameraResolution />} />
              <Route path="/camera-model" element={<CameraModel />} />
              <Route path="/camera-brand" element={<CameraBrand />} />
              <Route path="/cable-wiring" element={<CableWiring />} />
              <Route path="/rack" element={<Rack />} />
              <Route path="/cable-percentage" element={<CablePercentage />} />
              <Route path="/hardisk-capacity" element={<HardiskCapacity />} />

              <Route path="/putty-house-area" element={<PuttyHouseArea />} />
              <Route path="/type-of-painting" element={<TypeOfPainting />} />
              <Route path="/range-of-painting" element={<RangeOfPainting />} />
              <Route path="/range-of-accessories" element={<RangeOfAccessories />} />

              <Route path="/view-amc-forms" element={<AMCForms />} />
              <Route path="/view-amc-forms/:bstatus" element={<AMCForms />} />
              <Route path="/view-contract-forms" element={<ContractForms />} />
              <Route path="/view-contract-forms/:bstatus" element={<ContractForms />} />
              <Route path="/manage-testimonials" element={<ManageTestimonials />} />
              <Route path="/manage-faqs" element={<ManageFAQs />} />
              <Route path="/manage-flash-offer" element={<ManageFlashOffer />} />
              <Route path="/application-forms" element={<ApplicationForms />} />
              <Route path="/customer-queries" element={<ViewAllQueries />} />
              <Route path="/manage-warrenty" element={<ManageWarrenty />} />
              <Route path="/service-faqs" element={<ServiceFAQs />} />
              <Route path="/why-choose" element={<ManageWhyChoose />} />
              <Route path="/about" element={<ManageAbout />} />
              <Route path="/user-subscription-plans" element={<ManageUserSubscriptionPlans />} />
              <Route path="/user-subscription-banner" element={<ManageUserSubscriptionBanner />} />
              <Route path="/manage-user-subscription" element={<ManageUserSubcription />} />
              <Route path="/user-coupon" element={<ManageUserCoupon />} />
              <Route path="/manage-aboutus" element={<ManageAboutUs />} />
              <Route path="/manage-privacy-policy" element={<ManagePrivacyPolicy />} />
              <Route path="/registered-vendors" element={<RegisteredVendors />} />
              <Route path="/update-vendors" element={<UpdateRegisteredVendors />} />
              <Route path="/update-admin-profile" element={<UpdateProfile />} />
              <Route path="/change-admin-password" element={<ChangeAdminPassword />} />
              <Route path="/manage-vendor-commission" element={<VendorCommission />} />
              <Route path="/manage-terms-and-conditions/vendor" element={<VendorTermsAndConditions />} />
              <Route path="/manage-terms-and-conditions/user" element={<UserTermsAndConditions />} />
              <Route path="/manage-help-and-support" element={<HelpAndSupport />} />
              <Route path="/manage-vendor-brand" element={<ManageVendorBrand />} />
              <Route path="/manage-vendor-toolkit" element={<ManageToolkit />} />
              <Route path="/manage-training" element={<ManageTraining />} />
              <Route path="/manage-referral" element={<ManageReferral />} />
              <Route path="/vendor-queries" element={<VendorQueries />} />
              <Route path="/view-users" element={<ViewAllUser />} />
              <Route path="/vendor-subscription-commission" element={<VendorSubscriptionCommission />} />
              <Route path="/vendor-members" element={<VendorMembers />} />
              <Route path="/finance-domestic-services" element={<FinanceDomesticServices />} />
              <Route path="/finance-amc-services" element={<FinanceAMCServices />} />
              <Route path="/finance-contract-services" element={<FinanceContractServices />} />
              <Route path="/manage-reward-coupons" element={<ManageRewardCoupons />} />
              <Route path="/manage-about-company" element={<AboutCompany />} />
              <Route path="/view-all-review" element={<ViewAllReview />} />
              <Route path="/special-tags" element={<SpecialTags />} />
              <Route path="/vendor-tasks/:id" element={<VendorTasks />} />
              <Route path="/how-to-book" element={<ManageHowToBook />} />
              <Route path="/threshold-value" element={<ManageThresholdValue />} />
              <Route path="/service-price" element={<ManageDistrictWiseServicePrice />} />
              <Route path="/manage-work-time" element={<WorkTime />} />
              <Route path="/manage-refund-cancellation" element={<ManageRefundAndCancellation />} />


              <Route path="/Testing" element={<Testing />} />


              <Route path="/*" element={<Nopage />} />
            </Route>
          </Route>

          <Route path="/login" element={<Login />} />
        </Routes>
      </LOADINGDATA.Provider>
    </React.Fragment>
  );
}

export { LOADINGDATA };
export default App;
