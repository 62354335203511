import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import SeeMoreLess from "../../Component/SeeMoreLess";
const ManageTestimonials = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("")
    const [countryIcon, setcountryIcon] = useState("");

    const [imgdata, setimgdata] = useState("");
    const [image, setimage] = useState("");

    const [allData, setAllData] = useState([]);

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    useEffect(() => {
        fetchAllData();
    }, []);

    const onEdit = (item) => {
        window.scrollTo(0, 0);
        console.log("item", item);
        setTitle(item?.title);
        setDescription(item?.description)
        setimgdata(item?.image);
        setimage(item?.image);
        setId(item?._id);
        setHide(false);
    };


    const fetchAllData = () => {

        NewHomService.FetchTestimonials()
            .then((res) => {
                console.log("AllData", res.data);
                if (res && res?.status) {
                    // setLoader(false)

                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        // console.log("llll", item?.materialTypeName);
                        return {
                            sl: index + 1,
                            ID: item?._id,
                            Title: item?.title,
                            Description: item?.description,
                            Photo: item?.image,
                            UpdateDate: item?.createdAt,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setAllData(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteTestimonials(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllData();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    ///https://history.state.gov/countries/all
    const imageHandler = async (e) => {
        // setImgLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        console.log("imgdata", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);
        console.log("resImage", res?.doc?.url);
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            console.log(res?.doc?.url);
            setimgdata(res?.doc?.url);
            setimage(res?.doc?.url);
            // setImgLoader(false);
        } else {
            toast.error("Failed to upload Image");
            // setImgLoader(false);
        }
    };

    const addData = () => {
        let data = {
            title: title,
            description: description,
            image: imgdata,
        };

        if (title &&
            description) {
            // alert(data.title)
            // toast.success("Testimonial Added Successfully");
            // fetchAllData();
            NewHomService.AddTestimonials(data)
                .then((response) => {
                    // alert(1)
                    console.log("ResponseAWA176", response);
                    // toast.success("Testimonial Added Successfully");
                    if (response && response.status) {
                        fetchAllData();
                        // toast.success(response.message);

                        // setHide(true);
                        // console.log("ADDDATA", res);
                        setTitle("");
                        setDescription("")
                        setimgdata("")
                        setimage("")
                        let file = document.querySelector("#images");
                        file.value = "";
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            toast.success("Testimonial Added Successfully");
            setTitle("");
            setDescription("")
            setimgdata("")
            setimage("")
            let file = document.querySelector("#images");
            file.value = "";
            fetchAllData();

        } else {
            if (!title) {
                return toast.error("Title Is Required");
            }
            if (!description) {
                return toast.error("Message Is Required");
            }
        }
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            sortable: true,
            width: "50px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Date
                </div>
            ),
            selector: (row) => moment(row.UpdateDate).format("DD-MM-YYYY hh:mm:ss A"),
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Title
                </div>
            ),
            selector: (row) => row.Title,
            wrap: true,
            width: "100px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Message
                </div>
            ),
            selector: (row) => <SeeMoreLess maxWordShow={100} >{row.Description}</SeeMoreLess>,
            wrap: true,
            sortable: true,
            width: "400px",
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Photo
                </div>
            ),
            selector: (row) => <img style={{ width: "100%", margin: "5px", padding: " 5px" }} src={row.Photo} alt={""} />,
            width: "256px"
        },
        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const updateData = () => {
        console.log("ID", id);
        let data = {
            title: title,
            description: description,
            image: imgdata,
        };
        if (title) {
            NewHomService.UpdateTestimonials(id, data)
                .then((res) => {
                    console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        setHide(true);
                        console.log("RESPONSEDELETE", res);
                        setTitle("");
                        setDescription("")
                        setimgdata("")
                        setimage("")
                        let file = document.querySelector("#images");
                        file.value = "";
                        fetchAllData();
                    } else {
                        toast.error("Title Already Exist");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            toast.error("Title is requred")
        }
    };

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Testimonial
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Update Testimonial
                            </div>
                        )}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Title<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Message<span style={{ color: "red" }}>*</span> :
                            </label>

                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </div>

                        <div class="form-group">
                            <label for="formFile" class="form-label">
                                Photo :
                            </label>
                            <input
                                id="images"
                                onChange={imageHandler}
                                class="form-control"
                                type="file"
                                accept="image/*"
                            />

                            {image && (
                                <>
                                    <img
                                        style={{
                                            height: "10%",
                                            width: "10%",
                                            marginTop: "12px",
                                            // borderRadius: "9px",
                                        }}
                                        src={image}
                                    />
                                </>
                            )}
                        </div>

                        {hide ? (
                            <button class="btn btn-primary" onClick={addData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={updateData}>
                                Update
                            </button>
                        )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Testimonials
                        </div>
                        <DataTable columns={columns} data={allData} pagination />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageTestimonials;
