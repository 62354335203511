import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import Loader from "../../Component/Loader";

const ManageFlashOffer = () => {
    const [flashoffer, setFlashoffer] = useState("");
    const [imgdata, setimgdata] = useState("");
    const [image, setimage] = useState("");
    const [duration, setduration] = useState("");
    const [actualPrice, setactualPrice] = useState("");
    const [discountPrice, setdiscountPrice] = useState("");
    const [summary, setsummary] = useState("");
    const [category_id, setcategory_id] = useState("");
    const [subcategory_id, setsubcategory_id] = useState("");
    const [serviceId, setServiceId] = useState("")
    const [allData, setAllData] = useState([]);

    const [service_type_id, setservice_type_id] = useState("");

    const [totalService_type, settotalService_type] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);
    const [totalSubcategory, settotalSubcategory] = useState([]);

    const [singledata, setsingleData] = useState({})
    const [totalServices, setTotalServices] = useState([])

    const [isHomePage, setIsHomePage] = useState(false)
    const [chkVal, setChkVal] = useState(1)

    // console.log("2data", subcategory_id);


    // const [image, setimage] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    const [filteredData, setFilteredData] = useState([]);
    const [searchCategory, setSearchCategory] = useState("")
    const [searchSubCategory, setSearchSubCategory] = useState("")
    const [searchService, setSearchService] = useState("")

    useEffect(() => {
        getAllService_type();
        setservice_type_id("64d60270203bf4c1d37b6a1e")
        getAllCategoryinService_type("64d60270203bf4c1d37b6a1e")
        onCustomFilter("64d60270203bf4c1d37b6a1e", "", "", "")
        // fetchAllService();
    }, []);

    // useEffect(()=>{  
    //    service_type_id && getAllCategoryinService_type(service_type_id)
    //   },[service_type_id,])



    const handleChecked = (e) => {
        if (e.target.value == 1) {
            setIsHomePage(true)
            setChkVal(0)
        } else {
            setIsHomePage(false)
            setChkVal(1)
        }
        console.log("CHK", chkVal, isHomePage);
    }

    const resetState = () => {
        setFlashoffer()
        setServiceId()
        setsubcategory_id()
        setcategory_id()
        setServiceId()
        setFlashoffer("")
        setIsHomePage(false)
        setChkVal(1)
    }

    const onEdit = (item) => {

        window.scroll(0, 0)
        console.log("itemee", item);

        getAllService_type();
        setservice_type_id(item?.serviceTypeName._id);
        getAllCategoryinService_type(item?.serviceTypeName._id);

        setcategory_id(item?.categoryName._id);
        getAllSubCategory(item?.categoryName._id);

        setsubcategory_id(item?.subcategoryName._id)
        getAllServicesByCategory(item?.subcategoryName._id);

        setServiceId(item?.serviceName._id)

        setFlashoffer(item?.flashoffer)

        setIsHomePage(item?.isHomePage)
        isHomePage ? setChkVal(0) : setChkVal(1)
        setimgdata(item?.image);
        setimage(item?.image);

        setId(item?._id);

        setHide(false);
    };

    const onDelete = (id) => {

        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteFlashOffers(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllService();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    // const organiseData = () => {
    //     let temarr = [...questio_ans]
    //     const tempobj = {
    //         question: question,
    //         answer: ans
    //     }
    //     temarr.push(tempobj);
    //     setquestio_ans(temarr)
    //     setquestion('')
    //     setans('')
    // }

    // const addQuestion = () => {
    //     setfquestions([...fquestions, { question: "", answer: "" }]);
    // };

    // const updateQuestion = (index, field, value) => {
    //     const updatedQuestions = [...fquestions];
    //     updatedQuestions[index][field] = value;
    //     setfquestions(updatedQuestions);
    // };
    // const deleteQuestion = (index) => {
    //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
    //     setfquestions(updatedQuestions);
    // };

    const getAllService_type = async () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalService_type(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllCategoryinService_type = async (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalCategory(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const getAllCategory = async () => {
    //   HomeService.ManageCategory()
    //     .then((res) => {
    //       console.log("ResAllBlog", res.data);

    //       if (res && res?.status) {
    //         // setLoader(false)
    //         console.log(res?.data);
    //         settotalCategory(res?.data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const getAllSubCategory = async (id) => {
        HomeService.SubCategoriesviacategory(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("sub_cat", res?.data);
                    settotalSubcategory(res?.data);
                } else {
                    settotalSubcategory([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getAllServicesByCategory = async (id) => {
        console.log("services:", id);
        NewHomService.FetchServicesByCategory(id)
            .then((res) => {
                console.log("FetchServicesByCategory", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("services:", res);
                    setTotalServices(res?.data);
                } else {
                    setTotalServices();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchAllService = () => {
        NewHomService.FetchFlashOffers()
            .then((res) => {
                console.log("DATASW255", res.data);
                if (res && res?.status) {
                    // setLoader(false)

                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        return {
                            sl: index + 1,
                            ServiceType: item?.categoryName?.name,
                            Category: item?.categoryName?.name,
                            Subcategory: item?.subcategoryName?.name,
                            Service: item?.serviceName?.title,
                            Offer: item?.flashoffer,
                            sId: item?.serviceTypeId,
                            isHomePage: item?.isHomePage,
                            image: item?.image,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    console.log("RESPONSE", res);
                    setFilteredData(arr)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const imageHandler = async (e) => {
        // setImgLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        console.log("imgdata", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);
        console.log("resImage", res?.doc?.url);
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            console.log(res?.doc?.url);
            setimgdata(res?.doc?.url);
            setimage(res?.doc?.url);
            // setImgLoader(false);
        } else {
            toast.error("Failed to upload Image");
            // setImgLoader(false);
        }
    };

    const addData = () => {
        let data = {
            serviceTypeId: service_type_id,
            serviceCategoryId: category_id,
            subCategoryId: subcategory_id,
            serviceId: serviceId,
            flashoffer: flashoffer,
            isHomePage: isHomePage,
            image: imgdata,
        };

        if (service_type_id && category_id && subcategory_id && serviceId && flashoffer) {
            console.log("DATA", data);

            NewHomService.AddFlashOffers(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log("err",err);
                    toast.error(err.message);
                });

        } else {
            if (!flashoffer) {
                toast.error("Offer is mandatory");
            }
            if (!serviceId) {
                toast.error("Choose Service");
            }
            if (!subcategory_id) {
                toast.error("Choose Sub Category");
            }
            if (!category_id) {
                toast.error("Choose Category");
            }
        }
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            width:"100px",
            wrap: true,
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Service Type
        //         </div>
        //     ),
        //     selector: (row) => row.ServiceType,
        //     wrap: true,
        //     width: "150px"
        // },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Sub Category
                </div>
            ),
            selector: (row) => row.Subcategory,
            wrap: true,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service
                </div>
            ),
            selector: (row) => row.Service,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Offer Price
                </div>
            ),
            selector: (row) => row.Offer,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Display at Homepage
                </div>
            ),
            selector: (row) => {
                return row.isHomePage ? "Yes" : "No"
            },
            wrap: true,
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Image
        //         </div>
        //     ),
        //     selector: (row) => <img style={{ width: "72px", margin: "5px", padding: " 3px" }} src={row.image} alt={""} />,
        // },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const updateData = () => {
        let data = {
            serviceTypeId: service_type_id,
            serviceCategoryId: category_id,
            subCategoryId: subcategory_id,
            serviceId: serviceId,
            flashoffer: flashoffer,
            isHomePage: isHomePage,
            image: imgdata,
        };

        if (service_type_id && category_id && subcategory_id && serviceId && flashoffer) {
            console.log("DATA", data);
            NewHomService.UpdateFlashOffers(id, data)
                .then((res) => {
                    // console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        setHide(true);
                        // console.log("RESPONSEDELETE", res);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error("Error Occured!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!flashoffer) {
                return toast.error("Offer is mandatory");
            }
            if (!serviceId) {
                return toast.error("Choose Service");
            }
            if (!subcategory_id) {
                return toast.error("Choose Sub Category");
            }
            if (!category_id) {
                return toast.error("Choose Category");
            }
        }
    };

    const checkPercentage = (e) => {
        if (e.target.value < 0 || e.target.value > 100) {
            return toast.error("Invalid percentage!");
        }
        setFlashoffer(e.target.value)
    }

    const [loader, setLoader] = useState(false)

    const onCustomFilter = (s_id, c_id, sc_id, srv_id) => {
        setLoader(true)
        NewHomService.FetchFlashOffers()
            .then((res) => {
                console.log("DATA547", res.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = []

                    if (s_id && c_id && sc_id && srv_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeName?._id
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryName?._id
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subcategoryName?._id
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.serviceName?._id
                                    .includes(srv_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName?.serviceName,
                                    Category: item?.categoryName?.name,
                                    Subcategory: item?.subcategoryName?.name,
                                    Service: item?.serviceName?.title,
                                    Offer: item?.flashoffer,
                                    sId: item?.serviceTypeId,
                                    isHomePage: item?.isHomePage,
                                    image: item?.image,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id && c_id && sc_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeName?._id
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryName?._id
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subcategoryName?._id
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName?.serviceName,
                                    Category: item?.categoryName?.name,
                                    Subcategory: item?.subcategoryName?.name,
                                    Service: item?.serviceName?.title,
                                    Offer: item?.flashoffer,
                                    sId: item?.serviceTypeId,
                                    isHomePage: item?.isHomePage,
                                    image: item?.image,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id && c_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeName?._id
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryName?._id
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName?.serviceName,
                                    Category: item?.categoryName?.name,
                                    Subcategory: item?.subcategoryName?.name,
                                    Service: item?.serviceName?.title,
                                    Offer: item?.flashoffer,
                                    sId: item?.serviceTypeId,
                                    isHomePage: item?.isHomePage,
                                    image: item?.image,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeName?._id
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName?.serviceName,
                                    Category: item?.categoryName?.name,
                                    Subcategory: item?.subcategoryName?.name,
                                    Service: item?.serviceName?.title,
                                    Offer: item?.flashoffer,
                                    sId: item?.serviceTypeId,
                                    isHomePage: item?.isHomePage,
                                    image: item?.image,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else {
                        arr = res?.data?.sort().reverse().map((item, index) => {
                            return {
                                sl: index + 1,
                                Category: item?.categoryName?.name,
                                Subcategory: item?.subcategoryName?.name,
                                Service: item?.serviceName?.title,
                                Offer: item?.flashoffer,
                                sId: item?.serviceTypeId,
                                isHomePage: item?.isHomePage,
                                image: item?.image,

                                action: (
                                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                                        <svg
                                            onClick={() => onEdit(item)}
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                                cursor: "pointer",
                                                marginRight: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                        </svg>
                                        <svg
                                            onClick={() => onDelete(item?._id)}
                                            style={{
                                                color: "red",
                                                height: "20px",
                                                cursor: "pointer",
                                                width: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-trash3"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>
                                    </div>
                                ),
                            };
                        })
                    }


                    console.log("RESPONSE", res);
                    setFilteredData(arr)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onFilter = () => {
        let searchData = allData;
        console.log("GSGS945", allData);
        
        //category
        if (searchCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //subcategory
        if (searchSubCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Subcategory?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //service
        if (searchService !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Service?.toLowerCase().includes(searchService.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        
    }

    const viewAllData = () => {
        setSearchCategory("")
        setSearchSubCategory("")
        setFilteredData(allData)
    }

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Flash Offer
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit Flash Offer
                            </div>
                        )}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Type<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_type_id(e.target.value);
                                    getAllCategoryinService_type(e.target.value);
                                    onCustomFilter(e.target.value, "", "", "")
                                }}
                            // disabled
                            // value={service_type_id}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalService_type?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == service_type_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                            disabled={ele._id !== "64d60270203bf4c1d37b6a1e" && true}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setcategory_id(e.target.value);
                                    getAllSubCategory(e.target.value);
                                    onCustomFilter(service_type_id, e.target.value, "", "")
                                }}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalCategory?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == category_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Sub Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setsubcategory_id(e.target.value)
                                getAllServicesByCategory(e.target.value);
                                onCustomFilter(service_type_id, category_id, e.target.value, "")
                            }}>
                                <option value="" >
                                    Choose...
                                </option>
                                {totalSubcategory?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == subcategory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setServiceId(e.target.value)
                                onCustomFilter(service_type_id, category_id, subcategory_id, e.target.value)
                            }}>
                                <option value="" >Choose...</option>
                                {totalServices?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == serviceId ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Offer Percentage<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="number"
                                min={0}
                                max={100}
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={flashoffer}
                                onChange={
                                    (e) => checkPercentage(e)

                                }
                            />
                        </div>

                        <div class="form-check mb-3">
                            <input class="form-check-input" value={chkVal} type="checkbox"
                                onClick={(e) => handleChecked(e)}
                                checked={isHomePage}
                            />
                            <label class="form-check-label">
                                {isHomePage ? ("Uncheck and hide from Homepage") : ("Check and display at Homepage")}
                                {/* Display at Homepage */}
                            </label>
                        </div>

                        <div class="form-group mb-3"
                            style={{ display: isHomePage ? "none" : "none" }}
                        >
                            <label for="formFile" class="form-label">
                                Offer Image (Image dimention: 125 X 80):
                            </label>
                            <input
                                id="images"
                                onChange={imageHandler}
                                class="form-control"
                                type="file"
                                accept="image/*"
                            />

                            {image && (
                                <>
                                    <img
                                        style={{
                                            height: "10%",
                                            width: "10%",
                                            marginTop: "12px",
                                        }}
                                        src={image}
                                    />
                                </>
                            )}
                        </div>

                        {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Duration<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={duration}
                onChange={(e) => setduration(e.target.value)}
              />
            </div> */}

                        {/* <div class="form-group">
              <label for="formFile" class="form-label">
              Service image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />

              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div> */}

                        {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Title<span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={title}
                                onChange={(e) => settitle(e.target.value)}
                               
                            />
                        </div> */}

                        {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={actualPrice}
                onChange={(e) => setactualPrice(e.target.value)}
              />
            </div> */}

                        {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Discount Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={discountPrice}
                onChange={(e) => setdiscountPrice(e.target.value)}
              />
            </div> */}

                        {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Summary<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summary}
                onChange={(e) => setsummary(e.target.value)}
              />
            </div> */}





                        {hide ? (
                            <button class="btn btn-primary" onClick={addData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={updateData}>
                                Update
                            </button>
                        )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Flash Offer(s)
                        </div>
                        <div class="form-inline my-2">

                            <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                                value={searchCategory}
                                onChange={(e) => {
                                    setSearchCategory(e.target.value)
                                }}
                            />
                            <input class="form-control mr-sm-2" type="search" placeholder="Sub Category" aria-label="Search"
                                value={searchSubCategory}
                                onChange={(e) => {
                                    setSearchSubCategory(e.target.value)
                                }}
                            />
                            <input class="form-control mr-sm-2" type="search" placeholder="Service" aria-label="Search"
                                value={searchService}
                                onChange={(e) => {
                                    setSearchService(e.target.value)
                                }}
                            />

                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div>
                        {
                            loader ? (<><Loader /></>) : (<>
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    noDataComponent="No Flash Offers are available"
                                />
                            </>)
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageFlashOffer;
