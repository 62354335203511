import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml, { IMAMGE_URL } from "../../Utils/HttpClientXml";

import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Component/Loader";

const VendarSubscription = () => {
  const [name, setName] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [title, settitle] = useState("")
  const [totalDays, settotalDays] = useState("");
  const [commission, setCommission] = useState("");
  const [allData, setAllData] = useState([]);
  const [totalCategory, setTotalCategory] = useState([]);
  const [details, setDetails] = useState("")

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [service_type_id, setservice_type_id] = useState("64d60270203bf4c1d37b6a1e");
  const [totalService_type, settotalService_type] = useState([]);
  const [totalSubcategory, setTotalSubcategory] = useState([]);

  const [loader, setLoader] = useState(false)

  const [filteredData, setFilteredData] = useState([])
  const [searchCategory, setSearchCategory] = useState("")
  const [searchServiceType, setSearchServiceType] = useState("")
  const [searchTitle, setSearchTitle] = useState("")



  //   const [imgdata, setimgdata] = useState("");
  //   const [image, setimage] = useState("");

  // AddVendarSubscription,
  // MannageVendarSubscription,
  // UpdateVendarSubscription,
  // DeleteVendarSubscription


  useEffect(() => {
    getAllService_type();
    fetchAllvendarSubscription();
    setservice_type_id("64d60270203bf4c1d37b6a1e")
    getAllCategoryinService_type("64d60270203bf4c1d37b6a1e")
  }, []);

  const onFilter = () => {
    let searchData = allData;
    console.log("ALLSDATA57", searchData);
    //ServiceType
    if (searchServiceType !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Service?.toLowerCase().includes(searchServiceType.toLowerCase())) {
          return item;
        }
      });
      // console.log("SRCDATA257", searchData);
      setFilteredData(searchData);
    }
    //Category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //Title
    if (searchTitle !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Title?.toLowerCase().includes(searchTitle.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  };

  const viewAllData = () => {
    setSearchServiceType("")
    setSearchCategory("")
    setSearchTitle("")
    setFilteredData(allData)
}

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          setTotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          setTotalSubcategory(res?.data);
        } else {
          setTotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reset = () => {
    setName("")
    setcategory_id("")
    settitle("")
    settotalDays("")
    setDetails("")
    setCommission("")
  }

  const onEdit = (item) => {
    console.log("itrem", item);
    window.scrollTo(0, 0);



    getAllService_type();
    setservice_type_id(item?.categoryData?.serviceTypeID);
    getAllCategoryinService_type(item?.categoryData?.serviceTypeID);

    setcategory_id(item?.categoryID);
    getAllSubCategory(item?.categoryID);

    // setsubcategory_id(item?.subcategoryID)

    setDetails(item?.details)
    settotalDays(item?.totalDays)
    settitle(item?.title)
    setName(item?.price);
    setCommission(item?.commission_percntage)
    setId(item?._id);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteVendarSubscription(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllvendarSubscription();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };



  const getAllCategory = async () => {
    HomeService.ManageCategory()
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)
          setTotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllvendarSubscription = () => {
    HomeService.MannageVendarSubscription()
      .then((res) => {
        // console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.sort().reverse().map((item, index) => {
            return {
              sl: index + 1,
              ID: item?._id,
              Service: item?.categoryData?.serviceTypeDetail?.serviceName,
              Category: item?.categoryData?.name,
              Name: item?.categoryData?.name,
              price: item?.price,
              description: item?.details,
              Title: item?.title,
              totalDays: item?.totalDays,
              Commission: item?.commission_percntage,
              Status: item?.status,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addData = () => {
    let data = {
      categoryID: category_id,
      price: name,
      details: details,
      title: title,
      totalDays: totalDays,
      commission_percntage: commission,
    };



    if (name &&
      // category_id &&
      details && title && totalDays) {
      // alert(name + country_id)
      HomeService.AddVendarSubscription(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllvendarSubscription();
            reset();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("Price Is Required");
      }
      // if (!category_id) {
      //   toast.error("Category Name Field Is Required");
      // }
      if (!details) {
        toast.error("Details Is Required");
      }
      if (!title) {
        toast.error("Title Is Required");
      }
      if (!totalDays) {
        toast.error("Total Days Is Required");
      }
    }
  };

  const changeStatus = (id, val) => {
    let data = {
      status: val
    }
    console.log("IDSDS", id, data);
    // HomeService.UpdateCategory(id, data)
    HomeService.UpdateVendarSubscription(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllvendarSubscription();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width: "50px"
    },


    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.Service,
      width: "150px",
      wrap: true,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.Category,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Title
        </div>
      ),
      selector: (row) => row.Title,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Price
        </div>
      ),
      selector: (row) => row.price,
      width: "75px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Description
        </div>
      ),
      selector: (row) => {
        return <div
          dangerouslySetInnerHTML={{ __html: row.description }}
        />
      },
      wrap: true,
      width: "250px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Validity
        </div>
      ),
      selector: (row) => {
        return <>{row.totalDays} day(s)</>
      },
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Commission
        </div>
      ),
      selector: (row) => {
        return <>{row.Commission}%</>
      },
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {
              row.Status ? (
                <>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => changeStatus(row.ID, false)}
                  >
                    Active
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Deactivate
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => changeStatus(row.ID, true)}
                  >
                    Deactive
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Active
                    </span>
                  </button>

                </>
              )
            }


          </>
        )
      },
      width: "130px",
      wrap: true,
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const updateData = () => {
    console.log("ID", id);
    let data = {
      categoryID: category_id,
      title: title,
      price: name,
      details: details,
      totalDays: totalDays,
      commission_percntage: commission,
    };
    HomeService.UpdateVendarSubscription(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          reset()
          fetchAllvendarSubscription();
          setcategory_id();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkPrice = (e) => {

    e.target.value < 0 ? (toast.error("Price should not be negative")) : (
      setName(e.target.value)
    )
  }

  const checkDays = (e) => {

    e.target.value < 0 ? (toast.error("Day(s) should not be negative")) : (
      settotalDays(e.target.value)
    )
  }

  const checkPercentage = (e) => {
    e.target.value < 0 || e.target.value > 100
      ? toast.error("Invalid percentage value")
      : setCommission(e.target.value);
  };

  const onCustomFilter = (s_id, c_id) => {
    // setLoader(true)
    HomeService.MannageVendarSubscription()
      .then((res) => {
        console.log("gdgd591",res);
        if (res && res?.status) {
          // setLoader(false)
          let arr = []
          if (s_id && c_id) {
            arr = res?.data?.filter((item) => {
              if (
                item?.categoryData?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.categoryID
                  .includes(c_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  ID: item?._id,
                  Service: item?.categoryData?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryData?.name,
                  Name: item?.categoryData?.name,
                  price: item?.price,
                  description: item?.details,
                  Title: item?.title,
                  totalDays: item?.totalDays,
                  Commission: item?.commission_percntage,
                  Status: item?.status,
    
                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else if (s_id) {
            // console.log("AER183", s_id);
            arr = res?.data?.filter((item) => {
              if (
                item?.categoryData?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                console.log("item672", item);
                return {
                  sl: index + 1,
                  ID: item?._id,
                  Service: item?.categoryData?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryData?.name,
                  Name: item?.categoryData?.name,
                  price: item?.price,
                  description: item?.details,
                  Title: item?.title,
                  totalDays: item?.totalDays,
                  Commission: item?.commission_percntage,
                  Status: item?.status,
    
                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else {
            arr = res?.data?.sort().reverse().map((item, index) => {
              console.log("item", item);
              return {
                sl: index + 1,
                ID: item?._id,
                Service: item?.categoryData?.serviceTypeDetail?.serviceName,
                Category: item?.categoryData?.name,
                Name: item?.categoryData?.name,
                price: item?.price,
                description: item?.details,
                Title: item?.title,
                totalDays: item?.totalDays,
                Commission: item?.commission_percntage,
                Status: item?.status,


                
                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          }
          setFilteredData(arr);
        }
        // console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Subscription
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Subscription
              </div>
            )}


            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  getAllCategoryinService_type(e.target.value);
                  e.target.value && onCustomFilter(e.target.value, "")
                }}
              // disabled
              // value={service_type_id}
              >
                <option value="" >
                  Choose...
                </option>
                {totalService_type?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  e.target.value && getAllSubCategory(e.target.value);
                  e.target.value && onCustomFilter(service_type_id, e.target.value)
                }}
              >
                <option value="" >
                  Choose...
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name:
              </label>
              <select onChange={(e) => setcategory_id(e.target.value)}>
                <option value="" >
                  Select{" "}
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}


            <div class="form-group">
              <label for="exampleInputEmail1">
                Title<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                placeholder="0"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => checkPrice(e)}
              />
            </div>
            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Details<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={details}
                onChange={(e) => setDetails(e.target.value)}
              />
            </div> */}
            <label for="exampleInputEmail1">
              Description<span style={{ color: "red" }}>*</span> :
            </label>
            <textarea
              class="form-control mb-2"
              rows="3"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            ></textarea>
            {/* <div style={{ marginBottom: "21px" }}>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  // placeholder : "Enter content here",
                  toolbar: [
                    "undo",
                    "redo",
                    "bold",
                    "blockQuote",
                    "heading",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "mediaEmbed",
                    "insertTable",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                  ],
                }}
                data={details}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  let data = editor.getData();

                  setDetails(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div> */}

            <div className="row">
              <div className="col-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                    Subscription validily (in Days)<span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="0"
                    value={totalDays}
                    onChange={(e) => checkDays(e)}
                  />
                </div>
              </div>

              <div className="col-6">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                    Commission Percentage<span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    max={100}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="0"
                    value={commission}
                    onChange={(e) => checkPercentage(e)}
                  />
                </div>

              </div>

            </div>







            {hide ? (
              <button class="btn btn-primary" onClick={addData}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={updateData}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Subscription
            </div>
            <div class="form-inline my-2">

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Service Type"
                aria-label="Search"
                value={searchServiceType}
                onChange={(e) => {
                  setSearchServiceType(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Category"
                aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Title"
                aria-label="Search"
                value={searchTitle}
                onChange={(e) => {
                  setSearchTitle(e.target.value);
                }}
              />

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>
            {
              loader ? (
                <Loader />
              ) : (
                <DataTable columns={columns} data={filteredData} pagination />
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default VendarSubscription;
