import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import Loader from "../../Component/Loader";

const AddSlotDate = () => {
  const [name, setName] = useState("");

  const [state_id, setstate_id] = useState("");

  console.log(state_id);
  const [allState, setAllState] = useState([]);
  const [allCity, setAllCity] = useState([]);
  // const [totalCountry, settotalCountry] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [loader, setLoader] = useState(false)

  const [filteredData, setFilteredData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("")
  const [CategoryDate, setCategoryDate] = useState("")

  console.log(allCity, "allCity")
  console.log(state_id, "state_id")
  console.log(name, "awdasgtt")

  useEffect(() => {
    fetchAllCity();
    fetchAllState();
  }, []);

  useEffect(() => {
    if (state_id) {
      const newdata = allCity?.filter((item) => item?.category_id == state_id)
      setFilteredData(newdata)
    }

  }, [state_id])
  useEffect(() => {
    if (state_id && name) {
      const newdata = allCity?.filter((item) => item?.category_id == state_id && item?.Name == name)
      setFilteredData(newdata)
    }

  }, [state_id, name])

  const filterdate = (date) => {
    const dateString = date;
    const extractedDate = dateString.split("T")[0];
    const formattedDate = new Date(extractedDate).toISOString().split("T")[0];
    console.log("formattedDate", formattedDate);
    return formattedDate;
  };

  const onEdit = (item) => {

    window.scrollTo(0, 0);
    console.log("item", item);
    setName(filterdate(item?.servicedate));
    setId(item?._id);
    setstate_id(item.category_id);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.Delete_Servicedate(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllCity()
              fetchAllState();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllState = () => {
    // HomeService.ManageCategory()
    HomeService.CategoriesviaService_type("64d60270203bf4c1d37b6a1e")
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setAllState(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllCity = () => {
    HomeService.Manage_ServiceDate()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data?.sort().reverse().map((item, index) => {
            console.log(item);
            return {
              sl: index + 1,
              Name: filterdate(item?.servicedate),
              category: item?.category_data[0]?.name,
              Status: item?.status,
              ID: item?._id,
              category_id: item?.category_id,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllCity(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddCityCategory = () => {
    let data = {
      servicedate: name,
      category_id: state_id,
    };

    if (name && state_id) {
      // alert(name + country_id)
      HomeService.add_ServiceDate(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllCity();
            setName("");
            setstate_id("");
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("Service Date Field Is Required");
      }
      if (!state_id) {
        toast.error("Category Name Field Is Required");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      wrap: true,
      center: true,
      width: "75px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category Name
        </div>
      ),
      selector: (row) => row.category,
      wrap: true,
      center: true,
      // width: "500px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Date
        </div>
      ),
      selector: (row) => row.Name,
      wrap: true,
      center: true,
      width: "150px"
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Status
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         {
    //           row.Status ? (
    //             <>
    //               <button
    //                 className="btn btn-sm btn-success"
    //                 onClick={() => changeStatus(row.ID, false)}
    //               >
    //                 Active
    //                 <span
    //                   style={{ fontSize: "70%", display: "block" }}
    //                 >
    //                   Click to Deactivate
    //                 </span>
    //               </button>
    //             </>
    //           ) : (
    //             <>
    //               <button
    //                 className="btn btn-sm btn-danger"
    //                 onClick={() => changeStatus(row.ID, true)}
    //               >
    //                 Deactive
    //                 <span
    //                   style={{ fontSize: "70%", display: "block" }}
    //                 >
    //                   Click to Active
    //                 </span>
    //               </button>

    //             </>
    //           )
    //         }


    //       </>
    //     )
    //   },
    //   wrap: true,
    //   center: true,
    //   width: "175px"
    // },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      wrap: true,
      center: true,
      width: "125px"
    },
  ];

  const UpdateStateCategory = () => {
    console.log("ID", id);
    let data = {
      category_id: state_id,
      servicedate: name,
    };
    HomeService.Update_ServiceDate(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setName("");
          setstate_id("");

          fetchAllCity();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (id, val) => {
    let data = {
      status: val
    }
    HomeService.Update_ServiceDate(id, data)
      .then((res) => {
        // console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllCity()
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFilter = () => {
    let searchData = allCity;
    console.log("GSGS418", allCity);

    //category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.category?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    if (CategoryDate !== "") {
      searchData = searchData.filter((item) =>
        item?.Name == CategoryDate
      );
      setFilteredData(searchData);
    }



  }

  const viewAllData = () => {
    setSearchCategory("")
    setFilteredData(allCity)
  }

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Manage Deactivated Service Date
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Manage Deactivated Service Date
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setstate_id(e.target.value)}>
                <option value="" selected>
                  Select
                </option>
                {allState?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == state_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Date <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="date"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={AddCityCategory}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Service Date
            </div>
            <div class="form-inline my-2">

              <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value)
                }}
              />
              <input class="form-control mr-sm-2" type="date" placeholder="Category" aria-label="Search"
                value={CategoryDate}
                onChange={(e) => {
                  setCategoryDate(e.target.value)
                }}
              />


              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                onClick={onFilter}
              >Filter</button>
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                onClick={viewAllData}
              >View All</button>
            </div>
            {
              loader ? (<Loader />) : (
                <DataTable columns={columns} data={filteredData} pagination />
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlotDate;
