import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css"
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";
import Loader from "../../Component/Loader";
// import VendorListByCategory from "./VendorListByCategory";
import { isValidUrl } from "../../Utils/Helpers";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

const VendorTasks = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  // const [title, settitle] = useState('')
  const [exp_date, setexp_date] = useState("");
  const [couponPercent, setcouponPercent] = useState("");
  const [allData, setAllData] = useState([]);
  const [description, setdescription] = useState("");

  const [allVendor, setAllVendor] = useState([]);
  const [aVendor, setAVendor] = useState("");
  const [aStatus, setAStatus] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [hide, setHide] = useState(true);
  //   const [id, setId] = useState("");

  useEffect(() => {
    fetchAllData(id);
    console.log("IDXS", id);
    // console.log("TIME",startTimer(60 * 5));
  }, []);

  // useEffect(()=>{
  //     fetchCSVUsers();
  //   },[filteredData])

  const [vendorData, setVendorData] = useState();
  const [domenticJobs, setDomenticJobs] = useState();
  const [amcJobs, setAMCJobs] = useState();
  const [contractJobs, setContractJobs] = useState();

  const fetchAllData = (id) => {
    setLoader(true);
    NewHomService.FetchJobListByVendor(id)
      .then((res) => {
        console.log("ALLDATAER", res.data);
        if (res && res?.status) {
          setLoader(false);

          setVendorData(res?.data?.vendorDetail);

          //   setDomenticJobs(res?.data?.getDoemsticData);

          console.log("STSTE", res?.data?.getDoemsticData);

          let arrDomestic = res?.data?.getDoemsticData.map((item, key) => {
            //   console.log("ITEMSS", item);
            return {
              SL: key + 1,
              Status: item?.acceptStatus,
              Services: item?.addToCart,
              BookingDate: item?.createdOn,
            };
          });
          console.log("HSHSHS", arrDomestic);
          setDomenticJobs(arrDomestic);

          let arrAmc = res?.data?.getDataForContractAMC
            .filter((item) => {
              return item?.serviceData?.workTypeName == "AMC Services";
            })
            .map((item, key) => {
              //   console.log("ITEMSS", item);
              return {
                SL: key + 1,
                Status: item?.acceptStatus,
                Services: item?.serviceData?.categoryName,
              };
            });
          console.log("HSHSHS", arrAmc);
          setAMCJobs(arrAmc);

          let arrContract = res?.data?.getDataForContractAMC
            .filter((item) => {
              return item?.serviceData?.workTypeName == "Contract Services";
            })
            .map((item, key) => {
              //   console.log("ITEMSS", item);
              return {
                SL: key + 1,
                Status: item?.acceptStatus,
                Services: item?.serviceData?.categoryName,
              };
            });

          setContractJobs(arrContract);
        }
        // console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const dColumns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.SL,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => row?.Status,
      width: "140px",
    },
    {
        name: (
          <div
            style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
          >
            Booking Date
          </div>
        ),
        selector: (row) => moment(row?.BookingDate).format("DD-MM-YYYY"),
        width: "140px",
      },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Domestic Service(s)
        </div>
      ),
      selector: (row) => {
        return row.Services.length > 0 ? (
          <>
            <ul>
              {row.Services.map((item, index) => {
                return (
                  <>
                    <li>{item?.serviceName}</li>
                  </>
                );
              })}
            </ul>
          </>
        ) : (
          <>n/a</>
        );
      },
      width: "250px",
      wrap: true,
    },
  ];

  const aColumns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.SL,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => row?.Status,
      width: "140px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          AMC Service(s)
        </div>
      ),
      selector: (row) => row?.Services,
      width: "150px",
      wrap: true,
    },
  ];

  const cColumns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.SL,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => row?.Status,
      width: "140px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Contract Service(s)
        </div>
      ),
      selector: (row) => row?.Services,
      width: "150px",
      wrap: true,
    },
  ];

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Job List(s) of vendor {vendorData?.name}
            </div>

            {loader ? (
              <Loader />
            ) : (
              <>
                <div class="card">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <DataTable
                        columns={aColumns}
                        data={amcJobs}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                        noDataComponent="No AMC services"
                      />
                    </li>
                    <li class="list-group-item">
                      <DataTable
                        columns={cColumns}
                        data={contractJobs}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                        noDataComponent="No Contract services"
                      />
                    </li>
                    <li class="list-group-item">
                      <DataTable
                        columns={dColumns}
                        data={domenticJobs}
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                        noDataComponent="No Domestic services"
                      />
                    </li>
                  </ul>
                </div>
              </>
            )}

            {/* <DataTable columns={columns} data={allData} pagination /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorTasks;
