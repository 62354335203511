import React, { useEffect, useState } from "react";
import NewHomService from "../../Service/NewHomService";

const VendorListByCategory = ({ booking }) => {
  const [vendorList, setVendorList] = useState([]);
  console.log("vendorList",vendorList);
  useEffect(() => {
    console.log("UUD",booking);
    fetchAllVendor(booking);
  }, []);

  const fetchAllVendor = (id) => {
    console.log("XRT",id);
    NewHomService.FetchVendorsByCategory(id)
      .then((res) => {
        console.log("AHAHAHA15", res);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setVendorList(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      {vendorList?.map((ele, key) => {
        return (
          <option
            // selected={ele._id == aVendorId ? true : false}
            value={ele.vendorId}
            key={key}
          >
            {ele.vendorName}
          </option>
        );
      })}
    </>
  );
};

export default VendorListByCategory;
