import React, { useContext, useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { LOADINGDATA } from '../../App';
import HomeService from '../../Service/HomeService';
import toast from 'react-hot-toast';
import Loader from '../../Component/Loader';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import DataTable from 'react-data-table-component';

const ZipCode = () => {
    const { loader, setloader } = useContext(LOADINGDATA);

    const [name, setName] = useState([]);
    const [state_id, setstate_id] = useState("");
    const [Dist_id, setdist_id] = useState("");
    const [ZIP_Code, setZIP_Code] = useState("");

    // console.log(state_id);
    const [allState, setAllState] = useState([]);
    const [allCity, setAllCity] = useState([]);
    // const [totalCountry, settotalCountry] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [searchState, setSearchState] = useState("")
    const [searchCity, setSearchCity] = useState("")
    const [searchZIP, setSearchZIP] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    useEffect(() => {
        fetchAllzip();
        fetchAllState();
        fetchCSVData()
    }, []);

    useEffect(() => {
        fetchCSVData()
    }, [allCity]);
    useEffect(() => {
        if (state_id) {
            districtStateWise()
        }

    }, [state_id]);

    const onEdit = (item) => {
        window.scrollTo(0, 0);
        console.log("item", item);
        // setName(item?.name);
        setId(item?._id);
        setstate_id(item.stateId);
        setdist_id(item.districtId);
        setZIP_Code(item.zipcode)
        setHide(false);
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                HomeService.DeleteZip(id)
                    .then((res) => {
                        // console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            // console.log("RESPONSEDELETE", res);
                            fetchAllzip();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };
    // state
    const fetchAllState = () => {
        HomeService.ManageState()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    let arr = res?.data;
                    setAllState(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllzip = () => {
        HomeService.ManageZipCode()
            .then((res) => {
                // console.log("DATA96", res.data);
                if (res && res?.status) {
                    setloader(false);
                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        // console.log(item);
                        return {
                            sl: index + 1,
                            districtName: item?.districtName,
                            zipcode: item?.zipcode,
                            State: item?.stateName,
                            ID: item?._id,
                            Status: item?.status,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setFilteredData(arr)
                    setAllCity(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                setloader(false);
                console.log("err", err);
            });
    };

    const AddZipcode = () => {
        let data = {

            stateId: state_id,
            districtId: Dist_id,
            zipcode: ZIP_Code
        };
        if (Dist_id && state_id && ZIP_Code &&
            !cityErrorMsg) {
            // alert(name + country_id)
            HomeService.AddZip(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllzip();
                        // setName([]);
                        // setstate_id("");
                        // setdist_id('')
                        setZIP_Code('')
                        setCityErrorMsg("")
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!Dist_id) {
                return toast.error("District Name Field Is Required");
            }
            if (!state_id) {
                return toast.error("State Name Field Is Required");
            }
        }
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            wrap: true,
            center: true,
            width: "75px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    State
                </div>
            ),
            selector: (row) => row.State,
            wrap: true,
            center: true,
            width: "250px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    District Name
                </div>
            ),
            selector: (row) => row.districtName,
            wrap: true,
            center: true,
            // width: "250px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    ZIP Code
                </div>
            ),
            selector: (row) => row.zipcode,
            wrap: true,
            center: true,
            // width: "250px"
        },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
            wrap: true,
            center: true,
            width: "125px"
        },
    ];

    const UpdateZipCode = () => {
        console.log("ID", id);
        let data = {

            stateId: state_id,
            districtId: Dist_id,
            zipcode: ZIP_Code
        };
        HomeService.UpdateZipCode(id, data)
            .then((res) => {
                // console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    // console.log("RESPONSEDELETE", res);
                    setName([]);
                    setstate_id("");
                    setdist_id("");
                    setZIP_Code('')
                    fetchAllzip();
                } else {
                    toast.error("Error occured");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const districtStateWise = () => {
        HomeService.ManageDisStateWise(state_id)
            .then((res) => {
                // console.log("updated res", res);
                if (res && res.status) {
                    // toast.success("Updated Successfully");
                    setHide(true);
                    // console.log("RESPONSEDELETE", res);
                    setName(res?.data)
                } else {
                    toast.error("Error occured");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const [cityErrorMsg, setCityErrorMsg] = useState("")

    const checkTextValue = (e) => {
        const regex = new RegExp("^[a-zA-Z\-_ \s]+$");
        if (e.target.value === "" || regex.test(e.target.value)) {
            setName(e.target.value)
            setCityErrorMsg("")
            return true
        } else {
            setCityErrorMsg("Invalid Input")
            return false
        }
    }

    const changeStatus = (id, val) => {
        let data = {
            status: val
        }
        HomeService.UpdateDistrict(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    fetchAllzip()
                } else {
                    toast.error(res?.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    ////CSV Download//////////////

    const [csvData, setCsvData] = useState([]);


    const fetchCSVData = () => {
        // NewHomService.FetchAllUsers()
        //   .then((res) => {
        //     if (res && res?.status) {
        // setLoader(false);
        // console.log("DDDR",allCity);
        let arr = allCity
            ?.map((item, index) => {

                return {
                    SLNO: index + 1,
                    // ID: item?._id,
                    State: item?.State ? item?.State : "N/A",
                    District: item?.districtName ? item?.districtName : "N/A",
                    ZIP_code: item?.zipcode ? item?.zipcode : "N/A"
                };
            });
        console.log("DATASSS359", arr);
        setCsvData(arr);
        //   }
        // })
        // .catch((err) => {
        //   console.log("err", err);
        // });
    };

    ////CSV Download//////////////

    const onFilter = () => {
        let searchData = allCity;
        console.log("GSGS388", searchData, searchZIP);

        //City
        if (searchCity !== "") {
            searchData = searchData.filter((item) => {
                if (item?.districtName?.toLowerCase().includes(searchCity.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //State
        if (searchState !== "") {
            searchData = searchData.filter((item) => {
                if (item?.State?.toLowerCase().includes(searchState.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //ZIP
        if (searchZIP !== "") {
            searchData = searchData.filter((item) => {
                if (item?.zipcode.toString().includes(searchZIP.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }

    }

    const viewAllData = () => {
        setSearchCity("")
        setSearchState("")
        setSearchZIP("")
        setFilteredData(allCity)
    }

    const onCustomFilter = (s_id) => {
        // console.log("GDGD43",s_id);
        // setLoader(true)
        HomeService.ManageZipCode()
            .then((res) => {

                if (res && res?.status) {
                    // setLoader(false)
                    let arr = []
                    if (s_id) {
                        arr = res?.data?.filter((item) => {
                            console.log("GDGD441", item.districtId, s_id);
                            if (
                                item?.districtId
                                    .includes(s_id)
                            ) {
                                // console.log("GDGD446",item,s_id);
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                console.log("item451", item);
                                return {
                                    sl: index + 1,
                                    districtName: item?.districtName,
                                    zipcode: item?.zipcode,
                                    State: item?.stateName,
                                    ID: item?._id,
                                    Status: item?.status,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })


                    }
                    console.log("GDGD567", arr, s_id);
                    setFilteredData(arr);
                }
                // console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    if (loader) {
        return (
            <>
                <Loader />
            </>
        );
    } else
        return (
            <>
                <div component="div" className="TabsAnimation appear-done enter-done">
                    <div className="main-card mb-3 card">
                        <div className="card-body">
                            {hide ? (
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: "20px",
                                        color: "#868e96",
                                        margin: "35px",
                                    }}
                                    className="card-title"
                                >
                                    Add Zip Code
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textAlign: "center",
                                        fontSize: "20px",
                                        color: "#868e96",
                                        margin: "35px",
                                    }}
                                    className="card-title"
                                >
                                    Edit Zip Code
                                </div>
                            )}

                            <div class="form-group">
                                <label for="exampleInputEmail1">
                                    State Name<span style={{ color: "red" }}>*</span> :
                                </label>
                                <select onChange={(e) => {
                                    setstate_id(e.target.value)
                                    // e.target.value && onCustomFilter(e.target.value)
                                }}>
                                    <option value="">Select</option>
                                    {allState?.map((ele, id) => {
                                        console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == state_id ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {/* district */}
                            <div class="form-group">
                                <label for="exampleInputEmail1">
                                    District Name<span style={{ color: "red" }}>*</span> :
                                </label>
                                <select onChange={(e) => {
                                    setdist_id(e.target.value)
                                    e.target.value && onCustomFilter(e.target.value)
                                }}>
                                    <option value="">Select</option>
                                    {name?.map((ele, id) => {
                                        console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == Dist_id ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleInputEmail1">
                                    ZIP Code<span style={{ color: "red" }}>*</span> :
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    value={ZIP_Code}
                                    onChange={(e) => setZIP_Code(e.target.value)}
                                />
                                <small class="form-text text-danger">{cityErrorMsg}</small>
                            </div>

                            {hide ? (
                                <button class="btn btn-primary" onClick={AddZipcode}>
                                    Submit
                                </button>
                            ) : (
                                <button class="btn btn-primary" onClick={UpdateZipCode}>
                                    Update
                                </button>
                            )}

                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Manage District
                            </div>
                            <div class="form-inline my-2">

                                <input class="form-control mr-sm-2" type="search" placeholder="State" aria-label="Search"
                                    value={searchState}
                                    onChange={(e) => {
                                        setSearchState(e.target.value)
                                    }}
                                />
                                <input class="form-control mr-sm-2" type="search" placeholder="District" aria-label="Search"
                                    value={searchCity}
                                    onChange={(e) => {
                                        setSearchCity(e.target.value)
                                    }}
                                />
                                <input class="form-control mr-sm-2" type="search" placeholder="ZIP Code" aria-label="Search"
                                    value={searchZIP}
                                    onChange={(e) => {
                                        setSearchZIP(e.target.value)
                                    }}
                                />


                                <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                    onClick={onFilter}
                                >Filter</button>
                                <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                    onClick={viewAllData}
                                >View All</button>
                            </div>
                            {
                                loader ? (<>
                                    <Loader />
                                </>) : (<>
                                    <CSVLink
                                        className="btn btn-sm btn-info"
                                        filename={`city-list${moment().format("YYYYMMDD")}.csv`}
                                        data={csvData}
                                    >
                                        Export to CSV
                                    </CSVLink>
                                    <DataTable columns={columns} data={filteredData} pagination />
                                </>)
                            }

                        </div>
                    </div>
                </div>
            </>
        );
};

export default ZipCode