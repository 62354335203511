import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";

import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import ImageLoader from "../Loader/ImageLoader";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import Loader from "../../Component/Loader";

const ManageTraining = () => {
  const [trainingDate, setTrainingDate] = useState("");
  const [trainingTime, setTrainingTime] = useState("");
  const [trainingMode, setTrainingMode] = useState("");

  //   const [imgdata, setimgdata] = useState("");
  //   const [image, setimage] = useState("");
  //   const [duration, setduration] = useState("");
  //   const [actualPrice, setactualPrice] = useState("");
  //   const [discountPrice, setdiscountPrice] = useState("");
  //   const [summary, setsummary] = useState("");
  const [service_type_id, setservice_type_id] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [allData, setAllData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [totalService_type, settotalService_type] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);
  const [totalSubcategory, settotalSubcategory] = useState([]);

  const [singledata, setsingleData] = useState({});
  const [loader, setLoader] = useState(false)

  console.log("2data", subcategory_id);
  const [fquestions, setfquestions] = useState([
    {
      question: "",
      answer: "",
    },
  ]);

  // const [image, setimage] = useState("")

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCategory();
    // getAllSubCategory()
    fetchAllData();
    getAllService_type();

    // if (category_id) {
    //     getAllSubCategory(category_id)
    // }
  }, []);

  // useEffect(()=>{
  //    service_type_id && getAllCategoryinService_type(service_type_id)
  //   },[service_type_id,])

  const resetState = () => {
    setservice_type_id("");
    setcategory_id("");
    setsubcategory_id("");
    setTrainingDate("");
    setTrainingTime("");
    setTrainingMode("");

    // let file = document.querySelector("#images");
    // file.value = "";
  };

  const onEdit = (item) => {
    // getAllService_type();
    // getAllCategoryinService_type(item?.serviceTypeId)
    window.scroll(0, 0);
    console.log("ITEMDATA", item);

    setservice_type_id(item?.serviceTypeID);
    getAllCategoryinService_type(item?.serviceTypeID);
    setcategory_id(item.categoryID);
    getAllSubCategory(item.categoryID);
    setsubcategory_id(item.subCategoryID);
    setTrainingDate(item.trainingDate);
    setTrainingTime(item.trainingTime);
    setTrainingMode(item.trainingMode);

    // setsingleData(item);

    // setactualPrice(item?.actualPrice);
    // setdiscountPrice(item?.discountPrice);
    // setsummary(item?.summary);

    setId(item?._id);

    // setimage(IMAMGE_URL + item.serviceImage[0]);
    // setimage(item.serviceImage[0]);
    // setimgdata(item.serviceImage[0]);
    // setduration(item?.duration);

    // setcategory_id(item.countryId._id);
    // setfquestions(item?.frequentlyQuestions);

    // item?.note && setNoteValues(item?.note);

    // item?.howToServiceWork && setHowToServiceWork(item?.howToServiceWork);
    // item?.includes && setIncludesValues(item?.includes);
    // item?.excludes && setExcludesValues(item?.excludes);

    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        NewHomService.DeleteVendorTraining(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              //   console.log("RESPONSEDELETE", res);
              fetchAllData();
            } else {
              toast.error("Error occured!");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  // const organiseData = () => {
  //     let temarr = [...questio_ans]
  //     const tempobj = {
  //         question: question,
  //         answer: ans
  //     }
  //     temarr.push(tempobj);
  //     setquestio_ans(temarr)
  //     setquestion('')
  //     setans('')
  // }

  const addQuestion = () => {
    setfquestions([...fquestions, { question: "", answer: "" }]);
  };

  const updateQuestion = (index, field, value) => {
    const updatedQuestions = [...fquestions];
    updatedQuestions[index][field] = value;
    setfquestions(updatedQuestions);
  };
  const deleteQuestion = (index) => {
    const updatedQuestions = fquestions.filter((_, i) => i !== index);
    setfquestions(updatedQuestions);
  };

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log("sub_cat", res?.data);
          settotalSubcategory(res?.data);
        } else {
          settotalSubcategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllData = () => {
    NewHomService.FetchVendorTraining()
      .then((res) => {
        console.log("ALLDATA", res);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data
            ?.sort()
            .reverse()
            .map((item, index) => {
              return {
                sl: index + 1,
                ServiceType: item?.serviceTypeDetail?.serviceName,
                Category: item?.categoryDetail?.name,
                Subcategory: item?.subcategoryDetail?.name,
                TrainingDate: item?.trainingDate,
                TrainingTime: item?.trainingTime,
                TrainingMode: item?.trainingMode,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          setFilteredData(arr);
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res?.data);
      //   setimage(res?.doc?.url);
      //   setimgdata(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const addData = () => {
    // const temparr = [];
    // temparr.push(imgdata);
    let data = {
      serviceTypeID: service_type_id,
      categoryID: category_id,
      // subCategoryID: subcategory_id,
      trainingDate: trainingDate,
      trainingTime: trainingTime,
      trainingMode: trainingMode,
    };
    // console.log("ADDDATA", data);
    //    return
    if (
      service_type_id &&
      category_id &&
      trainingDate &&
      trainingTime &&
      trainingMode
    ) {
      NewHomService.AddVendorTraining(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            // toast.success(res.message);
            toast.success("Added successfully");
            fetchAllData();
            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!category_id) {
        return toast.error("Choose Category");
      }
      // if (!actualPrice) {
      //   return toast.error("ActualPrice Name Field Is Required");
      // }
      // if (!subcategory_id) {
      //   return toast.error("SubCategory Name Field Is Required");
      // }

      //   if (!imgdata) {
      //     return toast.error("Service Image Field Is Required");
      //   }
      //   if (!discountPrice) {
      //     return toast.error("Price  Field Is Required");
      //   }
      //   if (!summary) {
      //     return toast.error("Summary  Field Is Required");
      //   }
      //   if (!fquestions) {
      //     return toast.error("FrequentlyQuestions  Field Is Required");
      //   }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.ServiceType,
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.Category,
      wrap: true,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Sub Category
    //     </div>
    //   ),
    //   selector: (row) => row.Subcategory,
    //   wrap: true,
    // },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Date
        </div>
      ),
      selector: (row) => {
        return <>{moment(row.TrainingDate).format("DD-MM-YYYY")}</>;
      },
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Time
        </div>
      ),
      selector: (row) => {
        return <>{moment(row.TrainingTime, "HH:mm").format("hh:mm A")}</>;
        // return <>{row.TrainingTime}</>;
      },
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Mode
        </div>
      ),
      selector: (row) => row.TrainingMode,
      wrap: true,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       ActualPrice
    //     </div>
    //   ),
    //   selector: (row) => row.actualPrice,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Actual Price
    //     </div>
    //   ),
    //   selector: (row) => row.discountPrice,
    //   wrap: true,
    // },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const updateData = () => {
    // const temparr = [];
    // temparr.push(imgdata);

    console.log("ID", id);
    let data = {
      serviceTypeID: service_type_id,
      categoryID: category_id,
      // subCategoryID: subcategory_id,
      trainingDate: trainingDate,
      trainingTime: trainingTime,
      trainingMode: trainingMode,
    };
    console.log("data515", data);
    NewHomService.UpdateVendorTraining(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          fetchAllData();
          resetState();
        } else {
          toast.error("Error occured!");
        }
      })
      .catch((err) => {
        console.log("err");
      });
  };

  const [onEditing, setOnEditing] = useState(false);
  const initialHowToServiceWork = {
    title: "",
    description: "",
    image: "",
  };

  const [howToServiceWork, setHowToServiceWork] = useState([
    initialHowToServiceWork,
  ]);
  const handleChange = (i, e) => {
    let newFormValues = [...howToServiceWork];
    newFormValues[i][e.target.name] = e.target.value;
    setHowToServiceWork(newFormValues);
  };

  const [serviceWorkImageLoader, setServiceWorkImageLoader] = useState(false);
  const handleServiceWorkImage = async (i, e) => {
    setServiceWorkImageLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log('L86:', data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);

    if (res && res.status) {
      console.log("UploadImageRes", res);
      // setBannerImage(res?.url);
      let newFormValues = [...howToServiceWork];
      newFormValues[i].image = res?.doc?.url;
      setHowToServiceWork(newFormValues);
      // console.log("L60: ",howToServiceWork[0].image);
    } else {
      toast.error(res?.message);
    }
    setServiceWorkImageLoader(false);
  };
  const addServiceWork = () => {
    // alert(howToServiceWork.length)
    if (howToServiceWork?.length < 5000) {
      setHowToServiceWork([...howToServiceWork, initialHowToServiceWork]);
    } else {
      Swal("");
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeServiceWork = (i) => {
    let newFormValues = [...howToServiceWork];
    newFormValues.splice(i, 1);
    setHowToServiceWork(newFormValues);
  };

  const onFilter = () => {
    if (searchText !== "") {
      // serSearchText(e.target.value);
      console.log("searchText", searchText);
      let searchData = allData.filter((item) => {
        if (
          item?.ServiceType !== undefined &&
          item?.ServiceType.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return item;
        }
      });
      console.log("searchData", searchData);
      setFilteredData(searchData);
      // setTableRowsData(searchData);
    } else {
      setFilteredData(allData);
    }
  };

  const viewAllData = () => {
    setSearchText("");
    setFilteredData(allData);
  };

  const onCustomFilter = (s_id, c_id, t_dt, t_time, t_mode) => {
    setLoader(true)
    NewHomService.FetchVendorTraining()
      .then((res) => {
        console.log("gdgd633", res);
        if (res && res?.status) {
          setLoader(false)
          let arr = []
          if (s_id && c_id && t_dt && t_time && t_mode) {
            arr = res?.data?.filter((item) => {
              // console.log("TYR639", item, t_time);
              if (
                item?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .filter((item) => {
                if (
                  item?.categoryID
                    .includes(c_id)
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  moment(item?.trainingDate).format("YYYY-MM-DD") === t_dt
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  moment(item?.trainingTime, 'HHmmss').format("HH:mm") === t_time
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  item?.trainingMode
                    .includes(t_mode)
                ) {
                  return item;
                }
              })
              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  ServiceType: item?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryDetail?.name,
                  Subcategory: item?.subcategoryDetail?.name,
                  TrainingDate: item?.trainingDate,
                  TrainingTime: item?.trainingTime,
                  TrainingMode: item?.trainingMode,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          }

          else if (s_id && c_id && t_dt && t_time) {
            arr = res?.data?.filter((item) => {
              console.log("TYR639", moment(item?.trainingTime, 'HHmmss').format("HH:mm"), t_time);
              if (
                item?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .filter((item) => {
                if (
                  item?.categoryID
                    .includes(c_id)
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  moment(item?.trainingDate).format("YYYY-MM-DD") === t_dt
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  moment(item?.trainingTime, 'HHmmss').format("HH:mm") == t_time
                ) {
                  return item;
                }
              })

              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  ServiceType: item?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryDetail?.name,
                  Subcategory: item?.subcategoryDetail?.name,
                  TrainingDate: item?.trainingDate,
                  TrainingTime: item?.trainingTime,
                  TrainingMode: item?.trainingMode,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          }

          else if (s_id && c_id && t_dt) {
            arr = res?.data?.filter((item) => {
              console.log("TYR639", item, t_time);
              if (
                item?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .filter((item) => {
                if (
                  item?.categoryID
                    .includes(c_id)
                ) {
                  return item;
                }
              })
              .filter((item) => {
                if (
                  moment(item?.trainingDate).format("YYYY-MM-DD") === t_dt
                ) {
                  return item;
                }
              })
              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  ServiceType: item?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryDetail?.name,
                  Subcategory: item?.subcategoryDetail?.name,
                  TrainingDate: item?.trainingDate,
                  TrainingTime: item?.trainingTime,
                  TrainingMode: item?.trainingMode,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          }

          else if (s_id && c_id) {
            arr = res?.data?.filter((item) => {
              // console.log("TYR639", item, t_time);
              if (
                item?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .filter((item) => {
                if (
                  item?.categoryID
                    .includes(c_id)
                ) {
                  return item;
                }
              })

              .sort().reverse().map((item, index) => {
                // console.log("item", item);
                return {
                  sl: index + 1,
                  ServiceType: item?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryDetail?.name,
                  Subcategory: item?.subcategoryDetail?.name,
                  TrainingDate: item?.trainingDate,
                  TrainingTime: item?.trainingTime,
                  TrainingMode: item?.trainingMode,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          }

          else if (s_id) {
            // console.log("AER183", s_id);
            arr = res?.data?.filter((item) => {
              if (
                item?.serviceTypeID
                  .includes(s_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                // console.log("item672", item);
                return {
                  sl: index + 1,
                  ServiceType: item?.serviceTypeDetail?.serviceName,
                  Category: item?.categoryDetail?.name,
                  Subcategory: item?.subcategoryDetail?.name,
                  TrainingDate: item?.trainingDate,
                  TrainingTime: item?.trainingTime,
                  TrainingMode: item?.trainingMode,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else {
            arr = res?.data?.sort().reverse().map((item, index) => {
              // console.log("item", item);
              return {
                sl: index + 1,
                ServiceType: item?.serviceTypeDetail?.serviceName,
                Category: item?.categoryDetail?.name,
                Subcategory: item?.subcategoryDetail?.name,
                TrainingDate: item?.trainingDate,
                TrainingTime: item?.trainingTime,
                TrainingMode: item?.trainingMode,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          }
          setFilteredData(arr);
        }
        // console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Training
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Update Training
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  getAllCategoryinService_type(e.target.value)
                  e.target.value && onCustomFilter(e.target.value, "", "", "", "")
                }}
              // value={service_type_id}
              >
                <option value="">Choose...</option>
                {totalService_type?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == service_type_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  getAllSubCategory(e.target.value);
                  e.target.value && onCustomFilter(service_type_id, e.target.value, "", "", "")
                }}
              >
                <option value="">Choose...</option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Sub Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setsubcategory_id(e.target.value)}>
                <option value="">Choose...</option>
                {totalSubcategory?.map((ele, id) => {
                  console.log("eedata", ele);

                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}

            <div className="row">
              <div class="form-group col-4">
                <label for="exampleInputEmail1">
                  Date<span style={{ color: "red" }}>*</span> :
                </label>
                <input
                  type="date"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={moment(trainingDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setTrainingDate(e.target.value)
                    e.target.value && onCustomFilter(service_type_id, category_id, e.target.value, "", "")
                  }}
                />
              </div>

              <div class="form-group col-4">
                <label for="exampleInputEmail1">
                  Time<span style={{ color: "red" }}>*</span> :
                </label>
                <input
                  type="time"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={trainingTime}
                  onChange={(e) => {
                    setTrainingTime(e.target.value)
                    e.target.value && onCustomFilter(service_type_id, category_id, trainingDate, e.target.value, "")
                  }}
                />
              </div>

              <div class="form-group col-4">
                <label for="exampleInputEmail1">
                  Mode<span style={{ color: "red" }}>*</span> :
                </label>
                {/* <input
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={trainingMode}
                  onChange={(e) => setTrainingMode(e.target.value)}
                /> */}

                <select
                  onChange={(e) => {
                    setTrainingMode(e.target.value)
                    e.target.value && onCustomFilter(service_type_id, category_id, trainingDate, trainingTime, e.target.value)
                  }}
                // value={service_type_id}
                >
                  <option value="">Choose...</option>
                  <option
                    value="online"
                    selected={trainingMode === "online" ? true : false}
                  >
                    Only Online
                  </option>
                  <option
                    value="offline"
                    selected={trainingMode === "offline" ? true : false}
                  >
                    Only Offline
                  </option>
                  <option
                    value="both"
                    selected={trainingMode === "both" ? true : false}
                  >
                    Both Online & Offline
                  </option>
                </select>
              </div>
            </div>

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Duration<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={duration}
                onChange={(e) => setduration(e.target.value)}
              />
            </div> */}

            {/* <div class="form-group">
              <label for="formFile" class="form-label">
                Service Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />

              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div> */}

            {/* <div class="form-group" style={{ display: "none" }}>
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={actualPrice}
                onChange={(e) => setactualPrice(e.target.value)}
              />
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Actual Price<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={discountPrice}
                onChange={(e) => setdiscountPrice(e.target.value)}
              />
            </div> */}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Summary<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={summary}
                onChange={(e) => setsummary(e.target.value)}
              />
            </div> */}

            {/* Note */}

            {/* //Note */}

            {/* Multiple Service Works */}

            {/* //Multiple Service Works */}

            {/* Includes */}

            {/* //Includes */}

            {/* Excludes */}

            {/* //Excludes */}

            {hide ? (
              <button class="btn btn-primary" onClick={addData}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={updateData}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Training(s)
            </div>
            {/* <div class="form-inline my-2">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Filter by"
                aria-label="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div> */}
            {
              loader ? (<Loader />) : (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  paginationPerPage={25}
                  paginationRowsPerPageOptions={[25, 50, 100]}
                />
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default ManageTraining;
