import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Swal from "sweetalert2";
import NewHomService from "../../Service/NewHomService";
import HomeService from "../../Service/HomeService";
import { useActionData } from "react-router-dom";
import { Alert } from "bootstrap";
import moment from "moment";

var vendorIdGlobal = null;
var startDateGlobal = null;
var endDateGlobal = null;
var startTimeGlobal = null;
var endTimeGlobal = null;

const ApplicationForms = () => {
    // const [name, setName] = useState("");
    // const [price, setprice] = useState("");

    const [allData, setAllData] = useState([]);

    const [allmodeldata, setallModeldata] = useState([])
    const [allsubcatdata, setallsubcatdata] = useState([])

    const [showSubcategory, setShowSubcategory] = useState(false)
    const [allVendor, setAllVendor] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);

    const [serviceTypeID, setServiceTypeID] = useState("")
    const [serviceCategoryID, setServiceCategoryID] = useState("");
    // const [serviceSubCategoryID, setServiceSubCategoryID] = useState("")
    const [displayDetails, setDisplayDetails] = useState(false)
    // const [assignVendorId, setAssignVendorId] = useState("")
    // const [startDate, setStartDate] = useState("")
    const [formDetails, setFormDetails] = useState({})
    const [subcat_Id, setsubcat_Id] = useState("");

    const [selectedData, setSelectedData] = useState();
    const [assignedVendorList, setAssignedVendorList] = useState([])

    const [allApplications, setAllApplications] = useState([])
    const initialApplicationsWithVendor = {
        applicationId: "",
        customerName: "",
        customerMobile: "",
        assignedVendorId: "",
        taskStartDate: "",
        taskStartTime: "",
        taskEndDate: "",
        taskEndTime: "",
    }
    const [applicationsWithVendor, setApplicationsWithVendor] = useState([])


    useEffect(() => {
        setServiceTypeID("64d602b2e6419455b67e1ed8")
        fetchAllCategory("64d602b2e6419455b67e1ed8")
        fetchAllVendor()
    }, []);

    const assignVerdor = (id, categoryId) => {
        // console.log("DTA",id, vendorIdGlobal, startDateGlobal, startTimeGlobal,endDateGlobal,endTimeGlobal);
        if (id) {
            let data = {
                applicationFormId: id,
                vendorId: vendorIdGlobal,
                startDate: startDateGlobal,
                endDate: endDateGlobal,
                startTime: startTimeGlobal,
                endTime: endTimeGlobal,
            };
            // console.log("data",data);
            NewHomService.AssignAMCVendor(data)
                .then((res) => {
                    // console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Vendor Assigned Successfully");

                        fetchAllData(categoryId);
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        else {
            toast.error("Error Occured!")
        }
    }


    const fetchAllApplications = (serviceCategoryID) => {
        NewHomService.FetchAMCFormsByCategory(serviceCategoryID)
            .then((res) => {
                if (res && res?.status) {
                    let arr = res?.data;
                    setAllApplications(arr)
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllAssignedVendor = () => {
        NewHomService.FetchAllAssignedVendors()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAssignedVendorList(arr)
                    console.log("assignedVendorList", assignedVendorList);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllVendor = () => {
        NewHomService.FetchVendors()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAllVendor(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    settotalCategory(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onView = (id) => {
        NewHomService.FetchAMCFormDetails(id)
            .then((res) => {

                if (res && res.status) {
                    toast.success("Fetched successfully");
                    console.log("FormData", res);
                    setFormDetails(res?.data)
                    setDisplayDetails(true)
                    window.scroll(0, 0)
                    fetchAllData();
                } else {
                    toast.error("Failed to retrive form data");
                    setDisplayDetails(false)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchAllData = (serviceCategoryID) => {
        fetchAllApplications(serviceCategoryID)       
        fetchAllAssignedVendor();
        
        generateAssignedApplication()
        console.log("CONSOLE",applicationsWithVendor);

        if (Object.keys(applicationsWithVendor).length > 0) {
            // setLoader(false)
            let arr = applicationsWithVendor?.map((item, index) => {
                // const aVendorIdx = checkAssignedVendor(item._id, "vendorId")
                console.log("CONSOLE", item);
                // console.log("X",x);
                // console.log("llll", item?.materialTypeName);
                // let arx = assignedVendor.filter((task) => {task.applicationFormId === "14"})
                return {
                    Sl: index + 1,
                    ID: item?._id,
                    Name: item?.customerName,
                    Mobile: item?.customerMobile,
                    // action: (
                    //     <div
                    //         // style={{ display: "flex", flexDirection: "coloum" }}
                    //         style={{ display: "block", flexDirection: "coloum" }}
                    //     >
                    //         {

                    //             aVendorIdx !== "" ? (
                    //                 <>

                    //                 </>
                    //             ) : (
                    //                 <>
                    //                     <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"
                    //                         onClick={(e) => assignVerdor(item?._id, item?.categoryData._id)}
                    //                         className="mr-3"
                    //                         style={{
                    //                             height: "20px",
                    //                             width: "20px",
                    //                             cursor: "pointer",
                    //                             marginRight: "20px",
                    //                         }}
                    //                     >

                    //                         <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
                    //                     </svg>
                    //                 </>
                    //             )
                    //         }




                    //         <svg
                    //             onClick={() => onView(item?._id)}
                    //             style={{
                    //                 height: "20px",
                    //                 width: "20px",
                    //                 cursor: "pointer",
                    //                 marginRight: "20px",
                    //             }}
                    //             xmlns="http://www.w3.org/2000/svg"
                    //             width="16"
                    //             height="16"
                    //             fill="currentColor"
                    //             class="bi bi-pencil-square"
                    //             viewBox="0 0 16 16"
                    //         >
                    //             <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
                    //             <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />

                    //         </svg>

                    //         {/* <button
                    //             className="btn btn-sm btn-primary"
                    //             onClick={(e) => assignVerdor(item?._id)}
                    //         >
                    //             Assign Vendor
                    //         </button>

                    //         <button
                    //             className="btn btn-sm btn-info"
                    //             onClick={() => onView(item?._id)}
                    //         >
                    //             View Task
                    //         </button> */}




                    //     </div>
                    // ),
                };
            });
            applicationsWithVendor(arr);
        }

    }

    // const _fetchAllData = (serviceCategoryID) => {
    //     NewHomService.FetchAMCFormsByCategory(serviceCategoryID)
    //         .then((res) => {
    //             if (res && res?.status) {
    //                 // setLoader(false)
    //                 let arr = res?.data?.sort().reverse().map((item, index) => {
    //                     const aVendorIdx = checkAssignedVendor(item._id, "vendorId")
    //                     console.log("ResAllBlog", item);
    //                     // console.log("X",x);
    //                     // console.log("llll", item?.materialTypeName);
    //                     // let arx = assignedVendor.filter((task) => {task.applicationFormId === "14"})
    //                     return {
    //                         Sl: index + 1,
    //                         ID: item?._id,
    //                         Name: item?.name,
    //                         Mobile: item?.mobile_number,
    //                         action: (
    //                             <div
    //                                 // style={{ display: "flex", flexDirection: "coloum" }}
    //                                 style={{ display: "block", flexDirection: "coloum" }}
    //                             >
    //                                 {

    //                                     aVendorIdx !== "" ? (
    //                                         <>

    //                                         </>
    //                                     ) : (
    //                                         <>
    //                                             <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"
    //                                                 onClick={(e) => assignVerdor(item?._id, item?.categoryData._id)}
    //                                                 className="mr-3"
    //                                                 style={{
    //                                                     height: "20px",
    //                                                     width: "20px",
    //                                                     cursor: "pointer",
    //                                                     marginRight: "20px",
    //                                                 }}
    //                                             >

    //                                                 <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.994 19a8.999 8.999 0 1 1 3.53-17.281M5.995 9l4 4 7-8m-1 8v5m-2.5-2.5h5" />
    //                                             </svg>
    //                                         </>
    //                                     )
    //                                 }




    //                                 <svg
    //                                     onClick={() => onView(item?._id)}
    //                                     style={{
    //                                         height: "20px",
    //                                         width: "20px",
    //                                         cursor: "pointer",
    //                                         marginRight: "20px",
    //                                     }}
    //                                     xmlns="http://www.w3.org/2000/svg"
    //                                     width="16"
    //                                     height="16"
    //                                     fill="currentColor"
    //                                     class="bi bi-pencil-square"
    //                                     viewBox="0 0 16 16"
    //                                 >
    //                                     <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Zm-3 15H4.828a1 1 0 0 1 0-2h6.238a1 1 0 0 1 0 2Zm0-4H4.828a1 1 0 0 1 0-2h6.238a1 1 0 1 1 0 2Z" />
    //                                     <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />

    //                                 </svg>

    //                                 {/* <button
    //                                     className="btn btn-sm btn-primary"
    //                                     onClick={(e) => assignVerdor(item?._id)}
    //                                 >
    //                                     Assign Vendor
    //                                 </button>

    //                                 <button
    //                                     className="btn btn-sm btn-info"
    //                                     onClick={() => onView(item?._id)}
    //                                 >
    //                                     View Task
    //                                 </button> */}




    //                             </div>
    //                         ),
    //                     };
    //                 });
    //                 setAllData(arr);
    //             }
    //             // console.log("RESPONSE", res);
    //         })
    //         .catch((err) => {
    //             console.log("err", err);
    //         });
    // };




    const generateAssignedApplication = () => {
        // console.log("allData",allData);
        let newDataArr = []
        const newArr = allApplications.map((item, index) => {
            console.log("item", item);
            initialApplicationsWithVendor.applicationId = item?._id
            initialApplicationsWithVendor.customerName = item?.name
            initialApplicationsWithVendor.customerMobile = item?.mobile_number

            let assignedInfo = assignedVendorList?.filter((itm) => {
                return itm.applicationFormId == item?._id
            })
            if (Object.keys(assignedInfo).length > 0) {

                initialApplicationsWithVendor.assignedVendorId = assignedInfo[0]?.vendorId
                initialApplicationsWithVendor.taskStartDate = assignedInfo[0]?.startDate
                initialApplicationsWithVendor.taskStartTime = assignedInfo[0]?.startTime
                initialApplicationsWithVendor.taskEndDate = assignedInfo[0]?.endDate
                initialApplicationsWithVendor.taskEndTime = assignedInfo[0]?.endTime
            }
            // else {
            //     initialApplicationsWithVendor.assignedVendorId = ""
            //     initialApplicationsWithVendor.taskStartDate = ""
            //     initialApplicationsWithVendor.taskStartTime = ""
            //     initialApplicationsWithVendor.taskEndDate = ""
            //     initialApplicationsWithVendor.taskEndTime = ""
            // }
            newDataArr.push(initialApplicationsWithVendor)
        })
        setApplicationsWithVendor(newDataArr)
        // console.log("newDataArr", newDataArr);
    }

    const checkAssignedVendor = (applicationId, target) => {
        const retVal = assignedVendorList?.filter((item) => {
            return item.applicationFormId == applicationId
        })

        if (Object.keys(retVal).length > 0) {
            return retVal[0][target]
        } else {
            return ""
        }
        // console.log("vx",vx,"appid",applicationId);

        // return "64e9dca60d17df67e379b361"
    }

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.Sl,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Customer Name
                </div>
            ),
            selector: (row) => {
                return row?.Name !== "" ? row?.Name : ""
            },
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Mobile
                </div>
            ),
            selector: (row) => {
                return row?.Mobile !== "" ? row?.Mobile : ""
            },
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Vendor
                </div>
            ),
            selector: (row) => {
                const aVendorId = checkAssignedVendor(row.ID, "vendorId")
                return (
                    <>
                        {
                            aVendorId !== "" ? (
                                <>
                                    <select
                                        onChange={(e) => {
                                            vendorIdGlobal = e.target.value;
                                        }}
                                        disabled
                                    >
                                        <option value="">Choose...</option>
                                        {
                                            allVendor?.map((ele, id) => {
                                                return (
                                                    <option
                                                        selected={ele._id == aVendorId ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </>
                            ) : (
                                <>
                                    <select
                                        onChange={(e) => {
                                            vendorIdGlobal = e.target.value;
                                        }}
                                    >
                                        <option value="">Choose...</option>
                                        {
                                            allVendor?.map((ele, id) => {
                                                return (
                                                    <option
                                                        selected={ele._id == aVendorId ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </>

                            )
                        }

                    </>
                )
            },
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Start Date
                </div>
            ),
            selector: (row) => {
                const aStartDate = checkAssignedVendor(row.ID, "startDate")
                return (
                    <>
                        {
                            aStartDate !== "" ? (
                                <>
                                    {moment(aStartDate).format("YYYY-MM-DD")}
                                </>
                            ) : (
                                <>
                                    <input type="date"
                                        className="form-control"
                                        // value={moment(aStartDate).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                            startDateGlobal = e.target.value;
                                        }}
                                    />
                                </>
                            )
                        }
                    </>
                )
            },
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Start Time
                </div>
            ),
            selector: (row) => {
                const aStartTime = checkAssignedVendor(row.ID, "startTime")
                return (
                    <>
                        {
                            aStartTime !== "" ? (
                                <>
                                    {moment(aStartTime, 'HH:mm:ss').format('h:mm A')}
                                </>
                            ) : (
                                <>
                                    <input type="time"
                                        className="form-control"
                                        // value={}
                                        onChange={(e) => {
                                            startTimeGlobal = e.target.value;
                                        }}
                                    />
                                </>
                            )
                        }

                    </>
                )
            },
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    End Date
                </div>
            ),
            selector: (row) => {
                const aEndDate = checkAssignedVendor(row.ID, "endDate")
                return (
                    <>
                        {
                            aEndDate !== "" ? (
                                <>
                                    {moment(aEndDate).format("YYYY-MM-DD")}
                                </>
                            ) : (
                                <>
                                    <input type="date"
                                        className="form-control"
                                        // value={}
                                        onChange={(e) => {
                                            endDateGlobal = e.target.value;
                                            // setStartDate(e.target.value)
                                        }}
                                    />
                                </>
                            )
                        }

                    </>
                )
            },
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    End Time
                </div>
            ),
            selector: (row) => {
                const aEndTime = checkAssignedVendor(row.ID, "endTime")
                return (
                    <>
                        {
                            aEndTime !== "" ? (
                                <>
                                    {moment(aEndTime, 'HH:mm:ss').format('h:mm A')}
                                </>
                            ) : (
                                <>
                                    <input type="time"
                                        className="form-control"
                                        // value={}
                                        onChange={(e) => {
                                            endTimeGlobal = e.target.value;
                                        }}
                                    />
                                </>
                            )
                        }

                    </>
                )
            },
        },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];


    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        <div style={{ display: displayDetails ? "block" : "none" }}>
                            <h2>AMC Service Details</h2>
                            {/* {formDetails._id} */}
                            <table class="table table-bordered" >
                                <tbody>
                                    {
                                        formDetails.name !== undefined && formDetails.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Customer Name:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails.mobile_number !== undefined && formDetails.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Mobile:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.mobile_number}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.areaData?.name !== undefined && formDetails?.areaData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Location:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.areaData?.name}, {formDetails?.cityData?.name},{formDetails?.stateData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.categoryData?.name !== undefined && formDetails?.categoryData?.name !== null && formDetails?.categoryData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Requested Service:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>
                                                        {formDetails?.categoryData?.name}
                                                        {

                                                            formDetails?.subcategorydata?.name


                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.numberOfService !== undefined && formDetails?.numberOfService !== "" && formDetails?.numberOfService > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Number Of {formDetails?.categoryData?.name}:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.numberOfService}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.serviceBrandData?.name !== undefined && formDetails?.serviceBrandData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Name Of Brand {
                                                        // formDetails?.categoryData?.name.split(" ")[0]
                                                    }:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.serviceBrandData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.selectTechlonogyData?.name !== undefined && formDetails?.selectTechlonogyData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>{formDetails?.categoryData?.name} Techlonogy {
                                                        // formDetails?.categoryData?.name.split(" ")[0]
                                                    }:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.selectTechlonogyData?.name}
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.materialtype !== undefined && formDetails?.materialtype !== null &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Contract work:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{
                                                        formDetails?.materialtype === true ? (
                                                            <>With material</>
                                                        ) : (
                                                            <>Without material</>

                                                        )


                                                    }</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.servicePackageData?.pack !== undefined && formDetails?.servicePackageData?.pack !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Service package:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{HomeService.packName(formDetails?.servicePackageData?.pack)}</td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {
                                        formDetails?.houseTypeData?.name !== undefined && formDetails?.houseTypeData?.name !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Type of House/Building:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.houseTypeData?.name}</td>
                                                </tr>
                                            </>
                                        )
                                    }

                                    {
                                        formDetails?.selectModelData?.type !== undefined && formDetails?.selectModelData?.type !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Model:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.selectModelData?.type} BHK</td>
                                                </tr>
                                            </>
                                        )
                                    }





                                    {
                                        formDetails?.anyOtherInformation !== undefined && formDetails?.anyOtherInformation !== "" && formDetails?.anyOtherInformation !== null &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Other Information:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.anyOtherInformation}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.MaterialPrice !== undefined && formDetails?.MaterialPrice !== "" && formDetails?.MaterialPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Material Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.MaterialPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.AccessoriesPrice !== undefined && formDetails?.AccessoriesPrice !== "" && formDetails?.AccessoriesPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Accessories Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.AccessoriesPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.WorkshipmanPrice !== undefined && formDetails?.WorkshipmanPrice !== "" && formDetails?.WorkshipmanPrice > 0 &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Workshipman Price:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{parseFloat(formDetails?.WorkshipmanPrice).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.AMCCost !== undefined && formDetails?.AMCCost !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left", fontSize: "125%" }}>Estimated Cost:</th>
                                                    <td className="col-9" style={{ textAlign: "left", fontSize: "125%" }}>{parseFloat(formDetails?.AMCCost).toFixed(2)}</td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    {
                                        formDetails?.paymentStatus !== undefined && formDetails?.paymentStatus !== "" &&
                                        (
                                            <>
                                                <tr>
                                                    <th scope="row" className="col-3" style={{ textAlign: "left" }}>Payment Status:</th>
                                                    <td className="col-9" style={{ textAlign: "left" }}>{formDetails?.paymentStatus.toUpperCase()}</td>
                                                </tr>
                                            </>
                                        )
                                    }






                                </tbody>
                            </table>
                        </div>

                        {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                ServiceType<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setServiceTypeID(e.target.value)
                                    fetchAllCategory(e.target.value)
                                    fetchAllModeltype(e.target.value)
                                }}
                            >
                                <option value="">Select</option>
                                {allVendor?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceTypeID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Category :
                            </label>
                            <select onChange={(e) => {
                                setServiceCategoryID(e.target.value)
                                fetchAllData(e.target.value);
                                setDisplayDetails(false)
                                // fetchAllApplications(e.target.value)
                                // fetchAllAssignedVendor();
                                // generateAssignedApplication()


                            }}>
                                <option value="">--- All Category ---</option>
                                {totalCategory?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceCategoryID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div
                            class="form-group"
                            style={{ display: allsubcatdata.length > 0 ? "block" : "none" }}
                        >
                            <label for="exampleInputEmail1">Sub Category :</label>
                            <select
                                onChange={(e) => {
                                    setsubcat_Id(e.target.value);
                                    fetchAllData(e.target.value);
                                }}
                            >
                                <option value="">Select</option>
                                {allsubcatdata?.map((ele, id) => {
                                    // console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == subcat_Id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            AMC Form(s)
                        </div>
                        <DataTable
                            columns={columns}
                            data={applicationsWithVendor}
                            pagination
                            noDataComponent="No forms are submitted yet"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ApplicationForms;
