import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import SeeMoreLess from "../../Component/SeeMoreLess";

const CleaningPrice = () => {
  const [name, setName] = useState("");
  const [price, setprice] = useState("");
  const [feature, setfeature] = useState("");
  const [whitewash, setwhitewash] = useState('');
  const [dropdownoption, setdropdownoption] = useState([])
  const [inputType, setinputType] = useState("")


  const [serviceTypeID,setServiceTypeID]=useState("64d602b2e6419455b67e1ed8")
  const [kitchenCleaning,setKitchenCleaning]=useState(0)
  const [starecaseOpenArea,setStarecaseOpenArea]=useState(0)
  const [bathroomCleaningArea,setBathroomCleaningArea]=useState(0)


  const [allServiceCharges, setAllServiceCharges] = useState([]);

  const [meterial_id, setmeterial_id] = useState("");
  const [paintingquality_id, setpaintingquality_id] = useState("");
  const [placetypeid, setPlacetypeid] = useState("");
  const [paintVer_id, setpaintVer_id] = useState("");


  // const [allpaintingquality, setallpaintingquality] = useState([]);
  // const [allmeteritype, setallmeteritype] = useState([]);
  // const [allplacetype, setAllplacetype] = useState([]);
  // const [allSubclean,setallallSubclean]=useState([])
  const [allmodeldata, setallModeldata] = useState([])
  const [allsubcatdata, setallsubcatdata] = useState([])

  // const[totalPaintVersion,settotalPaintVersion]=useState([])


  const [service_id, setservice_id] = useState("");
  const [serviceCategoryID, setServiceCategoryID] = useState("");
  const [subcat_Id, setsubcat_Id] = useState("")
  const [modelId, setmodelId] = useState("");

  const [displaysubcat, setdisplaysubcat] = useState("none")
  const [showSubcategory, setShowSubcategory] = useState(false)
  const [allService, setallSarvice] = useState([]);
  const [totalCategory, settotalCategory] = useState([]);





  const [hide, setHide] = useState(true);
  const [id, setId] = useState("6542408a700f694a54d6e658");

  

  useEffect(() => {

    fetchServiceCharges()
    fetchAllSarvice()
    fetchAllCategory(serviceTypeID);
    setServiceCategoryID("653fb843e02b15a8240bb615")
    setShowSubcategory(true)

  }, []);

  const ResetState = () => {
    setServiceTypeID("64d602b2e6419455b67e1ed8")
    // setServiceCategoryID("")
    setKitchenCleaning(0)
    setStarecaseOpenArea(0)
    setBathroomCleaningArea(0)
  };

  const fetchAllSarvice = () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setallSarvice(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllSubCategoryArea = (id) => {
    HomeService.getAllSubcategory(id)
      .then((res) => {
        console.log("AllSffetate", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          if (Object.keys(res?.data).length > 0) {
            if(serviceTypeID==="64d602b2e6419455b67e1ed8"){
              setShowSubcategory(true)
            }
            
            setallsubcatdata(arr);
          }
        } else {
          setallsubcatdata([]);
          setShowSubcategory(false)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  const fetchAllCategory = (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          settotalCategory(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


 

  const fetchAllModeltype = (id) => {
    // const data={serviceCategoryID:catid}

    HomeService.getModeltypeUsingserviceId(id)
      .then((res) => {
        console.log("AllSttttttate", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setallModeldata(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };






  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeletePaintingVersion(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllLpaintinCost();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };



  const [entryCategoryName, setEntryCategoryName] = useState("")

  const fetchServiceCharges = () => {
    HomeService.ServiceChargesType1()
      .then((res) => {
        // console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.map((item, index) => {
            // console.log("llll", item?.materialTypeName);
            setKitchenCleaning(item?.kitchenCleaning)
            setStarecaseOpenArea(item?.starecaseOpenArea)
            setBathroomCleaningArea(item?.bathroomCleaningArea)
          });

        }

      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllLpaintinCost = () => {
    HomeService.MannagePaintingCost()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          // setLoader(false)

          let arr = res?.data?.map((item, index) => {
            console.log("llll", item?.materialTypeName);
            return {
              sl: index + 1,
              Name: item?.name,
              Cost: item?.cost,



              placetype: item?.placeTypeData?.type,
              paitQuality: item?.paintingQualityData?.name,
              paintingvertion: item?.paintingVersionData?.name,
              meterialval: item?.materialTypeData.type,



              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    // onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  {/* <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg> */}
                </div>
              ),
            };
          });
          // setAllLanguage(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          placeTypeName
        </div>
      ),
      selector: (row) => row.placetype,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Meterial Type
        </div>
      ),
      selector: (row) => row.meterialval,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Painting Version
        </div>
      ),
      selector: (row) => <SeeMoreLess maxWordShow={50}>{row.paintingvertion}
      </SeeMoreLess>,
      wrap: true
    },


    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Painting Quality
        </div>
      ),
      selector: (row) => row.paitQuality,
    },


    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Price
        </div>
      ),
      selector: (row) => row.Cost,
    },

    // {
    //     name: (
    //         <div
    //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //         >
    //             Features
    //         </div>
    //     ),
    //     selector: (row) => row.feature,
    // },
    // {
    //     name: (
    //         <div
    //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //         >
    //             Input Type
    //         </div>
    //     ),
    //     selector: (row) => row.inputType,
    // },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];

  const addLanguage = () => {

    // "placeStatus":false,
    // "materialTypeId": "650305a7f1d0c63605785ad2",
    // "placetypeId":"650c0c9f9e14480433c83482" ,
    // "paintingVersionId": "650c3b40c4891f8afa5a7f77",
    // "cost": 7
    let data = {
      serviceTypeID:serviceTypeID,
      serviceCategoryID:serviceCategoryID,
      kitchenCleaning:kitchenCleaning,
      starecaseOpenArea:starecaseOpenArea,
      bathroomCleaningArea:bathroomCleaningArea

    };

    if (serviceTypeID && serviceCategoryID) {
      // alert(name + country_id)
      HomeService.UpdateCleaningCostType1(id,data)
        .then((res) => {
          // console.log("L500", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchServiceCharges()
            ResetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!price) {
        toast.error("price  Field Is Required");
      }
      if (!serviceCategoryID) {
        toast.error("Category Is Required");
      }

    }
  };




  const UpdateLanguageCategory = () => {
    console.log("ID", id);
    let data = {
      name: name,
    };
    HomeService.UpdatePaintingCost(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setName("");
          fetchAllLpaintinCost();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };




  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Cleaning Charge
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Cleaning Cost
              </div>
            )}
            <div class="form-group">
              <label for="exampleInputEmail1">
                ServiceType<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setServiceTypeID(e.target.value);
                  fetchAllCategory(e.target.value);
                  fetchAllModeltype(e.target.value)
                  setShowSubcategory(false)
                }}
              >
                <option value="">Select</option>
                {allService?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == serviceTypeID ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name <span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {
                setServiceCategoryID(e.target.value);
                fetchAllSubCategoryArea(e.target.value)
                console.log("L609:", displaysubcat);

              }}>
                <option value="">Select</option>
                {totalCategory?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == serviceCategoryID ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>


            



            <div className="row" style={{ display: showSubcategory ? "block" : "none" }}>
              <div className="col-4">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                  Kitchen Cleaning Charge <span >per SqFt</span> :
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={kitchenCleaning}
                    onChange={(e) => setKitchenCleaning(e.target.value)}
                  />
                </div>
              </div>
              
              <div className="col-4">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                  Stare Case / Open Area Cleaning Charge <span >per SqFt</span> :
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={starecaseOpenArea}
                    onChange={(e) => setStarecaseOpenArea(e.target.value)}
                  />
                </div>
              </div>

              <div className="col-4">
                <div class="form-group">
                  <label for="exampleInputEmail1">
                  Bathroom Cleaning Charge <span >per SqFt</span> :
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={bathroomCleaningArea}
                    onChange={(e) => setBathroomCleaningArea(e.target.value)}
                  />
                </div>
              </div>
            </div>





            {hide ? (
              <button class="btn btn-primary" onClick={addLanguage}>
                Save
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateLanguageCategory}>
                Update
              </button>
            )}



            {/* <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Cleaning Charges
            </div> */}
            {/* <DataTable columns={columns} data={allServiceCharges} pagination /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CleaningPrice;
