import HttpClientXml from "../Utils/HttpClientXml";
// import { BASE_URL } from "../Utils/HttpClientXml";

async function AddHomeAppliance(data) {
    let endpoind = "add-homeapplicant";
    return HttpClientXml.post(endpoind, data);
}
const UpdateHomeAppliance = async (id, data) => {
    let endpoind = `update-homeapplicant/${id}`;
    return HttpClientXml.put(endpoind, data);
}

const FetchHomeAppliance = async () => {
    let endpoind = "view-homeapplicant";
    return HttpClientXml.get(endpoind);
}
async function AddServiceBrand(data) {
    let endpoind = "add-servicebrand";
    return HttpClientXml.post(endpoind, data);
}
const UpdateServiceBrand = async (id, data) => {
    let endpoind = `update-servicebrand/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const FetchServiceBrand = async () => {
    let endpoind = "view-servicebrand";
    return HttpClientXml.get(endpoind);
}
async function AddCCTVTechnology(data) {
    let endpoind = "add-servicetechnology";
    return HttpClientXml.post(endpoind, data);
}
const FetchCCTVTechnologies = async () => {
    let endpoind = "view-servicetechnology";
    return HttpClientXml.get(endpoind);
}
const UpdateCCTVTechnology = async (id, data) => {
    let endpoind = `update-servicetechnology/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCCTVTechnology = async (id) => {
    let endpoind = `delete-servicetechnology/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
const AddPieChartData = async (data) => {
    let endpoind = "add-piechats";
    return HttpClientXml.post(endpoind, data);
}
const FetchPieChartData = async () => {
    let endpoind = "view-piechats";
    return HttpClientXml.get(endpoind);
}
const UpdatePieChartData = async (id, data) => {
    let endpoind = `update-piechats/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeletePieChartData = async (id) => {
    let endpoind = `delete-piechats/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

const AddPriceDescription = async (data) => {
    let endpoind = "add-pricephotodesc";
    return HttpClientXml.post(endpoind, data);
}
const FetchPriceDescription = async () => {
    let endpoind = "view-pricephotodesc";
    return HttpClientXml.get(endpoind);
}
const UpdatePriceDescription = async (id, data) => {
    let endpoind = `update-pricephotodesc/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeletePriceDescription = async (id) => {
    let endpoind = `delete-pricephotodesc/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
///Work Type
const AddWorkType = async (data) => {
    let endpoind = "add-worktype";
    return HttpClientXml.post(endpoind, data);
}
const FetchWorkType = async () => {
    let endpoind = "view-worktype";
    return HttpClientXml.get(endpoind);
}
const UpdateWorkType = async (id, data) => {
    let endpoind = `update-worktype/${id}`;
    return HttpClientXml.put(endpoind, data);
}
///work type price
const AddWorkTypePrice = async (data) => {
    let endpoind = "add-worktypeprice";
    return HttpClientXml.post(endpoind, data);
}
const FetchWorkTypePrice = async () => {
    let endpoind = "view-worktypeprice";
    return HttpClientXml.get(endpoind);
}
const UpdateWorkTypePrice = async (id, data) => {
    let endpoind = `update-worktypeprice/${id}`;
    return HttpClientXml.put(endpoind, data);
}
///Floor Percentage
const AddFloorPercentage = async (data) => {
    let endpoind = "add-floorpercentage";
    return HttpClientXml.post(endpoind, data);
}
const FetchFloorPercentage = async () => {
    let endpoind = "view-floorpercentage";
    return HttpClientXml.get(endpoind);
}
const UpdateFloorPercentage = async (id, data) => {
    let endpoind = `update-floorpercentage/${id}`;
    return HttpClientXml.put(endpoind, data);
}
///channelAccories
const AddUpdateChannelAccories = async (data) => {
    let endpoind = "add-channelaccesories";
    return HttpClientXml.post(endpoind, data);
}
const FetchChannelAccories = async () => {
    let endpoind = "view-channelaccesories";
    return HttpClientXml.get(endpoind);
}
///Work area
const AddWorkArea = async (data) => {
    let endpoind = "add-workarea";
    return HttpClientXml.post(endpoind, data);
}
const FetchWorkArea = async () => {
    let endpoind = "view-workarea";
    return HttpClientXml.get(endpoind);
}
const UpdateWorkArea = async (id, data) => {
    let endpoind = `update-workarea/${id}`;
    return HttpClientXml.put(endpoind, data);
}
///Plywood
const AddPlywood = async (data) => {
    let endpoind = "add-plywood";
    return HttpClientXml.post(endpoind, data);
}
const FetchPlywood = async () => {
    let endpoind = "view-plywood";
    return HttpClientXml.get(endpoind);
}
///Required contract
const AddRequiredContract = async (data) => {
    let endpoind = "add-requiredcontract";
    return HttpClientXml.post(endpoind, data);
}
const FetchRequiredContract = async () => {
    let endpoind = "view-requiredcontract";
    return HttpClientXml.get(endpoind);
}
const UpdateRequiredContract = async (id, data) => {
    let endpoind = `update-requiredcontract/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRequiredContract = async (id) => {
    let endpoind = `delete-requiredcontract/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
/// ServiceProblem
const AddServiceProblem = async (data) => {
    let endpoind = "add-serviceProblem";
    return HttpClientXml.post(endpoind, data);
}
const FetchServiceProblem = async () => {
    let endpoind = "view-serviceProblem";
    return HttpClientXml.get(endpoind);
}
const UpdateServiceProblem = async (id, data) => {
    let endpoind = `update-serviceProblem/${id}`;
    return HttpClientXml.put(endpoind, data);
}
/// ContractWorktype
const AddContractWorktype = async (data) => {
    let endpoind = "add-contractworktype";
    return HttpClientXml.post(endpoind, data);
}
const FetchContractWorktype = async () => {
    let endpoind = "view-contractworktype";
    return HttpClientXml.get(endpoind);
}
const UpdateContractWorktype = async (id, data) => {
    let endpoind = `update-contractworktype/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteContractWorktype = async (id) => {
    let endpoind = `delete-contractworktype/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
/// Materials
const AddMaterials = async (data) => {
    let endpoind = "add-materials";
    return HttpClientXml.post(endpoind, data);
}
const FetchMaterials = async () => {
    let endpoind = "view-materials";
    return HttpClientXml.get(endpoind);
}
const UpdateMaterials = async (id, data) => {
    let endpoind = `update-materials/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteMaterials = async (id) => {
    let endpoind = `delete-materialplumbing/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
/// Accessories
const AddAccessories = async (data) => {
    let endpoind = "add-acceries";
    return HttpClientXml.post(endpoind, data);
}
const FetchAccessories = async () => {
    let endpoind = "view-acceries";
    return HttpClientXml.get(endpoind);
}
const UpdateAccessories = async (id, data) => {
    let endpoind = `update-acceries/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteAccessories = async (id) => {
    let endpoind = `delete-acceries/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
/// SelectReason
const AddSelectReason = async (data) => {
    let endpoind = "add-selectreason";
    return HttpClientXml.post(endpoind, data);
}
const FetchSelectReason = async () => {
    let endpoind = "view-selectreason";
    return HttpClientXml.get(endpoind);
}
const UpdateSelectReason = async (id, data) => {
    let endpoind = `update-selectreason/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteSelectReason = async (id) => {
    let endpoind = `delete-selectreason/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
/// TypeOfWork
const AddTypeOfWork = async (data) => {
    let endpoind = "add-typeofwork";
    return HttpClientXml.post(endpoind, data);
}
const FetchTypeOfWork = async () => {
    let endpoind = "view-typeofwork";
    return HttpClientXml.get(endpoind);
}
const UpdateTypeOfWork = async (id, data) => {
    let endpoind = `update-typeofwork/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteTypeOfWork = async (id) => {
    let endpoind = `delete-typeofwork/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
///CCTv
///CameraChannelModel***
const AddCameraChannelModel = async (data) => {
    let endpoind = "add-camerachanneldvr";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraChannelModel = async () => {
    let endpoind = "view-camerachanneldvr";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraChannelModel = async (id, data) => {
    let endpoind = `update-camerachanneldvr/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraChannelModel = async (id) => {
    let endpoind = `delete-camerachanneldvr/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
///CameraVersion***
const AddCameraVersion = async (data) => {
    let endpoind = "add-cameraversion";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraVersion = async () => {
    let endpoind = "view-cameraversion";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraVersion = async (id, data) => {
    let endpoind = `update-cameraversion/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraVersion = async (id) => {
    let endpoind = `delete-cameraversion/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CameraType
const AddCameraType = async (data) => {
    let endpoind = "add-cameratype";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraType = async () => {
    let endpoind = "view-cameratype";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraType = async (id, data) => {
    let endpoind = `update-cameratype/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraType = async (id) => {
    let endpoind = `delete-cameratype/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CameraResolution
const AddCameraResolution = async (data) => {
    let endpoind = "add-cameraresolution";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraResolution = async () => {
    let endpoind = "view-cameraresolution";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraResolution = async (id, data) => {
    let endpoind = `update-cameraresolution/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraResolution = async (id) => {
    let endpoind = `delete-cameraresolution/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CameraModel
const AddCameraModel = async (data) => {
    let endpoind = "add-cameramodel";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraModel = async () => {
    let endpoind = "view-cameramodel";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraModel = async (id, data) => {
    let endpoind = `update-cameramodel/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraModel = async (id) => {
    let endpoind = `delete-cameramodel/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CameraBrand
const AddCameraBrand = async (data) => {
    let endpoind = "add-camerabrand";
    return HttpClientXml.post(endpoind, data);
}
const FetchCameraBrand = async () => {
    let endpoind = "view-camerabrand";
    return HttpClientXml.get(endpoind);
}
const UpdateCameraBrand = async (id, data) => {
    let endpoind = `update-camerabrand/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteCameraBrand = async (id) => {
    let endpoind = `delete-camerabrand/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///RequiredArea
const AddRequiredArea = async (data) => {
    let endpoind = "add-requiredarea";
    return HttpClientXml.post(endpoind, data);
}
const FetchRequiredArea = async () => {
    let endpoind = "view-requiredarea";
    return HttpClientXml.get(endpoind);
}
const UpdateRequiredArea = async (id, data) => {
    let endpoind = `update-requiredarea/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRequiredArea = async (id) => {
    let endpoind = `delete-requiredarea/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CableWiring
const AddCableWiring = async (data) => {
    let endpoind = "add-cablewiring";
    return HttpClientXml.post(endpoind, data);
}
const FetchCableWiring = async () => {
    let endpoind = "view-cablewiring";
    return HttpClientXml.get(endpoind);
}
const UpdateCableWiring = async (id, data) => {
    let endpoind = `update-cablewiring/${id}`;
    return HttpClientXml.put(endpoind, data);
}

///Rack
const AddRack = async (data) => {
    let endpoind = "add-camerarack";
    return HttpClientXml.post(endpoind, data);
}
const FetchRack = async () => {
    let endpoind = "view-camerarack";
    return HttpClientXml.get(endpoind);
}
const UpdateRack = async (id, data) => {
    let endpoind = `update-camerarack/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRack = async (id) => {
    let endpoind = `delete-camerarack/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
///CablePercentage
const AddCablePercentage = async (data) => {
    let endpoind = "add-cameracablepercentage";
    return HttpClientXml.post(endpoind, data);
}
const FetchCablePercentage = async () => {
    let endpoind = "view-cameracablepercentage";
    return HttpClientXml.get(endpoind);
}

///PuttyHouseArea
const AddPuttyHouseArea = async (data) => {
    let endpoind = "add-puttyhousearea";
    return HttpClientXml.post(endpoind, data);
}
const FetchPuttyHouseArea = async () => {
    let endpoind = "view-puttyhousearea";
    return HttpClientXml.get(endpoind);
}
const UpdatePuttyHouseArea = async (id, data) => {
    let endpoind = `update-puttyhousearea/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeletePuttyHouseArea = async (id) => {
    let endpoind = `delete-puttyhousearea/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///TypeOfPainting
const AddTypeOfPainting = async (data) => {
    let endpoind = "add-typeofpainting";
    return HttpClientXml.post(endpoind, data);
}
const FetchTypeOfPainting = async () => {
    let endpoind = "view-typeofpainting";
    return HttpClientXml.get(endpoind);
}
const UpdateTypeOfPainting = async (id, data) => {
    let endpoind = `update-typeofpainting/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteTypeOfPainting = async (id) => {
    let endpoind = `delete-typeofpainting/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///RangeOfPainting
const AddRangeOfPainting = async (data) => {
    let endpoind = "add-rangeofpainting";
    return HttpClientXml.post(endpoind, data);
}
const FetchRangeOfPainting = async () => {
    let endpoind = "view-rangeofpainting";
    return HttpClientXml.get(endpoind);
}
const UpdateRangeOfPainting = async (id, data) => {
    let endpoind = `update-rangeofpainting/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRangeOfPainting = async (id) => {
    let endpoind = `delete-rangeofpainting/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///RangeOfAccessories
const AddRangeOfAccessories = async (data) => {
    let endpoind = "add-rangeofpainting";
    return HttpClientXml.post(endpoind, data);
}
const FetchRangeOfAccessories = async () => {
    let endpoind = "view-rangeofpainting";
    return HttpClientXml.get(endpoind);
}
const UpdateRangeOfAccessories = async (id, data) => {
    let endpoind = `update-rangeofpainting/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRangeOfAccessories = async (id) => {
    let endpoind = `delete-rangeofpainting/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///RangeOfPlywood
const AddRangeOfPlywood = async (data) => {
    let endpoind = "add-plywood";
    return HttpClientXml.post(endpoind, data);
}
const FetchRangeOfPlywood = async () => {
    let endpoind = "view-plywood";
    return HttpClientXml.get(endpoind);
}
const UpdateRangeOfPlywood = async (id, data) => {
    let endpoind = `update-plywood/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRangeOfPlywood = async (id) => {
    let endpoind = `delete-plywood/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///RangeOfChannelAccories
const AddRangeOfChannelAccories = async (data) => {
    let endpoind = "add-channelaccesories";
    return HttpClientXml.post(endpoind, data);
}
const FetchRangeOfChannelAccories = async () => {
    let endpoind = "view-channelaccesories";
    return HttpClientXml.get(endpoind);
}
const UpdateRangeOfChannelAccories = async (id, data) => {
    let endpoind = `update-channelaccesories/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRangeOfChannelAccories = async (id) => {
    let endpoind = `delete-channelaccesories/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///HardiskCapacity
const AddHardiskCapacity = async (data) => {
    let endpoind = "add-capacityhardisk";
    return HttpClientXml.post(endpoind, data);
}
const FetchHardiskCapacity = async () => {
    let endpoind = "view-capacityhardisk";
    return HttpClientXml.get(endpoind);
}
const UpdateHardiskCapacity = async (id, data) => {
    let endpoind = `update-capacityhardisk/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteHardiskCapacity = async (id) => {
    let endpoind = `delete-capacityhardisk/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///All Submited Form Data
const FetchAMCFormsByCategory = async (id) => {
    let endpoind = `view-amc-service/${id}`;
    return HttpClientXml.get(endpoind);
}
const FetchAMCFormDetails = async (id) => {
    let endpoind = `view-partiular-amc-service/${id}`;
    return HttpClientXml.get(endpoind);
}

const FetchAllAssignedVendors = async () => {
    let endpoind = `view-assignvendor-list`;
    return HttpClientXml.get(endpoind);
}

const FetchContractFormsByCategory = async (id) => {
    let endpoind = `view-contract-service/${id}`;
    return HttpClientXml.get(endpoind);
}
const FetchContractFormDetails = async (categoryId, Id) => {
    let endpoind = `view-particular-contract-service/${categoryId}/${Id}`;
    return HttpClientXml.get(endpoind);
}

///Vendor
const FetchVendors = async () => {
    let endpoind = "admin-view-vendor";
    return HttpClientXml.get(endpoind);
}
const FetchVendorsByCategory = async (categoryID) => {
    let endpoind = `view-vendor-for-domastical/${categoryID}`;
    return HttpClientXml.get(endpoind);
}

///Testimonials
const AddTestimonials = async (data) => {
    // console.log("ASCDATA",data);
    let endpoind = "add-clienttestimonial";
    // let x = HttpClientXml.post(endpoind, data)
    // console.log("ASCDATA",x);
    return HttpClientXml.post(endpoind, data);

}
const FetchTestimonials = async () => {
    let endpoind = "view-clienttestimonial";
    return HttpClientXml.get(endpoind);
}
const UpdateTestimonials = async (id, data) => {
    let endpoind = `update-clienttestimonial/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteTestimonials = async (id) => {
    let endpoind = `delete-clienttestimonial/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///FAQs
const AddFAQs = async (data) => {
    let endpoind = "add-userfaq";
    return HttpClientXml.post(endpoind, data);
}
const FetchFAQs = async () => {
    let endpoind = "view-userfaq";
    return HttpClientXml.get(endpoind);
}
const UpdateFAQs = async (id, data) => {
    let endpoind = `update-userfaq/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteFAQs = async (id) => {
    let endpoind = `delete-userfaq/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///Assign Vendor
const AssignAMCVendor = async (data) => {
    let endpoind = "assignvendor";
    return HttpClientXml.post(endpoind, data);
}

///Services
const FetchServicesByCategory = async (id) => {
    let endpoind = `view-service-by-sub-category/${id}`;
    return HttpClientXml.get(endpoind);
}

///FlashOffers
const AddFlashOffers = async (data) => {
    let endpoind = "add-flashoffer";
    return HttpClientXml.post(endpoind, data);
}
const FetchFlashOffers = async () => {
    let endpoind = "view-flashoffer";
    return HttpClientXml.get(endpoind);
}
const UpdateFlashOffers = async (id, data) => {
    let endpoind = `update-flashoffer/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteFlashOffers = async (id) => {
    let endpoind = `delete-flashoffer/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///CustomerQueries
const FetchCustomerQueries = async () => {
    let endpoind = "view-customerqueries";
    return HttpClientXml.get(endpoind);
}
const SendReplyToCustomerQueries = async (data) => {
    let endpoind = "reply-customerqueries";
    return HttpClientXml.post(endpoind, data);
}


///Warrenty
const AddWarrenty = async (data) => {
    let endpoind = "add-servicewarrenty";
    return HttpClientXml.post(endpoind, data);
}

const FetchWarrenty = async () => {
    let endpoind = "view-servicewarrenty";
    return HttpClientXml.get(endpoind);
}
const UpdateWarrenty = async (id, data) => {
    let endpoind = `update-servicewarrenty/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteWarrenty = async (id) => {
    let endpoind = `delete-servicewarrenty/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///ServiceFAQs
const AddServiceFAQs = async (data) => {
    let endpoind = "add-servicefaq";
    return HttpClientXml.post(endpoind, data);
}
const FetchServiceFAQs = async () => {
    let endpoind = "view-servicefaq";
    return HttpClientXml.get(endpoind);
}
const UpdateServiceFAQs = async (id, data) => {
    let endpoind = `update-servicefaq/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteServiceFAQs = async (id) => {
    let endpoind = `delete-servicefaq/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}
///WhyChoose
const ManageWhyChoose = async (data) => {
    let endpoind = "add-why-choose";
    return HttpClientXml.post(endpoind, data);
}
const FetchWhyChoose = async () => {
    let endpoind = "view-why-choose";
    return HttpClientXml.get(endpoind);
}

///Language
const AddLanguage = async (data) => {
    let endpoind = "add-language";
    return HttpClientXml.post(endpoind, data);
}
const FetchLanguage = async () => {
    let endpoind = "view-language";
    return HttpClientXml.get(endpoind);
}
const UpdateLanguage = async (id, data) => {
    let endpoind = `update-language/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteLanguage = async (id) => {
    let endpoind = `delete-language/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///UserSubscriptionPlans
const AddUserSubscriptionPlans = async (data) => {
    let endpoind = "add-usersubscription";
    return HttpClientXml.post(endpoind, data);
}
const FetchUserSubscriptionPlans = async () => {
    let endpoind = "view-usersubscription";
    return HttpClientXml.get(endpoind);
}
const UpdateUserSubscriptionPlans = async (id, data) => {
    let endpoind = `update-usersubscription/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteUserSubscriptionPlans = async (id) => {
    let endpoind = `delete-usersubscription/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///UserSubscriptionBanners
const ManageUserSubscriptionBanners = async (data) => {
    let endpoind = "add-usersubscription-banner";
    return HttpClientXml.post(endpoind, data);
}
const FetchUserSubscriptionBanners = async () => {
    let endpoind = "view-usersubscription-banner";
    return HttpClientXml.get(endpoind);
}

///UserCoupon
const AddUserCoupon = async (data) => {
    let endpoind = "coupon";
    return HttpClientXml.post(endpoind, data);
}
const FetchUserCoupon = async () => {
    let endpoind = "coupon";
    return HttpClientXml.get(endpoind);
}
const UpdateUserCoupon = async (id, data) => {
    let endpoind = `coupon/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteUserCoupon = async (id) => {
    let endpoind = `coupon/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///ModelPrice
const AddModelPrice = async (data) => {
    let endpoind = "add-selectmodelpricen";
    return HttpClientXml.post(endpoind, data);
}
const FetchModelPrice = async () => {
    let endpoind = "view-selectmodelpricen";
    return HttpClientXml.get(endpoind);
}
const UpdateModelPrice = async (id, data) => {
    let endpoind = `update-selectmodelpricen/${id}`;
    return HttpClientXml.put(endpoind, data);
}
// const DeleteModelPrice = async (id) => {
//     let endpoind = `coupon/${id}`;
//     return HttpClientXml.deletemethod(endpoind);
// }

///AboutUs
const ManageAboutUs = async (data) => {
    let endpoind = "add-user-aboutus";
    return HttpClientXml.post(endpoind, data);
}
const FetchAboutUs = async () => {
    let endpoind = "view-user-aboutus";
    return HttpClientXml.get(endpoind);
}

///PrivacyPolicy
const ManagePrivacyPolicy = async (data) => {
    let endpoind = "add-user-privacypolicy";
    return HttpClientXml.post(endpoind, data);
}
const FetchPrivacyPolicy = async () => {
    let endpoind = "view-user-privacypolicy";
    return HttpClientXml.get(endpoind);
}

///UpdateBookingStatus
const UpdateBookingStatus = async (id, data) => {
    let endpoind = `update-checkout/${id}`;
    return HttpClientXml.put(endpoind, data);
}



///UpdateAdminProfile
const UpdateAdminProfile = async (data) => {
    let endpoind = "update";
    return HttpClientXml.put(endpoind, data);
}
///ChangeAdminPassword
const ChangeAdminPassword = async (data) => {
    let endpoind = "changepassword";
    return HttpClientXml.put(endpoind, data);
}
///FetchAdminProfile
const FetchAdminProfile = async () => {
    let endpoind = "view-profile";
    return HttpClientXml.get(endpoind);
}

///VendorCommission
const AddVendorCommission = async (data) => {
    let endpoind = "add-vendorcommition";
    return HttpClientXml.post(endpoind, data);
}
const FetchVendorCommission = async () => {
    let endpoind = "view-vendorcommition";
    return HttpClientXml.get(endpoind);
}
const UpdateVendorCommission = async (id, data) => {
    let endpoind = `update-vendorcommition/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteVendorCommission = async (id) => {
    let endpoind = `delete-vendorcommition/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///TermsAndConditions
const UpdateTermsAndConditions = async (data) => {
    let endpoind = `add-termandcondition`;
    return HttpClientXml.post(endpoind, data);
};

const FetchTermsAndConditions = async () => {
    let endpoind = "view-termandcondition";
    return HttpClientXml.get(endpoind);
};

///VendorBrand
const AddVendorBrand = async (data) => {
    let endpoind = "add-brand";
    return HttpClientXml.post(endpoind, data);
}
const FetchVendorBrand = async () => {
    let endpoind = "view-brand";
    return HttpClientXml.get(endpoind);
}
const UpdateVendorBrand = async (id, data) => {
    let endpoind = `update-brand/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteVendorBrand = async (id) => {
    let endpoind = `delete-brand/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///VendorToolkit
const AddVendorToolkit = async (data) => {
    let endpoind = "post-vendor-toolkit";
    return HttpClientXml.post(endpoind, data);
}
const FetchVendorToolkit = async () => {
    let endpoind = "get-vendor-toolkit";
    return HttpClientXml.get(endpoind);
}
const UpdateVendorToolkit = async (id, data) => {
    let endpoind = `edit-vendor-toolkit/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteVendorToolkit = async (id) => {
    let endpoind = `delete-vendor-toolkit/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///VendorStatus
const UpdateVendorStatus = async (id) => {
    let endpoind = `admin-change-status-vendor/${id}`;
    return HttpClientXml.put(endpoind);
}
const UpdateVendorPaymentStatus = async (data) => {
    let endpoind = `change-staus-tookit-payment`;
    return HttpClientXml.put(endpoind, data);
}

///VendorTraining
const AddVendorTraining = async (data) => {
    let endpoind = "trainingDateTimeType";
    return HttpClientXml.post(endpoind, data);
}
const FetchVendorTraining = async () => {
    let endpoind = "trainingDateTimeType";
    return HttpClientXml.get(endpoind);
}
const UpdateVendorTraining = async (id, data) => {
    let endpoind = `trainingDateTimeType/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteVendorTraining = async (id) => {
    let endpoind = `trainingDateTimeType/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///AssignVendorForDomesticService
const AssignVendorForDomesticService = async (data) => {
    let endpoind = "add-domastice-vendor-assign";
    return HttpClientXml.post(endpoind, data);
}

///ReferralAmount
const ManageReferralAmount = async (id, data) => {
    let endpoind = `put-referral-amount/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const FetchReferralAmount = async () => {
    let endpoind = "get-referral-amount";
    return HttpClientXml.get(endpoind);
}

///VendorQueries
const FetchVendorQueries = async () => {
    let endpoind = "get-vendor-queries";
    return HttpClientXml.get(endpoind);
}
const SendReplyToVendorQueries = async (data) => {
    let endpoind = "send-mail-vendor-query";
    return HttpClientXml.post(endpoind, data);
}

///TrainingByVendor
const FetchTrainingByVendor = async (id) => {
    let endpoind = `training-status-vendor-wise-get/${id}`;
    return HttpClientXml.get(endpoind);
}
const UpdateTrainingByVendor = async (id, data) => {
    let endpoind = `training-status-change-vendor-wise-get/${id}`;
    return HttpClientXml.put(endpoind, data);
}

///RoundByVendor
const FetchRoundByVendor = async (id) => {
    let endpoind = `view-interview-round-hr-tech/${id}`;
    return HttpClientXml.get(endpoind);
}
const UpdateRoundByVendor = async (id, data) => {
    let endpoind = `edit-interview-round-hr-tech/${id}`;
    return HttpClientXml.put(endpoind, data);
}

///Users
const FetchAllUsers = async () => {
    let endpoind = "view-userlist-domastical";
    return HttpClientXml.get(endpoind);
}
// const SendReplyToCustomerQueries = async (data) => {
//     let endpoind = "reply-customerqueries";
//     return HttpClientXml.post(endpoind, data);
// }


///VendorOfferLetter
const AddVendorOfferLetter = async (data) => {
    let endpoind = "post-offer-letter";
    return HttpClientXml.post(endpoind, data);
}
const FetchVendorOfferLetter = async (id) => {
    let endpoind = `get-offer-letter/${id}`;
    return HttpClientXml.get(endpoind);
}
const UpdateVendorOfferLetter = async (data) => {
    let endpoind = "trainingDateTimeType";
    return HttpClientXml.put(endpoind, data);
}
const DeleteVendorOfferLetter = async (id) => {
    let endpoind = `trainingDateTimeType/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///VendorMembers
const FetchVendorMembers = async () => {
    let endpoind = "view-vendor-for-addmembers";
    return HttpClientXml.get(endpoind);
}

///DiscountCoupon
const AddDiscountCoupon = async (data) => {
    let endpoind = "add-monthlydiscount";
    return HttpClientXml.post(endpoind, data);
}
const FetchDiscountCoupon = async () => {
    let endpoind = "view-monthlydiscount";
    return HttpClientXml.get(endpoind);
}
const UpdateDiscountCoupon = async (id, data) => {
    let endpoind = `update-monthlydiscount/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteDiscountCoupon = async (id) => {
    let endpoind = `delete-monthlydiscount/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}


const FetchVendorsByOptions = async (data) => {
    // console.log("ST1033",data);
    let endpoind = "admin-view-vendor-amc-cont";
    return HttpClientXml.post(endpoind, data);
}

///Finance
const FetchFinanceDomestic = async () => {
    let endpoind = "get-completed-order-commission";
    return HttpClientXml.get(endpoind);
}
const FetchFinanceAMC = async () => {
    let endpoind = "get-amc-completed-order-commission";
    return HttpClientXml.get(endpoind);
}
const FetchFinanceContract = async () => {
    let endpoind = "get-contract-completed-order-commission";
    return HttpClientXml.get(endpoind);
}

///RewardCoupon
const AddRewardCoupon = async (data) => {
    let endpoind = "add-reward";
    return HttpClientXml.post(endpoind, data);
}
const FetchRewardCoupon = async () => {
    let endpoind = "view-reward";
    return HttpClientXml.get(endpoind);
}
const UpdateRewardCoupon = async (id, data) => {
    let endpoind = `update-reward/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteRewardCoupon = async (id) => {
    let endpoind = `delete-reward/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///AboutCompany
const ManageAboutCompany = async (data) => {
    let endpoind = "add-about-company";
    return HttpClientXml.post(endpoind, data);
}
const FetchAboutCompany = async () => {
    let endpoind = "view-about-company";
    return HttpClientXml.get(endpoind);
}

///CustomerReviews
const FetchCustomerReviews = async () => {
    let endpoind = "view-all-review";
    return HttpClientXml.get(endpoind);
}

///FetchServiceTags
const FetchServiceTags = async () => {
    let endpoind = "get-special-service";
    return HttpClientXml.get(endpoind);
}

///FetchAllOrdersStatus
const FetchAllDomesticOrdersStatus = async () => {
    let endpoind = "get-count-domestic";
    return HttpClientXml.get(endpoind);
}
const FetchAMCOrdersStatus = async () => {
    let endpoind = "get-count-amc";
    return HttpClientXml.get(endpoind);
}
const FetchContractOrdersStatus = async () => {
    let endpoind = "get-count-contract";
    return HttpClientXml.get(endpoind);
}

///FetchAllSalesAmount
const FetchAllDomesticSalesAmount = async () => {
    let endpoind = "total-number-domestic";
    return HttpClientXml.get(endpoind);
}
const FetchAMCSalesAmount = async () => {
    let endpoind = "total-sell-number-amc";
    return HttpClientXml.get(endpoind);
}
const FetchContractSalesAmount = async () => {
    let endpoind = "total-sell-number-contract";
    return HttpClientXml.get(endpoind);
}

///SpecialTags
const AddSpecialTags = async (data) => {
    let endpoind = "post-special-service";
    return HttpClientXml.post(endpoind, data);
}
const FetchSpecialTags = async () => {
    let endpoind = "get-special-service";
    return HttpClientXml.get(endpoind);
}
const UpdateSpecialTags = async (id, data) => {
    let endpoind = `edit-special-service/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteSpecialTags = async (id) => {
    let endpoind = `delete-special-service/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

const ChangeVendorMemberStatus = async (id, data) => {
    let endpoind = `addservicemember-changestatus/${id}`;
    return HttpClientXml.put(endpoind, data);
}
///
const FetchJobListByVendor = async (id) => {
    let endpoind = `get-all-type-services-statu-count-and-detail/${id}`;
    return HttpClientXml.get(endpoind);
}

///HowToBookBanners
const ManageHowToBookBanners = async (data) => {
    let endpoind = "add-how-to-book";
    return HttpClientXml.post(endpoind, data);
}
const FetchHowToBookBanners = async () => {
    let endpoind = "view-how-to-book";
    return HttpClientXml.get(endpoind);
}

///ThresholdValue
const AddThresholdValue = async (data) => {
    let endpoind = "add-thresoldvalue";
    return HttpClientXml.post(endpoind, data);
}
const FetchThresholdValue = async () => {
    let endpoind = "view-thresoldvalue";
    return HttpClientXml.get(endpoind);
}
const UpdateThresholdValue = async (id, data) => {
    let endpoind = `update-thresoldvalue/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteThresholdValue = async (id) => {
    let endpoind = `delete-thresoldvalue/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///DistrictWiseServicePrice
const AddDistrictWiseServicePrice = async (data) => {
    let endpoind = "add/serviceprice/districtwise";
    return HttpClientXml.post(endpoind, data);
}

const FetchDistrictWiseServicePrice = async () => {
    let endpoind = "view/serviceprice/districtwise";
    return HttpClientXml.get(endpoind);
}
const UpdateDistrictWiseServicePrice = async (id, data) => {
    let endpoind = `update/serviceprice/districtwise/${id}`;
    return HttpClientXml.put(endpoind, data);
}
const DeleteDistrictWiseServicePrice = async (id) => {
    let endpoind = `delete/serviceprice/districtwise/${id}`;
    return HttpClientXml.deletemethod(endpoind);
}

///HelpAndSupport
const UpdateHelpAndSupport = async (data) => {
    let endpoind = `add/helpandsupport`;
    return HttpClientXml.post(endpoind, data);
};

const FetchHelpAndSupport = async () => {
    let endpoind = "view/helpandsupport";
    return HttpClientXml.get(endpoind);
};

const UpdateVendorSubscriptionPaymentStatus = async (data) => {
    let endpoind = `subcription-fee-payment`;
    return HttpClientXml.put(endpoind, data);
}

const UpdateVendorRegistrationPaymentStatus = async (data) => {
    let endpoind = `registration-fee-payment`;
    return HttpClientXml.put(endpoind, data);
}


///RefundCancellation
const ManageRefundCancellation = async (data) => {
    let endpoind = "add-refund-and-cancellation";
    return HttpClientXml.post(endpoind, data);
}
const FetchRefundCancellation = async () => {
    let endpoind = "view-refund-and-cancellation";
    return HttpClientXml.get(endpoind);
}

const FetchWorkTime = async () => {
    let endpoind = "view-work-timing";
    return HttpClientXml.get(endpoind);
}

export default {
    FetchWorkTime,

    ManageRefundCancellation,
    FetchRefundCancellation,

    UpdateVendorRegistrationPaymentStatus,
    UpdateVendorSubscriptionPaymentStatus,

    FetchHelpAndSupport,
    UpdateHelpAndSupport,

    AddDistrictWiseServicePrice,
    FetchDistrictWiseServicePrice,
    UpdateDistrictWiseServicePrice,
    DeleteDistrictWiseServicePrice,

    AddThresholdValue,
    FetchThresholdValue,
    UpdateThresholdValue,
    DeleteThresholdValue,

    FetchHowToBookBanners,
    ManageHowToBookBanners,

    ChangeVendorMemberStatus,
    FetchContractOrdersStatus,
    FetchAMCOrdersStatus,
    FetchContractSalesAmount,
    FetchAMCSalesAmount,
    FetchJobListByVendor,

    DeleteSpecialTags,
    UpdateSpecialTags,
    FetchSpecialTags,
    AddSpecialTags,

    // FetchAllContractSalesAmount,
    FetchAllDomesticSalesAmount,
    // FetchAllContractOrdersStatus,
    FetchAllDomesticOrdersStatus,
    FetchServiceTags,
    FetchCustomerReviews,

    UpdateRoundByVendor,
    FetchRoundByVendor,

    FetchAboutCompany,
    ManageAboutCompany,

    DeleteRewardCoupon,
    UpdateRewardCoupon,
    FetchRewardCoupon,
    AddRewardCoupon,

    FetchFinanceContract,
    FetchFinanceAMC,
    FetchFinanceDomestic,

    FetchVendorsByOptions,
    DeleteDiscountCoupon,
    UpdateDiscountCoupon,
    FetchDiscountCoupon,
    AddDiscountCoupon,

    FetchVendorMembers,
    DeleteVendorOfferLetter,
    UpdateVendorOfferLetter,
    AddVendorOfferLetter,
    FetchVendorOfferLetter,
    FetchAllUsers,

    UpdateVendorPaymentStatus,
    FetchVendorsByCategory,

    UpdateTrainingByVendor,
    FetchTrainingByVendor,

    SendReplyToVendorQueries,
    FetchVendorQueries,

    FetchReferralAmount,
    ManageReferralAmount,

    AssignVendorForDomesticService,

    DeleteVendorTraining,
    UpdateVendorTraining,
    FetchVendorTraining,
    AddVendorTraining,

    UpdateVendorStatus,

    DeleteVendorToolkit,
    UpdateVendorToolkit,
    FetchVendorToolkit,
    AddVendorToolkit,

    DeleteVendorBrand,
    UpdateVendorBrand,
    FetchVendorBrand,
    AddVendorBrand,

    FetchTermsAndConditions,
    UpdateTermsAndConditions,

    DeleteVendorCommission,
    UpdateVendorCommission,
    FetchVendorCommission,
    AddVendorCommission,

    FetchAdminProfile,
    ChangeAdminPassword,
    UpdateAdminProfile,

    UpdateBookingStatus,

    FetchPrivacyPolicy,
    ManagePrivacyPolicy,

    FetchAboutUs,
    ManageAboutUs,

    // DeleteModelPrice,
    UpdateModelPrice,
    FetchModelPrice,
    AddModelPrice,

    DeleteUserCoupon,
    UpdateUserCoupon,
    FetchUserCoupon,
    AddUserCoupon,

    FetchUserSubscriptionBanners,
    ManageUserSubscriptionBanners,

    DeleteUserSubscriptionPlans,
    UpdateUserSubscriptionPlans,
    FetchUserSubscriptionPlans,
    AddUserSubscriptionPlans,

    DeleteLanguage,
    UpdateLanguage,
    FetchLanguage,
    AddLanguage,
    FetchWhyChoose,
    ManageWhyChoose,
    DeleteServiceFAQs,
    UpdateServiceFAQs,
    FetchServiceFAQs,
    AddServiceFAQs,
    DeleteWarrenty,
    UpdateWarrenty,
    FetchWarrenty,
    AddWarrenty,

    FetchCustomerQueries,
    SendReplyToCustomerQueries,

    FetchAllAssignedVendors,

    DeleteFlashOffers,
    UpdateFlashOffers,
    FetchFlashOffers,
    AddFlashOffers,

    FetchServicesByCategory,

    DeleteFAQs,
    UpdateFAQs,
    FetchFAQs,
    AddFAQs,

    AssignAMCVendor,

    DeleteTestimonials,
    UpdateTestimonials,
    FetchTestimonials,
    AddTestimonials,
    FetchVendors,

    FetchContractFormDetails,
    FetchContractFormsByCategory,
    FetchAMCFormDetails,
    FetchAMCFormsByCategory,

    DeleteHardiskCapacity,
    UpdateHardiskCapacity,
    FetchHardiskCapacity,
    AddHardiskCapacity,
    DeleteRangeOfChannelAccories,
    UpdateRangeOfChannelAccories,
    FetchRangeOfChannelAccories,
    AddRangeOfChannelAccories,
    AddRangeOfPlywood,
    FetchRangeOfPlywood,
    UpdateRangeOfPlywood,
    DeleteRangeOfPlywood,



    DeleteRangeOfAccessories,
    UpdateRangeOfAccessories,
    FetchRangeOfAccessories,
    AddRangeOfAccessories,
    DeleteRangeOfPainting,
    UpdateRangeOfPainting,
    FetchRangeOfPainting,
    AddRangeOfPainting,
    DeleteTypeOfPainting,
    UpdateTypeOfPainting,
    FetchTypeOfPainting,
    AddTypeOfPainting,
    DeletePuttyHouseArea,
    UpdatePuttyHouseArea,
    FetchPuttyHouseArea,
    AddPuttyHouseArea,
    FetchCablePercentage,
    AddCablePercentage,
    DeleteRack,
    UpdateRack,
    FetchRack,
    AddRack,
    UpdateCableWiring,
    FetchCableWiring,
    AddCableWiring,
    DeleteRequiredArea,
    UpdateRequiredArea,
    FetchRequiredArea,
    AddRequiredArea,
    DeleteCameraBrand,
    UpdateCameraBrand,
    FetchCameraBrand,
    AddCameraBrand,
    DeleteCameraModel,
    UpdateCameraModel,
    FetchCameraModel,
    AddCameraModel,
    DeleteCameraResolution,
    UpdateCameraResolution,
    FetchCameraResolution,
    AddCameraResolution,
    DeleteCameraType,
    UpdateCameraType,
    FetchCameraType,
    AddCameraType,
    DeleteCameraVersion,
    UpdateCameraVersion,
    FetchCameraVersion,
    AddCameraVersion,
    DeleteCameraChannelModel,
    UpdateCameraChannelModel,
    FetchCameraChannelModel,
    AddCameraChannelModel,
    DeleteTypeOfWork,
    UpdateTypeOfWork,
    FetchTypeOfWork,
    AddTypeOfWork,
    DeleteSelectReason,
    UpdateSelectReason,
    FetchSelectReason,
    AddSelectReason,
    DeleteAccessories,
    UpdateAccessories,
    FetchAccessories,
    AddAccessories,
    AddMaterials,
    DeleteMaterials,
    UpdateMaterials,
    FetchMaterials,
    DeleteContractWorktype,
    UpdateContractWorktype,
    FetchContractWorktype,
    AddContractWorktype,
    AddServiceProblem,
    UpdateServiceProblem,
    FetchServiceProblem,
    DeleteRequiredContract,
    UpdateRequiredContract,
    AddRequiredContract,
    FetchRequiredContract,
    FetchPlywood,
    AddPlywood,
    FetchWorkArea,
    AddWorkArea,
    UpdateWorkArea,
    FetchChannelAccories,
    AddUpdateChannelAccories,
    UpdateFloorPercentage,
    FetchFloorPercentage,
    AddHomeAppliance,
    FetchHomeAppliance,
    AddServiceBrand,
    UpdateServiceBrand,
    FetchServiceBrand,
    AddCCTVTechnology,
    FetchCCTVTechnologies,
    UpdateCCTVTechnology,
    DeleteCCTVTechnology,
    AddPieChartData,
    FetchPieChartData,
    UpdatePieChartData,
    DeletePieChartData,
    AddPriceDescription,
    FetchPriceDescription,
    UpdatePriceDescription,
    UpdateHomeAppliance,
    AddWorkType,
    FetchWorkType,
    UpdateWorkType,
    AddWorkTypePrice,
    FetchWorkTypePrice,
    UpdateWorkTypePrice,
    AddFloorPercentage,
    DeletePriceDescription
}