import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import NewHomService from "../../Service/NewHomService";
import HomService from "../../Service/HomeService"
import SeeMoreLess from "../../Component/SeeMoreLess";
import Modal from "react-modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../Component/Loader";
import { isValidUrl } from "../../Utils/Helpers";
import { CSVLink } from "react-csv";
import { noConflict } from "jquery";

const RegisteredVendors = () => {
  const [name, setName] = useState("");
  const [processtype, setprocesstype] = useState("");
  const [interview_address, setinterview_address] = useState("");
  const [InterviewAddresssVerification, setInterviewAddresssVerification] =
    useState("");
  const [document_Verification, setdocument_Verification] = useState([]);
  // const [IntVerification, setIntVerification] = useState("");
  const [hrRound, sethrRound] = useState("");
  console.log("hrRound", hrRound);
  const [technicalRound, settechnicalRound] = useState("");
  const [docvarifyStatus, setDocvarifyStatus] = useState("");

  const [mettLink, setmettLink] = useState("");
  const [mettStatus, setmeetingStatus] = useState(false);
  const [MeetingTime, setMeetingTime] = useState("");

  const [state_id, setstate_id] = useState("");

  console.log("document_Verification", document_Verification);
  // const [allState, setAllState] = useState([]);
  const [allData, setAllData] = useState([]);
  // const [totalCountry, settotalCountry] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [vendorName, setVendorName] = useState("");

  const [docPhoto, setDocPhoto] = useState([]);
  const [docAadharCard, setDocAadharCard] = useState([]);
  const [docAadharCardBackPhoto, setDocAadharCardBackPhoto] = useState("");
  const [docPANCard, setDocPANCard] = useState([]);
  const [docPANCardBackPhoto, setDocPANCardBackPhoto] = useState("");
  const [docBankInformation, setDocBankInformation] = useState({});
  const [docCertficate, setDocCertficate] = useState([]);
  const [singleVendorDetail, setSingleVendorDetail] = useState({});

  const [displayTraining, setDisplayTraining] = useState(false);
  const [displayVerification, setDisplayVerification] = useState(false);
  const [displayToolkit, setDisplayToolkit] = useState(false);
  const [vendorTraining, setVendorTraining] = useState();
  const [displayHR, setDisplayHR] = useState(false);
  const [vendorHR, setVendorHR] = useState();

  // const [vendorToolkit, setVendorToolkit] = useState();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [replyToAppId, setReplyToAppId] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [replyToName, setReplyToName] = useState("");
  const [replyToMessage, setReplyToMessage] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);

  const [searchState, setSearchState] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchArea, setSearchArea] = useState("");

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    fetchAllData();
    setHide(true);
  }, []);

  useEffect(() => {
    modalIsOpen && afterOpenModal(singleVendorDetail?._id);
  }, [modalIsOpen]);

  // const updateDocvarify_data = (index,type, value) => {
  //     const updateddata = [...document_Verification];
  //     updateddata[index] = value;
  //     setdocument_Verification(updateddata);
  // };
  const resetData = () => {
    setprocesstype();
    setinterview_address();
    setInterviewAddresssVerification();
    setdocument_Verification();
    sethrRound();
    settechnicalRound();
    setDocvarifyStatus();
    setmettLink();
    setmeetingStatus();
    setMeetingTime();
    setstate_id();
    setAllData();
  };

  const updateDocvarify_data = (index, field, value) => {
    const updateddata = [...document_Verification];
    let allDocVerify = false;

    if (value == "true") {
      updateddata[index][field] = true;
    } else {
      updateddata[index][field] = false;
    }
    setdocument_Verification(updateddata);

    console.log("GDTDR120", updateddata);

    updateddata[0]?.status == true &&
      updateddata[1]?.status == true &&
      updateddata[2]?.status == true &&
      updateddata[3]?.status == true &&
      updateddata[4]?.status == true
      ? setDocvarifyStatus(true)
      : setDocvarifyStatus(false);
  };

  const handleVerification = (item) => {
    console.log("HRYDGT123", item?.interviewData?.photos);

    window.scrollTo(0, 0);
    console.log("SINGLEITEM", item);
    setSingleVendorDetail(item);
    setDisplayVerification(true);
    setDisplayTraining(false);
    setDisplayHR(false);
    setDisplayToolkit(false);

    setVendorName(item?.name);
    setName(item?.serviceName);
    setdocument_Verification(item?.interviewProcessData?.document_Verification);
    //photo
    setDocPhoto(item?.interviewData?.photos);
    //Aadhar Card
    setDocAadharCard(item?.interviewData?.aadhaarCard);
    setDocAadharCardBackPhoto(item?.interviewData?.aadhaarCardBackImage);
    //PAN card
    setDocPANCard([item?.interviewData?.pancard]);
    setDocPANCardBackPhoto([item?.interviewData?.pancardBackImage]);
    //certficate
    setDocCertficate([item?.interviewData?.certificates]);
    //Bank information
    let bankBranch;
    bankBranch = item?.interviewData?.branchName
      ? item?.interviewData?.branchName
      : "Not entered";
    setDocBankInformation({
      accountNo: item?.interviewData?.enterBankAccountNumber,
      branch: bankBranch,
      ifsc: item?.interviewData?.IFSCcode,
    });

    console.log("PTYPE", item?.interviewProcessData?.processtype);
    item?.interviewProcessData?.processtype !== undefined
      ? setprocesstype(item?.interviewProcessData?.processtype)
      : setprocesstype("na");

    setinterview_address(item?.interviewProcessData?.interview_address);
    setDocvarifyStatus(
      item?.interviewProcessData?.DocumentsVerification_status
    );
    // setIntVerification(IntVerification)
    setmettLink(item?.interviewProcessData?.onlineMeetingLink);
    setInterviewAddresssVerification(
      item?.interviewProcessData?.InterviewAddresssVerification
    );
    sethrRound(item?.interviewProcessData?.hrRound);
    settechnicalRound(item?.interviewProcessData?.technicalRound);
    setmeetingStatus(item?.interviewProcessData?.onlineMeetingStatus);
    setMeetingTime(item?.interviewProcessData?.MeetingTime);
    // setonlinemeetingstatus(item?.interviewProcessData?.onlineMeetingStatus)
    // setmeetingStatus(item?interviewProcessData?.MeetingTime)
    setId(item?.interviewProcessData?._id);
    setHide(false);

    console.log("MeetingTime", item?.interviewProcessData?.MeetingTime);
  };

  const handleTraining = (item) => {
    window.scrollTo(0, 0);
    console.log("SINGLEITEM", item);
    setSingleVendorDetail(item);
    FetchAllTrainingByVendor(item?._id);
    setVendorName(item?.name);
    setDisplayVerification(false);
    setDisplayTraining(true);
    setDisplayHR(false);
    setDisplayToolkit(false);

    // setVendorName(item?.name);
    // setName(item?.serviceName);
    // setdocument_Verification(item?.interviewProcessData?.document_Verification);
    // //photo
    // setDocPhoto(item?.interviewData?.photos);
    // //Aadhar Card
    // setDocAadharCard(item?.interviewData?.aadhaarCard);
    // //PAN card
    // setDocPANCard([item?.interviewData?.pancard]);
    // //certficate
    // setDocCertficate([item?.interviewData?.certificates]);
    // //Bank information
    // let bankBranch;
    // bankBranch = item?.interviewData?.branchName
    //   ? item?.interviewData?.branchName
    //   : "Not entered";
    // setDocBankInformation({
    //   accountNo: item?.interviewData?.enterBankAccountNumber,
    //   branch: bankBranch,
    //   ifsc: item?.interviewData?.IFSCcode,
    // });

    // console.log("PTYPE", item?.interviewProcessData?.processtype);
    // item?.interviewProcessData?.processtype !== undefined
    //   ? setprocesstype(item?.interviewProcessData?.processtype)
    //   : setprocesstype("na");

    // setinterview_address(item?.interviewProcessData?.interview_address);
    // setDocvarifyStatus(
    //   item?.interviewProcessData?.DocumentsVerification_status
    // );
    // // setIntVerification(IntVerification)
    // setmettLink(item?.interviewProcessData?.onlineMeetingLink);
    // setInterviewAddresssVerification(
    //   item?.interviewProcessData?.InterviewAddresssVerification
    // );
    // sethrRound(item?.interviewProcessData?.hrRound);
    // settechnicalRound(item?.interviewProcessData?.technicalRound);
    // setmeetingStatus(item?.interviewProcessData?.onlineMeetingStatus);
    // setMeetingTime(item?.interviewProcessData?.MeetingTime);

    // setonlinemeetingstatus(item?.interviewProcessData?.onlineMeetingStatus)
    // setmeetingStatus(item?interviewProcessData?.MeetingTime)
    setId(item?.interviewProcessData?._id);
    setHide(false);

    console.log("MeetingTime", item?.interviewProcessData?.MeetingTime);
  };

  const handleInterviewRound = (item) => {
    window.scrollTo(0, 0);
    // console.log("SINGLEITEM", item);
    setSingleVendorDetail(item);
    FetchAllInterviewRound(item?._id);
    setVendorName(item?.name);
    setDisplayVerification(false);
    setDisplayTraining(false);
    setDisplayToolkit(false);
    setDisplayHR(true);

    setId(item?.interviewProcessData?._id);
    setHide(false);

    console.log("MeetingTime", item?.interviewProcessData?.MeetingTime);
  };

  const handleTookit = (item) => {
    window.scrollTo(0, 0);
    console.log("SINGLEITEM", item);
    setSingleVendorDetail(item);
    FetchAllInterviewRound(item?._id);
    setVendorName(item?.name);
    setDisplayVerification(false);
    setDisplayTraining(false);
    setDisplayHR(false);
    setDisplayToolkit(true);

    setId(item?.interviewProcessData?._id);
    setHide(false);

    console.log("MeetingTime", item?.interviewProcessData?.MeetingTime);
  };

  const updateData = () => {
    // console.log("ID", id);
    const data = {
      processtype: processtype,
      interview_address: interview_address,
      InterviewAddresssVerification: InterviewAddresssVerification,
      onlineMeetingLink: mettLink,
      onlineMeetingStatus: mettStatus,
      MeetingTime: MeetingTime,

      document_Verification: document_Verification,

      DocumentsVerification_status: docvarifyStatus,

      hrRound: hrRound,
      technicalRound: technicalRound,
    };

    if (processtype == "online") {
      if (isValidUrl(mettLink)) {
        data.onlineMeetingLink = mettLink;
      } else {
        return toast.error("Invalid Meeting Link");
      }
      if (!MeetingTime) {
        return toast.error("Invalid Meeting Time");
      }
    } else if (processtype == "offline") {
      if (!interview_address) {
        return toast.error("Invalid Meeting address");
      }
    }

    console.log("data113", data);

    // return
    HomeService.UpdateVendar(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          resetData();
          fetchAllData();
        } else {
          toast.error(" data Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (id) => {
    // let data = {
    //   status: val,
    // };
    console.log("ID", id);
    NewHomService.UpdateVendorStatus(id)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///FetchAllRoundByVendor
  const FetchAllInterviewRound = async (vendorID) => {
    console.log("VIDDD", vendorID);
    // let newFormValues = [...offlineAddressesValues];
    // let newFormValues1 = [...onlineLinksValues];
    NewHomService.FetchRoundByVendor(vendorID)
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)
          // console.log("AADX", vendorHR);
          setVendorHR(res?.data);
          console.log("AADXSE", vendorHR);
          // res?.data.map((item, i) => {
          //   // newFormValues[i] = item.addressDetail;
          //   // setOfflineAddressesValues(newFormValues);
          //   // newFormValues1[i] = item.joinUrl;
          //   // setOnlineLinksValues(newFormValues1);
          // });
        }
        // console.log("HHH", offlineAddressesValues, onlineLinksValues);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ///FetchAllTrainingByVendor
  const FetchAllTrainingByVendor = async (vendorID) => {
    let newFormValues = [...offlineAddressesValues];
    let newFormValues1 = [...onlineLinksValues];
    NewHomService.FetchTrainingByVendor(vendorID)
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)
          console.log("AAD", res.data);
          setVendorTraining(res?.data);
          res?.data.map((item, i) => {
            // newFormValues = [...offlineAddressesValues];
            newFormValues[i] = item.addressDetail;
            setOfflineAddressesValues(newFormValues);

            // newFormValues1 = [...onlineLinksValues];
            newFormValues1[i] = item.joinUrl;
            setOnlineLinksValues(newFormValues1);
          });
        }
        console.log("HHH", offlineAddressesValues, onlineLinksValues);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubscriptionPaymentStatus = (vendorId, status) => {
    let data = {
      "vendorId": vendorId,
      "paymentStatus": status
    }
    NewHomService.UpdateVendorSubscriptionPaymentStatus(data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleRegistrationPaymentStatus = (vendorId, status) => {
    let data = {
      "vendorId": vendorId,
      "paymentStatus": status
    }
    NewHomService.UpdateVendorRegistrationPaymentStatus(data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ///
  const handlePaymentStatus = (item, target) => {
    let data = {
      vendorId: item._id,
    };
    if (target === "toolkit") {
      data.toolkitPaymentStatus = "paid";

      // data.subscriptionPaymentStatus =
      //   item.subscriptionPaymentStatus !== undefined
      //     ? item.subscriptionPaymentStatus
      //     : "unpaid";
    } else if (target === "subscription") {
      data.toolkitPaymentStatus =
        item.toolkitPaymentStatus !== undefined
          ? item.toolkitPaymentStatus
          : "unpaid";
      // data.subscriptionPaymentStatus = "paid";
    } else {
      data.toolkitPaymentStatus =
        item.toolkitPaymentStatus !== undefined
          ? item.toolkitPaymentStatus
          : "unpaid";
      // data.subscriptionPaymentStatus =
      //   item.subscriptionPaymentStatus !== undefined
      //     ? item.subscriptionPaymentStatus
      //     : "unpaid";
    }
    console.log("PAY", data);
    // return;
    NewHomService.UpdateVendorPaymentStatus(data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllData = () => {
    setLoader(true);
    HomeService.MannageVendar()
      .then((res) => {
        console.log("VENHYTS448", res.data);
        if (res && res?.status) {
          setLoader(false);
          let arr = res?.data
            ?.sort()
            .reverse()
            .map((item, index) => {
              console.log("VENDORSSD", item);
              return {
                sl: index + 1,
                Name: item?.name,
                VendorID: item?.identityId,
                mobileNo: item?.mobileNo,
                // image: IMAMGE_URL + item?.image,
                image: item?.image,
                countryName: item?.countryName,
                stateName: item?.stateName,
                cityName: item?.cityName,
                areaName: item?.areaName,
                languageName: item?.languageName,
                experence: item?.experence,
                workingtime: item?.workingtimeData ? item?.workingtimeData?.name : "n/a",
                workingSchedule: item?.workingSchedule,

                serviceTypeData: item?.serviceTypeData,
                serviceCategoryData: item?.serviceCategoryData,
                subCategoryData: item?.subCategoryData,
                brandData: item?.brandData,
                registrationFees: item?.registrationFees,
                paymentMood: item?.paymentMood,

                ID: item?._id,
                Status: item?.status,
                OfferLetterData: item?.interViewStatusData
                  ? item?.interViewStatusData?.acceptanceStatus
                  : "",
                // DOCV: item?.interviewProcessData.document_Verification,

                zipcodes: item?.zipcode,
                interview: item?.interviewProcessData,
                // whatsAppNumber:item.whatsAppNumber?item.whatsAppNumber:"N/A",
                whatsAppNumber:item.whatsAppVarification?item.whatsAppNumber:item?.mobileNo,

                toolkitFees:
                  item.vendorBuyToolkitData.reduce(
                    (total, tkit) => total + tkit.price,
                    0
                  ),
                registrationFeesPaymentStatus: item.registrationFeesPaymentStatus,


                action2: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    {item?.interviewProcessData?.DocumentsVerification_status &&
                      item?.interviewProcessData?.hrRound &&
                      item?.interviewProcessData?.technicalRound ? (
                      <>
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => handleVerification(item)}
                        >
                          Fully Verified
                          <span style={{ fontSize: "70%", display: "block" }}>
                            Click to View
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        {!item?.interviewProcessData
                          ?.DocumentsVerification_status &&
                          !item?.interviewProcessData?.hrRound &&
                          !item?.interviewProcessData?.technicalRound ? (
                          <>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleVerification(item)}
                            >
                              Not Verified
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to verify
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            {/* <button
                              className="btn btn-sm btn-primary"
                              onClick={() => handleVerification(item)}
                            >
                              Pending Verification{" "}
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to Complete
                              </span>
                            </button> */}
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => handleVerification(item)}
                            >
                              Fully Verified
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to Re-verify
                              </span>
                            </button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                ),

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    {item?.interviewProcessData
                      ?.DocumentsVerification_status ? (
                      <>
                        <button
                          className="btn btn-sm btn-success"
                        // onClick={() => handleVerification(item)}
                        >
                          Fully Verified
                          {/* <span style={{ fontSize: "70%", display: "block" }}>
                            Click to Re-verify
                          </span> */}
                        </button>
                      </>
                    ) : (
                      <>
                        {
                          <>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => handleVerification(item)}
                            >
                              Not Verified
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to verify
                              </span>
                            </button>
                          </>
                        }
                      </>
                    )}
                  </div>
                ),
                delete: (
                  <div style={{ display: "flex", flexDirection: "coloum" }} onClick={() => handleDelete(item?._id)}>


                    <i className="fa-solid fa-trash" style={{ color: '#f2471c', fontSize: "20px" }} ></i>





                  </div>
                ),

                training: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <>
                      <button
                        className={`btn btn-sm ${item?.trainingCompletedStatus ? "btn-success" : "btn-info"}`}
                        onClick={() => handleTraining(item)}
                      >
                        Check Status
                        <span style={{ fontSize: "70%", display: "block" }}>
                          Click to View
                        </span>
                      </button>
                    </>
                  </div>
                ),

                interviewRound: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <>
                      <button
                        // className="btn btn-sm btn-info"
                        className={`btn btn-sm ${item?.interviewProcessData?.hrRound && item?.interviewProcessData?.technicalRound ? 'btn-success' : 'btn-info'}`}
                        onClick={() => handleInterviewRound(item)}
                      >
                        Check Status
                        <span style={{ fontSize: "70%", display: "block" }}>
                          Click to View
                        </span>
                      </button>
                    </>
                  </div>
                ),

                toolkit: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <>
                      <button
                        className={`btn btn-sm ${item?.toolKitProvidedStatus ? "btn-success" : "btn-info"}`}
                        onClick={() => handleTookit(item)}
                      >
                        Check Status
                        <span style={{ fontSize: "70%", display: "block" }}>
                          Click to View
                        </span>
                      </button>
                    </>
                  </div>
                ),

                toolkitPayment: (
                  <div style={{ display: "block", flexDirection: "coloum" }}>
                    <>
                      {item?.vendorBuyToolkitData.length > 0 &&
                        item?.vendorBuyToolkitData[0].modePayment !==
                        undefined ? (
                        <>
                          <div className="row">
                            <div className="col">
                              <label
                                for="exampleInputEmail1"
                                class="mr-2"
                                style={{ display: "block" }}
                              >
                                <ul>
                                  {item?.vendorBuyToolkitData.map(
                                    (item, index) => {
                                      return (
                                        <>
                                          <li>
                                            {item.categoryName}: {item.price}
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ul>
                                Amount:
                                <span class="badge badge-secondary ml-1">
                                  {item?.vendorBuyToolkitData.reduce(
                                    (total, tkit) => total + tkit.price,
                                    0
                                  )}
                                </span>
                                {/* <label
                                for="exampleInputEmail1"
                                class="mr-2"
                                style={{ display: "block" }}
                              >
                                Amount:
                                <span class="badge badge-secondary ml-1">
                                  {item?.vendorSubscriptionData[0].price}
                                </span>
                              </label> */}
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <label
                                for="exampleInputEmail1"
                                class="mr-2"
                                style={{ display: "block" }}
                              >
                                Mode:
                                <span class="badge badge-secondary ml-1">
                                  {item?.vendorBuyToolkitData[0].modePayment}
                                </span>
                              </label>
                            </div>
                          </div>
                          {item?.vendorBuyToolkitData[0].paymentStatus !==
                            "paid" ? (
                            <>
                              <div className="row">
                                <div className="col">
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() =>
                                      handlePaymentStatus(item, "toolkit")
                                    }
                                    style={{ display: "block" }}
                                  >
                                    UNPAID
                                    <span
                                      style={{
                                        fontSize: "70%",
                                        display: "block",
                                      }}
                                    >
                                      Click if PAID
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <span class="badge badge-success">
                                {item?.vendorBuyToolkitData[0].paymentStatus}
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <>n/a</>
                      )}
                    </>
                  </div>
                ),

                _subscription: (
                  <div style={{
                    display: "block",
                    // flexDirection: "coloum",
                  }}

                  >
                    <>
                      <div className="row">
                        <div className="col-sm-4 fw-bold">Category</div>
                        <div className="col-sm-4 fw-bold">Amount</div>
                        <div className="col-sm-4 fw-bold">Status</div>
                      </div>
                      {
                        item?.vendorSubscriptionData.map((item, key) => {
                          return (
                            <>


                              <div className="row">
                                <div className="col-sm-4">
                                  {/* <span class="badge badge-primary mb-2"> */}
                                  {
                                    // item?.vendorSubscriptionData[0]?.subModelData
                                    //   ?.title
                                    item?.categoryName
                                  }
                                  {/* </span> */}
                                </div>
                                <div className="col-sm-4">
                                  {/* <label
                                    for="exampleInputEmail1"
                                    class="mr-2"
                                    style={{ display: "block" }}
                                  >
                                    Amount:
                                    <span class="badge badge-secondary ml-1">
                                      {item?.price}
                                    </span>
                                  </label> */}
                                  {item?.price}
                                </div>
                                <div className="col-sm-4">
                                  {/* <label
                                    for="exampleInputEmail1"
                                    class="mr-2"
                                    style={{ display: "block" }}
                                  >
                                    Mode:
                                    <span class="badge badge-secondary ml-1">
                                      {
                                        item?.paymentMethod
                                      }
                                    </span>
                                  </label> */}
                                  {item?.paymentMethod}
                                </div>
                              </div>

                              {/* <div className="row">
                                <div className="col">
                                  <label
                                    for="exampleInputEmail1"
                                    class="mr-2"
                                    style={{ display: "block" }}
                                  >
                                    Amount:
                                    <span class="badge badge-secondary ml-1">
                                      {item?.price}
                                    </span>
                                  </label>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                  <label
                                    for="exampleInputEmail1"
                                    class="mr-2"
                                    style={{ display: "block" }}
                                  >
                                    Mode:
                                    <span class="badge badge-secondary ml-1">
                                      {
                                        item?.paymentMethod
                                      }
                                    </span>
                                  </label>
                                </div>
                              </div> */}
                            </>
                          )
                        })
                      }

                      {item?.vendorSubscriptionData.length > 0 ? (
                        <>
                          {/* <div className="row">
                            <div className="col">
                              <span class="badge badge-primary mb-2">
                                {
                                  // item?.vendorSubscriptionData[0]?.subModelData
                                  //   ?.title
                                  item?.vendorSubscriptionData[0]?.categoryName
                                }
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <label
                                for="exampleInputEmail1"
                                class="mr-2"
                                style={{ display: "block" }}
                              >
                                Amount:
                                <span class="badge badge-secondary ml-1">
                                  {item?.vendorSubscriptionData[0].price}
                                </span>
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <label
                                for="exampleInputEmail1"
                                class="mr-2"
                                style={{ display: "block" }}
                              >
                                Mode:
                                <span class="badge badge-secondary ml-1">
                                  {
                                    item?.vendorSubscriptionData[0]
                                      .paymentMethod
                                  }
                                </span>
                              </label>
                            </div>
                          </div> */}

                          {/* <div className="row">
                            <div className="col">
                              <button
                                className="btn btn-sm btn-success"
                                onClick={() =>
                                  handlePaymentStatus(item, "subscription")
                                }
                                style={{ display: "block" }}
                              >
                                PAID
                                <span
                                  style={{ fontSize: "70%", display: "block" }}
                                >
                                  Click to change status
                                </span>
                              </button>
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <>n/a</>
                      )}
                    </>
                  </div>
                ),

                subscription: (

                  <>
                    {
                      item?.vendorSubscriptionData.length > 0 ? (
                        <>
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th scope="col-3">Category</th>
                                <th scope="col-3">Amount</th>
                                {/* <th scope="col-3">Status</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                item?.vendorSubscriptionData.map((item, key) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{item?.categoryName}</td>
                                        <td>{item?.price}</td>
                                        {/* <td>{item?.paymentMethod}</td> */}
                                      </tr>
                                    </>
                                  )
                                })
                              }

                            </tbody>
                            <thead>
                              <tr>
                                <th>Total amount</th>
                                <th>{item?.vendorSubscriptionData.reduce(
                                  (total, tkit) => total + tkit.price,
                                  0
                                )}</th>
                              </tr>
                              <tr>
                                <th>Payment mode</th>
                                <th>{item?.vendorSubscriptionData.length > 0 ? item?.vendorSubscriptionData[0].paymentMethod : "n/a"}</th>
                              </tr>
                              <tr>
                                <th>Payment status</th>
                                <th>
                                  {item?.vendorSubscriptionData[0]?.subscriptionPaymentStatus !==
                                    "paid" ? (
                                    <>
                                      {/* <div className="row">
                                <div className="col"> */}
                                      <button
                                        className="btn btn-sm btn-success"
                                        onClick={() =>
                                          handleSubscriptionPaymentStatus(item._id, "paid")
                                        }
                                        style={{ display: "block" }}
                                      >
                                        UNPAID
                                        <span
                                          style={{
                                            fontSize: "70%",
                                            display: "block",
                                          }}
                                        >
                                          Click if PAID
                                        </span>
                                      </button>
                                      {/* </div>
                              </div> */}
                                    </>
                                  ) : (
                                    <>
                                      <span class="badge badge-success">
                                        {item?.vendorSubscriptionData[0]?.subscriptionPaymentStatus}
                                      </span>
                                    </>
                                  )}

                                </th>
                              </tr>
                            </thead>
                          </table>

                        </>
                      ) : (
                        <>
                          n/a
                        </>
                      )
                    }

                  </>



                ),

                offerLetter: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <>
                      {item?.interviewProcessData
                        ?.DocumentsVerification_status &&
                        item?.interviewProcessData?.hrRound &&
                        item?.interviewProcessData?.technicalRound &&
                        item?.vendorBuyToolkitData[0]?.paymentStatus == "paid" &&
                        !item?.interViewStatusData?.acceptanceStatus ? (
                        <>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={(e) => openModal(item)}
                          >
                            Letter
                            <span style={{ fontSize: "70%", display: "block" }}>
                              Click to Issue
                            </span>
                          </button>
                        </>
                      ) : (
                        <>
                          {item?.interViewStatusData?.acceptanceStatus ? (
                            <>
                              {/* <strong>
                                {item?.interViewStatusData?.acceptanceStatus.toUpperCase()}
                              </strong> */}
                              <button
                                className="btn btn-sm btn-success"
                                onClick={(e) => openModal(item)}
                              >
                                {item?.interViewStatusData?.acceptanceStatus.toUpperCase()}
                                <span
                                  style={{ fontSize: "70%", display: "block" }}
                                >
                                  {item?.interViewStatusData?.acceptanceStatus.toUpperCase() ===
                                    "REJECTED"
                                    ? "Click to Re-issue"
                                    : "Click to View"}
                                </span>
                              </button>
                            </>
                          ) : (
                            <>n/a</>
                          )}
                        </>
                      )}
                      {/* {item?.interViewStatusData?.acceptanceStatus} */}
                    </>
                  </div>
                ),
              };
            });
          setFilteredData(arr);
          setAllData(arr);
          fillCSVData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Vendor ID
        </div>
      ),
      selector: (row) => {
        return row.VendorID !== undefined ? row.VendorID : "n/a";
      },
      width: "100px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Photo
        </div>
      ),
      selector: (row) => (

        <img
          style={{ width: "100%", borderRadius: "8px" }}
          src={row.image}
          alt={""}
        />

      ),
      width: "100px",
      padding: "10px",
      wrap: true,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name | Mobile
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <h4>{row.Name}</h4>
            <strong>Mobile:</strong> {row.mobileNo}
          </>
        );
      },
      wrap: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          WhatsApp Number
        </div>
      ),
      selector: (row) => row.whatsAppNumber,
      wrap: true,
      width: "150px",
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Offer Letter
        </div>
      ),
      selector: (row) => row.offerLetter,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Document Verification
        </div>
      ),
      selector: (row) => row.action2,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Interview Round
        </div>
      ),
      selector: (row) => row.interviewRound,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Toolkit
        </div>
      ),
      selector: (row) => row.toolkit,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Training
        </div>
      ),
      selector: (row) => row.training,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Toolkit Payment
        </div>
      ),
      selector: (row) => row.toolkitPayment,
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Subscription
        </div>
      ),
      selector: (row) => row.subscription,
      width: "350px",
      wrap: true,
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Location
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <div>
              <strong>Country</strong>: {row.countryName},<br />
              <strong>State: </strong>
              {row.stateName},<br />
              <strong>City:</strong> {row.cityName},<br />
              <strong>Area:</strong> {row.areaName}
            </div>
          </>
        );
      },
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service ZIP Code(s)
        </div>
      ),
      selector: (row) => {
        return row.zipcodes ? (
          <>
            {row.zipcodes.map((code, key) => {
              return (
                <>
                  <span
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {code}
                  </span>
                  {key < row.zipcodes.length - 1 && <>,&nbsp;</>}
                </>
              );
            })}
          </>
        ) : (
          "n/a"
        );
      },
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Language
        </div>
      ),
      selector: (row) => row.languageName,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Service Type
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         <ul>
    //           {row.serviceTypeData.map((item, key) => {
    //             return (
    //               <>
    //                 <li>{item.serviceName}</li>
    //               </>
    //             );
    //           })}
    //         </ul>
    //       </>
    //     );
    //   },
    //   width: "200px",
    //   wrap: true,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type | Category | Subcategory
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <ul>
              {row.serviceTypeData.map((item, key) => {
                return (
                  <>
                    <li>
                      <span style={{ fontSize: "18px" }}>
                        {item.serviceName}
                      </span>
                      {row.serviceCategoryData && (
                        <>
                          <ul>
                            {row.serviceCategoryData
                              .filter((cate) => cate.serviceTypeID === item._id)
                              .map((categories) => {
                                return (
                                  <>
                                    <li>
                                      <span style={{ fontSize: "16px" }}>
                                        {categories.name}
                                      </span>
                                      {row.subCategoryData && (
                                        <>
                                          <ul>
                                            {row.subCategoryData
                                              .filter(
                                                (subcate) =>
                                                  subcate.categoryID ===
                                                  categories._id
                                              )
                                              .map((subcategories) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <span
                                                        style={{
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {subcategories.name}
                                                      </span>
                                                    </li>
                                                  </>
                                                );
                                              })}
                                          </ul>
                                        </>
                                      )}
                                    </li>
                                  </>
                                );
                              })}
                          </ul>
                        </>
                      )}
                    </li>
                  </>
                );
              })}
            </ul>
          </>
        );
      },
      width: "300px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Experience
        </div>
      ),
      selector: (row) => row.experence,
      width: "120px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Work Timing
        </div>
      ),
      selector: (row) => {
        return row.workingtime;
      },
      wrap: true,
    },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Category
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         <SeeMoreLess maxWordShow={100}>
    //           <ul className="list-group">
    //             {row.serviceCategoryData.map((item, key) => {
    //               return (
    //                 <>
    //                   <li className="list-group-item">{item.name}</li>
    //                 </>
    //               );
    //             })}
    //           </ul>
    //         </SeeMoreLess>
    //       </>
    //     );
    //   },
    //   width: "200px",
    //   wrap: true,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Category
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         <SeeMoreLess maxWordShow={10}>
    //           {row.serviceCategoryData.map((item, key) => {
    //             return <>{item.name},&nbsp;</>;
    //           })}
    //         </SeeMoreLess>
    //       </>
    //     );
    //   },
    //   width: "200px",
    //   wrap: true,
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Sub Category
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         <ul className="list-group my-2">
    //           {row.subCategoryData.map((item, key) => {
    //             return (
    //               <>
    //                 <li className="list-group-item">{item.name}</li>
    //               </>
    //             );
    //           })}
    //         </ul>
    //       </>
    //     );
    //   },
    //   width: "300px",
    //   wrap: true,
    //   alignItems: "top",
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Sub Category
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         {row.subCategoryData.map((item, key) => {
    //           return <>{item.name},&nbsp;</>;
    //         })}
    //       </>
    //     );
    //   },
    //   width: "300px",
    //   wrap: true,
    //   alignItems: "top",
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Brand(s)
    //     </div>
    //   ),
    //   selector: (row) => {
    //     return (
    //       <>
    //         <ul className="list-group">
    //           {row.brandData.map((item, key) => {
    //             return (
    //               <>
    //                 <li className="list-group-item">{item.name}</li>
    //               </>
    //             );
    //           })}
    //         </ul>
    //       </>
    //     );
    //   },
    //   width: "150px",
    //   wrap: true,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Brand(s)
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {row.brandData.map((item, key) => {
              return <>{item.name},&nbsp;</>;
            })}
          </>
        );
      },
      width: "150px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Registration Fee
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <strong>Amount:</strong> {row.registrationFees}
            <br />
            <strong>Payment Mode:</strong> {row.paymentMood}<br />
            {row?.registrationFeesPaymentStatus !==
              "paid" ? (
              <>
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-sm btn-success"
                      onClick={() =>
                        handleRegistrationPaymentStatus(row.ID, "paid")
                      }
                      style={{ display: "block" }}
                    >
                      UNPAID
                      <span
                        style={{
                          fontSize: "70%",
                          display: "block",
                        }}
                      >
                        Click if PAID
                      </span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <span class="badge badge-success">
                  {row?.registrationFeesPaymentStatus}
                </span>
              </>
            )}
          </>
        );
      },
      wrap: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Toolkit Fee
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <strong>Amount</strong>: {row.toolkitFees}
          </>
        );
      },
      width: "150px",
      wrap: true,
    },

    // {
    //   name: (
    //     <div
    //       style={{
    //         fontSize: "14px",
    //         color: "#495057",
    //         marginLeft: "15px",
    //         fontWeight: "bolder",
    //       }}
    //     >
    //       Training
    //     </div>
    //   ),
    //   selector: (row) => row.training,
    //   width: "150px",
    //   wrap: true,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Job List
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <Link to={`/vendor-tasks/${row?.ID}`}>View Job(s)</Link>
          </>
        );
      },
      width: "120px",
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      width: "150px",
      selector: (row) => {
        return (
          <>
            {
              row.Status
                && row.interview?.DocumentsVerification_status
                && row.interview?.hrRound
                && row.interview?.technicalRound ?
                (
                  <>
                    <button
                      className={`btn btn-sm  ${row.interview?.DocumentsVerification_status && row.interview?.hrRound && row.interview?.technicalRound ? "btn-success" : "btn-danger"}`}
                      onClick={() => changeStatus(row.ID, false)}
                    >
                      Active
                      <span style={{ fontSize: "70%", display: "block" }}>
                        Click to Deactivate
                      </span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => changeStatus(row.ID, true)}
                    >
                      Deactive
                      <span style={{ fontSize: "70%", display: "block" }}>
                        Click to Active
                      </span>
                    </button>
                  </>
                )}
          </>
        );
      },
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Delete Vendor
        </div>
      ),
      selector: (row) => {
        return row.delete;
      },
      wrap: true,
      center: true
    },
  ];

  // console.log("docvarifyStatus", typeof docvarifyStatus, docvarifyStatus);
  const updateTrainingInfo = (e, index, id, mode, vendorID) => {
    e.preventDefault();
    let data = {};

    mode === "online" && (data.joinUrl = onlineLinksValues[index]);
    mode === "offline" && (data.addressDetail = offlineAddressesValues[index]);

    console.log("SAVEDATA", data);
    NewHomService.UpdateTrainingByVendor(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Training Info updated successfully");
          FetchAllTrainingByVendor(vendorID);
          // setHide(true);
          // console.log("RESPONSEDELETE", res);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [offlineAddressesValues, setOfflineAddressesValues] = useState([]);
  const handleOfflineAddressesChange = (i, e) => {
    let newFormValues = [...offlineAddressesValues];
    newFormValues[i] = e.target.value;
    setOfflineAddressesValues(newFormValues);
  };

  const handleDelete = async (id) => {

    console.log("item", id)
    const res = await HomService.DeleteHomeVendar(id);

    if (res && res?.status) {
      toast.success(res?.message)
      fetchAllData()
    }
    else {
      toast.error(res?.message)
    }
  }
  const [onlineLinksValues, setOnlineLinksValues] = useState([]);
  const handleOnlineLinksChange = (i, e) => {
    let newFormValues = [...onlineLinksValues];
    newFormValues[i] = e.target.value;
    setOnlineLinksValues(newFormValues);
  };

  const updateTrainingStatus = (e, id, status, vendorID) => {
    e.preventDefault();
    let data = {
      trainingStatus: status,
    };
    NewHomService.UpdateTrainingByVendor(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          FetchAllTrainingByVendor(vendorID);
          // setHide(true);
          // console.log("RESPONSEDELETE", res);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateInterviewStatus = (e, item, target) => {
    e.preventDefault();
    let data = {};
    if (target === "hr") {
      data.hrRound = true;
    }
    if (target === "technical") {
      data.technicalRound = true;
    }
    if (target === "toolkit") {
      data.toolkitProvided = true;
    }

    NewHomService.UpdateRoundByVendor(item._id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          FetchAllInterviewRound(item.vendorId);
          setId(item?.interviewProcessData?._id);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const _updateInterviewStatus = (e, item, target) => {
    // e.preventDefault();
    let data = {};
    if (target === "hr") {
      data.hrRound = false;
    }
    if (target === "technical") {
      data.technicalRound = false;
    }
    if (target === "toolkit") {
      data.toolkitProvided = false;
    }

    NewHomService.UpdateRoundByVendor(item._id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success(res.msg);
          FetchAllInterviewRound(item.vendorId);
          console.log("ITEMXX", item);
          handleInterviewRound(item);
          // setHide(true);
          // console.log("RESPONSEDELETE", res);
        } else {
          toast.error(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openModal = (VendorData) => {
    // console.log("VendorData", VendorData);
    setIsOpen(true);
    setSingleVendorDetail(VendorData);
    // setReplyToAppId(appId)
    // setReplyTo(email)
    // setReplyToName(name)
  };

  const [offerLetterContent, setOfferLetterContent] = useState("");
  const [offerLetterContentDB, setOfferLetterContentDB] = useState("");

  const AddOfferLetter = (e, vendorID) => {
    e.preventDefault();
    let data = {
      letterBody: offerLetterContent,
      vendorId: vendorID,
    };

    if (vendorID && offerLetterContent) {
      NewHomService.AddVendorOfferLetter(data)
        .then((res) => {
          // console.log("Response Add Blog Category", res);
          if (res && res.status) {
            toast.success(res.msg);
            setOfferLetterContent("");
            setIsOpen(false);
            // changeStatus(vendorID, true)
            // fetchAllState();
            // getAllCountry();
            // setName("");
            // setimgdata("")
            // setimage("")
          } else {
            toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!offerLetterContent) toast.error("Letter body should not be empty");
      // if(!imgdata)
      // toast.error("Country icon Field Is Required");
    }
  };

  const FetchOfferLetter = async (vendorID) => {
    // const res = await NewHomService.FetchData();
    const res = await NewHomService.FetchVendorOfferLetter(vendorID);

    if (res && res?.status) {
      setOfferLetterContent(res?.data[0]?.letterBody);
      setOfferLetterContentDB(res?.data[0]?.letterBody);
      // setLoader(false)
      // console.log("resss", res);
      // res?.data.length > 0
      //   ? setOfferLetterContent(res?.data[0]?.letterBody)
      //   : setOfferLetterContent("");
    }
  };

  const afterOpenModal = (id) => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
    console.log("gfgggggggggggggggg");
    FetchOfferLetter(id);
  };

  const closeModal = () => {
    // resetState();
    setOfferLetterContent("");
    setIsOpen(false);
  };

  const saveOfferLetter = (vendorID) => {
    AddOfferLetter(vendorID);
    // setIsOpen(false);
    const data = {
      queriesId: replyToAppId,
      customer_email: replyTo,
      customer_name: replyToName,
      text: replyToMessage,
    };
    toast.success("Sending email...");
    replyToMessage !== ""
      ? NewHomService.SendReplyToVendorQueries(data)
        .then((res) => {
          // console.log("updated res", res);

          if (res && res.status) {
            setIsOpen(false);
            Swal.fire(`Reply sent to ${data.customer_name} successfully`);
            // resetState();
          } else {
            toast.error("Unable to send reply. Try again!");
          }
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Message should not be empty");
  };

  const [searchVendorName, setSearchVendorName] = useState("");
  const [searchVendorMobile, setSearchVendorMobile] = useState("");

  const onFilter = () => {
    let searchData = allData;
    console.log("ALLSDATA", allData);
    //Vendor Name
    if (searchVendorName !== "") {
      searchData = searchData.filter((item) => {
        if (
          item?.Name?.toLowerCase().includes(searchVendorName.toLowerCase())
        ) {
          return item;
        }
      });
      setFilteredData(searchData);
      fillCSVData(searchData);
    }
    //Vendor Mobile
    if (searchVendorMobile !== "") {
      console.log("searchData", searchData);
      searchData = searchData.filter((item) => {
        if (item?.mobileNo?.toString()?.includes(searchVendorMobile)) {
          return item;
        }
      });
      setFilteredData(searchData);
    }

    //State
    if (searchState !== "") {
      console.log("searchData", searchData);
      searchData = searchData.filter((item) => {
        if (item?.stateName?.toString()?.includes(searchState)) {
          return item;
        }
      });
      setFilteredData(searchData);
    }

    //City
    if (searchCity !== "") {
      console.log("searchData", searchData);
      searchData = searchData.filter((item) => {
        if (item?.cityName?.toString()?.includes(searchCity)) {
          return item;
        }
      });
      setFilteredData(searchData);
    }

    //Area
    if (searchArea !== "") {
      console.log("searchData", searchData);
      searchData = searchData.filter((item) => {
        if (item?.areaName?.toString()?.includes(searchArea)) {
          return item;
        }
      });
      setFilteredData(searchData);
    }

    //From and To Date
    if (fromDate) {
      // console.log("DATESE", fromDate,toDate);
      searchData = searchData.filter((item) => {
        let itemDate = new Date(item?.RegistrationDate);
        let frmDt = new Date(fromDate);
        let toDt = new Date(toDate);
        console.log(
          "DATESEAR",
          itemDate.getDate(),
          " -XX- ",
          frmDt.getDate(),
          " -XX- ",
          toDt.getDate()
        );
        if (
          itemDate.getDate() >= frmDt.getDate() &&
          itemDate.getDate() <= toDt.getDate()
        ) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  };

  const viewAllData = () => {
    setSearchVendorName("");
    setSearchState("");
    setSearchCity("");
    setSearchArea("");
    setFilteredData(allData);
    fillCSVData(allData);
  };

  ////CSV Download//////////////

  const [csvData, setCsvData] = useState([]);

  const fillCSVData = (data) => {
    console.log("DATA1547", data);
    let arrCSV = data.map((item, index) => {
      return {
        SLNO: index + 1,
        ID: item?.VendorID,
        Name: item?.Name,
        Country: item?.countryName,
        State: item?.stateName,
        City: item?.cityName,
        Area: item?.areaName,
        Experence: item?.experence,
        Language: item?.languageName,
        RegistrationFees: item?.registrationFees,
        PaymentMode: item?.paymentMood,
        WorkingTime: item?.workingtime.toUpperCase(),
        // Category: item?.
      };
    });
    setCsvData(arrCSV);
  };

  ////CSV Download//////////////

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                {/* Add Vendor */}
              </div>
            ) : (
              <>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#868e96",
                    margin: "35px",
                  }}
                  className="card-title"
                >
                  Vendor: {vendorName}
                  {/* {displayTraining} */}
                </div>

                {/* Training */}
                <div
                  id="training"
                  style={{ display: displayTraining ? "block" : "none" }}
                >
                  {/* Training */}
                  <div class="card mb-3">
                    <div class="card-header">Training Details</div>
                    <div class="card-body">
                      {vendorTraining?.map((item, key) => {
                        return (
                          <>
                            <div
                              class="card"
                              key={key}
                              style={{ borderBottom: "solid 1px #6c757d" }}
                            >
                              <div class="card-body">
                                <div className="row">
                                  <div class="form-group col-8">
                                    <span class="badge badge-primary">
                                      {item?.serviceDetail.serviceName}
                                    </span>
                                    <h3>{item?.categoryDetail.name}</h3>
                                    <label
                                      for="exampleInputEmail1"
                                      className="mr-2"
                                      style={{ display: "block" }}
                                    >
                                      Training Mode:
                                      <span class="badge badge-secondary ml-2">
                                        {item?.trainingMode}
                                      </span>
                                    </label>
                                    <label
                                      for="exampleInputEmail1"
                                      className="mr-2"
                                      style={{ display: "block" }}
                                    >
                                      Training Date & Time:
                                      <span class="badge badge-secondary ml-2">
                                        {moment(item?.trainingDate).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </span>
                                      <span class="badge badge-secondary ml-1">
                                        {moment(
                                          item?.trainingTime,
                                          "HH:mm"
                                        ).format("hh:mm A")}
                                      </span>
                                    </label>
                                    {item?.trainingMode === "offline" ? (
                                      <>
                                        <div>
                                          <label for="exampleInputEmail1">
                                            Offline Address :
                                          </label>

                                          <textarea
                                            class="form-control"
                                            rows="2"
                                            value={offlineAddressesValues[key]}
                                            onChange={(e) =>
                                              handleOfflineAddressesChange(
                                                key,
                                                e
                                              )
                                            }
                                          ></textarea>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div>
                                          <label for="exampleInputEmail1">
                                            Training Link
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                            :
                                          </label>
                                          <input
                                            type="text"
                                            class="form-control"
                                            aria-describedby="emailHelp"
                                            value={onlineLinksValues[key]}
                                            onChange={(e) =>
                                              handleOnlineLinksChange(key, e)
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {item?.trainingStatus === "pending" ? (
                                      <>
                                        <div class="form-group mt-2">
                                          <button
                                            class="btn btn-primary mr-2"
                                            onClick={(e) => {
                                              updateTrainingInfo(
                                                e,
                                                key,
                                                item._id,
                                                item.trainingMode,
                                                item.vendorID
                                              );
                                            }}
                                          >
                                            {item?.trainingMode === "online" ? (
                                              <>Save Link</>
                                            ) : (
                                              <>Save Address</>
                                            )}
                                          </button>
                                          <button
                                            class="btn btn-success"
                                            onClick={(e) =>
                                              updateTrainingStatus(
                                                e,
                                                item._id,
                                                "complete",
                                                item.vendorID
                                              )
                                            }
                                          >
                                            Training Completed
                                          </button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* <button
                                      class="btn btn-danger mt-2"
                                      onClick={(e) =>
                                        updateTrainingStatus(
                                          e,
                                          item._id,
                                          "pending",
                                          item.vendorID
                                        )
                                      }
                                    >
                                      Make Pending
                                    </button> */}
                                      </>
                                    )}
                                  </div>
                                  <div className="col-4">
                                    {item.trainingStatus === "complete" && (
                                      <>
                                        <div
                                          class="alert alert-success text-center"
                                          role="alert"
                                          style={{ borderRadius: "10rem" }}
                                        >
                                          Training&nbsp;
                                          <strong>Completed</strong>
                                          &nbsp;Successfully
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* Interview Round */}
                <div
                  id="interview-round"
                  style={{ display: displayHR ? "block" : "none" }}
                >
                  {/* Round */}
                  <div class="card mb-3">
                    <div class="card-header">Interview Rounds</div>
                    <div class="card-body">
                      {vendorHR?.map((item, key) => {
                        return (
                          <>
                            <div
                              class="card"
                              key={key}
                              style={{ borderBottom: "solid 1px #6c757d" }}
                            >
                              <div class="card-body">
                                <div className="row">
                                  <div class="form-group col-8">
                                    <span class="badge badge-primary">
                                      {item?.categoryDetail?.serviceName}
                                    </span>
                                    <h3>{item?.categoryDetail?.name}</h3>
                                    <div class="form-group mt-2">
                                      {!item?.hrRound ? (
                                        <>
                                          <button
                                            class="btn btn-primary mr-2"
                                            onClick={(e) =>
                                              updateInterviewStatus(
                                                e,
                                                item,
                                                "hr"
                                              )
                                            }
                                          >
                                            Verify HR Round
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <span class="badge badge-success mr-2">
                                            HR Round Verified
                                          </span>
                                          {/* <button
                                            class="btn btn-primary mr-2"
                                            onClick={(e) =>
                                              _updateInterviewStatus(
                                                e,
                                                item,
                                                "hr"
                                              )
                                            }
                                          >
                                            Un-verify HR Round
                                          </button> */}
                                        </>
                                      )}
                                      {!item?.technicalRound ? (
                                        <>
                                          <button
                                            class="btn btn-primary"
                                            onClick={(e) =>
                                              updateInterviewStatus(
                                                e,
                                                item,
                                                "technical"
                                              )
                                            }
                                          >
                                            Verify Technical Round
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <span class="badge badge-success">
                                            Technical Round Verified
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {/* <div className="col-4">
                                    {item.trainingStatus === "complete" && (
                                      <>
                                        <div
                                          class="alert alert-success text-center"
                                          role="alert"
                                          style={{ borderRadius: "10rem" }}
                                        >
                                          Training&nbsp;
                                          <strong>Completed</strong>
                                          &nbsp;Successfully
                                        </div>
                                      </>
                                    )}
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* Document Verification */}
                <div
                  id="document-verification"
                  style={{ display: displayVerification ? "block" : "none" }}
                >
                  <div className="row">
                    <div className="col">
                      <div class="form-group">
                        <label for="exampleInputEmail1">
                          Interview Process Mode:
                        </label>{" "}
                        <strong>{processtype}</strong>
                        {/* <select
                        onChange={(e) => {
                          setprocesstype(e.target.value);
                        }}
                        value={processtype}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="na">Not Choosen...</option>
                        <option value="online">Online</option>
                        <option value="offline">Offline</option>
                      </select> */}
                      </div>

                      <div
                        class="form-group"
                        style={{
                          display: processtype == "offline" ? "block" : "none",
                        }}
                      >
                        <label for="exampleInputEmail1">
                          Interview Address{" "}
                          <span style={{ color: "red" }}>*</span> :
                        </label>

                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          value={interview_address}
                          onChange={(e) => setinterview_address(e.target.value)}
                        ></textarea>
                      </div>

                      <div
                        class="form-group"
                        style={{
                          display: processtype == "online" ? "block" : "none",
                        }}
                      >
                        <label for="exampleInputEmail1">
                          Meeting Link <span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          value={mettLink}
                          onChange={(e) => setmettLink(e.target.value)}
                        />
                      </div>

                      <div
                        class="form-group"
                        style={{
                          display: processtype == "online" ? "block" : "none",
                        }}
                      >
                        <label for="exampleInputEmail1">
                          Meeting Time<span style={{ color: "red" }}>*</span> :
                        </label>
                        <input
                          type="time"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          onChange={(e) => setMeetingTime(e.target.value)}
                          value={MeetingTime}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="card mb-3">
                    <div class="card-header">Document Verification</div>
                    {/* <div class="card-body"> */}
                    {/* <h5 class="card-title">Special title treatment</h5>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> */}

                    {/* /////////////// */}

                    {/* <div className="row">
                        <div class="form-group">
                          <label
                            for="exampleInputEmail1"
                            style={{ float: "left", minWidth: "150px" }}
                          >
                            Status
                            <span style={{ color: "red" }}>*</span> :
                          </label>
                          <div
                            onChange={(e) => {
                              setDocvarifyStatus(e.target.value === "true");
                            }}
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "left",
                              float: "left",
                              marginLeft: "2rem",
                            }}
                          >
                            <input
                              type="radio"
                              name="docstatus"
                              checked={docvarifyStatus === true}
                              value={true}
                            ></input>
                            Verified
                            <input
                              type="radio"
                              name="docstatus"
                              checked={docvarifyStatus === false}
                              value={false}
                            ></input>
                            Not Verified
                          </div>
                        </div>
                      </div> */}

                    {/* <div className="row">
                      <div
                        class="form-group"
                        style={{
                          display: processtype == "offline" ? "block" : "none",
                        }}
                      >
                        <label
                          for="exampleInputEmail1"
                          style={{ float: "left", minWidth: "150px" }}
                        >
                          Interview Address
                          <span style={{ color: "red" }}>*</span> :
                        </label>
                        <div
                          onChange={(e) => {
                            setInterviewAddresssVerification(
                              e.target.value === "true"
                            );
                          }}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            float: "left",
                            marginLeft: "2rem",
                          }}
                        >
                          <input
                            type="radio"
                            name="intviewadres"
                            checked={InterviewAddresssVerification === true}
                            value={true}
                          ></input>
                          Verified
                          <input
                            type="radio"
                            name="intviewadres"
                            checked={InterviewAddresssVerification === false}
                            value={false}
                          ></input>
                          Not Verified
                        </div>
                      </div>

                      <div
                        class="form-group"
                        style={{
                          display: processtype == "online" ? "block" : "none",
                        }}
                      >
                        <label
                          for="exampleInputEmail1"
                          style={{ float: "left", minWidth: "150px" }}
                        >
                          Online Meeting<span style={{ color: "red" }}>*</span>{" "}
                          :
                        </label>
                        <div
                          onClick={(e) => {
                            setmeetingStatus(e.target.value == "true");
                          }}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "left",
                            float: "left",
                            marginLeft: "2rem",
                          }}
                        >
                          <input
                            type="radio"
                            name="meetstatus"
                            checked={mettStatus === true}
                            value={true}
                          ></input>
                          Verified
                          <input
                            type="radio"
                            name="meetstatus"
                            checked={mettStatus === false}
                            value={false}
                          ></input>
                          Not Verified
                        </div>
                      </div>
                    </div> */}
                    {/* </div> */}
                  </div>

                  {/* HR Round */}
                  {/* <div class="card mb-3">
                    <div class="card-header">HR Round</div>
                    <div class="card-body">
                    
                      <div className="row">
                        <div class="form-group">
                          <label
                            for="exampleInputEmail1"
                            style={{ float: "left", minWidth: "150px" }}
                          >
                            Status :
                          </label>
                          <div
                            onChange={(e) => {
                              sethrRound(e.target.value === "true");
                            }}
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              float: "left",
                              marginLeft: "2rem",
                            }}
                          >
                            <input
                              type="radio"
                              name="hrround"
                              checked={hrRound === true}
                              value={true}
                            ></input>
                            Verified
                            <input
                              type="radio"
                              name="hrround"
                              checked={hrRound === false}
                              value={false}
                            ></input>
                            Not Verified
                          </div>
                        </div>
                      </div>

                      {singleVendorDetail?.serviceCategoryData.map(
                        (item, key) => {
                          return (
                            <>
                              <div className="row" key={key}>
                                <div class="form-group">
                                  <label
                                    for="exampleInputEmail1"
                                    style={{
                                      float: "left",
                                      minWidth: "200px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.name} :
                                  </label>
                                  <div
                                    onChange={(e) => {
                                      sethrRound(e.target.value === "true");
                                    }}
                                    style={{
                                      display: "flex",
                                      gap: "5px",
                                      alignItems: "center",
                                      float: "left",
                                      marginLeft: "2rem",
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name="hrround"
                                      checked={hrRound === true}
                                      value={true}
                                    ></input>
                                    Verified
                                    <input
                                      type="radio"
                                      name="hrround"
                                      checked={hrRound === false}
                                      value={false}
                                    ></input>
                                    Not Verified
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div> */}

                  {/* Technical Round */}
                  {/* <div class="card mb-3">
                    <div class="card-header">Technical Round</div>
                    <div class="card-body">
                      <div class="form-group">
                        <label
                          for="exampleInputEmail1"
                          style={{ float: "left", minWidth: "150px" }}
                        >
                          Status<span style={{ color: "red" }}>*</span> :
                        </label>
                        <div
                          onChange={(e) => {
                            settechnicalRound(e.target.value === "true");
                          }}
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            float: "left",
                            marginLeft: "2rem",
                          }}
                        >
                          <input
                            type="radio"
                            name="techround"
                            checked={technicalRound == true}
                            value={true}
                          ></input>
                          Verified
                          <input
                            type="radio"
                            name="techround"
                            checked={technicalRound == false}
                            value={false}
                          ></input>
                          Not Verified
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div
                    class="form-group"
                  // style={{ border: "2px solid red" }}
                  >
                    {/* <span style={{ display: "flex", justifyContent: "left", fontWeight: "bold" }}>
                Document Verification Status
              </span> */}

                    {document_Verification?.map((ele, id) => {
                      console.log("document_Verification", ele);
                      let imageLoop = [];
                      console.log("docAadharCard", docAadharCard);
                      if (ele.type === "photo") {
                        imageLoop = docPhoto;
                      } else if (ele.type === "Aadhar Card") {
                        imageLoop = docAadharCard;
                      } else if (ele.type === "PAN card") {
                        imageLoop = docPANCard;
                      } else if (ele.type === "certficate") {
                        imageLoop = docCertficate;
                      }
                      // else if (ele.type === "Bank information") {
                      //   imageLoop = []
                      // }

                      console.log(
                        "imageLoop",
                        ele.type,
                        Object.keys(imageLoop).length
                      );

                      return (
                        <>
                          {/* /////////////// */}
                          <div class="card mb-2">
                            <div class="card-body">
                              <h5 class="card-title">{ele.type}</h5>
                              <p class="card-text">
                                <div className="row">
                                  {imageLoop[0] == "" &&
                                    ele.type !== "Bank information" && (
                                      <>
                                        <div className="col">
                                          --- No Image Uploaded ---
                                        </div>
                                      </>
                                    )}
                                  {imageLoop?.map((item, key) => {
                                    return (
                                      <>
                                        <div className="col" key={key}>
                                          <a
                                            href={item}
                                            target="_blank"
                                          >
                                            <img
                                              src={item}
                                              alt=""
                                              style={{ width: "100px" }}
                                            />
                                          </a>

                                          {ele.type === "Aadhar Card" &&
                                            docAadharCardBackPhoto && (
                                              <>

                                                <a
                                                  href={
                                                    // IMAMGE_URL +
                                                    docAadharCardBackPhoto
                                                  }
                                                  target="_blank"
                                                  className="ml-4"
                                                >
                                                  <img
                                                    src={
                                                      // IMAMGE_URL +
                                                      docAadharCardBackPhoto
                                                    }
                                                    alt=""
                                                    style={{ width: "100px" }}
                                                  />
                                                </a>

                                              </>
                                            )}

                                          {ele.type === "PAN card" &&
                                            docPANCardBackPhoto && (
                                              <>

                                                <a
                                                  href={
                                                    docPANCardBackPhoto
                                                  }
                                                  target="_blank"
                                                  className="ml-4"
                                                >
                                                  <img
                                                    src={
                                                      docPANCardBackPhoto
                                                    }
                                                    alt=""
                                                    style={{ width: "100px" }}
                                                  />
                                                </a>

                                              </>
                                            )}


                                        </div>
                                      </>
                                    );
                                  })}

                                  {ele.type === "Bank information" && (
                                    <>
                                      <div className="col-6">
                                        <strong>Account Number:</strong>&nbsp;
                                        {docBankInformation.accountNo}
                                        <br />
                                        <strong>Bank Branch:</strong>&nbsp;
                                        {docBankInformation.branch}
                                        <br />
                                        <strong>IFSC Code:</strong>&nbsp;
                                        {docBankInformation.ifsc}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </p>
                              <input
                                onChange={(e) => {
                                  updateDocvarify_data(
                                    id,
                                    "status",
                                    e.target.value
                                  );
                                }}
                                type="radio"
                                name={`doc_varify_${id}`}
                                checked={ele.status}
                                value={true}
                                className="mr-1"
                              />
                              Verified
                              <input
                                onChange={(e) => {
                                  updateDocvarify_data(
                                    id,
                                    "status",
                                    e.target.value
                                  );
                                }}
                                type="radio"
                                name={`doc_varify_${id}`}
                                checked={!ele.status}
                                value={false}
                                className="ml-2 mr-1"
                              />
                              Not Verified
                            </div>
                          </div>

                          {/* ////////////////// */}
                          {/* <div
                      key={ele._id}
                      className="form-group card"
                      style={{ margin: "2rem" }}
                    >
                      <label htmlFor="exampleInputEmail1">
                        {ele.type}
                        <span style={{ color: "red" }}>*</span> :
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            float: "right",
                            marginLeft: "2rem",
                          }}
                        > */}
                          {/* //////////////////docPhoto */}
                          {/* <div className="row">

                            {

                              docPhoto?.map((item, key) => {
                                console.log("docPhoto", docPhoto);
                                return (
                                  <>
                                    <div className="col" key={key}><a
                                      href={IMAMGE_URL + item}
                                      target="_blank">
                                      <img
                                        src={IMAMGE_URL + item}
                                        alt=""
                                        style={{ width: "100px" }}
                                      /></a></div>
                                  </>
                                )
                              })
                            }



                          </div> */}

                          {/* <input
                            onChange={(e) => {
                              updateDocvarify_data(id, "status", e.target.value);
                            }}
                            type="radio"
                            name={`doc_varify_${id}`}
                            checked={ele.status}
                            value={true}
                          />
                          Verified
                          <input
                            onChange={(e) => {
                              updateDocvarify_data(id, "status", e.target.value);
                            }}
                            type="radio"
                            name={`doc_varify_${id}`}
                            checked={!ele.status}
                            value={false}
                          />
                          Not Verified */}
                          {/* </div>
                      </label>
                    </div> */}
                        </>
                      );
                    })}
                  </div>
                </div>

                {/* Toolkit */}
                <div
                  id="toolkit"
                  style={{ display: displayToolkit ? "block" : "none" }}
                >
                  {/* HR */}
                  <div class="card mb-3">
                    <div class="card-header">Toolkit Delivery</div>
                    <div class="card-body">
                      {vendorHR?.map((item, key) => {
                        return (
                          <>
                            <div
                              class="card"
                              key={key}
                              style={{ borderBottom: "solid 1px #6c757d" }}
                            >
                              <div class="card-body">
                                <div className="row">
                                  <div class="form-group col-8">
                                    <span class="badge badge-primary">
                                      {item?.categoryDetail?.serviceName}
                                    </span>
                                    <h3>{item?.categoryDetail?.name}</h3>
                                    <div class="form-group mt-2">
                                      {!item?.toolkitProvided ? (
                                        <>
                                          <button
                                            class="btn btn-primary mr-2"
                                            onClick={(e) =>
                                              updateInterviewStatus(
                                                e,
                                                item,
                                                "toolkit"
                                              )
                                            }
                                          >
                                            Provide Toolkit
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <span class="badge badge-success mr-2">
                                            Toolkit Provided
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {/* <div className="col-4">
                                    {item.trainingStatus === "complete" && (
                                      <>
                                        <div
                                          class="alert alert-success text-center"
                                          role="alert"
                                          style={{ borderRadius: "10rem" }}
                                        >
                                          Training&nbsp;
                                          <strong>Completed</strong>
                                          &nbsp;Successfully
                                        </div>
                                      </>
                                    )}
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}

            {displayVerification ? (
              <>
                <button class="btn btn-primary" onClick={updateData}>
                  Update
                </button>
              </>
            ) : (
              <></>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Registered Vendor(s)
            </div>

            <div class="form-inline my-2">
              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Vendor Name"
                aria-label="Search"
                value={searchVendorName}
                onChange={(e) => {
                  setSearchVendorName(e.target.value);
                }}
              />
              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Vendor Mobile"
                aria-label="Search"
                value={searchVendorMobile}
                onChange={(e) => {
                  setSearchVendorMobile(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="State"
                aria-label="Search"
                value={searchState}
                onChange={(e) => {
                  setSearchState(e.target.value);
                }}
              />
              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="City"
                aria-label="Search"
                value={searchCity}
                onChange={(e) => {
                  setSearchCity(e.target.value);
                }}
              />
              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Area"
                aria-label="Search"
                value={searchArea}
                onChange={(e) => {
                  setSearchArea(e.target.value);
                }}
              />
              <div class="form-group">
                <input
                  class="form-control mr-sm-2"
                  type="date"
                  placeholder="From Date"
                  aria-label="Search"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>
              <input
                class="form-control mr-sm-2"
                type="date"
                placeholder="From Date"
                aria-label="Search"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
              />

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>

            {loader ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <CSVLink
                  className="btn btn-sm btn-info"
                  filename={`vendors${moment().format("YYYYMMDD")}.csv`}
                  data={csvData}
                >
                  Export to CSV
                </CSVLink>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  noDataComponent="No registered vendor(s) found!"
                  // fixedHeader={true}
                  highlightOnHover
                  pointerOnHover
                  responsive
                />
              </>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal(singleVendorDetail?._id)}
        onRequestClose={closeModal}
      >
        <form>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalLabel">
                  Vendor: {singleVendorDetail?.name}{" "}
                  <span style={{ fontSize: "80%" }}>({replyTo})</span>
                </h5> */}
                <button type="button" class="btn btn-primary">
                  Vendor{" "}
                  <span class="badge badge-light" style={{ color: "#000" }}>
                    {singleVendorDetail?.name}
                  </span>
                </button>
                <button
                  type="button"
                  class="close"
                  onClick={closeModal}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  {/* <div class="form-group">
                                        <label for="message-text" class="col-form-label">Message:</label>
                                        <textarea
                                            class="form-control"
                                            style={{ height: "100px" }}
                                            id="message-text"
                                            onChange={(e) => setReplyToMessage(e.target.value)}
                                        ></textarea>
                                    </div> */}
                  <div class="form-group">
                    {/* <label for="exampleInputEmail1">
                      Section Content <span style={{ color: "red" }}>*</span> :
                    </label> */}

                    {/* <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="7"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea> */}

                    <div
                      style={{
                        marginBottom: "21px",
                        display:
                          singleVendorDetail?.interViewStatusData
                            ?.acceptanceStatus === "accepted"
                            ? "none"
                            : "block",
                      }}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          placeholder: "Enter content here",
                          toolbar: [
                            "undo",
                            "redo",
                            "bold",
                            "blockQuote",
                            "heading",
                            "link",
                            "numberedList",
                            "bulletedList",
                            "mediaEmbed",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                          ],
                        }}
                        data={offerLetterContentDB ? offerLetterContentDB : ""}
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          let data = editor.getData();
                          setOfferLetterContent(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display:
                          singleVendorDetail?.interViewStatusData
                            ?.acceptanceStatus !== "accepted" && "none",
                      }}
                      contentEditable="true"
                      dangerouslySetInnerHTML={{ __html: offerLetterContentDB }}
                    ></div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button> */}
                {singleVendorDetail?.interViewStatusData?.acceptanceStatus !==
                  "accepted" && (
                    <>
                      <button
                        type="button"
                        class="btn btn-sm btn-success"
                        onClick={(e) =>
                          AddOfferLetter(e, singleVendorDetail?._id)
                        }
                      >
                        {offerLetterContentDB ? "Resend" : "Send"}

                      </button>
                    </>
                  )}
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default RegisteredVendors;
