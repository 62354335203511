import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import HomeService from "../../../Service/HomeService";
import Loader from "../../../Component/Loader";

const ManageServicePackagePrice = () => {
    const [price, setPrice] = useState("");
    // const [flatPrice, setflatPrice] = useState("");
    // const [ModelPrice, setModelPrice] = useState("");

    const [servicePackagePrice, setservicePackagePrice] = useState("");
    const [service_id, setservice_id] = useState("");//64d602b2e6419455b67e1ed8
    const [catagory_id, setcategory_id] = useState("");
    const [packageId, setPackageId] = useState("");

    const [inputType, setinputType] = useState("")
    console.log(service_id);
    const [allService, setallSarvice] = useState([]);
    const [servicedays, setservicedays] = useState([]);
    const [totaldate, settotaldate] = useState([]);
    const [allPackagePeriod, setAllPackagePeriod] = useState([]);

    const [loader, setLoader] = useState(false)
    const [filteredData, setFilteredData] = useState([]);
    const [searchCategory, setSearchCategory] = useState("")
    const [searchPackage, setSearchPackage] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    console.log(service_id,"allService")

    useEffect(() => {
        FetchAllServicedays();
        fetchAllSarvice();
        FetchAllServicePackages()
        setservice_id("64d602b2e6419455b67e1ed8")
        fetchAllCategory("64d602b2e6419455b67e1ed8");
        onCustomFilter("64d602b2e6419455b67e1ed8", "", "")
    }, []);

    const resetState = () => {
        // setfloorPrice("")
        // setflatPrice("")
        // setModelPrice("")
        setservicePackagePrice("")
        setservice_id("");
        setcategory_id("");
        setPackageId("")
        setPrice("")
    };

    const onEdit = (item) => {
        window.scrollTo(0, 0);
        // console.log("ITEMMMMMMM", item);
        // setfloorPrice(item?.floorPrice)
        // setflatPrice(item?.flatPrice)
        // setModelPrice(item?.selectModelPrice)



        // setservice_id("64d602a1e6419455b67e1ed4");
        // fetchAllCategory("64d602a1e6419455b67e1ed4");



        setservicePackagePrice(item?.servicePackagePrice)
        setId(item?._id);
        // setservice_id(item.serviceTypeID);
        setservice_id("64d602b2e6419455b67e1ed8");//AMC
        fetchAllCategory("64d602b2e6419455b67e1ed8");
        setcategory_id(item?.categoryId)
        setPackageId(item?.packageId)
        setPrice(item?.price)
        // setcategory_id(item?.serviceCategoryID)
        setHide(false);
    };


    //  AddNoofbuildings,
    //  MannageNoofbuildings,
    //  UpdateNoofbuildings,
    //  DeleteNoofbuildings

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

                HomeService.DeleteAMCservice(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            FetchAllServicedays();
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            // fetchAllState();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const FetchAllServicePackages = () => {
        HomeService.ViewAllServicePackageNew()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAllPackagePeriod(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllSarvice = () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setallSarvice(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    settotaldate(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };




    const FetchAllServicedays = () => {
        HomeService.ViewAllServicePackagePriceNew()
            .then((res) => {
                console.log("ResArllBlog", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        // console.log(item);
                        return {
                            sl: index + 1,
                            Category: item?.category,

                            Package: item?.package,
                            Price: item?.price,
                            // floorPrice: item?.floorPrice,
                            // flatPrice: item?.flatPrice,
                            // selectModelPrice: item?.selectModelPrice,
                            // servicePackagePrice: item?.servicePackagePrice,


                            // createdOn: filterdate(item?.servicedate_data[0].servicedate),

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        // onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setFilteredData(arr)
                    setservicedays(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Package
                </div>
            ),
            selector: (row) => HomeService.packName(row.Package),
        },

        ,
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Price,
        },



        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const addServiceDays = () => {
        let data = {
            // serviceTypeID: service_id,
            categoryId: catagory_id,
            packageId: packageId,


            price: price,
            // flatPrice: flatPrice,
            // selectModelPrice: ModelPrice,
            // servicePackagePrice: servicePackagePrice

        };
        // serviceTime: name

        if (catagory_id && packageId && price) {
            // alert(name + country_id)
            HomeService.AddServicePackagePriceNew(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        // FetchAllServicedays();
                        onCustomFilter(service_id, catagory_id, packageId)
                        toast.success(res.message);

                        resetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {

            if (!service_id) {
                toast.error("Service Type Field Is Required");
            }
            if (!catagory_id) {
                toast.error("Category Field Is Required");
            }
            if (!price) {
                toast.error("Price Field Is Required");
            }
            // if (!floorPrice) {
            //     toast.error("FloorPrice Field Is Required");
            // }
            // if (!ModelPrice) {
            //     toast.error("ModelPrice Name Field Is Required");
            // }
            // if (!servicePackagePrice) {
            //     toast.error("servicePackagePrice Field Is Required");
            // }
        }
    };

    const AddInputTypedata = () => {
        let data = {
            inputType: inputType
        };
        // serviceTime: name

        if (inputType) {
            // alert(name + country_id)
            HomeService.Addservicedaysinputtype(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        FetchAllServicedays();
                        toast.success(res.message);

                        resetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!inputType) {
                toast.error("Input Type Field Is Required");
            }

        }
    };


    //   UpdateAMCservice,
    //   DeleteAMCservice


    const UpdateServicedays = () => {
        console.log("ID", id);
        let data = {
            // serviceTypeID: service_id,
            serviceCategoryID: catagory_id,
            packageId: packageId,

            price: price,
            // flatPrice: flatPrice,
            // selectModelPrice: ModelPrice,
            // servicePackagePrice: servicePackagePrice

        };

        HomeService.UpdateServicePackagePriceNew(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    onCustomFilter(service_id, catagory_id, packageId)
                    console.log("RESPONSEDELETE", res);
                    resetState()
                    FetchAllServicedays();
                } else {
                    toast.error("Service Days Name Already Exist");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onCustomFilter = (s_id, c_id, sc_id) => {
        setLoader(true)
        HomeService.ViewAllServicePackagePriceNew()
            .then((res) => {
                console.log("Res431", res.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = []

                    if (s_id && c_id && sc_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.packageId
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                // console.log(item);
                                return {
                                    sl: index + 1,
                                    Category: item?.category,
                                    Package: item?.package,
                                    Price: item?.price,
                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                // onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id && c_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                // console.log(item);
                                return {
                                    sl: index + 1,
                                    Category: item?.category,
                                    Package: item?.package,
                                    Price: item?.price,
                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                // onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                // console.log(item);
                                return {
                                    sl: index + 1,
                                    Category: item?.category,

                                    Package: item?.package,
                                    Price: item?.price,
                                    // floorPrice: item?.floorPrice,
                                    // flatPrice: item?.flatPrice,
                                    // selectModelPrice: item?.selectModelPrice,
                                    // servicePackagePrice: item?.servicePackagePrice,


                                    // createdOn: filterdate(item?.servicedate_data[0].servicedate),

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                // onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else {
                        arr = res?.data?.sort().reverse().map((item, index) => {
                            console.log(item);
                            return {
                                sl: index + 1,
                                Category: item?.category,

                                Package: item?.package,
                                Price: item?.price,
                                // floorPrice: item?.floorPrice,
                                // flatPrice: item?.flatPrice,
                                // selectModelPrice: item?.selectModelPrice,
                                // servicePackagePrice: item?.servicePackagePrice,


                                // createdOn: filterdate(item?.servicedate_data[0].servicedate),

                                action: (
                                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                                        <svg
                                            onClick={() => onEdit(item)}
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                                cursor: "pointer",
                                                marginRight: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                        </svg>
                                        <svg
                                            // onClick={() => onDelete(item?._id)}
                                            style={{
                                                color: "red",
                                                height: "20px",
                                                cursor: "pointer",
                                                width: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-trash3"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>
                                    </div>
                                ),
                            };
                        })
                    }
                    console.log("er708", arr);
                    setFilteredData(arr)
                    // setservicedays(arr);
                }
                // console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onFilter = () => {
        let searchData = servicedays;
        console.log("GSGS720", servicedays);

        //Category
        if (searchCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }


    }

    const viewAllData = () => {
        setSearchCategory("")
        setFilteredData(servicedays)
    }


    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Service Package Price
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Update Service Package Price
                            </div>
                        )}

                        <div class="form-group"
                        // style={{ display: id ? "none" : "block" }}
                        >
                            <label for="exampleInputEmail1">
                                Service Type
                                :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_id(e.target.value);
                                    fetchAllCategory(e.target.value);
                                    onCustomFilter(e.target.value, "", "")
                                }}
                            >
                                <option value="" disabled>Select</option>
                                {allService?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == service_id ? true : false}
                                            disabled={ele._id !== service_id}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group"
                        // style={{ display: id ? "none" : "block" }}
                        >
                            <label for="exampleInputEmail1">
                                Category Name <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select

                                onChange={(e) => {
                                    setcategory_id(e.target.value);
                                    onCustomFilter(service_id, e.target.value, "")
                                }}
                            >
                                <option value="">Select</option>
                                {totaldate?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == catagory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group"
                        // style={{ display: id ? "none" : "block" }}
                        >
                            <label for="exampleInputEmail1">
                                Package <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setPackageId(e.target.value)
                                onCustomFilter(service_id, catagory_id, e.target.value)
                            }}>
                                <option value="">Select</option>
                                {allPackagePeriod?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == packageId ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele?.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>


                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Price <span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>



                        {hide ? (
                            <button class="btn btn-primary" onClick={addServiceDays}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={UpdateServicedays}>
                                Update
                            </button>
                        )}



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Service Package Price
                        </div>
                        <div class="form-inline my-2">

                            <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                                value={searchCategory}
                                onChange={(e) => {
                                    setSearchCategory(e.target.value)
                                }}
                            />


                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div>
                        {
                            loader ? (<>
                                <Loader />
                            </>) : (<>
                                <DataTable columns={columns} data={filteredData} pagination />
                            </>)
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageServicePackagePrice;

