import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
// import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import ImageLoader from "../Loader/ImageLoader";

const ManageHowToBook = () => {
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");
  const [serviceId, setServiceId] = useState("");

  //   const [service_type_id, setservice_type_id] = useState("");

  //   const [totalService_type, settotalService_type] = useState([]);
  //   const [totalCategory, settotalCategory] = useState([]);
  //   const [totalSubcategory, settotalSubcategory] = useState([]);

  //   const [singledata, setsingleData] = useState({});
  //   const [totalServices, setTotalServices] = useState([]);

  // console.log("2data", subcategory_id);

  // const [image, setimage] = useState("")

  //   const [hide, setHide] = useState(true);
  //   const [id, setId] = useState("");

  useEffect(() => {
    fetchAllBanners();
  }, []);

  //    service_type_id && getAllCategoryinService_type(service_type_id)
  //   },[service_type_id,])

  //   const resetState = () => {
  //     setFlashoffer();
  //     setServiceId();
  //     setsubcategory_id();
  //     setcategory_id();
  //     setServiceId();
  //     setFlashoffer("");
  //     setBannerImage([initialBannerImage]);
  //     document.querySelector("#thumbnail").value = "";
  //     setimage("");
  //   };

  //   const onEdit = (item) => {
  //     window.scroll(0, 0);
  //     console.log("itemee", item);

  //     getAllService_type();
  //     setservice_type_id(item?.serviceTypeData._id);
  //     getAllCategoryinService_type(item?.serviceTypeData._id);

  //     setcategory_id(item?.categoryData._id);
  //     getAllSubCategory(item?.categoryData._id);

  //     setsubcategory_id(item?.subcategoryData._id);
  //     getAllServicesByCategory(item?.subcategoryData._id);

  //     setServiceId(item?.serviceData._id);

  //     setBannerImage(item?.warrentyDetails);

  //     setId(item?._id);

  //     setHide(false);
  //   };

  //   const onDelete = (id) => {
  //     console.log("ID89", id);
  //     Swal.fire({
  //       title: "Are you sure?",
  //       // text: "You won't  to delete this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         NewHomService.DeleteWarrenty(id)
  //           .then((res) => {
  //             // console.log("DELETEBLOGCAT", res);
  //             if (res && res.status) {
  //               toast.success("Deleted Successfully");
  //               console.log("RESPONSEDELETE", res);
  //               fetchAllService();
  //             } else {
  //               toast.error("Failed to Delete the Item ");
  //             }
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     });
  //   };

  // const organiseData = () => {
  //     let temarr = [...questio_ans]
  //     const tempobj = {
  //         question: question,
  //         answer: ans
  //     }
  //     temarr.push(tempobj);
  //     setquestio_ans(temarr)
  //     setquestion('')
  //     setans('')
  // }

  // const addQuestion = () => {
  //     setfquestions([...fquestions, { question: "", answer: "" }]);
  // };

  // const updateQuestion = (index, field, value) => {
  //     const updatedQuestions = [...fquestions];
  //     updatedQuestions[index][field] = value;
  //     setfquestions(updatedQuestions);
  // };
  // const deleteQuestion = (index) => {
  //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
  //     setfquestions(updatedQuestions);
  // };

  //   const getAllService_type = async () => {
  //     HomeService.ManageService()
  //       .then((res) => {
  //         console.log("ResAllBlog", res.data);

  //         if (res && res?.status) {
  //           // setLoader(false)
  //           console.log(res?.data);
  //           settotalService_type(res?.data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   const getAllCategoryinService_type = async (id) => {
  //     HomeService.CategoriesviaService_type(id)
  //       .then((res) => {
  //         console.log("ResAllBlog", res.data);

  //         if (res && res?.status) {
  //           // setLoader(false)
  //           console.log(res?.data);
  //           settotalCategory(res?.data);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  // const getAllCategory = async () => {
  //   HomeService.ManageCategory()
  //     .then((res) => {
  //       console.log("ResAllBlog", res.data);

  //       if (res && res?.status) {
  //         // setLoader(false)
  //         console.log(res?.data);
  //         settotalCategory(res?.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  //   const getAllSubCategory = async (id) => {
  //     HomeService.SubCategoriesviacategory(id)
  //       .then((res) => {
  //         console.log("ResAllBlog", res.data);

  //         if (res && res?.status) {
  //           // setLoader(false)
  //           console.log("sub_cat", res?.data);
  //           settotalSubcategory(res?.data);
  //         } else {
  //           settotalSubcategory([]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  //   const getAllServicesByCategory = async (id) => {
  //     console.log("services:", id);
  //     NewHomService.FetchServicesByCategory(id)
  //       .then((res) => {
  //         console.log("FetchServicesByCategory", res.data);

  //         if (res && res?.status) {
  //           // setLoader(false)
  //           console.log("services:", res);
  //           setTotalServices(res?.data);
  //         } else {
  //           setTotalServices();
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  const fetchAllBanners = () => {
    NewHomService.FetchHowToBookBanners()
      .then((res) => {
        console.log("FETCH_BANNER", res?.data?.Banner_image);

        if (res && res?.status) {
          // setLoader(false)
          const arrayOfImages = res?.data?.image.map((url, index) => {
            return { id: index, image: url };
          });
          setBannerImage(arrayOfImages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //     // setImgLoader(true);
  //     let file = e.target.files[0];
  //     let data = new FormData();
  //     data.append("image", file);
  //     console.log("imgdata", data);
  //     let res = await HttpClientXml.fileUplode("upload", "POST", data);
  //     console.log("resImage", res);
  //     if (res && res.status) {
  //       toast.success("Image uploaded successfully");
  //       console.log(IMAMGE_URL + res?.data);
  //       setimage(IMAMGE_URL + res?.data);
  //       setimgdata(res?.data);
  //       // setImgLoader(false);
  //     } else {
  //       toast.error("Failed to upload Image");
  //       // setImgLoader(false);
  //     }
  //   };

  const manageData = () => {
    // let data = {
    //   Banner_image: bannerImage,
    // };

    const imageUrls = bannerImage.map(item => item.image).filter(image => image);
    
    let data={
      image:imageUrls
    }
    console.log("ADD_DATA", data);

    if (Object.keys(bannerImage).length > 0) {
      
      // return
      NewHomService.ManageHowToBookBanners(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllBanners();
          } else {
            toast.error(res.message);
            console.log("message", res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please upload an image");
    }
  };

  //     {
  //       name: (
  //         <div
  //           style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //         >
  //           SL
  //         </div>
  //       ),
  //       selector: (row) => row.sl,
  //     },
  //     {
  //       name: (
  //         <div
  //           style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //         >
  //           Service Type
  //         </div>
  //       ),
  //       selector: (row) => row.ServiceType,
  //     },
  //     {
  //       name: (
  //         <div
  //           style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //         >
  //           Category
  //         </div>
  //       ),
  //       selector: (row) => row.Category,
  //     },
  //     {
  //       name: (
  //         <div
  //           style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //         >
  //           Sub Category
  //         </div>
  //       ),
  //       selector: (row) => row.Subcategory,
  //     },

  //     {
  //       name: (
  //         <div
  //           style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
  //         >
  //           Service
  //         </div>
  //       ),
  //       selector: (row) => row.Service,
  //     },

  //     {
  //       name: (
  //         <div
  //           style={{
  //             fontSize: "14px",
  //             color: "#495057",
  //             marginLeft: "15px",
  //             fontWeight: "bolder",
  //           }}
  //         >
  //           Action
  //         </div>
  //       ),
  //       selector: (row) => row.action,
  //     },
  //   ];

  //   const updateData = () => {
  //     let data = {
  //       serviceTypeID: service_type_id,
  //       serviceCategoryID: category_id,
  //       subCategoryID: subcategory_id,
  //       serviceID: serviceId,
  //       warrentyDetails: bannerImage,
  //     };

  //     if (service_type_id && category_id && subcategory_id && serviceId) {
  //       console.log("DATA441", data);
  //       NewHomService.UpdateWarrenty(id, data)
  //         .then((res) => {
  //           // console.log("updated res", res);
  //           if (res && res.status) {
  //             toast.success("Updated Successfully");
  //             setHide(true);
  //             // console.log("RESPONSEDELETE", res);
  //             fetchAllService();
  //             resetState();
  //           } else {
  //             toast.error("Error Occured!");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       if (!flashoffer) {
  //         toast.error("Offer is mandatory");
  //       }
  //       if (!serviceId) {
  //         toast.error("Choose Service");
  //       }
  //       if (!subcategory_id) {
  //         toast.error("Choose Sub Category");
  //       }
  //       if (!category_id) {
  //         toast.error("Choose Category");
  //       }
  //     }
  //   };

  const [onEditing, setOnEditing] = useState(false);
  const initialBannerImage = {
    // title: "",
    // description: "",
    image: "",
  };

  const [bannerImage, setBannerImage] = useState([initialBannerImage]);
  const handleChange = (i, e) => {
    let newFormValues = [...bannerImage];
    newFormValues[i][e.target.name] = e.target.value;
    setBannerImage(newFormValues);
  };

  const [subscriptionBannerImageLoader, setSubscriptionBannerImageLoader] =
    useState(false);
  const handleBannerImage = async (i, e) => {
    setSubscriptionBannerImageLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log('L86:', data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);

    if (res && res.status) {
      console.log("UploadImageRes", res);
      // setBannerImage(res?.url);
      let newFormValues = [...bannerImage];
      newFormValues[i].image = res?.doc?.url;
      setBannerImage(newFormValues);
      // console.log("L60: ",bannerImage[0].image);
    } else {
      toast.error(res?.message);
    }
    setSubscriptionBannerImageLoader(false);
  };
  const addBannerImage = () => {
    // alert(bannerImage.length)
    if (bannerImage.length < 5000) {
      setBannerImage([...bannerImage, initialBannerImage]);
    } else {
      Swal("");
      Swal("Error", "Not more than 5000", "error");
    }
  };

  const removeBannerImage = (i) => {
    let newFormValues = [...bannerImage];
    newFormValues.splice(i, 1);
    setBannerImage(newFormValues);
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {/* {hide ? ( */}
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              How To Book
            </div>

            {/* Multiple Image */}

            <div
              className="row"
              data-aos="fade-up"
              style={{ display: onEditing ? "none" : "block" }}
            >
              <div className="col-lg-12">
                {/* <form> */}
                <label for="inputEmail4">Banner(s):</label>

                {bannerImage?.map((element, index) => (
                  <div
                    style={{
                      border: "solid 1px #ced4da",
                      padding: "1em",
                      margin: "0 0 1em 0",
                      borderRadius: "0.25rem",
                    }}
                    className="_form-inline"
                    key={index}
                  >
                    {/* Image Upload */}
                    {subscriptionBannerImageLoader ? (
                      <>
                        <ImageLoader />
                      </>
                    ) : null}
                    {element.image && (
                      <>
                        <div>
                          <img
                            style={{
                              //   height: "10%",
                              width: "50%",
                              marginTop: "12px",
                              borderRadius: "5px",
                            }}
                            src={element.image}
                          />
                        </div>
                      </>
                    )}
                    <label for="exampleInputEmail1">Image {index + 1} : (Dimension: 480px X 480px)</label>
                    <input
                      class="form-control"
                      onChange={(e) => handleBannerImage(index, e)}
                      type="file"
                      id="thumbnail"
                      accept="image/*"
                    />

                    {/* //Image Upload */}

                    {index ? (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger ml-1"
                        onClick={() => removeBannerImage(index)}
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    ) : null}
                  </div>
                ))}

                <div className="button-section my-2">
                  <button
                    className="btn btn-sm btn-success"
                    type="button"
                    onClick={() => addBannerImage()}
                  >
                    <i class="fas fa-plus"></i> Image
                  </button>
                </div>
                {/* </form> */}
              </div>
            </div>

            {/* //Multiple Warrenty */}

            <button class="btn btn-primary" onClick={manageData}>
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageHowToBook;
