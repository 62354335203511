import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import HomeService from '../../Service/HomeService';
import HttpClientXml, { IMAMGE_URL } from '../../Utils/HttpClientXml';
import DataTable from 'react-data-table-component';
import toast from 'react-hot-toast';

export default function UpdateRegisteredVendors() {
    const [filteredData, setFilteredData] = useState([]);
    const [loader, setLoader] = useState(false);

    console.log(filteredData, "name")


    useEffect(() => {
        fetchAllData()
    }, [])


    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            width: "50px",
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Vendor ID
                </div>
            ),
            selector: (row) => {
                return row.VendorID !== undefined ? row.VendorID : "n/a";
            },
            width: "100px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Photo
                </div>
            ),
            selector: (row) => (
                <a href={row.image} target="_blank">
                    <img
                        style={{ width: "100%", borderRadius: "8px" }}
                        src={row.image}
                        alt={""}
                    />
                </a>
            ),
            width: "100px",
            padding: "10px",
            wrap: true,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Name | Mobile
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                        <h4>{row.Name}</h4>
                        <strong>Mobile:</strong> {row.mobileNo}
                    </>
                );
            },
            wrap: true,
            width: "150px",
        },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Offer Letter
        //         </div>
        //     ),
        //     selector: (row) => row.offerLetter,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Document Verification
        //         </div>
        //     ),
        //     selector: (row) => row.action2,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Interview Round
        //         </div>
        //     ),
        //     selector: (row) => row.interviewRound,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Toolkit
        //         </div>
        //     ),
        //     selector: (row) => row.toolkit,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Training
        //         </div>
        //     ),
        //     selector: (row) => row.training,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Toolkit Payment
        //         </div>
        //     ),
        //     selector: (row) => row.toolkitPayment,
        //     width: "150px",
        //     wrap: true,
        // },
        // {
        //     name: (
        //         <div
        //             style={{
        //                 fontSize: "14px",
        //                 color: "#495057",
        //                 marginLeft: "15px",
        //                 fontWeight: "bolder",
        //             }}
        //         >
        //             Subscription
        //         </div>
        //     ),
        //     selector: (row) => row.subscription,
        //     width: "150px",
        //     wrap: true,
        // },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Location
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                        <div>
                            <strong>Country</strong>: {row.countryName},<br />
                            <strong>State: </strong>
                            {row.stateName},<br />
                            <strong>City:</strong> {row.cityName},<br />
                            <strong>Area:</strong> {row.areaName}
                        </div>
                    </>
                );
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service ZIP Code(s)
                </div>
            ),
            selector: (row) => {
                return row.zipcodes ? (
                    <>
                        {row.zipcodes.map((code, key) => {
                            return (
                                <>
                                    <span
                                        style={{
                                            whiteSpace: "nowrap",
                                        }}
                                    > 
                                        {code}
                                    </span>
                                    {key < row.zipcodes.length - 1 && <>,&nbsp;</>}
                                </>
                            );
                        })}
                    </>
                ) : (
                    "n/a"
                );
            },
            width: "150px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Language
                </div>
            ),
            selector: (row) => row.languageName,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service Type
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                        <ul>
                            {row.serviceTypeData.map((item, key) => {
                                return (
                                    <>
                                        <li>{item.serviceName}</li>
                                    </>
                                );
                            })}
                        </ul>
                    </>
                );
            },
            width: "200px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service Type | Category | Subcategory
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                        <ul>
                            {row.serviceTypeData.map((item, key) => {
                                return (
                                    <>
                                        <li>
                                            <span style={{ fontSize: "18px" }}>
                                                {item.serviceName}
                                            </span>
                                            {row.serviceCategoryData && (
                                                <>
                                                    <ul>
                                                        {row.serviceCategoryData
                                                            .filter((cate) => cate.serviceTypeID === item._id)
                                                            .map((categories) => {
                                                                return (
                                                                    <>
                                                                        <li>
                                                                            <span style={{ fontSize: "16px" }}>
                                                                                {categories.name}
                                                                            </span>
                                                                            {row.subCategoryData && (
                                                                                <>
                                                                                    <ul>
                                                                                        {row.subCategoryData
                                                                                            .filter(
                                                                                                (subcate) =>
                                                                                                    subcate.categoryID ===
                                                                                                    categories._id
                                                                                            )
                                                                                            .map((subcategories) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <li>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: "12px",
                                                                                                                }}
                                                                                                            >
                                                                                                                {subcategories.name}
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </>
                                                                                                );
                                                                                            })}
                                                                                    </ul>
                                                                                </>
                                                                            )}
                                                                        </li>
                                                                    </>
                                                                );
                                                            })}
                                                    </ul>
                                                </>
                                            )}
                                        </li>
                                    </>
                                );
                            })}
                        </ul>
                    </>
                );
            },
            width: "300px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Experience
                </div>
            ),
            selector: (row) => row.experence,
            width: "120px",
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Work Timing
                </div>
            ),
            selector: (row) => {
                return row.workingtime;
            },
            wrap: true,
        },
        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //     >
        //       Category
        //     </div>
        //   ),
        //   selector: (row) => {
        //     return (
        //       <>
        //         <SeeMoreLess maxWordShow={100}>
        //           <ul className="list-group">
        //             {row.serviceCategoryData.map((item, key) => {
        //               return (
        //                 <>
        //                   <li className="list-group-item">{item.name}</li>
        //                 </>
        //               );
        //             })}
        //           </ul>
        //         </SeeMoreLess>
        //       </>
        //     );
        //   },
        //   width: "200px",
        //   wrap: true,
        // },
        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //     >
        //       Category
        //     </div>
        //   ),
        //   selector: (row) => {
        //     return (
        //       <>
        //         <SeeMoreLess maxWordShow={10}>
        //           {row.serviceCategoryData.map((item, key) => {
        //             return <>{item.name},&nbsp;</>;
        //           })}
        //         </SeeMoreLess>
        //       </>
        //     );
        //   },
        //   width: "200px",
        //   wrap: true,
        // },
        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //     >
        //       Sub Category
        //     </div>
        //   ),
        //   selector: (row) => {
        //     return (
        //       <>
        //         <ul className="list-group my-2">
        //           {row.subCategoryData.map((item, key) => {
        //             return (
        //               <>
        //                 <li className="list-group-item">{item.name}</li>
        //               </>
        //             );
        //           })}
        //         </ul>
        //       </>
        //     );
        //   },
        //   width: "300px",
        //   wrap: true,
        //   alignItems: "top",
        // },
        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //     >
        //       Sub Category
        //     </div>
        //   ),
        //   selector: (row) => {
        //     return (
        //       <>
        //         {row.subCategoryData.map((item, key) => {
        //           return <>{item.name},&nbsp;</>;
        //         })}
        //       </>
        //     );
        //   },
        //   width: "300px",
        //   wrap: true,
        //   alignItems: "top",
        // },
        // {
        //   name: (
        //     <div
        //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //     >
        //       Brand(s)
        //     </div>
        //   ),
        //   selector: (row) => {
        //     return (
        //       <>
        //         <ul className="list-group">
        //           {row.brandData.map((item, key) => {
        //             return (
        //               <>
        //                 <li className="list-group-item">{item.name}</li>
        //               </>
        //             );
        //           })}
        //         </ul>
        //       </>
        //     );
        //   },
        //   width: "150px",
        //   wrap: true,
        // },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Brand(s)
                </div>
            ),
            selector: (row) => {
                return (
                    <>
                    <ul>
                        {row.brandData.map((item, key) => {
                            return <><li>{item.name}</li></>;
                        })}
                    </ul>
                    </>
                );
            },
            width: "150px",
            wrap: true,
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Registration Fee
        //         </div>
        //     ),
        //     selector: (row) => {
        //         return (
        //             <>
        //                 <strong>Amount:</strong> {row.registrationFees}
        //                 <br />
        //                 <strong>Payment Mode:</strong> {row.paymentMood}
        //             </>
        //         );
        //     },
        //     wrap: true,
        //     width: "150px",
        // },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Toolkit Fee
        //         </div>
        //     ),
        //     selector: (row) => {
        //         return (
        //             <>
        //                 <strong>Amount</strong>: {row.registrationFees}
        //             </>
        //         );
        //     },
        //     width: "150px",
        //     wrap: true,
        // },

        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Job List
        //         </div>
        //     ),
        //     selector: (row) => {
        //         return (
        //             <>
        //                 <Link to={`/vendor-tasks/${row?.ID}`}>View Job(s)</Link>
        //             </>
        //         );
        //     },
        //     width: "120px",
        //     wrap: true,
        // },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Approve
                </div>
            ),
            selector: (row) => row.action,
            width: "150px",
            wrap: true,
        },

    ];

    const fetchAllData = async () => {
        const res = await HttpClientXml.requestData(
            "view/updated/vendor",
            "GET"
        );
        console.log(res, "resdata")
        let apiData = [];
        if (res && res?.status) {
            console.log(res?.data, "sdefee");
            apiData = res?.data?.map((item, i) => ({
                sl: i + 1,
                Name: item?.name,
                VendorID: item?.identityId,
                mobileNo: item?.mobileNo,
                image: item?.image,
                countryName: item?.countryData?.[0]?.name,
                stateName: item?.stateData?.name,
                cityName: item?.cityData?.name,
                areaName: item?.areaData?.name,
                languageName: item?.languagesData?.name,
                experence: item?.experenceData?.name,
                workingtime: item?.workingTimeData.name ? item?.workingTimeData?.name : "n/a",
                workingSchedule: item?.workingSchedule,

                serviceTypeData: item?.serviceTypeData,
                serviceCategoryData: item?.serviceCategoryData,
                subCategoryData: item?.subCategoryData,
                brandData: item?.brandData,
                registrationFees: item?.registrationFees,
                paymentMood: item?.paymentMood,

                ID: item?._id,
                Status: item?.status,
                OfferLetterData: item?.interViewStatusData
                    ? item?.interViewStatusData?.acceptanceStatus
                    : "",
                // DOCV: item?.interviewProcessData.document_Verification,

                zipcodes: item?.zipcode,
                previousData: item?.oldVendorData,
                action: <button
                    className="btn btn-sm btn-danger"
                    onClick={(e) => ApproveUpdate(e, item?.vendorId)}
                    type="button"
                >
                    Approve

                </button>





            }));
        }
        setFilteredData(apiData);

    };

    const ApproveUpdate = async (e, id) => {
        e.preventDefault()
        // console.log(id, "dwdawdagg")
        // toast.success(id)

        const data = {
            "isAdminApproved": true,
            "vendorId": id
        }

        const res = await HttpClientXml.requestData("approved/vendorprofile", data, "PUT")

        if (res && res?.status) {
            toast.success("Update Approved");
            fetchAllData()
        }
        else {
            toast.error(res?.message || "failed");
        }

    }
    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                    <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Updated Registered Vendor(s)
              </div>
                        <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            noDataComponent="No registered vendor(s) found!"
                            // fixedHeader={true}
                            highlightOnHover
                            pointerOnHover
                            responsive
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
