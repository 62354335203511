import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml, { IMAMGE_URL } from "../../Utils/HttpClientXml";

import Swal from "sweetalert2";
import Loader from "../../Component/Loader";
import { CSVLink } from "react-csv";
import moment from "moment";

const SubCategory = () => {
  const [name, setName] = useState("");
  const [category_id, setcategory_id] = useState("");
  // console.log(category_id);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("")

  const [loader, setLoader] = useState(false)

  const [totalCategory, setTotalCategory] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [searchServiceType, setSearchServiceType] = useState("")
  const [searchCategory, setSearchCategory] = useState("")
  const [searchSubCategory, setSearchSubCategory] = useState("")
  console.log(totalCategory,"totalCategory")

  useEffect(() => {
    fetchAllState();
    // getAllCategory();

    // fetchAllData();
    getAllService_type();
    setServiceTypeID("64d60270203bf4c1d37b6a1e");
    getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
    onFilterByServiceAndCateforyType("64d60270203bf4c1d37b6a1e")
    fetchCSVData()
  }, []);

  useEffect(() => {
    fetchCSVData()
  }, [allData, filteredData]);

  const onEdit = (item) => {
    console.log("item", item);
    window.scroll(0, 0)
    setName(item?.name);
    setId(item?._id);
    setServiceTypeID(item?.categoryData?.serviceTypeID)
    getAllCategoryinService_type(item?.categoryData?.serviceTypeID)
    setcategory_id(item.categoryID);
    setimgdata(item.icon);
    setimage(item.icon);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteSubCategory(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllState();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      console.log(res?.data);
      // setimgdata(res?.data);
      // setimage(res?.data);

      setimgdata(res?.doc?.url);
      setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const getAllCategory = async () => {
    HomeService.ManageCategory()
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)
          setTotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllState = () => {
    setLoader(true)
    HomeService.ManageSubCategory()
      .then((res) => {
        console.log("AWAE124", res.data);
        if (res && res?.status) {
          setLoader(false)

          let arr = res?.data?.sort().reverse().map((item, index) => {
            return {
              sl: index + 1,
              ID: item?._id,
              Name: item?.name,
              icon: item?.icon,
              ServiceTypeID: item?.serviceTypeID,
              categoryname: item?.categoryData?.name,
              ServiceType: item?.serviceTypeData?.serviceName,
              MostViewed: item?.mostviewed,
              Trending: item?.trending,

              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  {
                    item?.serviceTypeID === "64d60270203bf4c1d37b6a1e" ? (
                      <>
                        <svg
                          onClick={() => onEdit(item)}
                          style={{
                            height: "20px",
                            width: "20px",
                            cursor: "pointer",
                            marginRight: "20px",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>

                        <svg
                          onClick={() => onDelete(item?._id)}
                          style={{
                            color: "red",
                            height: "20px",
                            cursor: "pointer",
                            width: "20px",
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </>
                    ) : (
                      <>
                        N/A
                      </>
                    )
                  }

                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddStateCategory = () => {
    let data = {
      name: name,
      icon: imgdata,
      categoryID: category_id,
      serviceTypeID: serviceTypeID,
    };

    if (name && category_id && imgdata) {
      // alert(name + country_id)
      HomeService.AddSubCategory(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchAllState();
            setName("");
            setimgdata("")
            setimage("")
            // setcategory_id("");
            // onFilterByServiceAndCateforyType(serviceTypeID,category_id)
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("Sub Category Name Field Is Required");
      }
      if (!category_id) {
        toast.error("Category Name Field Is Required");
      }
      if (!imgdata) {
        toast.error("Icon Field Is Required")
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.ServiceType,
      wrap: true,
      center: true,
      width: "175px"
    },


    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.categoryname,
      wrap: true,
      center: true,
      width: "125px"

    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Sub Category
        </div>
      ),
      selector: (row) => row.Name,
      wrap: true,
      center: true,
      width: "125px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Image
        </div>
      ),
      selector: (row) => <img style={{ width: "100%" }} src={row.icon} />,
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Special Type(s)
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {
              row.ServiceTypeID == "64d60270203bf4c1d37b6a1e" ? (
                <>
                  {
                    row.MostViewed ? (
                      <>
                        <button
                          className="btn btn-sm btn-success mr-2"
                          onClick={(e) => ChangeType(row.ID, "mostviewed", false)}
                        >
                          Most Booked
                          <span
                            style={{ fontSize: "70%", display: "block" }}
                          >
                            Click to Normal
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-sm btn-danger mr-2"
                          onClick={(e) => ChangeType(row.ID, "mostviewed", true)}
                        >
                          Normal
                          <span
                            style={{ fontSize: "70%", display: "block" }}
                          >
                            Click to Most Booked
                          </span>
                        </button>

                      </>
                    )
                  }
                  {
                    row.Trending ? (
                      <>
                        <button
                          className="btn btn-sm btn-success mr-2"
                          onClick={(e) => ChangeType(row.ID, "trending", false)}
                        >
                          Trending
                          <span
                            style={{ fontSize: "70%", display: "block" }}
                          >
                            Click to Normal
                          </span>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="btn btn-sm btn-danger mr-2"
                          onClick={(e) => ChangeType(row.ID, "trending", true)}
                        >
                          Normal
                          <span
                            style={{ fontSize: "70%", display: "block" }}
                          >
                            Click to Trending
                          </span>
                        </button>

                      </>
                    )
                  }
                </>

              ) : (
                <>
                  <p>N/A</p>
                </>
              )






            }
          </>
        )
      },
      wrap: true,
      center: true,
      width: "275px"
    },


    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      wrap: true,
      center: true,
      width: "125px"
    },
  ];

  const UpdateStateCategory = () => {
    console.log("ID", id);
    let data = {
      categoryID: category_id,
      name: name,
      icon: imgdata,
    };
    HomeService.UpdateSubCategory(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setName("");
          setimgdata("")
          setimage("")
          setcategory_id("");
          setServiceTypeID("")
          // onFilterByServiceAndCateforyType(serviceTypeID,category_id)
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ChangeType = (id, target, value) => {
    console.log("IDCT", id);
    let data = {};
    if (target === "mostviewed") {
      data.mostviewed = value
    } else if (target === "trending") {
      data.trending = value
    }
    // switch (target) {
    //   case "mostviewed":
    //     data.mostviewed = value
    //   case "trending":
    //     data.trending = value
    //   default:
    // }
    console.log("DATACT", data);
    // return
    HomeService.UpdateSubCategory(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          setName("");
          setimgdata("")
          setimage("")
          setcategory_id("");
          setServiceTypeID("")
          fetchAllState();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const onFilter = () => {

  //   if (searchText !== "") {
  //     console.log("allData", allData);
  //     let searchData = allData.filter((item) => {
  //       console.log("ITEM:", item.ServiceType);
  //       if (
  //         item?.ServiceType !== undefined &&
  //         item?.ServiceType
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchText.toLowerCase())
  //       ) {
  //         return item;
  //       }
  //     });
  //     console.log("searchData", searchData);
  //     setFilteredData(searchData)
  //     // setTableRowsData(searchData);
  //   } else {
  //     setFilteredData(allData)
  //   }
  // }

  ////CSV Download//////////////

  const [csvData, setCsvData] = useState([]);


  const fetchCSVData = () => {
    // NewHomService.FetchAllUsers()
    //   .then((res) => {
    //     if (res && res?.status) {
    // setLoader(false);
    console.log("DDDR531", allData);
    let arr = allData
      ?.map((item, index) => {

        return {
          SLNO: index + 1,
          // ID: item?._id,
          ServiceType: item?.ServiceType ? item?.ServiceType : "N/A",
          Category: item?.categoryname ? item?.categoryname : "N/A",
          SubCategory: item?.Name ? item?.Name : "N/A",
          MostViewed: item?.MostViewed ? "Yes" : "No",
          Trending: item?.Trending ? "Yes" : "No",
        };
      });
    console.log("DATASSS538", arr);
    setCsvData(arr);
    //   }
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });
  };

  ////CSV Download//////////////

  const onFilter = () => {
    let searchData = allData;
    console.log("ALLSDATA", allData);
    //Service Type
    if (searchServiceType !== "") {
      searchData = searchData.filter((item) => {
        if (item?.ServiceType?.toLowerCase().includes(searchServiceType.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //Category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.categoryname?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //Sub Category
    if (searchSubCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Name?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  };

  const viewAllData = () => {
    setSearchServiceType("")
    setSearchCategory("")
    setSearchSubCategory("")
    setFilteredData(allData)
  }

  const [serviceTypeID, setServiceTypeID] = useState("")
  const [totalService_type, settotalService_type] = useState([]);

  console.log(serviceTypeID, "serviceTypeID")

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlogsdsdsd", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          setTotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFilterByServiceAndCateforyType = (s_id, c_id) => {
    setLoader(true)
    HomeService.ManageSubCategory()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          setLoader(false)

          let arr = []



          if (s_id && c_id) {
            arr = res?.data?.filter((item) => {
              if (item?.serviceTypeID?.toLowerCase().includes(s_id)) {
                return item;
              }
            }).filter((item) => {
              if (item?.categoryID?.toLowerCase().includes(c_id)) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  ID: item?._id,
                  Name: item?.name,
                  icon: item?.icon,
                  ServiceTypeID: item?.serviceTypeID,
                  categoryname: item?.categoryData?.name,
                  ServiceType: item?.serviceTypeData?.serviceName,
                  MostViewed: item?.mostviewed,
                  Trending: item?.trending,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      {
                        item?.serviceTypeID === "64d60270203bf4c1d37b6a1e" ? (
                          <>
                            <svg
                              onClick={() => onEdit(item)}
                              style={{
                                height: "20px",
                                width: "20px",
                                cursor: "pointer",
                                marginRight: "20px",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                              />
                            </svg>

                            <svg
                              onClick={() => onDelete(item?._id)}
                              style={{
                                color: "red",
                                height: "20px",
                                cursor: "pointer",
                                width: "20px",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                          </>
                        ) : (
                          <>
                            N/A
                          </>
                        )
                      }

                    </div>
                  ),
                };
              });
          } else if (s_id) {
            arr = res?.data?.filter((item) => {
              if (item?.serviceTypeID?.toLowerCase().includes(s_id)) {
                return item;
              }
            }).sort().reverse().map((item, index) => {
              return {
                sl: index + 1,
                ID: item?._id,
                Name: item?.name,
                icon: item?.icon,
                ServiceTypeID: item?.serviceTypeID,
                categoryname: item?.categoryData?.name,
                ServiceType: item?.serviceTypeData?.serviceName,
                MostViewed: item?.mostviewed,
                Trending: item?.trending,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    {
                      item?.serviceTypeID === "64d60270203bf4c1d37b6a1e" ? (
                        <>
                          <svg
                            onClick={() => onEdit(item)}
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                              marginRight: "20px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>

                          <svg
                            onClick={() => onDelete(item?._id)}
                            style={{
                              color: "red",
                              height: "20px",
                              cursor: "pointer",
                              width: "20px",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </>
                      ) : (
                        <>
                          N/A
                        </>
                      )
                    }

                  </div>
                ),
              };
            });
          }





          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add SubCategory
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit SubCategory
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setServiceTypeID(e.target.value);
                  e.target.value !== "" && getAllCategoryinService_type(e.target.value);
                  onFilterByServiceAndCateforyType(e.target.value)
                }}
              >
                <option value="" >
                  Choose...
                </option>
                {
                  totalService_type?.map((ele, id) => {
                    // totalService_type?.map((ele, id) => {
                    return (
                      <option
                        selected={ele._id == serviceTypeID ? true : false}
                        value={ele._id}
                        key={ele._id}
                        disabled={ele._id !== "64d60270203bf4c1d37b6a1e" && true}
                      >
                        {ele.serviceName}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {
                setcategory_id(e.target.value)
                onFilterByServiceAndCateforyType(serviceTypeID, e.target.value)
              }}>
                <option value="" >
                  Choose...
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Sub Category Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="formFile" class="form-label">
                Icon<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />

              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={AddStateCategory}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateStateCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage subCategory
            </div>

            {/* <div class="form-inline my-2">
              <input class="form-control mr-sm-2" type="search" placeholder="Filter by category name" aria-label="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              />
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                onClick={onFilter}
              >Filter</button>
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                onClick={viewAllData}
              >View All</button>
            </div> */}

            <div class="form-inline my-2">

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Service Type"
                aria-label="Search"
                value={searchServiceType}
                onChange={(e) => {
                  setSearchServiceType(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Category Name"
                aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Sub Category Name"
                aria-label="Search"
                value={searchSubCategory}
                onChange={(e) => {
                  setSearchSubCategory(e.target.value);
                }}
              />

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>

            {
              loader ? (
                <Loader />
              ) : (
                <>
                  <CSVLink
                    className="btn btn-sm btn-info"
                    filename={`subcategory-list${moment().format("YYYYMMDD")}.csv`}
                    data={csvData}
                  >
                    Export to CSV
                  </CSVLink>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 100]}
                  />
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategory;
