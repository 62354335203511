import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import Loader from "../../Component/Loader";
import NewHomService from "../../Service/NewHomService";
import { CSVLink } from "react-csv";
import moment from "moment";

const AddCategory = () => {
  const [name, setName] = useState("");
  const [service_type_id, setservice_type_id] = useState("");
  // console.log(service_type_id);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("")
  const [serviceCategories, setServiceCategories] = useState([])
  const [specialTagArrayDetail, setSpecialTagArrayDetail] = useState([])

  const [loader, setLoader] = useState(false)

  const [totalService_type, settotalService_type] = useState([]);
  const [totalCat_servicetype, settotalCat_servicetype] = useState([]);

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [searchServiceType, setSearchServiceType] = useState("")
  const [searchCategory, setSearchCategory] = useState("")

  // console.log("IMGDFD", imgdata);
  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  console.log(specialTagArrayDetail, "specialTagArrayDetail")

  useEffect(() => {
    // fetchAllData();
    getAllService_type();
    onFilterByServiceType("64d60270203bf4c1d37b6a1e")
    // setservice_type_id("64d60270203bf4c1d37b6a1e");
    // onFilterByServiceType("64d60270203bf4c1d37b6a1e")
    // getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
    getAllServiceTags()
  }, []);

  useEffect(() => {
    fetchCSVData();
  }, [filteredData])

  // let specialcategoryTypes = []
  const handleCheckboxChange = (e) => {
    // console.log("DDDDD", e.target.value);
    // Destructuring
    const { id, value, checked } = e.target;
    if (checked) {
      // specialcategoryTypes.push(value)
      setSpecialTagArrayDetail([...specialTagArrayDetail, value]);
    }
    else {
      // specialcategoryTypes = specialcategoryTypes.filter((item) => item != value)
      let newFormValues = [...specialTagArrayDetail];
      console.log("SERDS", id);
      const key = specialTagArrayDetail.indexOf(value);
      newFormValues.splice(key, 1);
      setSpecialTagArrayDetail(newFormValues);
    }
    // setSpecialTagArrayDetail(specialcategoryTypes)
    // console.log("SERSER", specialcategoryTypes);
  };

  const onEdit = (item) => {
    console.log(item, 'sdhgyhr')
    window.scroll(0, 0);
    // console.log("item", item);
    setName(item?.name);
    setId(item?._id);
    setservice_type_id(item.serviceTypeID);
    onFilterByServiceType(item.serviceTypeID)
    setimgdata(item.icon);
    setPerKilometerCharge(item.perKilometerCharge)
    setSpecialTagArrayDetail(item?.specialTagArray)
    // setimage(IMAMGE_URL + item.icon);
    setimage(item.icon);
    // setservice_type_id(item.countryId._id);
    setHide(false);
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    // console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    // console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res);
      // setimgdata(res?.data);
      setimgdata(res?.doc?.url);
      setimage(res?.doc?.url);
      console.log("L57:", image);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteCategory(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllData();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const getAllService_type = async () => {
    HomeService.ManageService()
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalService_type(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        // if (res && res?.status) {
        //   // setLoader(false)
        //   console.log(res?.data);
        //   // settotalCat_servicetype(res?.data);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getAllServiceTags = async () => {
    NewHomService.FetchServiceTags()
      .then((res) => {
        console.log("XXDES", res);
        if (res && res?.status) {
          setServiceCategories(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const onFilter = () => {

  //   if (searchText !== "") {
  //     // setSearchText(e.target.value);
  //     console.log("searchText", searchText);
  //     let searchData = allData.filter((item) => {
  //       if (
  //         item.serviceName
  //           .toString()
  //           .toLowerCase()
  //           .includes(searchText.toLowerCase())
  //       ) {
  //         return item;
  //       }
  //     });
  //     console.log("searchData", searchData);
  //     setFilteredData(searchData)
  //     // setTableRowsData(searchData);
  //   } else {
  //     setFilteredData(allData)
  //   }
  // }

  const _onFilterByServiceType = (id) => {
    // fetchAllData();
    let searchData = allData;
    console.log("ALLSDATA167", allData);
    //Service Type

    if (id) {
      searchData = searchData.filter((item) => {
        // console.log("A172",typeof(item?.serviceTypeID),typeof(id));
        if (item?.serviceTypeID.includes(id)) {
          return item;
        }
      });
      console.log("ABC177", searchData);
      setFilteredData(searchData);
    }
  };


  const onFilterByServiceType = (id) => {
    setLoader(true)
    HomeService.ManageCategory()
      .then((res) => {
        console.log("23ahffeyfgy", res);
        if (res && res?.status) {
          setLoader(false)

          let arr = res?.data?.filter((item) => {
            // console.log("A172",typeof(item?.serviceTypeID),typeof(id));
            if (item?.serviceTypeID.includes(id)) {
              return item;
            }
          }).sort().reverse().map((item, index) => {
            return {
              sl: index + 1,
              ID: item?._id,
              Status: item?.status,
              serviceName: item?.serviceTypeData?.serviceName,
              Name: item?.name,
              // icon: IMAMGE_URL + item?.icon,
              specicalServiceTagData: <div>{item?.specicalServiceTagData?.map((item, i) => {
                return (
                  <>
                    <label>{item?.name} , </label>
                  </>
                )
              })}</div>,
              icon: item?.icon,
              imgPath: item?.icon,
              serviceTypeID: item?.serviceTypeID,
              ChargePerKilometer: item?.perKilometerCharge,


              // no edit or delete
              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onFilter = () => {
    let searchData = allData;
    console.log("ALLSDATA", allData);
    //Service Type
    if (searchServiceType !== "") {
      searchData = searchData.filter((item) => {
        if (item?.serviceName?.toLowerCase().includes(searchServiceType.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //Category
    if (searchCategory !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Name?.toLowerCase().includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
  };

  const changeStatus = (id, val) => {
    let data = {
      status: val
    }
    HomeService.UpdateCategory(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllData = () => {
    setLoader(true)
    HomeService.ManageCategory()
      .then((res) => {
        console.log("ResAllBlog", res.data);
        if (res && res?.status) {
          setLoader(false)

          let arr = res?.data?.sort().reverse().map((item, index) => {
            return {
              sl: index + 1,
              ID: item?._id,
              Status: item?.status,
              serviceName: item?.serviceTypeData?.serviceName,
              Name: item?.name,
              // icon: IMAMGE_URL + item?.icon,
              icon: item?.icon,
              imgPath: item?.icon,
              serviceTypeID: item?.serviceTypeID,
              ChargePerKilometer: item?.perKilometerCharge,

              // no edit or delete
              action: (
                <div style={{ display: "flex", flexDirection: "coloum" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });
          setFilteredData(arr)
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addData = () => {
    let data = {
      name: name,
      serviceTypeID: service_type_id,
      icon: imgdata,
      perKilometerCharge: perKilometerCharge,
      specialTagArray: specialTagArrayDetail
    };

    if (name && service_type_id && imgdata) {
      // alert(name + country_id)
      HomeService.addCategory(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            // fetchAllData();
            setName("");
            setimage("");
            setSpecialTagArrayDetail([]);
            let file = document.querySelector("#images");
            file.value = "";
            setPerKilometerCharge(0)
            // setservice_type_id("");
            // console.log("FSFSSF",filteredData);
            // alert(service_type_id)
            onFilterByServiceType(service_type_id)
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!service_type_id) {
        toast.error("Service Type is mandatory");
      }
      if (!name) {
        toast.error("Category Name is mandatory");
      }
      if (!imgdata) {
        toast.error("Category Image is mandatory");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      wrap: true,
      center: true,
      width: "125px"
    },
    {
      name: (
        <>
          <div
            style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
          >
            Service Name
          </div>
          {/* <input
            type="text"
            className="form-control"
            placeholder="Service Name"
            // value={title}
            onChange={(e) => onFilter(e)}
            style={{ width: "80%" }}
          /> */}

        </>

      ),
      selector: (row) => row.serviceName,
      wrap: true,
      center: true,
      width: "225px"
      // sortable: true,
    },
    {
      name: (
        <>
          <div
            style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
          >
            Category Name
          </div>
          {/* <div style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}>
            <input type="text" class="form-control" placeholder="Category Name" onChange={(e) => setSearchCategory(e.target.value)} />
          </div> */}
        </>

      ),
      selector: (row) => row.Name,
      wrap: true,
      center: true,
      // width: "125px"
      // sortable: true,
    },
    // {
    //   name: (
    //     <>
    //       <div
    //         style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //       >
    //         Special Categories
    //       </div>
    //     </>

    //   ),
    //   selector: (row) => row.specicalServiceTagData,
    //   wrap: true,
    //   width: "200px"
    // },
    // {
    //   name: (
    //     <div
    //       style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
    //     >
    //       Path
    //     </div>
    //   ),
    //   selector: (row) => row.imgPath,
    // },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Image
        </div>
      ),
      selector: (row) => <img style={{ width: "100%" }} src={row.icon} />,
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Charge Per Kilometer
        </div>
      ),
      selector: (row) => {
        return row.ChargePerKilometer ? row.ChargePerKilometer.toFixed(2) : "0.00"
      },
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Status
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {
              row.Status ? (
                <>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => changeStatus(row.ID, false)}
                  >
                    Active
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Deactivate
                    </span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => changeStatus(row.ID, true)}
                  >
                    Deactive
                    <span
                      style={{ fontSize: "70%", display: "block" }}
                    >
                      Click to Active
                    </span>
                  </button>

                </>
              )
            }


          </>
        )
      },
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => {
        if (row.serviceTypeID !== "64d602a1e6419455b67e1ed4") {
          return row.action
        }
        else {
          return row.action
        }
      },
      wrap: true,
      center: true,
      width: "125px"
    },
  ];

  const updateData = () => {
    // console.log("ID", imgdata);
    let data = {
      name: name,
      serviceTypeID: service_type_id,
      icon: image,
      perKilometerCharge: perKilometerCharge,
      specialTagArray: specialTagArrayDetail
    };
    console.log("L296:", data);
    HomeService.UpdateCategory(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          // console.log("RESPONSEDELETE", res);
          setName("");
          setimage("");
          setSpecialTagArrayDetail([])
          let file = document.querySelector("#images");
          file.value = "";
          setPerKilometerCharge(0)
          fetchAllData()
          // alert(service_type_id)
          // setservice_type_id();
          onFilterByServiceType(service_type_id)
        } else {
          toast.error("Error Occured!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewAllData = () => {
    setSearchServiceType("")
    setSearchCategory("")
    setFilteredData(allData)
  }

  const [perKilometerCharge, setPerKilometerCharge] = useState()

  ////CSV Download//////////////

  const [csvData, setCsvData] = useState([]);


  const fetchCSVData = () => {
    // NewHomService.FetchAllUsers()
    //   .then((res) => {
    //     if (res && res?.status) {
    // setLoader(false);
    console.log("DDDR", filteredData);
    let arr = filteredData
      ?.sort()
      .reverse()
      .map((item, index) => {
        console.log("DATASSSS649", item);
        return {
          SLNO: index + 1,
          ServiceType: item?.serviceName ? item?.serviceName : "N/A",
          Category: item?.Name ? item?.Name : "N/A",
          Status: item?.Status ? "Active" : "Inactive",

        };
      });
    setCsvData(arr);
    //   }
    // })
    // .catch((err) => {
    //   console.log("err", err);
    // });
  };

  ////CSV Download//////////////

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Category
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Category
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Type<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_type_id(e.target.value);
                  e.target.value !== "" && getAllCategoryinService_type(e.target.value);
                  onFilterByServiceType(e.target.value)
                }}
              >
                <option value="" >
                  Choose...
                </option>
                {
                  totalService_type?.filter(item => item._id === "64d60270203bf4c1d37b6a1e").map((ele, id) => {
                    // totalService_type?.map((ele, id) => {
                    return (
                      <option
                        selected={ele._id == service_type_id ? true : false}
                        value={ele._id}
                        key={ele._id}
                        disabled={ele._id !== "64d60270203bf4c1d37b6a1e" && true}
                      >
                        {ele.serviceName}
                      </option>
                    );
                  })}
              </select>
            </div>
            {/* <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category in service type <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => { setservice_type_id(e.target.value); getAllCategoryinService_type(e.target.value) }}>
                                <option value="" disabled selected>
                                    Select{" "}
                                </option>
                                {totalCat_servicetype?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == setservice_type_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => {
                  setName(e.target.value)

                }}
              />
            </div>

            <div class="form-group">
              <label for="formFile" class="form-label">
                Category Image<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                id="images"
                onChange={imageHandler}
                class="form-control"
                type="file"
                accept="image/*"
              />


              {image && (
                <>
                  <img
                    style={{
                      height: "10%",
                      width: "10%",
                      marginTop: "12px",
                      borderRadius: "9px",
                    }}
                    src={image}
                  />
                </>
              )}
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Charges per Kilometer<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="0"
                value={perKilometerCharge}
                onChange={(e) => {
                  setPerKilometerCharge(e.target.value)

                }}
              />
            </div>

            <div className="form-group"
              style={{
                display: "none",
              }}
            >
              <label for="exampleInputEmail1">
                Special Tags :
              </label>
              <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">
                {
                  serviceCategories.map((item, index) => {
                    return (
                      <>
                        <div class="form-check-inline" key={index}>
                          <input class="form-check-input" type="checkbox" value={item?._id} id={index}
                            onChange={(e) => handleCheckboxChange(e)}
                            checked={specialTagArrayDetail.includes(item?._id)}
                          />
                          <label class="form-check-label" for="flexCheckDefault">
                            {item?.name}
                          </label>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={addData}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={updateData}>
                Update
              </button>
            )}


            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Category
            </div>

            <div class="form-inline my-2">

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Service Type"
                aria-label="Search"
                value={searchServiceType}
                onChange={(e) => {
                  setSearchServiceType(e.target.value);
                }}
              />

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Category Name"
                aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value);
                }}
              />

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>
            {
              loader ? (
                <Loader />
              ) : (
                <>
                  <CSVLink
                    className="btn btn-sm btn-info"
                    filename={`customer${moment().format("YYYYMMDD")}.csv`}
                    data={csvData}
                  >
                    Export to CSV
                  </CSVLink>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={25}
                    paginationRowsPerPageOptions={[25, 50, 100]}
                  />
                </>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
