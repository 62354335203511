import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import Swal from "sweetalert2";
import NewHomService from "../../../Service/NewHomService";
import HomeService from "../../../Service/HomeService";


const ContractWorktype = () => {
    // const [name, setName] = useState("");
    // const [price, setprice] = useState("");
    const [feature, setfeature] = useState("");
    const [whitewash, setwhitewash] = useState('');
    const [dropdownoption, setdropdownoption] = useState([])
    const [inputType, setinputType] = useState("")





    const [allData, setAllData] = useState([]);

    const [meterial_id, setmeterial_id] = useState("");
    const [paintingquality_id, setpaintingquality_id] = useState("");
    const [placetypeid, setPlacetypeid] = useState("");
    const [paintVer_id, setpaintVer_id] = useState("");


    // const [allpaintingquality, setallpaintingquality] = useState([]);
    // const [allmeteritype, setallmeteritype] = useState([]);
    // const [allplacetype, setAllplacetype] = useState([]);
    // const [allSubclean,setallallSubclean]=useState([])
    const [allmodeldata, setallModeldata] = useState([])
    const [allsubcatdata, setallsubcatdata] = useState([])

    // const[totalPaintVersion,settotalPaintVersion]=useState([])


    const [service_id, setservice_id] = useState("");

    const [modelId, setmodelId] = useState("");

    const [displaysubcat, setdisplaysubcat] = useState("none")
    const [showSubcategory, setShowSubcategory] = useState(false)
    const [allService, setallSarvice] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);


    const [serviceTypeID, setServiceTypeID] = useState("")
    const [serviceCategoryID, setServiceCategoryID] = useState("");
    const [serviceSubCategoryID, setServiceSubCategoryID] = useState("")

    const [name, setName] = useState("")
    const [price, setPrice] = useState(0)
    const [starecaseOpenArea, setStarecaseOpenArea] = useState(0)
    const [bathroomCleaningArea, setBathroomCleaningArea] = useState(0)





    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");



    useEffect(() => {
        fetchAllData();
        //  fetchAllplacetype()
        // fetchAllmeterialType()
        //   fetchAllpaintingquality()
        fetchAllSarvice()
        // fetchPaintingVersion()

    }, []);

    const ResetState = () => {
        setServiceTypeID("")
        setServiceCategoryID("")
        setServiceSubCategoryID("")
        setName("")
        setPrice("")
    };

    const fetchAllSarvice = () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setallSarvice(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllSubCategoryArea = (id) => {
        HomeService.getAllSubcategory(id)
            .then((res) => {
                // console.log("AllSffetate", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    if (Object.keys(res?.data).length > 0) {
                        setShowSubcategory(true)
                        setallsubcatdata(arr);
                    }
                } else {
                    setallsubcatdata([]);
                    setShowSubcategory(false)
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const [showApplianceDisplay, setShowApplianceDisplay] = useState(true)
    const handleSubCategoryChange = (id) => {
        id === "654099ba6784a7b60e675058" ? setShowApplianceDisplay(true) : setShowApplianceDisplay(false)
    }

    // const fetchPaintingVersion= () => {
    //   HomeService.MannagePaintingVersion()
    //     .then((res) => {
    //       console.log("AllStgate", res.data);
    //       if (res && res?.status) {
    //         // setLoader(false)
    //         let arr = res?.data;
    //         settotalPaintVersion(arr);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // };

    const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    settotalCategory(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };


    const onEdit = (item) => {
        window.scrollTo(0, 0);
        // console.log("itemrr", item);
        if (item?.serviceTypeID) {
            setServiceTypeID(item?.serviceTypeID)
            fetchAllCategory(item?.serviceTypeID)
            setServiceCategoryID(item?.serviceCategoryID)
            setName(item?.name)
            setPrice(item?.price)
        }

        setId(item?._id);
        setHide(false);
    };

    // const options = [
    //     { value: 'whitewash', label: 'Whitewash' },
    //     { value: 'putty', label: 'Putty' },
    //     { value: 'papering', label: 'Papering' },
    //     { value: 'primer', label: 'Primer' },
    //     { value: 'painting', label: 'Painting' },

    //   ];
    // const fetchAllplacetype = () => {
    //   HomeService.MannagePlaceType()
    //     .then((res) => {
    //       console.log("AllState", res.data);
    //       if (res && res?.status) {
    //         // setLoader(false)
    //         let arr = res?.data;
    //         setAllplacetype(arr);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // };


    // const fetchPlaceType = (catid,sid) => {
    //   const data={serviceCategoryID:catid}
    //   HomeService.AllPlaceTypeforserviceIdandMeterialid(sid,data)
    //     .then((res) => {
    //       console.log("AllStateme", res.data);
    //       if (res && res?.status) {
    //         // setLoader(false)
    //         let arr = res?.data;
    //         setAllplacetype(arr);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // };

    // const fetchAllmeterialType = (catid,sid) => {
    //   const data={serviceCategoryID:catid}
    //   HomeService.AllmeterialtypeforserviceIdandMeterialid(sid,data)
    //     .then((res) => {
    //       console.log("AllStateme", res.data);
    //       if (res && res?.status) {
    //         // setLoader(false)
    //         let arr = res?.data;
    //         setallmeteritype(arr);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // };


    // const fetchAllpaintingquality = () => {
    //   // const data={serviceCategoryID:catid}

    //   HomeService.MannagePaintingQuality()
    //     .then((res) => {
    //       console.log("AllStttate", res.data);
    //       if (res && res?.status) {
    //         // setLoader(false)
    //         let arr = res?.data;
    //         setallpaintingquality(arr);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // };

    const fetchAllModeltype = (id) => {
        // const data={serviceCategoryID:catid}

        HomeService.getModeltypeUsingserviceId(id)
            .then((res) => {
                console.log("AllSttttttate", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setallModeldata(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };






    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteContractWorktype(id)
                    .then((res) => {
                        // console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            // console.log("RESPONSEDELETE", res);
                            fetchAllData();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };



    const [entryCategoryName, setEntryCategoryName] = useState("")

    const fetchAllData = () => {
        NewHomService.FetchContractWorktype()
            .then((res) => {
                console.log("ResAllBlog", res);
                if (res && res?.status) {
                    // setLoader(false)

                    let arr = res?.data?.map((item, index) => {
                        console.log("311:", item?.name);
                        return {
                            sl: index + 1,
                            Service: item?.serviceTypeData?.serviceName,
                            Category: item?.categoryData?.name,
                            Name: item?.name,
                            Price: item?.price,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setAllData(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };


    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service
                </div>
            ),
            selector: (row) => row.Service,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Name
                </div>
            ),
            selector: (row) => row.Name,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Price,
        },


        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const AddData = () => {


        let data = {
            serviceTypeID: serviceTypeID,
            serviceCategoryID: serviceCategoryID,
            // serviceSubCategoryID: serviceSubCategoryID,
            name: name,
            price: price

        };

        if (serviceTypeID && serviceCategoryID) {
            // alert(name + country_id)
            // HomeService.addCleaningCostType1(data)
            NewHomService.AddContractWorktype(data)
                .then((res) => {
                    // console.log("L500", res);
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllData();
                        ResetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!name) {
                return toast.error("Name  Field Is Required");
            }
            if (!price) {
                return toast.error("Price  Field Is Required");
            }

        }
    };




    const UpdateData = () => {
        // console.log("ID", id);
        let data = {
            serviceTypeID: serviceTypeID,
            serviceCategoryID: serviceCategoryID,
            // serviceSubCategoryID: serviceSubCategoryID,
            name: name,
            price: price

        };
        // console.log(data);
        NewHomService.UpdateContractWorktype(id, data)
            .then((res) => {
                // console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    // console.log("RESPONSEDELETE", res);
                    // setName("");
                    ResetState()
                    fetchAllData();
                } else {
                    toast.error("Error occured!");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };




    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Contract WorkType
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Update Contract WorkType
                            </div>
                        )}
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                ServiceType<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setServiceTypeID(e.target.value);
                                    fetchAllCategory(e.target.value);
                                    fetchAllModeltype(e.target.value)
                                }}
                            >
                                <option value="">Select</option>
                                {allService?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceTypeID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setServiceCategoryID(e.target.value);
                                // fetchAllSubCategoryArea(e.target.value)
                                // console.log("L609:", displaysubcat);
                                // fetchAllmeterialType(e.target.value,service_id);
                                // fetchPlaceType(e.target.value,service_id)
                                // fetchAllpaintingquality(e.target.value,service_id)
                            }}>
                                <option value="">Select</option>
                                {totalCategory?.map((ele, id) => {
                                    console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceCategoryID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>






                        <div className="row" style={{ display: showSubcategory ? "block" : "none" }}>
                            <div class="form-group">
                                <label for="exampleInputEmail1">
                                    Sub Category:
                                </label>
                                <select
                                    onChange={(e) => {
                                        setServiceSubCategoryID(e.target.value);
                                        // handleSubCategoryChange(e.target.value)


                                    }}
                                >
                                    <option value="">Select</option>
                                    {allsubcatdata?.map((ele, id) => {
                                        console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == serviceSubCategoryID ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="row" style={{ display: showApplianceDisplay ? "block" : "none" }}>

                            <div className="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Name<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>


                            <div className="col-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">
                                        Price<span style={{ color: "red" }}>*</span>:
                                    </label>
                                    <input
                                        type="number"
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                            </div>

                        </div>







                        {hide ? (
                            <button class="btn btn-primary" onClick={AddData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={UpdateData}>
                                Update
                            </button>
                        )}



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Contract WorkType
                        </div>
                        <DataTable columns={columns} data={allData} pagination />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractWorktype;
