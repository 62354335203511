import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";

const RegistrationFee = () => {
  const [name, setName] = useState("");
  const [state_id, setstate_id] = useState("");
  const [category_id, setcategory_id] = useState("");
  const [subcategory_id, setsubcategory_id] = useState("");

  console.log(state_id);
  const [allLanguage, setAllLanguage] = useState([]);
  const [totalCategory, setTotalCategory] = useState([]);
  const [totalSubCategory, setTotalSubCategory] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    getAllCategory();
    fetchallregistrationfee();
  }, []);

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("item", item);
    setName(item?.name);
    setId(item?._id);
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteCity(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchallregistrationfee();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const getAllSubCategory = async (id) => {
    HomeService.SubCategoriesviacategory(id)
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)

          setTotalSubCategory(res?.data);
        }
        if (res && res?.status == false) {
          setTotalSubCategory([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getAllCategory = async () => {
    HomeService.ManageCategory()
      .then((res) => {
        if (res && res?.status) {
          // setLoader(false)
          setTotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchallregistrationfee = () => {
    HomeService.Manage_AddRegistrationFee()
      .then((res) => {
        console.log("ResAllegfee", res);
        if (res && res?.status) {
          // setLoader(false)
          let arr=[];
          const item=res?.data;
           arr[0] = 
            
            {
           
              Name: item?.price,

             
                  }
        
          setAllLanguage(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const addregistrationfee = () => {
    let data = {
      // serviceCategoryID: category_id,
      // serviceSubCategoryID: subcategory_id,
      price: name,
    };

    if (name) {
      // alert(name + country_id)
      HomeService.AddRegistrationFee(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            fetchallregistrationfee();
            setName("");
            setstate_id("");
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!name) {
        toast.error("Registration Fee Field Is Required");
      }
    }
  };

  const columns = [
  
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Registration Fee
        </div>
      ),
      selector: (row) => row.Name,
    },

   
  ];

  const UpdateLanguageCategory = () => {
    console.log("ID", id);
    let data = {
      name: name,
    };
    HomeService.UpdateCity(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setName("");
          fetchallregistrationfee();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNumber = (e) => {
    e.target.value < 0
      ? toast.error("Invalid price")
      : setName(e.target.value);
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Registration Fee
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Registration Fee
              </div>
            )}

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Category Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  getAllSubCategory(e.target.value);
                }}
              >
                <option value="" disabled selected>
                  Select{" "}
                </option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}
            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                SubCategory Name<span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => setsubcategory_id(e.target.value)}>
                <option value="" disabled selected>
                  Select{" "}
                </option>
                {totalSubCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == subcategory_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div> */}


            <div class="form-group">
              <label for="exampleInputEmail1">
                Registration Fee <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => checkNumber(e)}
              />
            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={addregistrationfee}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateLanguageCategory}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Registration Fee
            </div>
            <DataTable columns={columns} data={allLanguage} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationFee;
