const fbmatchdata = () => {

}

const parseDays = (value) => {
    const YEAR = 365, MONTH = 30, WEEK = 7;
    let year, months, week, days;
    let txtYear, txtMonths, txtWeeks, txtDays;

    year = value >= YEAR ? Math.floor(value / YEAR) : 0;
    value = year ? value - (year * YEAR) : value;

    months = value >= MONTH ? Math.floor((value % YEAR) / MONTH) : 0;
    value = months ? value - (months * MONTH) : value;

    week = value >= WEEK ? Math.floor((value % YEAR) / WEEK) : 0;
    value = week ? value - (week * WEEK) : value;

    days = value < WEEK ? Math.floor((value % YEAR) % WEEK) : 0;

    let retVal = ""

    txtYear = year > 1 ? `${year} years` : year === 1 ? `${year} year` : ""
    txtMonths = months > 1 ? `${months} months` : months === 1 ? `${months} month` : ""
    txtWeeks = week > 1 ? `${week} weeks` : week === 1 ? `${week} week` : ""
    txtDays = days > 1 ? `${days} days` : days === 1 ? `${days} day` : ""

    return `${txtYear} ${txtMonths} ${txtWeeks} ${txtDays}`
    // return `years=${year},months=${months},weeks=${week},days=${days}`;
}

const formatAvailibilityOfWork = (val) => {
    switch (val) {
        case 'Available all time':
            return 'All Time';
        case 'My business hours':
            return 'Specific days';
        default:
            return 'N/A';
    }
}

const isValidUrl = (string) => {
    try {
        const newUrl = new URL(string);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    } catch (err) {
        return false;
    }
}

const formatDate = (UTCDate) => {
    const date = new Date(UTCDate);

    // Extracting components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    // Convert to 12-hour format
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

    // Constructing the formatted date and time
    // const formattedDateTime = `${month}-${day}-${year} ${formattedHours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;
    const formattedDateTime = `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
    return formattedDateTime
}

export {
    formatDate,
    isValidUrl,
    formatAvailibilityOfWork,
    parseDays,
    fbmatchdata,
}