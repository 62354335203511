import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import moment from "moment";

const ManageUserSubcription = () => {
  const [allData, setallData] = useState([]);
  const [All_data, setAll_data] = useState([]);
  const [SearchName, setSearchName] = useState("");
  const [SearchDate, setSearchDate] = useState("");
  const [SearchDateFrom, setSearchDateFrom] = useState("");

  console.log(All_data, "All_data");
  console.log(SearchDate, "SearchDate");
  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          User Name
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          User email
        </div>
      ),
      selector: (row) => row.email,
      wrap: true,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          User MobileNo.
        </div>
      ),
      selector: (row) => row.mobileNo,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Activation Date{" "}
        </div>
      ),
      selector: (row) => row.activation_date,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Expiry Date
        </div>
      ),
      selector: (row) => row.expairy_date,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Duration
        </div>
      ),
      selector: (row) => row.duration,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Type
        </div>
      ),
      selector: (row) => row.serviceTypeName,
      wrap: true,
      // width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category details{" "}
        </div>
      ),
      selector: (row) => row.categoriesDetails,
      wrap: true,
      width: "200px",
    },
  ];

  const userSubsctionData = async () => {
    const res = await HttpClientXml.requestData(
      "view/user-subscription",
      "GET"
    );
    let apiData = [];
    if (res && res?.status) {
      console.log(res?.data, "sdefee");
      apiData = res?.data?.map((item, i) => ({
        sl: i + 1,
        name: item?.userDetails?.name,
        email: item?.userDetails?.email,
        mobileNo: item?.userDetails?.mobileNo,
        activation_date: moment(item?.activation_date).format("DD-MM-YYYY"),
        expairy_date: moment(item?.expairy_date).format("DD-MM-YYYY"),
        serviceTypeName: item?.subscriptionDetails?.serviceTypeName,
        name: item?.subscriptionDetails?.name,
        categoriesDetails: item?.subscriptionDetails?.categoriesDetails?.map(
          (item, i) => <p>{item?.name},</p>
        ),
        duration:
          item?.subscriptionDetails?.duration +
          " " +
          item?.subscriptionDetails?.duration_unit,
      }));
    }
    setallData(apiData);
    setAll_data(apiData);
  };

  // filter
  const onFilter = () => {
    let filteredData = All_data;

    // Filter by name if SearchName is not empty
    if (SearchName !== "") {
      filteredData = filteredData.filter((item) =>
        item?.name?.toLowerCase().includes(SearchName.toLowerCase())
      );
    }

    // Filter by date range if both SearchDate and SearchDateFrom are not empty
  if (SearchDate !== "" && SearchDateFrom !== "") {
    const startDate = moment(SearchDate, "YYYY-MM-DD");
    const endDate = moment(SearchDateFrom, "YYYY-MM-DD");

    filteredData = filteredData.filter((item) => {
      const activationDate = moment(item?.activation_date, "DD-MM-YYYY");
      return activationDate.isBetween(startDate, endDate, undefined, '[]');
    });
  }

    // Set the filtered data
    setallData(filteredData);
  };

  const viewAllData = () => {
    // setSearchName("");
    // setSearchDate("");
    setallData(All_data);
  };
  useEffect(() => {
    userSubsctionData();
  }, []);

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              USER SUBCRIPTION
            </div>
            <div class="form-inline my-2">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="User Name"
                aria-label="Search"
                value={SearchName}
                onChange={(e) => {
                  setSearchName(e.target.value);
                }}
              />
              <input
                class="form-control mr-sm-2"
                type="date"
                placeholder="Date"
                aria-label="Search"
                value={SearchDate}
                onChange={(e) => {
                  setSearchDate(e.target.value);
                }}
              /> - 
               &nbsp;<input
                class="form-control mr-sm-2"
                type="date"
                placeholder="Date"
                aria-label="Search"
                value={SearchDateFrom}
                onChange={(e) => {
                  setSearchDateFrom(e.target.value);
                }}
              />

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>
            <DataTable columns={columns} data={allData.reverse()} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageUserSubcription;
