import HttpClientXml from "../Utils/HttpClientXml";
import { BASE_URL } from "../Utils/HttpClientXml";

async function AddBlog(data) {
  let endpoind = "add-blog";
  return HttpClientXml.post(endpoind, data);
}
async function ManageBlog() {
  let endpoind = "view-all-blog";
  return HttpClientXml.get(endpoind);
}
async function UpdateBlog(id, data) {
  let endpoind = `update-blog/${id}`;
  return HttpClientXml.put(endpoind, data);
}
async function DeleteBlog(id) {
  let endpoind = `delete-blog/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

async function addBlogCategory(data) {
  let endpoind = "add-blog-category";
  return HttpClientXml.post(endpoind, data);
}

async function ViewAllBlogCategory() {
  let endpoind = "view-all-blogCategory";
  return HttpClientXml.get(endpoind);
}

async function UpdateBlogCategory(id, data) {
  let endpoind = `update-blog-category/${id}`;
  return HttpClientXml.put(endpoind, data);
}

async function DeleteBlogCategory(id) {
  let endpoind = `delete-blog-category/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}

// const addState = async () => {
//   let endpoind = 'add-state';
//   return HttpClientXml.post(endpoind, data);
// }

//..............................................Country................

const addCountry = async (data) => {
  let endpoind = "add-country";
  return HttpClientXml.post(endpoind, data);
};

const ManageCountry = async () => {
  let endpoind = "view-all-country";
  return HttpClientXml.get(endpoind);
};

// const EditCountry = async () => {
//   let endpoind = 'update-city';
//   return HttpClientXml.get(endpoind);
// }

const UpdateCountry = async (id, data) => {
  let endpoind = `update-country/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteCountry = async (id) => {
  let endpoind = `delete-country/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//.............................................State..............

const addState = async (data) => {
  let endpoind = `add-state`;
  return HttpClientXml.post(endpoind, data);
};

const ManageState = async () => {
  let endpoind = "view-state";
  return HttpClientXml.get(endpoind);
};
const UpdateState = async (id, data) => {
  let endpoind = `update-state/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteState = async (id) => {
  let endpoind = `delete-state/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.................................................District............

const addDistrict = async (data) => {
  let endpoind = `add-district`;
  return HttpClientXml.post(endpoind, data);
};

const ManageDistrict = async () => {
  let endpoind = "view-district";
  return HttpClientXml.get(endpoind);
};
const UpdateDistrict = async (id, data) => {
  let endpoind = `update-district/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteDistrict = async (id) => {
  let endpoind = `delete-district/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
// ...........................................ZIP CODE.............
// state wise district
const AddZip = async (data) => {
  let endpoind = `add/zipcode`;
  return HttpClientXml.post(endpoind, data);
};
const UpdateZipCode = async (id, data) => {
  let endpoind = `update/zipcode/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const ManageDisStateWise = async (id) => {
  let endpoind = `/view/district/statewise/${id}`;
  return HttpClientXml.get(endpoind);
};
const ManageZipCode = async () => {
  let endpoind = "view/zipcode";
  return HttpClientXml.get(endpoind);
};
const DeleteZip = async (id) => {
  let endpoind = `delete/zipcode/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//.................................................City............

const addCity = async (data) => {
  let endpoind = `add-city`;
  return HttpClientXml.post(endpoind, data);
};

const ManageCity = async () => {
  let endpoind = "view-all-city";
  return HttpClientXml.get(endpoind);
};
const UpdateCity = async (id, data) => {
  let endpoind = `update-city/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteCity = async (id) => {
  let endpoind = `delete-city/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//...............................................Area..........

const addArea = async (data) => {
  let endpoind = `add-area`;
  return HttpClientXml.post(endpoind, data);
};

const ManageArea = async () => {
  let endpoind = "view-area";
  return HttpClientXml.get(endpoind);
};

const UpdateArea = async (id, data) => {
  let endpoind = `update-area/${id}`;
  return HttpClientXml.put(endpoind, data);
}

const DeleteArea = async (id) => {
  let endpoind = `delete-area/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.................................................Language........

const addLanguage = async (data) => {
  let endpoind = "add-language";
  return HttpClientXml.post(endpoind, data);
};

const ManageLanguage = async () => {
  let endpoind = "view-language";
  return HttpClientXml.get(endpoind);
};
// const UpdateCity = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

const DeleteLanguage = async (id) => {
  let endpoind = `delete-language/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.........................................................Service-Type.......
const addService = async (data) => {
  let endpoind = `add-service-type`;
  return HttpClientXml.post(endpoind, data);
};

const ManageService = async () => {
  let endpoind = "view-service-type";
  return HttpClientXml.get(endpoind);
};
const UpdateService = async (id, data) => {
  let endpoind = `update-service-type/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteService = async (id) => {
  let endpoind = `delete-service-type/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
const CategoriesviaService_type = async (id) => {
  let endpoind = `view-particular-catgory-by-service/${id}`;
  return HttpClientXml.get(endpoind);
};

//.......................................................category.....

const addCategory = async (data) => {
  let endpoind = `add-category`;
  return HttpClientXml.post(endpoind, data);
};

const ManageCategory = async () => {
  let endpoind = "view-category";
  return HttpClientXml.get(endpoind);
};

const UpdateCategory = async (id, data) => {
  let endpoind = `update-category/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteCategory = async (id) => {
  let endpoind = `delete-category/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//...................................................Sub Category........
const AddSubCategory = async (data) => {
  let endpoind = `add-sub-category`;
  return HttpClientXml.post(endpoind, data);
};

const ManageSubCategory = async () => {
  let endpoind = "view-sub-category";
  return HttpClientXml.get(endpoind);
};
const UpdateSubCategory = async (id, data) => {
  let endpoind = `update-sub-category/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteSubCategory = async (id) => {
  let endpoind = `delete-sub-category/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//......................................................experence...

const addExperience = async (data) => {
  let endpoind = `add-city`;
  return HttpClientXml.post(endpoind, data);
};

const ManageExperience = async () => {
  let endpoind = "view-all-city";
  return HttpClientXml.get(endpoind);
};
// const UpdateExperience = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

const DeleteExperience = async (id) => {
  let endpoind = `delete-experence/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//....................................................service...
const add_Service = async (data) => {
  let endpoind = "add-service";
  return HttpClientXml.post(endpoind, data);
};

const Manage_Service = async () => {
  let endpoind = "view-service";
  return HttpClientXml.get(endpoind);
};
const Update_Service = async (id, data) => {
  let endpoind = `update-service/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const SubCategoriesviacategory = async (id) => {
  let endpoind = `view-subcatBycat/${id}`;
  return HttpClientXml.get(endpoind);
};

const Delete_Service = async (id) => {
  let endpoind = `delete-service/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...................................................Service Date...
const add_ServiceDate = async (data) => {
  let endpoind = "serviceDate";
  return HttpClientXml.post(endpoind, data);
};

const Manage_ServiceDate = async () => {
  let endpoind = "serviceDate";
  return HttpClientXml.get(endpoind);
};

const Update_ServiceDate = async (id, data) => {
  let endpoind = `serviceDate/${id}`;
  return HttpClientXml.put(endpoind, data);
};

// const SubCategoriesviacategory = async (id) => {

//   let endpoind = `view-subcatBycat/${id}`;
//   return HttpClientXml.get(endpoind);
// }

const Delete_Servicedate = async (id) => {
  let endpoind = `serviceDate/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//....................................................Service Time...

const add_ServiceTime = async (data) => {
  let endpoind = "serviceTime";
  return HttpClientXml.post(endpoind, data);
};

const Manage_ServiceTime = async () => {
  let endpoind = "serviceTime";
  return HttpClientXml.get(endpoind);
};

const Allsevicedate_category = async (id) => {
  let endpoind = `getserviceDateByCat/${id}`;
  return HttpClientXml.get(endpoind);
};

const Update_ServiceTime = async (id, data) => {
  let endpoind = `serviceTime/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const Delete_Servicetime = async (id) => {
  let endpoind = `serviceTime/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

// const SubCategoriesviacategoryTime= async (id) => {

//   let endpoind = `view-subcatBycat/${id}`;
//   return HttpClientXml.get(endpoind);
// }

//..................................................AddCupon....

const AddCupon = async (data) => {
  let endpoind = "coupon";
  return HttpClientXml.post(endpoind, data);
};

const Manage_Cupon = async () => {
  let endpoind = "coupon";
  return HttpClientXml.get(endpoind);
};

//........................................AddBooking.......

// const AddBooking = async (data) => {
//   let endpoind = `add-city`;
//   return HttpClientXml.post(endpoind, data);
// }

const ManageBookingCheckout = async () => {
  let endpoind = "view-checkout";
  return HttpClientXml.get(endpoind);
};
// const UpdateExperience = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

// const DeleteExperience = async (id) => {
//   let endpoind = `delete-city/${id}`;
//   return HttpClientXml.deletemethod(endpoind)
// }
//................................................Add_interviewdate.....

const Add_interviewdate = async (data) => {
  let endpoind = `interdate`;
  return HttpClientXml.post(endpoind, data);
};

const ManageInterviewdate = async () => {
  let endpoind = "interdate";
  return HttpClientXml.get(endpoind);
};
const UpdateInterviewdate = async (id, data) => {
  let endpoind = `interdate/${id}`;
  return HttpClientXml.put(endpoind, data);
}

const Deletenterviewdate = async (id) => {
  let endpoind = `interdate/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

const allinterviewtime = async (data) => {
  let endpoind = `interviewTimebydate/`;
  return HttpClientXml.post(endpoind, data);
};

//..............................................Add interviewTime..

const Add_intervieTime = async (data) => {
  let endpoind = `interviewTime`;
  return HttpClientXml.post(endpoind, data);
};

const ManageIntervieTime = async () => {
  let endpoind = "interviewTime";
  return HttpClientXml.get(endpoind);
};

const UpdateInterviewTime = async (id, data) => {
  let endpoind = `interviewTime/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteIntervieTime = async (id) => {
  let endpoind = `interviewTime/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..........................................Add InterviewType..

const Add_interviewType = async (data) => {
  let endpoind = `interviewType`;
  return HttpClientXml.post(endpoind, data);
};

const ManageInterviewtype = async () => {
  let endpoind = "interviewType";
  return HttpClientXml.get(endpoind);
};
// const UpdateExperience = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

const DeleteInterviewType = async (id) => {
  let endpoind = `interviewType/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..........................................Add Experience....

const AddExperience = async (data) => {
  let endpoind = `add-experence`;
  return HttpClientXml.post(endpoind, data);
};

const Manage_Experience = async () => {
  let endpoind = "view-experence";
  return HttpClientXml.get(endpoind);
};
// const UpdateExperience = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

const Delete_Experience = async (id) => {
  let endpoind = `delete-experence/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..........................................Add Registration fee....

const AddRegistrationFee = async (data) => {
  let endpoind = `add-registartion-fee`;
  return HttpClientXml.post(endpoind, data);
};

const Manage_AddRegistrationFee = async () => {
  let endpoind = "view-registration-fee";
  return HttpClientXml.get(endpoind);
};
// const UpdateExperience = async (id, data) => {
//   let endpoind = `update-city/${id}`;
//   return HttpClientXml.put(endpoind, data);
// }

const Delete_AddRegistrationFee = async (id) => {
  let endpoind = `delete-experence/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//..........................................Add WorkingTime....

const Add_WorkingTime = async (data) => {
  let endpoind = `add-workingtime`;
  return HttpClientXml.post(endpoind, data);
};

const ManageWorkingTime = async () => {
  let endpoind = "view-workingtime";
  return HttpClientXml.get(endpoind);
};
const DeleteWorkingTime = async (id) => {
  let endpoind = `delete-worktime/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
const UpdateWorkingTime = async (id, data) => {
  let endpoind = `update-worktime/${id}`;
  return HttpClientXml.put(endpoind, data);
};

//...................................Add Home banner.....
const uploadBannerImnage = async (data) => {
  let endpoind = "upload-home-banner";
  return HttpClientXml.fileUplode(endpoind, data);
};

const AddHomeBanner = async (data) => {
  let endpoind = `add-banner`;
  return HttpClientXml.post(endpoind, data);
};
const UpdateHomebanner = async (id, data) => {
  let endpoind = `update-home-banner/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const AddCategoryBanner = async (data) => {
  let endpoind = `add-category-banner`;
  return HttpClientXml.post(endpoind, data);
};

const ManageHomeBanner = async () => {
  let endpoind = "view-home-banner";
  return HttpClientXml.get(endpoind);
};

const ManageCategoryBanner = async () => {
  let endpoind = "view-category-banner";
  return HttpClientXml.get(endpoind);
};

const UpdateCategoryBanner = async (id, data) => {
  let endpoind = `update-category-banner/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const ManageServiceBanner = async () => {
  let endpoind = "view-service-banner";
  return HttpClientXml.get(endpoind);
};

const DeleteCategoryBanner = async (id) => {
  let endpoind = `delete-category-banner/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...............................................SpareParts...

const AddSpareparts = async (data) => {
  let endpoind = `add-spare-parts`;
  return HttpClientXml.post(endpoind, data);
};

const ManageSpareparts = async () => {
  let endpoind = "view-all-spareparts";
  return HttpClientXml.get(endpoind);
};
// const Update_SpareParts = async (data) => {
//   let endpoind = 'update-spate-parts';
//   return HttpClientXml.fileUplode(endpoind, data);
// }
const Update_SpareParts = async (id, data) => {
  let endpoind = `update-spate-parts/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteSpareparts = async (id) => {
  let endpoind = `delete-spare-parts/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.........................................SubCategoryBanner....

const AddSubCategoryBanner = async (data) => {
  let endpoind = `add-sub-category-banner`;
  return HttpClientXml.post(endpoind, data);
};

const MannageSubCategoryBanner = async () => {
  let endpoind = "view-sub-category-banner";
  return HttpClientXml.get(endpoind);
};
const UpdateSubCategoryBanner = async (id, data) => {
  let endpoind = `update-sub-category-banner/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteSubCategoryBanner = async (id) => {
  let endpoind = `delete-sub-category-banner/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//............................................vendar

const MannageVendar = async () => {
  let endpoind = "view-interview-process";
  return HttpClientXml.get(endpoind);
};
const ViewUpdatedVendar = async () => {
  let endpoind = "view/updated/vendor";
  return HttpClientXml.get(endpoind);
};

const UpdateHomeVendar = async (id, data) => {
  let endpoind = `upate-interview-process/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const DeleteHomeVendar = async (id) => {
  let endpoind = `delete-vendordata-by-admin/${id}`;
  return HttpClientXml.put(endpoind);
};

const viewPerticularVendar = async (id) => {
  let endpoind = `view-particular-interview/${id}`;
  return HttpClientXml.get(endpoind);
};

// const DeleteVendar = async (id) => {
//   let endpoind = `view-interview-process/${id}`;
//   return HttpClientXml.deletemethod(endpoind)
// }
const UpdateVendar = async (id, data) => {
  let endpoind = `upate-interview-process/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const AddVendarBanner = async (data) => {
  let endpoind = `add-vendor-banner-image`;
  return HttpClientXml.post(endpoind, data);
};

//.............................................House Type.....
const AddHomeType = async (data) => {
  let endpoind = `add-housetype`;
  return HttpClientXml.post(endpoind, data);
};

const MannageHomeType = async () => {
  let endpoind = "view-all-housetype";
  return HttpClientXml.get(endpoind);
};

const DeleteHomeType = async (id) => {
  let endpoind = `delete-housetype/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

const viewParticularCategoryHomeType = async (id, data) => {
  let endpoind = `view-housetype-by-categoryandservicetype/${id}`;
  return HttpClientXml.post(endpoind, data);
};
//..........No of buildings....

const AddNoofbuildings = async (data) => {
  let endpoind = `add-numberofflat`;
  return HttpClientXml.post(endpoind, data);
};

const MannageNoofbuildings = async () => {
  let endpoind = "view-numberofflat";
  return HttpClientXml.get(endpoind);
};
const UpdateNoofbuildings = async (id, data) => {
  let endpoind = `update-numberofflat/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteNoofbuildings = async (id) => {
  let endpoind = `delete-numberofflat/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..................................................housetype,buildingtype,modeltype,place type...
//...all upi  for from input....
const UpdateHouseType = async (id, data) => {
  let endpoind = `update-housetype/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdatebuildingType = async (id, data) => {
  let endpoind = `update-buildingtype/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdatePlaceType = async (id, data) => {
  let endpoind = `update-placetype/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const UpdateAreaIndivisual = async (id, data) => {
  let endpoind = `update-areaIndividual/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdatePaintingBrand = async (id, data) => {
  let endpoind = `update-printingbrand/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdateServiceDays = async (id, data) => {
  let endpoind = `update-servicedays/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdatePaintingVersion = async (id, data) => {
  let endpoind = `update-paintingversion/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdateServicePakage = async (id, data) => {
  let endpoind = `update-servicepackages/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const UpdateDiscountPakage = async (id, data) => {
  let endpoind = `update-discountpackages/${id}`;
  return HttpClientXml.put(endpoind, data);
};

//..............................................Building Type....
const AddBuildingType = async (data) => {
  let endpoind = `add-buildingtype`;
  return HttpClientXml.post(endpoind, data);
};
const MannageBuildingType = async (id) => {
  let endpoind = `view-buildingtype/${id}`;
  return HttpClientXml.get(endpoind);
};

const DeleteBuildingType = async (id) => {
  let endpoind = `delete-buildingtype/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...................................................Model Types
const AddModelType = async (data) => {
  let endpoind = `add-selectmodeltype`;
  return HttpClientXml.post(endpoind, data);
};
const MannageModelType = async () => {
  let endpoind = "view-all-selectmodel";
  return HttpClientXml.get(endpoind);
};

const getModeltypeUsingserviceId = async (id) => {
  let endpoind = `view-selectmodel/${id}`;
  return HttpClientXml.get(endpoind);
};

const getAllSubcategory = async (id) => {
  let endpoind = `view-particular-sub-catgory-by-category/${id}`;
  return HttpClientXml.get(endpoind);
};

// const AddModeCost = async (data) => {
//   let endpoind = `addModelCost`;
//   return HttpClientXml.post(endpoind, data);
// };
// const ViewModeCost = async () => {
//   let endpoind = "view-selectmodelprice";
//   return HttpClientXml.get(endpoind);
// };
const UpdateModelType = async (id, data) => {
  let endpoind = `update-select-modeltype/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteModelType = async (id) => {
  let endpoind = `delete-selectmodel/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//..............................................Place Types
const AddPlaceType = async (data) => {
  let endpoind = `add-placetype`;
  return HttpClientXml.post(endpoind, data);
};
const MannagePlaceType = async () => {
  let endpoind = "view-all-placetype";
  return HttpClientXml.get(endpoind);
};
const AllPlacetypeforserviceIdandMeterialid = async (id, data) => {
  let endpoind = `view-placetype-by-categoryandservicetype/${id}`;
  return HttpClientXml.post(endpoind, data);
};
const DeletePlaceType = async (id) => {
  let endpoind = `delete-placetype/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//.............................................Meterial type...
const AddMeterialtype = async (data) => {
  let endpoind = `add-materialtype`;
  return HttpClientXml.post(endpoind, data);
};
const MannageMeterialType = async () => {
  let endpoind = "view-all-materialtype";
  return HttpClientXml.get(endpoind);
};
const AllmeterialtypeforserviceIdandMeterialid = async (id, data) => {
  let endpoind = `view-materialtype-by-categoryandservicetype/${id}`;
  return HttpClientXml.post(endpoind, data);
};
const DeleteMeterialType = async (id) => {
  let endpoind = `delete-materialtype/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...............................................AreaIndividual

const AddAreaIndividual = async (data) => {
  let endpoind = `add-areaIndividual`;
  return HttpClientXml.post(endpoind, data);
};
const MannageAreaIndividual = async () => {
  let endpoind = "view-areaIndividual ";
  return HttpClientXml.get(endpoind);
};

const UpdateAreaIndividual = async (id, data) => {
  let endpoind = `upate-interview-process/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteAreaIndividual = async (id) => {
  let endpoind = `delete-areaIndividual/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//...............................................paintingbrands...

const AddPaintingBrands = async (data) => {
  let endpoind = `add-paintingbrand`;
  return HttpClientXml.post(endpoind, data);
};
const MannagePaintingBrands = async () => {
  let endpoind = "view-paintingbrand";
  return HttpClientXml.get(endpoind);
};

const UpdatePaintingBrandsl = async (id, data) => {
  let endpoind = `upate-interview-process/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeletePaintingBrands = async (id) => {
  let endpoind = `delete-paintingbrand/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..............................................................................PaintingQuality

const AddPaintingQuality = async (data) => {
  let endpoind = `add-paintingquality`;
  return HttpClientXml.post(endpoind, data);
};
const MannagePaintingQuality = async () => {
  let endpoind = "view-paintingquality";
  return HttpClientXml.get(endpoind);
};
const AllPlaceTypeforserviceIdandMeterialid = async (id, data) => {
  let endpoind = `view-placetype-by-categoryandservicetype/${id}`;
  return HttpClientXml.post(endpoind, data);
};

const UpdatePaintingQuality = async (id, data) => {
  let endpoind = `update-paintingquality/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeletePaintingQuality = async (id) => {
  let endpoind = `delete-paintingquality/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//..............................................................................addPainting Version.

const AddPaintingVersion = async (data) => {
  let endpoind = `add-paintingversion`;
  return HttpClientXml.post(endpoind, data);
};
const MannagePaintingVersion = async () => {
  let endpoind = "view-paintingversion";
  return HttpClientXml.get(endpoind);
};

// const UpdatePaintingVersion = async (id, data) => {
//   let endpoind = `upate-interview-process/${id}`;
//   return HttpClientXml.put(endpoind, data);
// };

const DeletePaintingVersion = async (id) => {
  let endpoind = `delete-paintingversion/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//.................................................AddQuestionData
const Addinterviewques = async (data) => {
  let endpoind = `add-vendorinterviewquestion`;
  return HttpClientXml.post(endpoind, data);
};
const Mannageinterviewques = async () => {
  let endpoind = "view-vendorinterviewquestion";
  return HttpClientXml.get(endpoind);
};

const Updateinterviewques = async (id, data) => {
  let endpoind = `update-vendorinterviewquestin/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const Deleteinterviewques = async (id) => {
  let endpoind = `delete-vendorinterviewquestion/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//.......................................................................Vendar Banner
const AddVendarBannerdata = async (data) => {
  let endpoind = `add-vendor-banner-image`;
  return HttpClientXml.post(endpoind, data);
};
const MannageVendarBannerdata = async () => {
  let endpoind = "view-vendor-dashboard-image";
  return HttpClientXml.get(endpoind);
};

const UpdateVendarBannerdata = async (id, data) => {
  let endpoind = `update-vendorbannrImage/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteVendarBannerdata = async (id) => {
  let endpoind = `delete-vendorbannrImage/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.......................................................................Vendar Banner
const AddServiceDays = async (data) => {
  let endpoind = `add-servicedays`;
  return HttpClientXml.post(endpoind, data);
};
const MannageServiceDays = async () => {
  let endpoind = "view-servicedays";
  return HttpClientXml.get(endpoind);
};

// const UpdateServiceDays = async (id, data) => {
//   let endpoind = `update-vendorbannrImage/${id}`;
//   return HttpClientXml.put(endpoind, data);
// };

const DeleteServiceDays = async (id) => {
  let endpoind = `delete-servicedays/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...................................................Input Type
const AddPlaceinputtype = async (data) => {
  let endpoind = `update-place-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const AddSelectModelinputtype = async (data) => {
  let endpoind = `update-select-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const AddMeterialinputtype = async (data) => {
  let endpoind = `update-material-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const AddhouseTypeinputtype = async (data) => {
  let endpoind = `update-house-input`;
  return HttpClientXml.put(endpoind, data);
};
const AddBuildingtypeinputtype = async (data) => {
  let endpoind = `update-building-inputtype`;
  return HttpClientXml.put(endpoind, data);
};

const AddAreaindivisualinputtype = async (data) => {
  let endpoind = `update-areaindividual-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const AddprintingQualityinputtype = async (data) => {
  let endpoind = `update-printingquality-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const AddprintingVersioninputtype = async (data) => {
  let endpoind = `update-printingversion-inputtype`;
  return HttpClientXml.put(endpoind, data);
};
const Addservicedaysinputtype = async (data) => {
  let endpoind = `update-servicedays-inputtype`;
  return HttpClientXml.put(endpoind, data);
};

//.........................................vendar questions and answar...
const AddVendarquestionAnswar = async (data) => {
  let endpoind = `add-faqvendor`;
  return HttpClientXml.post(endpoind, data);
};
const MannagequestionAnswar = async () => {
  let endpoind = "view-faqvendor";
  return HttpClientXml.get(endpoind);
};

const UpdatequestionAnswar = async (id, data) => {
  let endpoind = `update-faqvendor/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeletequestionAnswar = async (id) => {
  let endpoind = `delete-faqvendor/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//..............................................ViewContuctus....

const ViewContuctus = async () => {
  let endpoind = "view-contact-us";
  return HttpClientXml.get(endpoind);
};
//....................................add-subscription...

const AddVendarSubscription = async (data) => {
  let endpoind = `add-subscription`;
  return HttpClientXml.post(endpoind, data);
};
const MannageVendarSubscription = async () => {
  let endpoind = "view-all-subscription";
  return HttpClientXml.get(endpoind);
};

const UpdateVendarSubscription = async (id, data) => {
  let endpoind = `update-subscription/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteVendarSubscription = async (id) => {
  let endpoind = `delete-subscription/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//....................................................add service pakage

const AddServicePakage = async (data) => {
  let endpoind = `add-servicepackages`;
  return HttpClientXml.post(endpoind, data);
};
const MannageServicePakage = async () => {
  let endpoind = "view-servicepackages";
  return HttpClientXml.get(endpoind);
};

const DeleteServicePakage = async (id) => {
  let endpoind = `delete-servicepackages/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

const servicepakagebyCatId = async (sid, cid) => {
  let endpoind = `view-servicepackages-catgory/${sid}/${cid}`;
  return HttpClientXml.get(endpoind);
};

//..............................................discount Pakage
const AdddiscountPakage = async (data) => {
  let endpoind = `add-discountpackages`;
  return HttpClientXml.post(endpoind, data);
};
const MannagediscountPakage = async () => {
  let endpoind = "view-discountpackages";
  return HttpClientXml.get(endpoind);
};

const UpdatediscountPakage = async (id, data) => {
  let endpoind = `update-faqvendor/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeletediscountPakage = async (id) => {
  let endpoind = `delete-discountpackages/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//...............................................serviceorderImage...
const addOfferserviceimage = async (data) => {
  let endpoind = `add-offerserviceimage`;
  return HttpClientXml.post(endpoind, data);
};
const MannageOfferserviceimage = async () => {
  let endpoind = "view-offerserviceimage";
  return HttpClientXml.get(endpoind);
};

const UpdateOfferserviceimage = async (id, data) => {
  let endpoind = `update-offerserviceimage/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteOfferserviceimage = async (id) => {
  let endpoind = `remove-offerserviceimage/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//................................................About Service.......

const addAboutService = async (data) => {
  let endpoind = `add-about-service`;
  return HttpClientXml.post(endpoind, data);
};

const MannageAboutService = async () => {
  let endpoind = "view-about-service";
  return HttpClientXml.get(endpoind);
};
//................................About-Company......
const addAboutCompany = async (data) => {
  let endpoind = `add-about-company`;
  return HttpClientXml.post(endpoind, data);
};

const MannageAboutCompany = async () => {
  let endpoind = "view-about-company";
  return HttpClientXml.get(endpoind);
};
//.....................................Customer Quarys...

const addCustomerQuarys = async (data) => {
  let endpoind = `add-customer-queries`;
  return HttpClientXml.post(endpoind, data);
};

const MannageCustomerQuarys = async () => {
  let endpoind = "view-customer-queries";
  return HttpClientXml.get(endpoind);
};
//...................................Add Minimum Price....

const addMinimumPrice = async (data) => {
  let endpoind = `add-minimumprice`;
  return HttpClientXml.post(endpoind, data);
};

const MannageMinimumPrice = async () => {
  let endpoind = "view-minimumprice";
  return HttpClientXml.get(endpoind);
};
//..............................Painting Cost...

const addPaintingCost = async (data) => {
  let endpoind = `add-paintingcost`;
  return HttpClientXml.post(endpoind, data);
};
const MannagePaintingCost = async () => {
  let endpoind = "view-paintingcost";
  return HttpClientXml.get(endpoind);
};

const UpdatePaintingCost = async (id, data) => {
  let endpoind = `update-paintingcost/${id}`;
  return HttpClientXml.put(endpoind, data);
};

//...................................................addAMCprice...

const addAMCservice = async (data) => {
  let endpoind = `add-setamcprice`;
  return HttpClientXml.post(endpoind, data);
};
const MannageAMCservice = async () => {
  let endpoind = "view-setamcprice";
  return HttpClientXml.get(endpoind);
};
const UpdateAMCservice = async (id, data) => {
  let endpoind = `update-setamcprice/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteAMCservice = async (id) => {
  let endpoind = `delete-setamcprice/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};
//..................................................Add CCTV...

const addAMCCtv = async (data) => {
  let endpoind = `add-cctvworking`;
  return HttpClientXml.post(endpoind, data);
};
const MannageCCtv = async () => {
  let endpoind = "view-cctvworking";
  return HttpClientXml.get(endpoind);
};
const UpdateCCtv = async (id, data) => {
  let endpoind = `update-cctvworking/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const DeleteCCtv = async (id) => {
  let endpoind = `delete-cctvworking/${id}`;
  return HttpClientXml.deletemethod(endpoind);
};

//.....................................................image upload......
const uploadImnage = async (data) => {
  let endpoind = "upload";
  return HttpClientXml.fileUplode(endpoind, data);
};

////** NewLY */

///--------------------House Type-------------------------
const AddHouseTypeNew = async (data) => {
  let endpoind = `add-housetypenew`;
  return HttpClientXml.post(endpoind, data);
};
const ViewAllHouseTypeNew = async () => {
  let endpoind = "view-housetypenew";
  return HttpClientXml.get(endpoind);
};
const viewHouseType = async (id) => {
  let endpoind = `view-housetypenew/${id}`;
  return HttpClientXml.get(endpoind, id);
};
const UpdateHouseTypeNew = async (id, data) => {
  let endpoind = `update-housetypenew/${id}`;
  return HttpClientXml.put(endpoind, data);
};

///*-------------------------Floor Number*-------------------------
const ViewAllFloorNumberNew = async () => {
  let endpoind = "view-floorNumber";
  return HttpClientXml.get(endpoind);
};
const AddFloorNumberNew = async (data) => {
  let endpoind = "add-floorNumber";
  return HttpClientXml.post(endpoind, data);
};
const UpdateFloorNumberNew = async (id, data) => {
  let endpoind = `update-floorNumber/${id}`;
  return HttpClientXml.put(endpoind, data);
};
///---------------------------Service Package---------------------------
const ViewAllServicePackageNew = async () => {
  let endpoind = "view-servicepackage";
  return HttpClientXml.get(endpoind);
};
const AddServicePackageNew = async (data) => {
  let endpoind = "add-servicepackage";
  return HttpClientXml.post(endpoind, data);
};
const UpdateServicePackageNew = async (id, data) => {
  let endpoind = `update-servicepackage/${id}`;
  return HttpClientXml.put(endpoind, data);
};
const packName = (val) => {
  switch (val) {
    case "1":
      return "Monthly";
      break;
    case "2":
      return "Bi-monthly";
      break;
    case "3":
      return "Quartly";
      break;
    case "6":
      return "Half Yearly";
      break;
    case "12":
      return "Anually";
      break;
    default:
      return val;
  }
};
///------------------------Service Package Price------------------------
const AddServicePackagePriceNew = async (data) => {
  let endpoind = "add-servicepackageprice";
  return HttpClientXml.post(endpoind, data);
};
const ViewAllServicePackagePriceNew = async () => {
  let endpoind = "view-servicepackageprice";
  return HttpClientXml.get(endpoind);
};
const UpdateServicePackagePriceNew = async (id, data) => {
  let endpoind = `update-servicepackageprice/${id}`;
  return HttpClientXml.put(endpoind, data);
};

///-----------------------Model Wise Charge-----------------------
const modelWiseCharge = async (data) => {
  let endpoind = "add-selectmodelpricen";
  return HttpClientXml.post(endpoind, data);
};

///Cleaning Price
const addCleaningCostType1 = async (data) => {
  let endpoind = `add-cleaningserviceprice`;
  return HttpClientXml.post(endpoind, data);
};
const ServiceChargesType1 = async () => {
  let endpoind = "view-cleaningserviceprice";
  return HttpClientXml.get(endpoind);
};
const UpdateCleaningCostType1 = async (id, data) => {
  let endpoind = `update-cleaningserviceprice/${id}`;
  return HttpClientXml.put(endpoind, data);
};

const addHouseTypePrice = async (data) => {
  let endpoind = "add-sethousepercentage";
  return HttpClientXml.post(endpoind, data);
};

const viewHouseTypePrice = async () => {
  let endpoind = "view-sethousepercentage";
  return HttpClientXml.get(endpoind);
};

const DeleteHouseTypePrice = async (id) => {
  let endpoind = `delete-sethousepercentage/${id}`;
  return HttpClientXml.deletemethod(endpoind);
}


const viewModalWisePrice = async () => {
  let endpoind = "view-selectmodelpricen";
  return HttpClientXml.get(endpoind);
}

///*/Range Of Work
const addRangeOfWorkName = async (data) => {
  let endpoind = "add-rengeofwork";
  return HttpClientXml.post(endpoind, data);
}
const viewRangeOfWorkName = async () => {
  let endpoind = "view-rengeofwork";
  return HttpClientXml.get(endpoind);
}

const UpdateHouseTypePrice = async (id, data) => {
  let endpoind = `update-sethousepercentage/${id}`;
  return HttpClientXml.put(endpoind, data);
};

export default {
  UpdateServicePackagePriceNew,
  UpdateInterviewdate,

  DeleteHouseTypePrice,
  UpdateHouseTypePrice,

  AddBlog,
  ManageBlog,
  UpdateBlog,
  DeleteBlog,

  addBlogCategory,
  ViewAllBlogCategory,
  UpdateBlogCategory,
  DeleteBlogCategory,

  addState,
  ManageState,
  UpdateState,
  DeleteState,

  addCountry,
  ManageCountry,
  UpdateCountry,
  DeleteCountry,


  addDistrict,
  ManageDistrict,
  UpdateDistrict,
  DeleteDistrict,

  addCity,
  ManageCity,
  UpdateCity,
  DeleteCity,

  addArea,
  ManageArea,
  UpdateArea,
  DeleteArea,

  addLanguage,
  ManageLanguage,
  DeleteLanguage,

  addService,
  ManageService,
  UpdateService,
  Delete_Service,
  CategoriesviaService_type,

  addCategory,
  ManageCategory,
  getAllSubcategory,
  UpdateCategory,
  DeleteCategory,

  AddSubCategory,
  ManageSubCategory,
  UpdateSubCategory,
  DeleteSubCategory,

  addExperience,
  ManageExperience,

  add_Service,
  Manage_Service,
  Update_Service,
  SubCategoriesviacategory,

  uploadImnage,

  add_ServiceDate,
  Manage_ServiceDate,
  Update_ServiceDate,
  Delete_Servicedate,

  add_ServiceTime,
  Manage_ServiceTime,
  Update_ServiceTime,
  Delete_Servicetime,
  Allsevicedate_category,

  AddCupon,
  Manage_Cupon,

  ManageBookingCheckout,

  Add_interviewdate,
  ManageInterviewdate,
  Deletenterviewdate,

  Add_intervieTime,
  ManageIntervieTime,
  UpdateInterviewTime,
  allinterviewtime,
  DeleteIntervieTime,

  Add_interviewType,
  ManageInterviewtype,
  DeleteInterviewType,

  AddExperience,
  Manage_Experience,
  Delete_Experience,

  AddRegistrationFee,
  Manage_AddRegistrationFee,
  Delete_AddRegistrationFee,

  Add_WorkingTime,
  ManageWorkingTime,
  UpdateWorkingTime,
  DeleteWorkingTime,

  ManageSpareparts,
  AddSpareparts,
  Update_SpareParts,
  DeleteSpareparts,

  ManageCategoryBanner,
  DeleteCategoryBanner,
  ManageServiceBanner,
  uploadBannerImnage,
  AddHomeBanner,
  UpdateHomebanner,
  ManageHomeBanner,
  UpdateCategoryBanner,
  AddCategoryBanner,
  AddSubCategoryBanner,
  UpdateSubCategoryBanner,
  DeleteSubCategoryBanner,
  MannageSubCategoryBanner,

  MannageVendar,
  ViewUpdatedVendar,
  UpdateHomeVendar,
  viewPerticularVendar,
  UpdateVendar,
  AddVendarBanner,

  AddHomeType,
  MannageHomeType,
  DeleteHomeType,
  viewParticularCategoryHomeType,

  AddBuildingType,
  MannageBuildingType,
  DeleteBuildingType,

  AddPlaceType,
  MannagePlaceType,
  AllPlaceTypeforserviceIdandMeterialid,
  DeletePlaceType,

  AddModelType,
  MannageModelType,
  getModeltypeUsingserviceId,
  AllPlacetypeforserviceIdandMeterialid,
  UpdateModelType,
  // AddModeCost,
  // ViewModeCost,
  DeleteModelType,

  AddMeterialtype,
  MannageMeterialType,
  AllmeterialtypeforserviceIdandMeterialid,
  DeleteMeterialType,

  AddAreaIndividual,
  MannageAreaIndividual,
  UpdateAreaIndividual,
  DeleteAreaIndividual,

  AddPaintingBrands,
  MannagePaintingBrands,
  // UpdatePaintingBrands,
  DeletePaintingBrands,

  AddPaintingQuality,
  MannagePaintingQuality,

  UpdatePaintingQuality,
  DeletePaintingQuality,

  AddPaintingVersion,
  MannagePaintingVersion,
  UpdatePaintingVersion,
  DeletePaintingVersion,

  AddVendarBannerdata,
  MannageVendarBannerdata,
  UpdateVendarBannerdata,
  DeleteVendarBannerdata,

  Addinterviewques,
  Mannageinterviewques,
  Updateinterviewques,
  Deleteinterviewques,

  AddServiceDays,
  MannageServiceDays,

  DeleteServiceDays,

  AddPlaceinputtype,
  AddSelectModelinputtype,
  AddMeterialinputtype,
  AddhouseTypeinputtype,
  AddBuildingtypeinputtype,
  AddAreaindivisualinputtype,
  AddprintingQualityinputtype,
  AddprintingVersioninputtype,
  Addservicedaysinputtype,

  AddVendarquestionAnswar,
  MannagequestionAnswar,
  UpdatequestionAnswar,
  DeletequestionAnswar,

  ViewContuctus,

  AddVendarSubscription,
  MannageVendarSubscription,
  UpdateVendarSubscription,
  DeleteVendarSubscription,

  AddServicePakage,
  MannageServicePakage,
  servicepakagebyCatId,
  DeleteServicePakage,

  AdddiscountPakage,
  MannagediscountPakage,
  UpdatediscountPakage,
  DeletediscountPakage,

  addOfferserviceimage,

  MannageOfferserviceimage,
  UpdateOfferserviceimage,
  DeleteOfferserviceimage,

  addAboutService,
  MannageAboutService,

  addAboutCompany,
  MannageAboutCompany,

  addCustomerQuarys,
  MannageCustomerQuarys,

  addMinimumPrice,
  MannageMinimumPrice,

  addPaintingCost,
  MannagePaintingCost,
  UpdatePaintingCost,

  UpdateHouseType,
  UpdatebuildingType,
  UpdatePlaceType,
  UpdateAreaIndivisual,
  UpdatePaintingBrand,
  UpdatePaintingVersion,
  UpdateServiceDays,
  UpdateServicePakage,
  UpdateDiscountPakage,

  AddNoofbuildings,
  MannageNoofbuildings,
  UpdateNoofbuildings,
  DeleteNoofbuildings,

  addAMCservice,
  MannageAMCservice,
  UpdateAMCservice,
  DeleteAMCservice,

  //NewLY
  AddHouseTypeNew,
  viewHouseType,
  addHouseTypePrice,
  ViewAllHouseTypeNew,
  UpdateHouseTypeNew,
  ViewAllFloorNumberNew,
  AddFloorNumberNew,
  UpdateFloorNumberNew,
  ViewAllServicePackageNew,
  AddServicePackageNew,
  packName,
  AddServicePackagePriceNew,
  ViewAllServicePackagePriceNew,
  addCleaningCostType1,
  ServiceChargesType1,
  UpdateCleaningCostType1,
  viewHouseTypePrice,
  viewModalWisePrice,

  // model wise charge
  modelWiseCharge,
  addRangeOfWorkName,
  viewRangeOfWorkName,
  UpdateServicePackageNew,

  // state wise dis
  ManageDisStateWise, AddZip, ManageZipCode, UpdateZipCode, DeleteZip, DeleteHomeVendar
};
