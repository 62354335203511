import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";


const WorkTypePrice = () => {
    const [price, setPrice] = useState("");
    // const [flatPrice, setflatPrice] = useState("");
    // const [ModelPrice, setModelPrice] = useState("");

    const [servicePackagePrice, setservicePackagePrice] = useState("");
    const [service_id, setservice_id] = useState("");//64d602a1e6419455b67e1ed4
    const [catagory_id, setcategory_id] = useState("");
    const [workTypeID, setWorkTypeID] = useState("");

    const [inputType, setinputType] = useState("")
    console.log(service_id);
    const [allService, setallSarvice] = useState([]);
    const [allData, setAllData] = useState([]);
    const [totaldate, settotaldate] = useState([]);
    const [allWorkType, setAllWorkType] = useState([]);
    const [allHouseType, setAllHouseType] = useState([]);
    const [allRangeOfWork, setAllRangeOfWork] = useState([]);

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    useEffect(() => {
        FetchAllData();
        fetchAllSarvice();
        FetchAllWorkType();
    }, []);

    const InitialState = () => {
        setservice_id("");
        setcategory_id("")
        setHoseTypeID("")
        setWorkTypeID("")
        setMaterialType("")
        setRengeOfWorkID("")
        setMaterialType("")
        setPrice(0)
    };

    const onEdit = (item) => {
        console.log("item48",item);
        window.scrollTo(0, 0);
        setId(item?._id);
        let serviceTypeId = "64d602a1e6419455b67e1ed4"
        setservice_id(serviceTypeId);
        fetchAllCategory(serviceTypeId);
        FetchAllHouseType(serviceTypeId)
        FetchAllRangeOfWork(serviceTypeId)
        setRengeOfWorkID(item?.rangeOfworkData?._id)
        setcategory_id(item?.categoryID)
        DisplayOtherDetails(item?.categoryID)
        setHoseTypeID(item?.hoseTypeID)
        setWorkTypeID(item?.worktypeID)
        setMaterialType(item?.materialType)
        setPrice(item?.price)

        setHide(false);
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {

                HomeService.DeleteAMCservice(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            InitialState()
                            FetchAllData();
                            toast.success("Deleted Successfully");
                            // console.log("RESPONSEDELETE", res);

                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const FetchAllWorkType = () => {
        NewHomService.FetchWorkType()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setAllWorkType(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const FetchAllRangeOfWork = (id) => {
        HomeService.viewRangeOfWorkName()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data?.filter((item) => item.serviceTypeID === id);
                    // console.log(arr);
                    setAllRangeOfWork(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const FetchAllHouseType = (id) => {
        // const data={serviceCategoryID:catid}
        HomeService.viewHouseType(id)
            .then((res) => {
                console.log("AllSttttttate", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    //   let arr = res?.data;
                    setAllHouseType(res?.data);
                } else {
                    setAllHouseType([]);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllSarvice = () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data
                    setallSarvice(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const fetchAllCategory = (id) => {
        if (id !== "") {
            HomeService.CategoriesviaService_type(id)
                .then((res) => {
                    console.log("AllState", res.data);
                    if (res && res?.status) {
                        // setLoader(false)
                        let arr = res?.data;
                        settotaldate(arr);
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                });
        }
    };




    const FetchAllData = () => {
        NewHomService.FetchWorkTypePrice()
            .then((res) => {
                console.log("ResArllBlog", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        console.log(item);
                        return {
                            sl: index + 1,
                            Material: item?.materialType === true ? "Included" : "Not included",
                            Category: item?.categoryData?.name,
                            WorkType: item?.workTypeData?.type,
                            HouseType: item?.houseTypeData?.name,
                            RangeOfWork: item?.rangeOfworkData?.name,

                            Price: item?.price,


                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setAllData(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
            wrap:true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    House Type
                </div>
            ),
            selector: (row) => row.HouseType,
            wrap:true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Work Type 
                </div>
            ),
            selector: (row) => row.WorkType,
            wrap:true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Range Of Work
                </div>
            ),
            selector: (row) => row.RangeOfWork,
            wrap:true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Material
                </div>
            ),
            selector: (row) => row.Material,
            wrap:true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Price,
            wrap:true
        },
        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const AddData = () => {
        let data = {}
        if (showOtherDetails) {
            data = {
                materialType: materialType,
                categoryID: catagory_id,
                hoseTypeID: hoseTypeID,
                worktypeID: workTypeID,
                // rengeOfWorkID: rengeOfWorkID,
                price: price,
            };
        } else {
            data = {
                //materialType: materialType,
                categoryID: catagory_id,
                // hoseTypeID: hoseTypeID,
                worktypeID: workTypeID,
                //rengeOfWorkID: rengeOfWorkID,
                price: price,
            };
        }

        // materialType ? (data.rengeOfWorkID = rengeOfWorkID):(data.rengeOfWorkID = null)


        console.log("GSGS357",data);
        if (catagory_id && workTypeID && price) {
            // alert(name + country_id)
            NewHomService.AddWorkTypePrice(data)
                .then((res) => {
                    // console.log("Response Add State", res);
                    if (res && res.status) {
                        FetchAllData();
                        toast.success(res.message);

                        InitialState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {

            if (!catagory_id) {
                return toast.error("Choose a category");
            }
            // if (!workTypeID) {
            //     toast.error("Choose work type");
            // }
            if (!price) {
                return toast.error("Enter an amount");
            }
            // if (!floorPrice) {
            //     toast.error("FloorPrice Field Is Required");
            // }
            // if (!ModelPrice) {
            //     toast.error("ModelPrice Name Field Is Required");
            // }
            // if (!servicePackagePrice) {
            //     toast.error("servicePackagePrice Field Is Required");
            // }
        }
    };

    const AddInputTypedata = () => {
        let data = {
            inputType: inputType
        };
        // serviceTime: name

        if (inputType) {
            // alert(name + country_id)
            HomeService.Addservicedaysinputtype(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        FetchAllData();
                        toast.success(res.message);

                        InitialState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!inputType) {
                toast.error("Input Type Field Is Required");
            }

        }
    };


    //   UpdateAMCservice,
    //   DeleteAMCservice


    const UpdateData = () => {
        console.log("ID", id);
        let data = {}
        if (showOtherDetails) {
            data = {
                materialType: materialType,
                categoryID: catagory_id,
                hoseTypeID: hoseTypeID,
                worktypeID: workTypeID,
                rengeOfWorkID: rengeOfWorkID,
                price: price,
            };
        } else {
            data = {
                //materialType: materialType,
                categoryID: catagory_id,
                // hoseTypeID: hoseTypeID,
                worktypeID: workTypeID,
                rengeOfWorkID: rengeOfWorkID,
                price: price,
            };
        }

        console.log(data);

        NewHomService.UpdateWorkTypePrice(id, data)
            .then((res) => {
                // console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    // console.log("RESPONSEDELETE", res);
                    InitialState()
                    FetchAllData();
                } else {
                    toast.error("Error occured");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [hoseTypeID, setHoseTypeID] = useState()
    const [materialType, setMaterialType] = useState(true)
    const [rengeOfWorkID, setRengeOfWorkID] = useState("")
    const [showOtherDetails, setShowOtherDetails] = useState(false)
    const [displayRangeOfWork, setDisplayRangeOfWork] = useState(true)

    const DisplayOtherDetails = (id) => {
        if (id === "64d619768fbc5011464281d7") {
            setShowOtherDetails(true)
        } else {
            setShowOtherDetails(false)
        }
    }

    const changeRangeOfWork = (val) => {
        if(val==="true"){
            setDisplayRangeOfWork(true)
        }else{
            setDisplayRangeOfWork(false)
        }
        // setDisplayRangeOfWork(val)
        console.log("493:", val);
    }

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Work Type Price
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Update Work Type Price
                            </div>
                        )}

                        <div class="form-group"
                        // style={{ display: id ? "none" : "block" }}
                        >
                            <label for="exampleInputEmail1">
                                Service Type {" "}
                                :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_id(e.target.value);
                                    fetchAllCategory(e.target.value);
                                    FetchAllHouseType(e.target.value)
                                    FetchAllRangeOfWork(e.target.value)
                                }}
                            >
                                <option value="">Select</option>
                                {allService?.map((ele, id) => {
                                    // console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == service_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group"
                        // style={{ display: id ? "none" : "block" }}
                        >
                            <label for="exampleInputEmail1">
                                Category Name <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select

                                onChange={(e) => {
                                    setcategory_id(e.target.value);
                                    DisplayOtherDetails(e.target.value)

                                }}
                            >
                                <option value="">Select</option>
                                {totaldate?.map((ele, id) => {
                                    // console.log(ele);
                                    return (
                                        <option
                                            selected={ele._id == catagory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                            {
                                service_id === "" && (<>
                                    <small id="emailHelp" class="form-text">Choose a service type.</small>
                                </>)
                            }
                        </div>

                        <div className="row">

                            {/* Work Type */}
                            <div class="form-group col-6"
                            // style={{ display: id ? "none" : "block" }}
                            >
                                <label for="exampleInputEmail1">
                                    Work Type <span style={{ color: "red" }}>*</span> :
                                </label>
                                <select onChange={(e) => setWorkTypeID(e.target.value)}>
                                    <option value="">Select</option>
                                    {allWorkType?.map((ele, id) => {
                                        console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == workTypeID ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele?.type}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            {/* House Type */}
                            <div class="form-group col-6"
                                style={{ display: showOtherDetails ? "block" : "none" }}
                            >
                                <label for="exampleInputEmail1">
                                    House Type:
                                </label>
                                <select
                                    onChange={(e) => {
                                        setHoseTypeID(e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {allHouseType?.map((ele, id) => {
                                        // console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == hoseTypeID ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele?.name}
                                            </option>
                                        );
                                    })}
                                </select>
                                {
                                    service_id === "" && (<>
                                        <small id="emailHelp" class="form-text">Choose a service type.</small>
                                    </>)
                                }

                            </div>
                        </div>




                        <div className="row">

                            {/* Material */}
                            <div class="form-group col-6"
                                style={{ display: showOtherDetails ? "block" : "none" }}
                            >
                                <label for="exampleInputEmail1">
                                    Material :
                                </label>
                                <select
                                    onChange={(e) => {
                                        setMaterialType(e.target.value);
                                        changeRangeOfWork(e.target.value)
                                    }}
                                >
                                    <option value=""
                                    selected={materialType==="" ? true : false}
                                    >Select</option>

                                    <option
                                        selected={materialType ? true : false}
                                        value={true}

                                    >
                                        With material
                                    </option>
                                    <option
                                        selected={materialType ? false : true}
                                        value={false}
                                    >
                                        Without material
                                    </option>

                                </select>
                            </div>


                            {/* Range Of Work */}
                            <div class="form-group col-6"
                                style={{ display: displayRangeOfWork ? "block" : "none" }}
                            >

                                <label for="exampleInputEmail1">
                                    Range Of Work:
                                </label>
                                <select
                                    onChange={(e) => {
                                        setRengeOfWorkID(e.target.value);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {allRangeOfWork?.map((ele, id) => {
                                        // console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == rengeOfWorkID ? true : false}
                                                value={ele._id}
                                                key={ele._id}
                                            >
                                                {ele?.name}
                                            </option>
                                        );
                                    })}
                                </select>



                                {
                                    service_id === "" && (<>
                                        <small id="emailHelp" class="form-text">Choose a service type.</small>
                                    </>)
                                }

                            </div>






                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Rate per Sq.Ft. <span style={{ color: "red" }}>*</span> :
                            </label>
                            <input
                                type="number"
                                class="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>



                        {hide ? (
                            <button class="btn btn-primary" onClick={AddData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={UpdateData}>
                                Update
                            </button>
                        )}



                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Work Type Price
                        </div>
                        <DataTable columns={columns} data={allData} pagination />
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkTypePrice;

