import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link, useParams } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UserTermsAndConditions = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allState, setAllState] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    // getAllCountry();
    FetchData();
  }, []);

  const FetchData = async () => {
    // const res = await NewHomService.FetchData();
    const res = await NewHomService.FetchTermsAndConditions();

    if (res && res?.status) {
      // setLoader(false)

      const pageData = res?.data?.filter((item) => item.Type === "user");
      console.log("DATA", pageData);
      setTitle(pageData[0]?.title);
      setDescription(pageData[0]?.description);
    }
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);

    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      // console.log(IMAMGE_URL + res?.data);
      console.log("Image Res", res);
      // setimgdata(res?.doc?.url);

      // setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const manageData = () => {
    let data = {
      Type: "user",
      title: title,
      description: description,
      // image: image
    };

    if (title && description) {
      console.log();
      NewHomService.UpdateTermsAndConditions(data)
        .then((res) => {
          // console.log("Response Add Blog Category", res);
          if (res && res.status) {
            toast.success(res.message);
            FetchData();
          } else {
            toast.error(res.message);
            // toast.error("L195")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!title) {
        toast.error("Heading is mandatory");
      }
      if (!description) {
        toast.error("Description is mandatory");
      }
    }
  };

  // const UpdateCountryCategory = () => {
  //     console.log("ID", id);
  //     let data = {

  //         title: title,
  //         description: description,
  //         imageurl: image

  //     }
  //     HomeService.UpdateOfferserviceimage(id, data)
  //         .then((res) => {
  //             console.log("updated res", res);
  //             if (res && res.status) {
  //                 toast.success("Updated Successfully");
  //                 setHide(true);
  //                 console.log("RESPONSEDELETE", res);
  //                 setTitle("");
  //                 //   getAllCountry();
  //             } else {
  //                 toast.error("Blog Category Name Already Exist");
  //             }
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         });
  // };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              User Terms And Conditions
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Section Heading <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Section Content <span style={{ color: "red" }}>*</span> :
              </label>

              {/* <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="7"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea> */}

              <div style={{ marginBottom: "21px" }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    // placeholder : "Enter content here",
                    toolbar: [
                      "undo",
                      "redo",
                      "bold",
                      "blockQuote",
                      "heading",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "insertTable",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                    ],
                  }}
                  data={description}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    let data = editor.getData();

                    setDescription(data);
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </div>

            
            <button class="btn btn-primary" onClick={manageData}>
              Update
            </button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default UserTermsAndConditions;
