import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";

const ManageReferral = () => {
  const [userRefereerAmount, setUserRefereerAmount] = useState(0);
  const [userReferalAmount, setUserReferalAmount] = useState(0);

  const [vendorRefereerAmount, setVendorRefereerAmount] = useState(0);
  const [vendorReferalAmount, setVendorReferalAmount] = useState(0);

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allState, setAllState] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    FetchData();
  }, []);

  const FetchData = async () => {
    // const res = await NewHomService.FetchData();
    const res = await NewHomService.FetchReferralAmount();
    console.log("resss", res);
    if (res) {
      console.log("resss", res);
      setUserRefereerAmount(res.data[0]?.userReferralAmountFrom);
      setUserReferalAmount(res.data[0]?.userReferralAmountTo);
      setVendorRefereerAmount(res.data[0]?.vendorRerReferralAmountFrom);
      setVendorReferalAmount(res.data[0]?.vendorRerReferralAmountTo);
      setId(res.data[0]?._id);
    }
  };

  const manageData = (e,id) => {
    e.preventDefault();
    let data = {
      userReferralAmountTo: parseInt(userReferalAmount),
      userReferralAmountFrom: parseInt(userRefereerAmount),
      vendorRerReferralAmountTo: parseInt(vendorReferalAmount),
      vendorRerReferralAmountFrom: parseInt(vendorRefereerAmount),
    };

    if (
      userRefereerAmount &&
      userReferalAmount &&
      vendorRefereerAmount &&
      vendorReferalAmount
    ) {
      NewHomService.ManageReferralAmount(id, data)
        .then((res) => {
          // console.log("Response Add Blog Category", res);
          if (res && res.status) {
            toast.success(res.msg);
            // toast.error("Settings saved successfully")
            FetchData();
          } else {
            toast.error(res.message);
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!userRefereerAmount) {
        return toast.error("Refereer Amount for user is mandatory");
      }
      if (!userReferalAmount) {
        return toast.error("Referal Amount for user is mandatory");
      }
      if (!vendorRefereerAmount) {
        return toast.error("Refereer Amount for vendor is mandatory");
      }
      if (!vendorReferalAmount) {
        return toast.error("Referal Amount for vendor is mandatory");
      }
    }
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Referral Settings
            </div>

            <div class="container border py-2 mb-2">
              <h2>User</h2>
              <div className="row">
                <div class="form-group col-6">
                  <label for="exampleInputEmail1">
                    Refereer Incentive Amount{" "}
                    <span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={userRefereerAmount}
                    onChange={(e) => setUserRefereerAmount(e.target.value)}
                    placeholder="0"
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputEmail1">
                    Referal Incentive Amount{" "}
                    <span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={userReferalAmount}
                    onChange={(e) => setUserReferalAmount(e.target.value)}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>

            <div class="container border py-2 mb-2">
              <h2>Vendor</h2>

              <div className="row">
                <div class="form-group col-6">
                  <label for="exampleInputEmail1">
                    Refereer Incentive Amount{" "}
                    <span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={vendorRefereerAmount}
                    onChange={(e) => setVendorRefereerAmount(e.target.value)}
                    placeholder="0"
                  />
                </div>
                <div class="form-group col-6">
                  <label for="exampleInputEmail1">
                    Referal Incentive Amount{" "}
                    <span style={{ color: "red" }}>*</span> :
                  </label>
                  <input
                    type="number"
                    min={0}
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={vendorReferalAmount}
                    onChange={(e) => setVendorReferalAmount(e.target.value)}
                    placeholder="0"
                  />
                </div>
              </div>
            </div>

            <button
              class="btn btn-primary"
              onClick={(e) => manageData(e, "6596963bc36b4e9aac29dc86")}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageReferral;
