import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import moment, { isMoment } from "moment";
import Modal from "react-modal";
import Loader from "../../Component/Loader";
import { CSVLink } from "react-csv";

const FinanceDomesticServices = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [countryIcon, setcountryIcon] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allData, setAllData] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [replyToAppId, setReplyToAppId] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [replyToName, setReplyToName] = useState("");
  const [replyToMessage, setReplyToMessage] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  // console.log("filteredData", filteredData);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);

  const [category_id, setcategory_id] = useState("");
  const [totalCategory, settotalCategory] = useState([]);
  const [searchDate, setSearchDate] = useState("");
  const [searchMonth, setSearchMonth] = useState("");
  const [searchYear, setSearchYear] = useState("");

  const totalDates = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  const totalMonths = [
    {
      name: "January",
      number: 1,
    },
    {
      name: "February",
      number: 2,
    },
    {
      name: "March",
      number: 3,
    },
    {
      name: "April",
      number: 4,
    },
    {
      name: "May",
      number: 5,
    },
    {
      name: "June",
      number: 6,
    },
    {
      name: "July",
      number: 7,
    },
    {
      name: "August",
      number: 8,
    },
    {
      name: "September",
      number: 9,
    },
    {
      name: "October",
      number: 10,
    },
    {
      name: "November",
      number: 11,
    },
    {
      name: "December",
      number: 12,
    },
  ];
  const totalYears = [2021, 2022, 2023, 2024];
  const [totalRate, setTotalRate] = useState(0);
  console.log("totalRate", totalRate);
  const [totalCommissionEarned, setTotalCommissionEarned] = useState(0);

  useEffect(() => {
    getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
    fetchAllData();
  }, []);

  useEffect(() => {
    getTotalNetRate();
    getTotalCommissionEarned();
  }, [filteredData]);

  const getAllCategoryinService_type = async (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("ResAllBlog", res.data);

        if (res && res?.status) {
          // setLoader(false)
          console.log(res?.data);
          settotalCategory(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("item", item);
    setTitle(item?.title);
    setDescription(item?.description);
    setimgdata(item?.image);
    setimage(item?.image);
    setId(item?._id);
    setHide(false);
  };

  const payToVendor=()=>{
    
  }

  const fetchAllData = () => {
    setLoader(true);
    NewHomService.FetchFinanceDomestic()
      .then((res) => {
        console.log("ALLDATA", res.data);
        if (res && res?.status) {
          setLoader(false);

          let arr = res?.data
            // ?.sort()
            // .reverse()
            .map((item, index) => {
              console.log("REST", item);
              return {
                sl: index + 1,
                Record: item,
                // Name: item?.name,
                // Mobile: item?.mobileNo,
                // Email: item?.email,
                // Image: item?.img,
                // WhatsAppNumber: item?.whatsAppNumber,
                // WhatsAppVarification: item?.whatsAppVarification,
                // ReferralCode: item?.referralCode,

                // State: item?.stateName,
                // City: item?.cityName,
                // Area: item?.areaName,
                // Language: item?.language,

                // RegistrationDate: item?.createdOn,

                action: (
                  <div
                    style={{ display: "flex", flexDirection: "coloum" }}
                    className="my-2"
                  >
                    <button
                      className="btn btn-sm btn-success"
                      // onClick={(e) => openModal(item?._id, item?.email, item?.name)}
                      onClick={(e)=>payToVendor(e,item?.categoryId,item?.serviceDetail)}
                    >
                      Pay
                      <br />
                      <span style={{ fontSize: "80%", fontWeight: "normal" }}>
                        To Vendor
                      </span>
                    </button>
                  </div>
                ),
              };
            });
          setFilteredData(arr.reverse());
          setAllData(arr.reverse());
          fillCSVData(arr.reverse());
        } else {
          setLoader(false);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        NewHomService.DeleteTestimonials(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllData();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res?.doc?.url);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      console.log(res?.doc?.url);
      setimgdata(res?.doc?.url);
      setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const addData = () => {
    let data = {
      title: title,
      description: description,
      image: imgdata,
    };

    if (title && description) {
      // alert(name)
      NewHomService.AddTestimonials(data)
        .then((res) => {
          // console.log("Response", res);
          if (res && res.status) {
            fetchAllData();
            toast.success(res.message);
            // setHide(true);
            // console.log("ADDDATA", res);
            setTitle("");
            setDescription("");
            setimgdata("");
            setimage("");
            let file = document.querySelector("#images");
            file.value = "";
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!title) toast.error("Title Field Is Required");
      // if(!imgdata)
      // toast.error("Country icon Field Is Required");
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      width: "50px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Booking Date
        </div>
      ),
      selector: (row) => {
        return <>{moment(row.Record?.booking_date).format("DD-MM-YYYY")}</>;
      },
      sortable: true,
      width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Booking ID
        </div>
      ),
      selector: (row) => row.Record?.order_id,
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.Record?.categoryDetail?.name,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {row.Record?.serviceDetail.map((item, key) => {
              return (
                <>
                  Sub Category: {item?.serviceFullDetail.subCategoryName}
                  <br />
                  Service Name: {item?.serviceName}
                </>
              );
            })}
          </>
        );
      },
      width: "250px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Customer
        </div>
      ),
      selector: (row) => {
        return (
          <>
            <strong>{row.Record.userDetail.name}</strong>
            <br />
            Mobile: {row.Record.userDetail.mobileNo}
          </>
        );
      },
      wrap: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Vendor Name
        </div>
      ),
      selector: (row) => row.Record?.workAssign?.vendorDetail?.name?row.Record?.workAssign?.vendorDetail?.name:"n/a",
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Paid by User
        </div>
      ),
      selector: (row) => row.Record?.total.toFixed(0),
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Commission
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {
              row.Record?.workAssign?.vendorDetail?.commissionDetail
                ?.commition_percentage?row.Record?.workAssign?.vendorDetail?.commissionDetail
                ?.commition_percentage:0
            }
            %
          </>
        );
      },
      wrap: true,
      width: "125px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Subscription Commission
          <br />
          <span style={{ fontSize: "80%", fontWeight: "normal" }}>
            (for Vendor)
          </span>
        </div>
      ),
      selector: (row) => {
        return (
          <>
            {row.Record?.workAssign?.vendorSubscription ? (
              <>
                <strong>
                  {row.Record?.workAssign?.vendorSubscription?.details}
                </strong>
                &nbsp;(
                {
                  row.Record?.workAssign?.vendorSubscription?.subscriptionDetail
                  ?.commission_percntage?row.Record?.workAssign?.vendorSubscription?.subscriptionDetail
                  ?.commission_percntage:0
                }
                % )
              </>
            ) : (
              <>n/a</>
            )}
          </>
        );
      },
      wrap: true,
      width: "125px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Net Rate
          <br />
          <span style={{ fontSize: "80%", fontWeight: "normal" }}>
            (after deducting commission)
          </span>
        </div>
      ),
      selector: (row) => {
        return row.Record?.netAmountToVendorAfterSubscriptionAdded
          ? row.Record?.netAmountToVendorAfterSubscriptionAdded?.toFixed(0)
          : "00";
      },
      sortable: true,
      width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Payment Status
          <br />
          <span style={{ fontSize: "80%", fontWeight: "normal" }}>
            (to Vendor)
          </span>
        </div>
      ),
      selector: (row) => "n/a",
      wrap: true,
      width: "100px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Date Of Payment
        </div>
      ),
      selector: (row) => "n/a",
      sortable: true,
      width: "170px",
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      width: "100px",
    },
  ];

  const updateData = () => {
    console.log("ID", id);
    let data = {
      title: title,
      description: description,
      image: imgdata,
    };
    if (title) {
      NewHomService.UpdateTestimonials(id, data)
        .then((res) => {
          console.log("updated res", res);
          if (res && res.status) {
            toast.success("Updated Successfully");
            setHide(true);
            console.log("RESPONSEDELETE", res);
            setTitle("");
            setDescription("");
            setimgdata("");
            setimage("");
            let file = document.querySelector("#images");
            file.value = "";
            fetchAllData();
          } else {
            toast.error("Title Already Exist");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Title is requred");
    }
  };

  const resetState = () => {
    setReplyToAppId("");
    setReplyTo("");
    setReplyToName("");
    setReplyToMessage("");
  };

  const openModal = (appId, email, name) => {
    setIsOpen(true);
    setReplyToAppId(appId);
    setReplyTo(email);
    setReplyToName(name);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    resetState();
    setIsOpen(false);
  };

  const sendReply = () => {
    // setIsOpen(false);
    const data = {
      queriesId: replyToAppId,
      customer_email: replyTo,
      customer_name: replyToName,
      text: replyToMessage,
    };
    toast.success("Sending email...");
    replyToMessage !== ""
      ? NewHomService.SendReplyToCustomerQueries(data)
          .then((res) => {
            // console.log("updated res", res);

            if (res && res.status) {
              setIsOpen(false);
              Swal.fire(`Reply sent to ${data.customer_name} successfully`);
              resetState();
            } else {
              toast.error("Unable to send reply. Try again!");
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Message should not be empty");
  };

  const onFilter = () => {
    let searchData = allData;
    console.log("ALLSDATA", allData);
    //Category
    if (category_id !== "") {
      searchData = searchData.filter((item) => {
        if (item?.Record.categoryId.includes(category_id)) {
          return item;
        }
      });
      setFilteredData(searchData.reverse());
    }
    //Vendor name
    if (searchText !== "") {
      searchData = searchData.filter((item) => {
        if (
          item?.Record.workAssign.vendorDetail.name
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return item;
        }
      });
      setFilteredData(searchData.reverse());
      fillCSVData(searchData.reverse());
    }
    //Year
    if (searchYear !== "") {
      searchData = searchData.filter((item) => {
        if (
          moment(item?.Record.booking_date).format("YYYY").includes(searchYear)
        ) {
          return item;
        }
      });
      setFilteredData(searchData.reverse());
      fillCSVData(searchData.reverse());
    }
    //Month
    if (searchMonth && !searchYear) {
      return toast.error("Select Year");
    }
    if (searchMonth && searchYear) {
      let srcDT = `${searchMonth}-${searchYear}`;
      console.log("SMONTH", srcDT);
      searchData = searchData.filter((item) => {
        if (
          moment(item?.Record.booking_date).format("M-YYYY").includes(srcDT)
        ) {
          return item;
        }
      });
      setFilteredData(searchData.reverse());
      fillCSVData(searchData.reverse());
    }
    //Date
    if (searchDate && (!searchMonth || !searchYear)) {
      return toast.error("Select Month & Year");
    }
    if (searchDate && searchMonth && searchYear) {
      let srcDT = `${searchDate}-${searchMonth}-${searchYear}`;
      // console.log("SDATE", srcDT);
      searchData = searchData.filter((item) => {
        if (
          moment(item?.Record.booking_date).format("D-M-YYYY").includes(srcDT)
        ) {
          return item;
        }
      });
      setFilteredData(searchData.reverse());
      fillCSVData(searchData.reverse());
    }
    // fillCSVData(searchData)
    getTotalNetRate();
    getTotalCommissionEarned();
  };

  const viewAllData = () => {
    setSearchText("");
    setFilteredData(allData.reverse());
    fillCSVData(allData.reverse());
  };

  const getTotalNetRate = () => {
    console.log("DDD", filteredData, allData);
    let rate = 0;
    if (filteredData?.length > 0) {
      rate = filteredData?.reduce((total, item) => {
        return (
          total +
          parseFloat(item.Record?.netAmountToVendorAfterSubscriptionAdded)
        );
      }, 0);
    }
    setTotalRate(rate);
  };

  const getTotalCommissionEarned = () => {
    console.log("CCC", filteredData);
    let rate = 0;
    if (filteredData.length > 0) {
      rate = filteredData.reduce((total, item) => {
        return total + parseFloat(item.Record?.profitAmountToAdmin);
      }, 0);
    }
    setTotalCommissionEarned(rate);
  };

  ////CSV Download//////////////

  const [csvData, setCsvData] = useState([]);

  const fillCSVData = (data) => {
    console.log("DATACSVS", data);
    let arrCSV = data?.map((item, index) => {
      return {
        SLNO: index + 1,
        BookingDate: moment(item?.Record?.booking_date)?.format("DD-MM-YYYY"),
        BookingID: item?.Record?.order_id,
        Customer: item?.Record?.userDetail?.name,
        CustomerMobile: item?.Record?.userDetail?.mobileNo,
        Vendor: item?.Record?.workAssign?.vendorDetail?.name
          ? item?.Record?.workAssign?.vendorDetail?.name
          : "n/a",
        PaidByCustomer: item?.Record?.total?.toFixed(2),
        Commission: `${
          item?.Record?.workAssign?.vendorDetail?.commissionDetail
            ?.commition_percentage
            ? item?.Record?.workAssign?.vendorDetail?.commissionDetail
                ?.commition_percentage
            : 0
        }%`,
        SubscriptionCommissionForVendor: `${
          item?.Record?.workAssign?.vendorSubscription?.subscriptionDetail
            ?.commission_percntage
            ? item?.Record?.workAssign?.vendorSubscription?.subscriptionDetail
                ?.commission_percntage
            : 0
        }%`,
        NetRate: item?.Record?.netAmountToVendorAfterSubscriptionAdded?item?.Record?.netAmountToVendorAfterSubscriptionAdded.toFixed(2):0,
        Status: item?.Record?.bookingStatus,
      };
    });
    console.log("DRESE", arrCSV);
    setCsvData(arrCSV);
  };

  ////CSV Download//////////////

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Domestic Service(s)
            </div>

            {/* <div class="form-inline my-2">
                <select
                  onChange={(e) => {
                    setcategory_id(e.target.value);
                    // getAllSubCategory(e.target.value);
                  }}
                >
                  <option value="">Choose Category</option>
                  {totalCategory?.map((ele, id) => {
                    return (
                      <option
                        selected={ele._id == category_id ? true : false}
                        value={ele._id}
                        key={ele._id}
                      >
                        {ele.name}
                      </option>
                    );
                  })}
                </select>
              </div> */}

            <div class="form-inline my-2">
              <select
                class="form-control mr-sm-2"
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  // getAllSubCategory(e.target.value);
                }}
              >
                <option value="">Choose Category</option>
                {totalCategory?.map((ele, id) => {
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>

              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Vendor name"
                aria-label="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />

              <select
                class="form-control mr-sm-2"
                onChange={(e) => {
                  setSearchDate(e.target.value);
                }}
              >
                <option value="">--Date--</option>
                {totalDates?.map((ele, id) => {
                  return (
                    <option
                      selected={ele == searchDate ? true : false}
                      value={ele}
                      key={ele}
                    >
                      {ele}
                    </option>
                  );
                })}
              </select>

              <select
                class="form-control mr-sm-2"
                onChange={(e) => {
                  setSearchMonth(e.target.value);
                }}
              >
                <option value="">--Month--</option>
                {totalMonths?.map((ele, id) => {
                  return (
                    <option
                      selected={ele.number == searchMonth ? true : false}
                      value={ele.number}
                      key={ele.number}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>

              <select
                class="form-control mr-sm-2"
                onChange={(e) => {
                  setSearchYear(e.target.value);
                }}
              >
                <option value="">--Year--</option>
                {totalYears?.map((ele, id) => {
                  return (
                    <option
                      selected={ele == searchYear ? true : false}
                      value={ele}
                      key={ele}
                    >
                      {ele}
                    </option>
                  );
                })}
              </select>

              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>

            {loader ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <ul class="list-group mb-2 col-4">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Total Net Rate
                    <span class="badge badge-primary badge-pill">
                      {totalRate ? totalRate.toFixed(2) : "0.00"}
                    </span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Total commission earned
                    <span class="badge badge-primary badge-pill">
                      {totalCommissionEarned
                        ? totalCommissionEarned.toFixed(2)
                        : "0.00"}
                    </span>
                  </li>
                </ul>

                <CSVLink
                  className="btn btn-sm btn-info"
                  filename={`domestic-finance-${moment().format(
                    "YYYYMMDD"
                  )}.csv`}
                  data={csvData}
                >
                  Export to CSV
                </CSVLink>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  noDataComponent="No order(s) found!"
                />
              </>
            )}

            {/* <DataTable columns={columns} data={allData} pagination /> */}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      >
        <form>
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Reply to: {replyToName}{" "}
                  <span style={{ fontSize: "80%" }}>({replyTo})</span>
                </h5>
                <button
                  type="button"
                  class="close"
                  onClick={closeModal}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      Message:
                    </label>
                    <textarea
                      class="form-control"
                      style={{ height: "100px" }}
                      id="message-text"
                      onChange={(e) => setReplyToMessage(e.target.value)}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button> */}
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  onClick={sendReply}
                >
                  Send Reply
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FinanceDomesticServices;
