import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
// import { Link } from "react-router-dom";
import HomeService from "../../../Service/HomeService";

const ManageServicePackage = () => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("")
  //   const [service_id, setservice_id] = useState("64d602b2e6419455b67e1ed8");
  // const [allService, setallSarvice] = useState([]);
  const [allData, setAllData] = useState([]);



  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    FetchAllData();
    fetchAllSarvice();
  });

  const onEdit = (item) => {
    // fetchAllCategory(item?.serviceTypeID);
    window.scrollTo(0, 0);
    console.log("L29:", item);
    setName(item?.pack);
    setTitle(item?.name)
    setId(item?._id);
    // setservice_id(item?.serviceTypeID);
    setHide(false);
  };

  const resetState = () => {
    setName("");
    setTitle("")
    // setservice_id("");
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.DeleteHomeType(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              FetchAllData();
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const fetchAllSarvice = () => {
    HomeService.ManageService()
      .then((res) => {
        // console.log("AllState", res.data);
        if (res && res?.status) {
          let arr = res?.data;
          // setallSarvice(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllCategory = (id) => {
    HomeService.CategoriesviaService_type(id)
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          let arr = res?.data;
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const FetchAllData = () => {
    HomeService.ViewAllServicePackageNew()
      .then((res) => {
        if (res && res?.status) {
          let arr = res?.data?.filter((item) => item.pack != "renew")
            .sort().reverse()
            .map((item, index) => {
              return {
                sl: index + 1,
                //   sl: item?._id,
                package: item?.pack,
                name: item?.name,
                //   serviceName: item?.serviceTypeName,

                //   CategoryName: (
                //     <div>
                //       <Link
                //         style={{ margin: "0" }}
                //         to=
                //         {`/AddHouseType/${item?.categoryData?.serviceTypeID}
                //         /${item?.categoryData?._id}`}
                //       >
                //         {item?.categoryData?.name}
                //       </Link>
                //     </div>
                //   ),


                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const AddData = () => {
    let data = {
      //   serviceTypeID: service_id,
      pack: name,
      name: title
    };

    if (name) {
      HomeService.AddServicePackageNew(data)
        .then((res) => {
          console.log("L180:", res);
          if (res && res.status) {
            FetchAllData();
            toast.success(res.message);

            resetState();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {

      if (!name) {
        toast.error("Pack Is Required");
      }
      //   if (!service_id) {
      //     toast.error("Service Type Field Is Required");
      //   }
    }
  };


  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Package
        </div>
      ),
      selector: (row) => row.package,
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Name
        </div>
      ),
      selector: (row) => row.name,
    },
    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
    },
  ];


  const UpdateData = () => {
    let data = {
      // serviceTypeID: service_id,
      pack: name,
      name: title,
    };
    HomeService.UpdateServicePackageNew(id, data)
      .then((res) => {
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          resetState();
          FetchAllData();
        } else {
          toast.error("Error occured!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [errNum, setErrNum] = useState()

  const checkNumber = (e) => {

    e.target.value < 0 ? (toast.error("Invalid number of month")) : (
      setName(e.target.value)
    )
  }

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Service Package
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Update Service Package
              </div>
            )}

            {/* <div class="form-group" style={{ display: hide ? "block" : "none" }}>
              <label for="exampleInputEmail1">
                ServiceType<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setservice_id(e.target.value);
                  fetchAllCategory(e.target.value);
                }}
              >
                <option value="">Select</option>
                {allService?.map((ele, id) => {

                  return (
                    <option
                      selected={ele._id == service_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.serviceName}
                    </option>
                  );
                })}
              </select>
            </div> */}



            <div class="form-group">
              <label for="exampleInputEmail1">
                Pack<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="number"
                min={0}
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => checkNumber(e)}
              />
              {errNum}
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Name<span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>




            {hide ? (
              <button class="btn btn-primary" onClick={AddData}>
                Add
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateData}>
                Update
              </button>
            )}




            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Service Package
            </div>

            <DataTable columns={columns} data={allData} pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageServicePackage;
