import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "../../Component/Loader";

const AddSlotTime = () => {
  const [time, setTime] = useState("");
  console.log("nnn", time)
  const [category_id, setcategory_id] = useState("");
  const [date_id, setdate_id] = useState("");

  console.log(category_id);
  const [allService, setallSarvice] = useState([]);
  const [allCity, setAllCity] = useState([]);
  const [totaldate, settotaldate] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [loader, setLoader] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("")
  const [searchDate, setSearchDate] = useState("")

  console.log(filteredData, "filteredData")
  console.log(category_id, "category_id")
  console.log(allCity, "allCity")

  useEffect(()=>{
    if (category_id) {
      const data = allCity.filter((item)=> item?.category_id==category_id)
      setFilteredData(data)
    }

  },[category_id])

  useEffect(() => {
    FetchAllServiceType();
    fetchAllSarvice();
  }, []);

  const onEdit = (item) => {
    fetchAllDate(item.category_id)
    window.scrollTo(0, 0);
    console.log("item11", item);
    setId(item?._id);
    setcategory_id(item.category_id);

    setdate_id(item?.service_dateid)
    // setTime(item?.serviceTime)
    setTime(moment(item?.serviceTime, 'HH:mm:ss A').format('HH:mm'));
    console.log("time", item?.serviceTime);
    // setTime(moment(item?.serviceTime).format("H:MM A"))
    setHide(false);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        HomeService.Delete_Servicetime(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              FetchAllServiceType();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };




  const handleTimeChange = (e) => {
    const inputTime = e.target.value;

    // Parse the input time as a Date object
    const date = new Date(`1970-01-01T${inputTime}`);

    // Format the time in 12-hour format with "AM" or "PM"
    const formattedTime = date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
    console.log("tvall", formattedTime)
    setTime(formattedTime);
  };


  const fetchAllSarvice = () => {
    // HomeService.ManageCategory()
    HomeService.CategoriesviaService_type("64d60270203bf4c1d37b6a1e")
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          setallSarvice(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllDate = (id) => {
    HomeService.Allsevicedate_category(id)
      .then((res) => {
        console.log("AllState", res.data);
        if (res && res?.status) {
          // setLoader(false)
          let arr = res?.data;
          settotaldate(arr);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const filterdate = (date) => {
    const dateString = date;
    const extractedDate = dateString.split("T")[0];
    const formattedDate = new Date(extractedDate).toISOString().split("T")[0];
    console.log("formattedDate", formattedDate);
    return formattedDate;
  };


  const FetchAllServiceType = () => {
    HomeService.Manage_ServiceTime()
      .then((res) => {
        console.log("ResAllBlog", res?.data);
        let arr = [];
        if (res && res.status && Array.isArray(res.data)) {
          arr = res?.data.reverse().map((item, index) => {
            return {
              sl: index + 1,
              Name: item?.category_data[0]?.name, // Adjusted this line to get the category name from category_data
              serviceTime: item?.serviceTime,
              category_id:item?.category_id,
              createdOn: filterdate(item?.createdOn), // Assuming filterdate is a function that formats the date
              Category: item?.category_data[0]?.name,
              action: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <svg
                    onClick={() => onEdit(item)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                      marginRight: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pencil-square"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fillRule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                  <svg
                    onClick={() => onDelete(item?._id)}
                    style={{
                      color: "red",
                      height: "20px",
                      cursor: "pointer",
                      width: "20px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                  </svg>
                </div>
              ),
            };
          });

          setFilteredData(arr);
          setAllCity(arr);
          console.log(arr, "sddgd994");
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };


  const AddCityCategory = () => {
    let data = {
      serviceTime: time,
      category_id: category_id,
      service_dateid: date_id,
      // serviceTime: name
    };

    if (time && category_id && date_id) {
      // alert(name + country_id)
      HomeService.add_ServiceTime(data)
        .then((res) => {
          console.log("Response Add State", res);
          if (res && res.status) {
            toast.success(res.message);
            FetchAllServiceType();
            setTime("");
            setcategory_id("");
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!time) {
        toast.error("Service Time Field Is Required");
      }
      if (!category_id) {
        toast.error("Category Name Field Is Required");
      }
      if (!date_id) {
        toast.error("Date Field Is Required");
      }
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      wrap: true,
      center: true,
      width: "75px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Category
        </div>
      ),
      selector: (row) => row.Category,
      wrap: true,
      center: true,
      // width: "125px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Created On
        </div>
      ),
      selector: (row) => { return moment(row.createdOn).format("DD-MM-YYYY") },
      wrap: true,
      center: true,
      width: "125px"
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Service Time
        </div>
      ),
      // selector: (row) => row.serviceTime,
      selector: (row) =>
        moment(row.serviceTime, 'HH:mm').format('hh:mm A'),
      wrap: true,
      center: true,
      width: "125px"
    },

    {
      name: (
        <div
          style={{
            fontSize: "14px",
            color: "#495057",
            marginLeft: "15px",
            fontWeight: "bolder",
          }}
        >
          Action
        </div>
      ),
      selector: (row) => row.action,
      wrap: true,
      center: true,
      width: "125px"
    },
  ];

  const UpdateServiceTime = () => {
    console.log("ID", id);
    let data = {
      serviceTime: time,
      category_id: category_id,
      service_dateid: date_id,
    };
    HomeService.Update_ServiceTime(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          setHide(true);
          console.log("RESPONSEDELETE", res);
          setTime("");
          setcategory_id("");
          FetchAllServiceType();
        } else {
          toast.error("Blog Category Name Already Exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFilter = () => {
    let searchData = allCity;
    //Order Status
    console.log("GSGS482", searchData);
    if (searchCategory) {
      searchData = searchData.filter(item => {
        if (item?.Category?.toLowerCase()?.includes(searchCategory.toLowerCase())) {
          return item;
        }
      });
      setFilteredData(searchData);
    }
    //Order Date
    if (searchDate) {
      searchData = searchData.filter((item) => {
        let itemDate = new Date(item?.createdOn)
        let frmDt = new Date(searchDate)
        if (
          // itemDate.getDate() == frmDt.getDate()
          moment(itemDate).format("YYYY-MM-DD") == moment(frmDt).format("YYYY-MM-DD")
        ) {

          return item;
        }
      });
      setFilteredData(searchData);
    }



  }

  const viewAllData = () => {
    // setSearchText("")
    setSearchCategory("")
    setSearchDate(new Date())
    setFilteredData(allCity)
    // fillCSVData(allCity)
  }

  const onCustomFilter = (c_id, i_dt) => {
    setLoader(true)
    // console.log("GRE386",c_id, i_dt);
    HomeService.Manage_ServiceTime()
      .then((res) => {
        // console.log("SERE862", res.data);
        if (res && res?.status) {
          setLoader(false)
          let arr = []
          if (c_id && i_dt) {
            arr = res?.data?.filter((item) => {
              console.log("SER394", item, c_id);
              if (
                item?.category_id
                  .includes(c_id)
              ) {
                return item;
              }
            }).filter((item) => {
              if (
                item?.service_dateid
                  .includes(i_dt)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  Name: item?.name,
                  serviceTime: item?.serviceTime,
                  createdOn: filterdate(item?.servicedate_data[0].servicedate),
                  Category: item?.category_data[0]?.name,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          }
          else if (c_id) {
            // console.log("DATADATE375",res?.data);
            arr = res?.data?.filter((item) => {
              if (
                item?.category_id
                  .includes(c_id)
              ) {
                return item;
              }
            })
              .sort().reverse().map((item, index) => {
                return {
                  sl: index + 1,
                  Name: item?.name,
                  serviceTime: item?.serviceTime,
                  createdOn: filterdate(item?.servicedate_data[0].servicedate),
                  Category: item?.category_data[0]?.name,

                  action: (
                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                      <svg
                        onClick={() => onEdit(item)}
                        style={{
                          height: "20px",
                          width: "20px",
                          cursor: "pointer",
                          marginRight: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => onDelete(item?._id)}
                        style={{
                          color: "red",
                          height: "20px",
                          cursor: "pointer",
                          width: "20px",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash3"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                      </svg>
                    </div>
                  ),
                };
              })
          } else {
            arr = res?.data?.sort().reverse().map((item, index) => {
              return {
                sl: index + 1,
                Name: item?.name,
                serviceTime: item?.serviceTime,
                createdOn: filterdate(item?.servicedate_data[0].servicedate),
                Category: item?.category_data[0]?.name,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <svg
                      onClick={() => onEdit(item)}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                        marginRight: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                      />
                    </svg>
                    <svg
                      onClick={() => onDelete(item?._id)}
                      style={{
                        color: "red",
                        height: "20px",
                        cursor: "pointer",
                        width: "20px",
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                    </svg>
                  </div>
                ),
              };
            });
          }

          setFilteredData(arr)
          // setAllData(arr);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            {hide ? (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Add Service Time
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  color: "#868e96",
                  margin: "35px",
                }}
                className="card-title"
              >
                Edit Service Time
              </div>
            )}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Category<span style={{ color: "red" }}>*</span> :
              </label>
              <select
                onChange={(e) => {
                  setcategory_id(e.target.value);
                  fetchAllDate(e.target.value);
                  e.target.value && onCustomFilter(e.target.value, "")
                }}
              >
                <option value="" disabled selected>
                  Select
                </option>
                {allService?.map((ele, id) => {
                  console.log(ele);
                  return (
                    <option
                      selected={ele._id == category_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {ele.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div class="form-group">
              <label for="exampleInputEmail1">
                Date <span style={{ color: "red" }}>*</span> :
              </label>
              <select onChange={(e) => {
                setdate_id(e.target.value)
                e.target.value && onCustomFilter(category_id, e.target.value)
              }}>
                <option value="" disabled selected>
                  Select
                </option>
                {totaldate?.sort((a, b) => new Date(b.servicedate) - new Date(a.servicedate)).map((ele, id) => {
                  console.log("ele452", ele.createdOn);
                  return (
                    <option
                      selected={ele._id == date_id ? true : false}
                      value={ele._id}
                      key={ele._id}
                    >
                      {filterdate(ele.servicedate)}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <div class="form-group">
              <label for="exampleInputEmail1">
                Service-Time <span style={{ color: "red" }}>*</span> :
              </label>
              <input
                type="time"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div> */}

            <div class="form-group">
              <label for="exampleInputEmail1">
                Service Time <span style={{ color: "red" }}>*</span> :
              </label>
              {/* <div className="_form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp">{time}</div> */}
              <input
                type="time"
                className="form-control"
                // id="exampleInputEmail1"
                // aria-describedby="emailHelp"
                // onChange={handleTimeChange}
                // value={moment(time).format("hh:mm A")}
                // value={moment(time, 'HH:mm:ss').format('h:mm A')}
                onChange={(e) => { setTime(e.target.value) }}
                value={time}
              />




            </div>

            {hide ? (
              <button class="btn btn-primary" onClick={AddCityCategory}>
                Submit
              </button>
            ) : (
              <button class="btn btn-primary" onClick={UpdateServiceTime}>
                Update
              </button>
            )}

            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Manage Service-Time
            </div>
            <div class="form-inline my-2">

              <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                value={searchCategory}
                onChange={(e) => {
                  setSearchCategory(e.target.value)
                }}
              />


              <div class="form-group">
                <input
                  class="form-control mr-sm-2"
                  type="date"
                  placeholder="From Date"
                  aria-label="Search"
                  value={searchDate}
                  onChange={(e) => {
                    setSearchDate(e.target.value);
                  }}
                />
              </div>





              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                onClick={onFilter}
              >Filter</button>
              <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                onClick={viewAllData}
              >View All</button>
            </div>
            {
              loader ? (<Loader />) : (
                <DataTable columns={columns} data={filteredData} pagination />
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default AddSlotTime;
