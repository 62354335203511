import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Select from "react-select";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
import SeeMoreLess from "../../Component/SeeMoreLess";
import NewHomService from "../../Service/NewHomService";
import ImageLoader from "../Loader/ImageLoader";

///*/
const PriceDescription = () => {
    const [description, setDescription] = useState("");
    const [percentage, setPercentage] = useState("");
    const [feature, setfeature] = useState("");
    const [whitewash, setwhitewash] = useState("");
    const [dropdownoption, setdropdownoption] = useState([]);
    const [inputType, setinputType] = useState("");

    const [allData, setAllData] = useState([]);

    const [meterial_id, setmeterial_id] = useState("");
    const [paintingquality_id, setpaintingquality_id] = useState("");
    const [placetypeid, setPlacetypeid] = useState("");
    const [paintVer_id, setpaintVer_id] = useState("");

    const initialColors = [{ value: "#f7584a", text: "#f7584a" }, { value: "#5fb54c", text: "#5fb54c" }, { value: "#8a31f5", text: "#8a31f5" }]

    const [allColor, setAllColor] = useState(initialColors);
    const [allsubcatdata, setallsubcatdata] = useState([]);

    const [serviceTypeID, setServiceTypeID] = useState("");
    const [serviceCategoryID, setServiceCategoryID] = useState("");
    const [subcat_Id, setsubcat_Id] = useState("");
    const [color, setColor] = useState("");

    const [allService, setallSarvice] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);

    // const [showCategory, setShowCategory] = useState(false)

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    // console.log("cleaningValue", allColor);

    useEffect(() => {
        fetchAllData();
        //  fetchAllplacetype()
        // fetchAllmeterialType()
        //   fetchAllpaintingquality()
        fetchAllSarvice();
        // fetchPaintingVersion()
    }, []);

    const ResetState = () => {
        setDescription("");
        setServiceTypeID("")
        setServiceCategoryID("")
        setMultiImageValues([{}])
        setMultipleVideoValues([{}])
    };

    const fetchAllSarvice = () => {
        HomeService.ManageService()
            .then((res) => {
                // console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    setallSarvice(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    // const fetchAllSubCategoryArea = (id) => {
    //     HomeService.getAllSubcategory(id)
    //         .then((res) => {
    //             console.log("AllSffetate", res.data);
    //             if (res && res?.status) {
    //                 // setLoader(false)
    //                 //   if (res?.data.length > 0) {
    //                 //     setdisplaysubcat("block");
    //                 //     setallsubcatdata(res?.data);
    //                 //   } else {
    //                 //     setallsubcatdata([]);
    //                 //   }

    //                 setallsubcatdata(res?.data);
    //             } else {
    //                 setallsubcatdata([]);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err);
    //         });
    // };

    const fetchAllCategory = (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    // setLoader(false)
                    let arr = res?.data;
                    settotalCategory(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    // const fetchHomeType = (id) => {
    //     // const data={serviceCategoryID:catid}
    //     HomeService.viewHouseType(id)
    //         .then((res) => {
    //             // console.log("AllSttttttate", res.data);
    //             if (res && res?.status) {
    //                 // setLoader(false)
    //                 //   let arr = res?.data;
    //                 setAllColor(res?.data);
    //             } else {
    //                 setAllColor([]);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log("err", err);
    //         });
    // };

    const onEdit = (item) => {
        console.log("Data: ", item);
        window.scroll(0, 0);
        setServiceTypeID(item?.serviceTypeID)
        setDescription(item?.description)
        setPercentage(item?.percentage)
        setColor(item?.color)
        // checkCategoryDisplay(item?.serviceTypeID)
        fetchAllCategory(item?.serviceTypeID);
        setServiceCategoryID(item?.serviceCategoryID)
        setMultiImageValues(item?.images)
        setMultipleVideoValues(item?.videos)
        setId(item?._id)
        setHide(false);
        // console.log("L144:",id);
    };

    const onDelete = (id) => {
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeletePriceDescription(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllData();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    const fetchAllData = () => {
        NewHomService.FetchPriceDescription()
            .then((res) => {
                console.log("Data", res?.message);

                if (res && res?.status) {
                    // setLoader(false)

                    let arr = res?.message?.sort().reverse().map((item, index) => {
                        // console.log("ddd", item);
                        return {
                            sl: index + 1,
                            description: item?.description,
                            serviceTypeData: item?.serviceTypeData?.serviceName,
                            categoryName: item?.serviceCategoryData?.name,
                            // images: item?.images[0].imageurl,
                            // videos: item?.videos[0].videourl,

                            //   paintingvertion: item?.paintingVersionData?.name,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>

                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    setAllData(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };



    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            width: "50px",
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service Type
                </div>
            ),
            selector: (row) => row.serviceTypeData,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category Name
                </div>
            ),
            selector: (row) => row.categoryName,
            wrap: true,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Description
                </div>
            ),
            selector: (row) => <SeeMoreLess maxWordShow={150} >{row.description}</SeeMoreLess>,
            wrap: true,
            width: "500px",
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Photo(s)
        //         </div>
        //     ),
        //     selector: (row) => row.images,
        // },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Video(s)
        //         </div>
        //     ),
        //     selector: (row) => row.videos,
        // },
        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const addData = () => {
        let data = {
            serviceTypeID: serviceTypeID,
            serviceCategoryID: serviceCategoryID,
            description: description,
            images: multiImageValues,
            videos: multipleVideoValues,
        };


        console.log("Object Structure for Add", data);
        if (
            serviceTypeID &&
            serviceCategoryID &&
            description
        ) {

            NewHomService.AddPriceDescription(data)
                .then((res) => {
                    if (res && res.status) {
                        toast.success(res.message);
                        fetchAllData();
                        ResetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!serviceTypeID) {
                return toast.error("Service Type Is Required");
            }
            if (!serviceCategoryID) {
                return toast.error("Category Is Required");
            }
            if (!description) {
                return toast.error("Description Is Required");
            }
        }
    };

    const updateData = () => {

        let data = {
            serviceTypeID: serviceTypeID,
            serviceCategoryID: serviceCategoryID,
            description: description,
            images: multiImageValues,
            videos: multipleVideoValues,
        };

        NewHomService.UpdatePriceDescription(id, data)
            .then((res) => {
                console.log("updated res", res);
                if (res && res.status) {
                    toast.success("Updated Successfully");
                    setHide(true);
                    ResetState()
                    fetchAllData();
                } else {
                    toast.error("Error occured");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    ///Add Images


    const initialImages = {
        imageurl: ""
    };

    const [multiImageValues, setMultiImageValues] = useState([initialImages]);

    const handleChange = (i, e) => {
        let newFormValues = [...multiImageValues];
        newFormValues[i][e.target.name] = e.target.value;
        setMultiImageValues(newFormValues);
    };

    const [multiImageLoader, setMultiImageLoader] = useState(false)
    const HandleMultiImage = async (i, e) => {
        setMultiImageLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        // console.log('L86:', data);
        // let res = await HttpClientXml.fileUplode("upload-Image", "POST", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);

        if (res && res.status) {
            // console.log("UploadImageRes", res);
            let newFormValues = [...multiImageValues];
            newFormValues[i].imageurl = res?.doc?.url;
            setMultiImageValues(newFormValues);
            // console.log("multiImageValues: ", multiImageValues);
        } else {
            toast.error(res?.message);
        }
        setMultiImageLoader(false);
    };




    const addFormFields = () => {

        if (multiImageValues.length < 50) {
            setMultiImageValues([...multiImageValues, initialImages]);
        } else {
            Swal("")
            Swal("Error", "Not more than 5000", "error");
        }
    };

    const removeFormFields = (i) => {
        let newFormValues = [...multiImageValues];
        newFormValues.splice(i, 1);
        setMultiImageValues(newFormValues);
    };

    ///Add Images//

    ///Add Videos//

    const initialVideos = {
        videourl: "",

    };

    const [multipleVideoValues, setMultipleVideoValues] = useState([initialVideos]);

    const handleVideoChange = (i, e) => {
        let newFormValues = [...multipleVideoValues];
        newFormValues[i][e.target.name] = e.target.value;
        setMultipleVideoValues(newFormValues);
    };




    const [videoLoader, setVideoLoader] = useState(false)
    const handleMultiVideo = async (i, e) => {

        setVideoLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        // console.log('L52:', data);
        // let res = await HttpClientXml.fileVideoUplode("video-upload", "POST", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);

        // console.log("L54", res);
        if (res && res.status) {

            let newFormValues = [...multipleVideoValues];
            newFormValues[i].videourl = res?.doc?.url;
            setMultipleVideoValues(newFormValues);
            console.log("multipleVideoValues: ", multipleVideoValues);
        } else {
            toast.error(res?.message);
        }
        setVideoLoader(false);


    };

    const addFormFields2 = () => {
        if (multipleVideoValues.length < 10) {
            setMultipleVideoValues([...multipleVideoValues, initialVideos]);
        } else {
            Swal("")
            Swal("Error", "Not more than 10", "error");
        }
    };

    const removeFormFields2 = (i) => {
        let newFormValues = [...multipleVideoValues];
        newFormValues.splice(i, 1);
        setMultipleVideoValues(newFormValues);
    };

    ///Add Videos

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add Category Description
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Update Category Description
                            </div>
                        )}
                        {/* ServiceType */}
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                ServiceType<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setServiceTypeID(e.target.value);
                                    fetchAllCategory(e.target.value);
                                    // checkCategoryDisplay(e.target.value)
                                }}
                            >
                                <option value="">Select</option>
                                {allService
                                    .filter(item => item._id !== "64d60270203bf4c1d37b6a1e")
                                    ?.map((ele, id) => {
                                        //   console.log(ele);
                                        return (
                                            <option
                                                selected={ele._id == serviceTypeID ? true : false}
                                                value={ele._id}
                                                key={ele._id}

                                            >
                                                {ele.serviceName}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>

                        {/* Category Name */}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category Name <span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setServiceCategoryID(e.target.value);
                                }}
                            >
                                <option value="">Select</option>
                                {totalCategory?.map((ele, id) => {
                                    console.log("ele", ele);
                                    return (
                                        <option
                                            selected={ele._id == serviceCategoryID ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* Description */}
                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Description<span style={{ color: "red" }}>*</span> :
                            </label>
                            <textarea
                                class="form-control"
                                id="exampleFormControlTextarea1"
                                rows="5"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </div>


                        {/* Multiple Images */}

                        <div className="row" data-aos="fade-up" style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} >

                            {multiImageValues?.map((element, index) => (

                                <div className="form-inline my-2 col-6" key={index}>

                                    <label for="exampleInputEmail1" style={{ marginTop: "1rem" }}>
                                        Image {index + 1}:
                                    </label>
                                    <input
                                        class="form-control"
                                        onChange={(e) => HandleMultiImage(index, e)}
                                        type="file"
                                        id="thumbnail"
                                        accept="image/*"
                                    />
                                    {multiImageLoader ? (
                                        <>
                                            <ImageLoader />
                                        </>
                                    ) : null}
                                    {element.imageurl && (
                                        <>
                                            <div>
                                                <img
                                                    style={{
                                                        height: "70%",
                                                        width: "70%",
                                                        marginTop: "12px",
                                                        borderRadius: "5px",
                                                    }}
                                                    src={element.imageurl}
                                                />

                                            </div>
                                        </>
                                    )}

                                    {index ? (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger my-2"
                                            onClick={() => removeFormFields(index)}
                                        >
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    ) : null}
                                </div>

                            ))}


                            <div className="button-section mt-2">
                                <button
                                    className="btn btn-sm btn-success"
                                    type="button"
                                    onClick={() => addFormFields()}
                                >
                                    <i class="fas fa-plus"></i>
                                </button>

                            </div>
                            {/* </form> */}
                            {/* </div> */}
                        </div>

                        {/* //Multiple Images */}



                        {/* Multiple Videos */}

                        <div className="row" data-aos="fade-up" style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} >
                            <div className="col-lg-12">
                                {/* <form> */}


                                {multipleVideoValues.map((element, index) => (

                                    <div className="form-inline mb-2" key={index}>



                                        <label for="exampleInputEmail1">
                                            Video {index + 1}:
                                        </label>
                                        <input
                                            class="form-control"
                                            onChange={(e) => handleMultiVideo(index, e)}
                                            type="file"
                                            id="trailer"
                                            accept="video/*"
                                        />
                                        <br />
                                        {videoLoader ? (
                                            <>
                                                <ImageLoader />{" "}
                                            </>
                                        ) : null}
                                        {element.videourl && (
                                            <>
                                                <div>
                                                    <video width="320" src={element.videourl} type="video/mp4" controls >

                                                    </video>

                                                </div>
                                            </>
                                        )}



                                        {index ? (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger ml-1"
                                                onClick={() => removeFormFields2(index)}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        ) : null}
                                    </div>

                                ))}


                                <div className="button-section mt-2">
                                    <button
                                        className="btn btn-sm btn-success"
                                        type="button"
                                        onClick={() => addFormFields2()}
                                    >
                                        <i class="fas fa-plus"></i>
                                    </button>

                                </div>
                                {/* </form> */}
                            </div>
                        </div>

                        {/* //Multiple Videos */}










                        {
                            hide ? (
                                <button class="btn btn-primary" onClick={addData} >
                                    Submit
                                </button>
                            ) : (
                                <button class="btn btn-primary" onClick={updateData}>
                                    Update
                                </button>
                            )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Category Description(s)
                        </div>
                        <DataTable columns={columns} data={allData} pagination />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PriceDescription;
