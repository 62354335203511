import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import HomeService from "../../Service/HomeService";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Await, Link } from "react-router-dom";
import HttpClientXml from "../../Utils/HttpClientXml";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import NewHomService from "../../Service/NewHomService";
import moment from "moment";
import Modal from "react-modal";
import Loader from "../../Component/Loader";

const VendorMembers = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [countryIcon, setcountryIcon] = useState("");

  const [imgdata, setimgdata] = useState("");
  const [image, setimage] = useState("");

  const [allData, setAllData] = useState([]);

  const [hide, setHide] = useState(true);
  const [id, setId] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [replyToAppId, setReplyToAppId] = useState("");
  const [replyTo, setReplyTo] = useState("");
  const [replyToName, setReplyToName] = useState("");
  const [replyToMessage, setReplyToMessage] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchAllData();
  }, []);

  const onEdit = (item) => {
    window.scrollTo(0, 0);
    console.log("item", item);
    setTitle(item?.title);
    setDescription(item?.description);
    setimgdata(item?.image);
    setimage(item?.image);
    setId(item?._id);
    setHide(false);
  };

  const fetchAllData = () => {
    setLoader(true);
    NewHomService.FetchVendorMembers()
      .then((res) => {
        console.log("ALLDATAS", res.data);
        if (res && res?.status) {
          setLoader(false);

          let arr = res?.data
            ?.sort()
            .reverse()
            .map((item, index) => {
              // console.log("llll", item?.materialTypeName);
              return {
                sl: index + 1,
                VendorName: item?.name,
                Members: item?.addMembersData,

                action: (
                  <div style={{ display: "flex", flexDirection: "coloum" }}>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={(e) =>
                        openModal(item?._id, item?.email, item?.name)
                      }
                    >
                      Reply
                    </button>
                  </div>
                ),
              };
            });
          setFilteredData(arr);
          setAllData(arr);
        } else {
          setLoader(false);
        }
        console.log("RESPONSE", res);
      })
      .catch((err) => {
        console.log("err", err);
        setLoader(false);
      });
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't  to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        NewHomService.DeleteTestimonials(id)
          .then((res) => {
            console.log("DELETEBLOGCAT", res);
            if (res && res.status) {
              toast.success("Deleted Successfully");
              console.log("RESPONSEDELETE", res);
              fetchAllData();
            } else {
              toast.error("Failed to Delete the Item ");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const imageHandler = async (e) => {
    // setImgLoader(true);
    let file = e.target.files[0];
    let data = new FormData();
    data.append("image", file);
    console.log("imgdata", data);
    let res = await HttpClientXml.fileUplode("upload", "POST", data);
    console.log("resImage", res?.doc?.url);
    if (res && res.status) {
      toast.success("Image uploaded successfully");
      console.log(res?.doc?.url);
      setimgdata(res?.doc?.url);
      setimage(res?.doc?.url);
      // setImgLoader(false);
    } else {
      toast.error("Failed to upload Image");
      // setImgLoader(false);
    }
  };

  const addData = () => {
    let data = {
      title: title,
      description: description,
      image: imgdata,
    };

    if (title && description) {
      // alert(name)
      NewHomService.AddTestimonials(data)
        .then((res) => {
          // console.log("Response", res);
          if (res && res.status) {
            fetchAllData();
            toast.success(res.message);
            // setHide(true);
            // console.log("ADDDATA", res);
            setTitle("");
            setDescription("");
            setimgdata("");
            setimage("");
            let file = document.querySelector("#images");
            file.value = "";
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (!title) toast.error("Title Field Is Required");
      // if(!imgdata)
      // toast.error("Country icon Field Is Required");
    }
  };

  const columns = [
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          SL
        </div>
      ),
      selector: (row) => row.sl,
      sortable: true,
      width: "50px",
    },

    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Vendor Name
        </div>
      ),
      selector: (row) => row.VendorName,
      wrap: true,
      sortable: true,
      width: "250px",
    },
    {
      name: (
        <div
          style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        >
          Members
        </div>
      ),
      selector: (row) => {
        return Object.keys(row.Members).length > 0 ? (
          <>
            <ul className="list-group list-group-flush my-2">
              {row.Members.map((item, key) => {
                return (
                  <>
                    <li className="list-group-item" key={key}>
                      <span class="badge badge-success badge-pill mb-2 mr-1">
                        {key + 1}
                      </span>
                      <span class="badge badge-primary badge-pill mb-2">
                        {item?.name}
                      </span>
                      <div>
                        <strong>Phone: </strong>
                        {item?.phoneNumber}
                        <br />
                        <strong>Location: </strong>
                        {item?.areaName},&nbsp;{item?.cityName}
                        <br />
                        <strong>Service Category: </strong>
                        {item?.categoryData.length > 0 ? (
                          item?.categoryData.map((item, index) => {
                            return <>{item?.name},&nbsp;</>;
                          })
                        ) : (
                          <>n/a</>
                        )}
                      </div>
                      <div>
                        
                        {item.status ? (
                          <>
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => changeStatus(item?._id, false)}
                            >
                              Active
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to Deactivate
                              </span>
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => changeStatus(item?._id, true)}
                            >
                              Deactive
                              <span
                                style={{ fontSize: "70%", display: "block" }}
                              >
                                Click to Active
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </>
        ) : (
          <>No Members added by Vendor</>
        );
      },
      wrap: true,
    },

    // {
    //   name: (
    //     <div
    //       style={{
    //         fontSize: "14px",
    //         color: "#495057",
    //         marginLeft: "15px",
    //         fontWeight: "bolder",
    //       }}
    //     >
    //       Action
    //     </div>
    //   ),
    //   selector: (row) => row.action,
    //   width: "100px",
    // },
  ];

  const updateData = () => {
    console.log("ID", id);
    let data = {
      title: title,
      description: description,
      image: imgdata,
    };
    if (title) {
      NewHomService.UpdateTestimonials(id, data)
        .then((res) => {
          console.log("updated res", res);
          if (res && res.status) {
            toast.success("Updated Successfully");
            setHide(true);
            console.log("RESPONSEDELETE", res);
            setTitle("");
            setDescription("");
            setimgdata("");
            setimage("");
            let file = document.querySelector("#images");
            file.value = "";
            fetchAllData();
          } else {
            toast.error("Title Already Exist");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Title is requred");
    }
  };

  const resetState = () => {
    setReplyToAppId("");
    setReplyTo("");
    setReplyToName("");
    setReplyToMessage("");
  };

  const openModal = (appId, email, name) => {
    setIsOpen(true);
    setReplyToAppId(appId);
    setReplyTo(email);
    setReplyToName(name);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  };

  const closeModal = () => {
    resetState();
    setIsOpen(false);
  };

  const sendReply = () => {
    // setIsOpen(false);
    const data = {
      queriesId: replyToAppId,
      customer_email: replyTo,
      customer_name: replyToName,
      text: replyToMessage,
    };
    toast.success("Sending email...");
    replyToMessage !== ""
      ? NewHomService.SendReplyToVendorQueries(data)
          .then((res) => {
            // console.log("updated res", res);

            if (res && res.status) {
              setIsOpen(false);
              Swal.fire(`Reply sent to ${data.customer_name} successfully`);
              resetState();
            } else {
              toast.error("Unable to send reply. Try again!");
            }
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Message should not be empty");
  };

  const onFilter = () => {
    if (searchText !== "") {
      // setSearchText(e.target.value);
      console.log("searchText", searchText);
      let searchData = allData.filter((item) => {
        if (
          item.VendorName.toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
        ) {
          return item;
        }
      });
      console.log("searchData", searchData);
      setFilteredData(searchData);
      // setTableRowsData(searchData);
    } else {
      setFilteredData(allData);
    }
  };

  const viewAllData = () => {
    setSearchText("");
    setFilteredData(allData);
  };

  const changeStatus = (id, val) => {
    let data = {
      status: val,
    };
    NewHomService.ChangeVendorMemberStatus(id, data)
      .then((res) => {
        console.log("updated res", res);
        if (res && res.status) {
          toast.success("Updated Successfully");
          fetchAllData();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div component="div" className="TabsAnimation appear-done enter-done">
        <div className="main-card mb-3 card">
          <div className="card-body">
            <div
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "#868e96",
                margin: "35px",
              }}
              className="card-title"
            >
              Vendor Team Member(s)
            </div>
            <div class="form-inline my-2">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Filter by vendor name"
                aria-label="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1"
                type="submit"
                onClick={onFilter}
              >
                Filter
              </button>
              <button
                class="btn btn-sm btn-outline-primary my-2 my-sm-0"
                type="submit"
                onClick={viewAllData}
              >
                View All
              </button>
            </div>
            {loader ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  pagination
                  noDataComponent="No customer queries found!"
                />
              </>
            )}

            {/* <DataTable columns={columns} data={allData} pagination /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorMembers;
