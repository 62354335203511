import React, { useEffect, useState } from 'react'
import Table from '../../Component/Table'
import HomeService from '../../Service/HomeService'
import Loader from '../../Component/Loader'
import NewHomService from '../../Service/NewHomService'
import { Link } from 'react-router-dom'


export default function Index() {

  const [totalVendors, setTotalVendors] = useState(0)
  const [loadVendors, setLoadVendors] = useState(false)

  const [totalUsers, setTotalUsers] = useState(0)
  const [loadUsers, setLoadUsers] = useState(false)

  const [domesticOrders, setDomesticOrders] = useState()
  // const [amcContractOrders, setAmcContractOrders] = useState()
  // const [amcOrders, setAMCOrders] = useState()
  const [amcPending, setAMCPending] = useState(0)
  const [amcCancelled, setAMCCancelled] = useState(0)
  const [amcCompleted, setAMCCompleted] = useState(0)

  const [contractPending, setContractPending] = useState(0)
  const [contractCancelled, setContractCancelled] = useState(0)
  const [contractCompleted, setContractCompleted] = useState(0)

  const [domesticSales, setDomesticSales] = useState()
  const [amcContractSales, setAmcContractSales] = useState()
  const [amcSales, setAMCSales] = useState()
  const [contractSales, setContractSales] = useState()


  useEffect(() => {
    fetchAllVendors()
    fetchAllUsers()
    fetchAllDomesticOrdersStatus()
    // fetchAllAMCContyractOrdersStatus()
    fetchAllDomesticSalesAmount()
    // fetchAllAMCContyractSalesAmount()
    fetchAMCSalesAmount()
    fetchContractSalesAmount()

    fetchAMCOrdersStatus()
    fetchContractOrdersStatus()
    // console.log("LINE38", amcOrders);
  }, [])

  const fetchAllDomesticOrdersStatus = () => {
    setLoadVendors(true);
    NewHomService.FetchAllDomesticOrdersStatus()
      .then((res) => {
        if (res && res?.status) {
          console.log("LINE51", res);
          setDomesticOrders(res?.data)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchContractOrdersStatus = () => {
    // setLoadVendors(true);
    NewHomService.FetchContractOrdersStatus()
      .then((res) => {

        if (res && res?.status) {
          // setAMCOrders(res)
          console.log("HDHD70", res?.data);
          setContractPending(res?.data.pending)
          setContractCancelled(res?.data.cancelled)
          setContractCompleted(res?.data.complete)

        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };



  // OK

  const fetchAMCOrdersStatus = () => {
    // setLoadVendors(true);
    NewHomService.FetchAMCOrdersStatus()
      .then((res) => {

        if (res && res?.status) {
          // setAMCOrders(res)
          console.log("HDHD65", res);
          let amcPendingCount = res?.data.filter(status => status._id === "pending")
          setAMCPending(amcPendingCount[0].count)
          let amcCancelledCount = res?.data.filter(status => status._id === "cancelled")
          setAMCCancelled(amcCancelledCount[0].count)
          let amcCompletedCount = res?.data.filter(status => status._id === "complete")
          setAMCCompleted(amcCompletedCount[0].count)

        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllVendors = () => {
    setLoadVendors(true);
    HomeService.MannageVendar()
      .then((res) => {
        if (res && res?.status) {
          setLoadVendors(false);
          setTotalVendors(res?.data.length)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllUsers = () => {
    setLoadUsers(true);
    NewHomService.FetchAllUsers()
      .then((res) => {
        if (res && res?.status) {
          setLoadUsers(false);
          setTotalUsers(res?.data.length)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAllDomesticSalesAmount = () => {
    setLoadVendors(true);
    NewHomService.FetchAllDomesticSalesAmount()
      .then((res) => {
        if (res && res?.status) {
          console.log("SESESE", res.data);
          setDomesticSales(res?.data[0])
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchAMCSalesAmount = () => {
    // setLoadVendors(true);
    NewHomService.FetchAMCSalesAmount()
      .then((res) => {
        if (res && res?.status) {
          console.log("L83", res);
          setAMCSales(res?.data[0]?.totalNumber)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchContractSalesAmount = () => {
    // setLoadVendors(true);
    NewHomService.FetchContractSalesAmount()
      .then((res) => {
        if (res && res?.status) {
          console.log("L97", res);
          setContractSales(res?.data)
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // OK


  const fetchAllAMCContyractSalesAmount = () => {
    setLoadVendors(true);
    NewHomService.FetchAllContractSalesAmount()
      .then((res) => {
        if (res && res?.status) {
          console.log("weree", res.data[0]);
          setAmcContractSales(res?.data[0])
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };




  return (
    <>
      <div className=''>
        <div className=''>

          <div className="row">
            <div className="col-md-6 col-lg-4">
              <Link to="/registered-vendors">
                <div className="card-shadow-success mb-3 widget-chart widget-chart2 text-start card">
                  <div className="widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="_widget-content-left pe-2 fsize-1">

                          <div className="widget-numbers mt-0 fsize-3 text-success">
                            {
                              !loadVendors ? (
                                <>{totalVendors}</>
                              ) : (
                                <>
                                  <Loader />
                                </>
                              )
                            }

                          </div>
                        </div>
                        {/* <div className="widget-content-right w-100">
                        <div className="progress-bar-xs progress">
                          <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            aria-valuenow={100}
                            aria-valuemin={100}
                            aria-valuemax={100}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div> */}
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="text-muted opacity-6">Total Vendor(s)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-lg-4">
              <Link to="/view-users">
                <div className="card-shadow-warning mb-3 widget-chart widget-chart2 text-start card">
                  <div className="widget-content">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">

                        <div className="widget-content-left pe-2 fsize-1">

                          <div className="widget-numbers mt-0 fsize-3 text-warning">

                            {
                              !loadUsers ? (
                                <>{totalUsers}</>
                              ) : (
                                <>
                                  <Loader />
                                </>
                              )
                            }

                          </div>

                        </div>

                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="text-muted opacity-6">Total User(s)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="card-shadow-info mb-3 widget-chart widget-chart2 text-start card">
                <div className="widget-content">
                  <div className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left pe-2 fsize-1">
                        <div className="widget-numbers mt-0 fsize-3 text-info">{(parseInt((domesticSales?.totalNumber ? domesticSales?.totalNumber : 0)) + parseInt((amcSales ? amcSales : 0)) + parseInt((contractSales ? contractSales : 0))).toFixed(2)}</div>
                      </div>
                    </div>
                    <div className="widget-content-left fsize-1">
                      <div className="text-muted opacity-6">Total Sales</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-shadow-danger mb-3 card">
            <div className="widget-content">
              <div className='row'>
                <h4 className='mb-2'>Domestic Service(s)</h4>
                <div className='col-4'>
                  <Link to="/Add-booking/pending">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          {/* <div className="widget-numbers mt-0 fsize-3 text-info">{parseInt(domesticOrders?.pending ? domesticOrders?.pending : 0) + parseInt(amcContractOrders?.pending ? amcContractOrders?.pending : 0)}</div> */}
                          <div className="widget-numbers mt-0 fsize-3 text-info">{domesticOrders?.pending ? domesticOrders?.pending : 0}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Pending Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                  <Link to="/Add-booking/cancelled">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-danger">{domesticOrders?.cancelled ? domesticOrders?.cancelled : 0}</div>
                          {/* <div className="widget-numbers mt-0 fsize-3 text-danger">{parseInt(domesticOrders?.cancelled ? domesticOrders?.cancelled : 0) + parseInt(amcContractOrders?.cancelled ? amcContractOrders?.cancelled : 0)}</div> */}
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Cancelled Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                  <Link to="/Add-booking/complete">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-success">{domesticOrders?.complete ? domesticOrders?.complete : 0}</div>
                          {/* <div className="widget-numbers mt-0 fsize-3 text-success">{parseInt(domesticOrders?.complete ? domesticOrders?.complete : 0) + parseInt(amcContractOrders?.complete ? amcContractOrders?.complete : 0)}</div> */}
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Completed Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>





              </div>
            </div>
          </div>

          {/* AMC Orders */}
          <div className="card-shadow-danger mb-3 card">
            <div className="widget-content">
              <div className='row'>
                <h4 className='mb-2'>AMC Service(s)</h4>
                <div className='col-4'>
                  <Link to="/view-amc-forms/pending">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          {/* <div className="widget-numbers mt-0 fsize-3 text-info">{parseInt(domesticOrders?.pending ? domesticOrders?.pending : 0) + parseInt(amcContractOrders?.pending ? amcContractOrders?.pending : 0)}</div> */}
                          <div className="widget-numbers mt-0 fsize-3 text-info">{amcPending}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Pending Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                <Link to="/view-amc-forms/cancelled">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-danger">{amcCancelled}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Cancelled Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                  <Link to="/view-amc-forms/complete">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-success">{amcCompleted}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Completed Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>





              </div>
            </div>
          </div>

          {/* Contract Orders */}
          <div className="card-shadow-danger mb-3 card">
            <div className="widget-content">
              <div className='row'>
                <h4 className='mb-2'>Contract Service(s)</h4>
                <div className='col-4'>
                  <Link to="/view-contract-forms/pending">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-info">{contractPending}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Pending Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                  <Link to="/view-contract-forms/cancelled">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-danger">{contractCancelled}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Cancelled Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className='col-4'>
                  <Link to="/view-contract-forms/complete">
                    <div className="widget-content-outer">
                      <div className="widget-content-wrapper">
                        <div className="widget-content-left pe-2 fsize-1">
                          <div className="widget-numbers mt-0 fsize-3 text-success">{contractCompleted}</div>
                        </div>
                      </div>
                      <div className="widget-content-left fsize-1">
                        <div className="_text-muted opacity-7">Completed Orders</div>
                      </div>
                    </div>
                  </Link>
                </div>





              </div>
            </div>
          </div>





        </div>

      </div>
      {/* <Table /> */}

    </>


  )
}
