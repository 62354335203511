import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import ImageLoader from "../Loader/ImageLoader";
import Loader from "../../Component/Loader";

const ServiceFAQs = () => {
    const [flashoffer, setFlashoffer] = useState("");
    const [imgdata, setimgdata] = useState("");
    const [image, setimage] = useState("");
    const [duration, setduration] = useState("");
    const [actualPrice, setactualPrice] = useState("");
    const [discountPrice, setdiscountPrice] = useState("");
    const [summary, setsummary] = useState("");
    const [category_id, setcategory_id] = useState("");
    const [subcategory_id, setsubcategory_id] = useState("");
    const [serviceId, setServiceId] = useState("")
    const [allData, setAllData] = useState([]);

    const [service_type_id, setservice_type_id] = useState("");

    const [totalService_type, settotalService_type] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);
    const [totalSubcategory, settotalSubcategory] = useState([]);

    const [singledata, setsingleData] = useState({})
    const [totalServices, setTotalServices] = useState([])

    const [filteredData, setFilteredData] = useState([]);
    const [searchCategory, setSearchCategory] = useState("")
    const [searchSubCategory, setSearchSubCategory] = useState("")
    const [loader, setLoader] = useState(false)

    // console.log("2data", subcategory_id);


    // const [image, setimage] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");

    useEffect(() => {
        getAllService_type();
        setservice_type_id("64d60270203bf4c1d37b6a1e")
        getAllCategoryinService_type("64d60270203bf4c1d37b6a1e")
        fetchAllService();
    }, []);

    // useEffect(()=>{  
    //    service_type_id && getAllCategoryinService_type(service_type_id)
    //   },[service_type_id,])

    const resetState = () => {
        setFlashoffer()
        setServiceId()
        setsubcategory_id()
        setcategory_id()
        setServiceId()
        setFAQ([initialFAQ])
    }

    const onEdit = (item) => {

        window.scroll(0, 0)
        console.log("itemee", item);

        getAllService_type();
        setservice_type_id(item?.serviceTypeData._id);
        getAllCategoryinService_type(item?.serviceTypeData._id);

        setcategory_id(item?.categoryData._id);
        getAllSubCategory(item?.categoryData._id);

        setsubcategory_id(item?.subcategoryData._id)
        getAllServicesByCategory(item?.subcategoryData._id);

        setServiceId(item?.serviceData._id)

        setFAQ(item?.faqData)


        setId(item?._id);

        setHide(false);
    };

    const onDelete = (id) => {
        console.log("ID89", id);
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteServiceFAQs(id)
                    .then((res) => {
                        console.log("DELETEBLOGCAT", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllService();
                        } else {
                            toast.error("Failed to Delete the Item ");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    // const organiseData = () => {
    //     let temarr = [...questio_ans]
    //     const tempobj = {
    //         question: question,
    //         answer: ans
    //     }
    //     temarr.push(tempobj);
    //     setquestio_ans(temarr)
    //     setquestion('')
    //     setans('')
    // }

    // const addQuestion = () => {
    //     setfquestions([...fquestions, { question: "", answer: "" }]);
    // };

    // const updateQuestion = (index, field, value) => {
    //     const updatedQuestions = [...fquestions];
    //     updatedQuestions[index][field] = value;
    //     setfquestions(updatedQuestions);
    // };
    // const deleteQuestion = (index) => {
    //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
    //     setfquestions(updatedQuestions);
    // };

    const getAllService_type = async () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalService_type(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllCategoryinService_type = async (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalCategory(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const getAllCategory = async () => {
    //   HomeService.ManageCategory()
    //     .then((res) => {
    //       console.log("ResAllBlog", res.data);

    //       if (res && res?.status) {
    //         // setLoader(false)
    //         console.log(res?.data);
    //         settotalCategory(res?.data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const getAllSubCategory = async (id) => {
        HomeService.SubCategoriesviacategory(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("sub_cat", res?.data);
                    settotalSubcategory(res?.data);
                } else {
                    settotalSubcategory([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getAllServicesByCategory = async (id) => {
        console.log("services:", id);
        NewHomService.FetchServicesByCategory(id)
            .then((res) => {
                console.log("FetchServicesByCategory", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("services:", res);
                    setTotalServices(res?.data);
                } else {
                    setTotalServices();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchAllService = () => {
        NewHomService.FetchServiceFAQs()
            .then((res) => {
                console.log("Response Data", res);
                if (res && res?.status) {
                    // setLoader(false)

                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        return {
                            sl: index + 1,
                            ServiceType: item?.serviceTypeData?.serviceName,
                            Category: item?.categoryData?.name,
                            Subcategory: item?.subcategoryData?.name,
                            Service: item?.serviceData?.title,
                            sId: item?.serviceTypeId,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    console.log("RESPONSE", res);
                    setFilteredData(arr)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const imageHandler = async (e) => {
        // setImgLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        console.log("imgdata", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);
        console.log("resImage", res);
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            console.log(IMAMGE_URL + res?.data);
            setimage(IMAMGE_URL + res?.data);
            setimgdata(res?.data);
            // setImgLoader(false);
        } else {
            toast.error("Failed to upload Image");
            // setImgLoader(false);
        }
    };

    const addData = () => {
        let data = {
            serviceTypeID: service_type_id,
            serviceCategoryID: category_id,
            subCategoryID: subcategory_id,
            serviceID: serviceId,
            faqData: faqs,
        };

        if (service_type_id && category_id && subcategory_id && serviceId) {
            console.log("DATA", data);

            NewHomService.AddServiceFAQs(data)
                .then((res) => {
                    console.log("Response Add State", res);
                    if (res && res.status) {
                        toast.success("FAQ added successfully");
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error(res.message);

                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            if (!serviceId) {
                toast.error("Choose Service");
            }
            if (!subcategory_id) {
                toast.error("Choose Sub Category");
            }
            if (!category_id) {
                toast.error("Choose Category");
            }
        }
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            width: "100px"
        },
        // {
        //     name: (
        //         <div
        //             style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
        //         >
        //             Service Type
        //         </div>
        //     ),
        //     selector: (row) => row.ServiceType,
        // },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Sub Category
                </div>
            ),
            selector: (row) => row.Subcategory,
        },

        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service
                </div>
            ),
            selector: (row) => row.Service,
        },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const updateData = () => {
        let data = {
            serviceTypeID: service_type_id,
            serviceCategoryID: category_id,
            subCategoryID: subcategory_id,
            serviceID: serviceId,
            faqData: faqs,
        };

        if (service_type_id && category_id && subcategory_id && serviceId) {
            console.log("DATA441", data);
            NewHomService.UpdateServiceFAQs(id, data)
                .then((res) => {
                    // console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        setHide(true);
                        // console.log("RESPONSEDELETE", res);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!flashoffer) {
                toast.error("Offer is mandatory");
            }
            if (!serviceId) {
                toast.error("Choose Service");
            }
            if (!subcategory_id) {
                toast.error("Choose Sub Category");
            }
            if (!category_id) {
                toast.error("Choose Category");
            }
        }
    };

    const [onEditing, setOnEditing] = useState(false)
    const initialFAQ = {
        question: "",
        answer: "",
    };

    const [faqs, setFAQ] = useState([initialFAQ]);
    const handleChange = (i, e) => {
        let newFormValues = [...faqs];
        newFormValues[i][e.target.name] = e.target.value;
        setFAQ(newFormValues);
    };


    const [warrentyImageLoader, setWarrentyImageLoader] = useState(false)
    const handleWarrentyImage = async (i, e) => {
        setWarrentyImageLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        // console.log('L86:', data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);

        if (res && res.status) {
            console.log("UploadImageRes", res);
            // setBannerImage(res?.url);
            let newFormValues = [...faqs];
            newFormValues[i].icon = res?.doc?.url;;
            setFAQ(newFormValues);
            // console.log("L60: ",faqs[0].image);
        } else {
            toast.error(res?.message);
        }
        setWarrentyImageLoader(false);
    };
    const addFAQ = () => {
        // alert(faqs.length)
        if (faqs.length < 5000) {
            setFAQ([...faqs, initialFAQ]);
        } else {
            Swal("")
            Swal("Error", "Not more than 5000", "error");
        }
    };

    const removeFAQ = (i) => {
        let newFormValues = [...faqs];
        newFormValues.splice(i, 1);
        setFAQ(newFormValues);
    };

    const onFilter = () => {
        let searchData = allData;
        console.log("GSGS538", allData);

        //category
        if (searchCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //subcategory
        if (searchSubCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Subcategory?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }

    }

    const viewAllData = () => {
        setSearchCategory("")
        setSearchSubCategory("")
        setFilteredData(allData)
    }

    const onCustomFilter = (c_id, sc_id, srv_id) => {
        setLoader(true)
        NewHomService.FetchServiceFAQs()
            .then((res) => {
                console.log("SERE862", res.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = []
                    if (c_id && sc_id && srv_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.categoryData._id
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subcategoryData._id
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.serviceData._id
                                    .includes(srv_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeData?.serviceName,
                                    Category: item?.categoryData.name,
                                    Subcategory: item?.subcategoryData.name,
                                    Service: item?.serviceData?.title,
                                    sId: item?.serviceTypeId,


                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    }
                    else if (c_id && sc_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.categoryData._id
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subcategoryData._id
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeData?.serviceName,
                                    Category: item?.categoryData.name,
                                    Subcategory: item?.subcategoryData.name,
                                    Service: item?.serviceData?.title,
                                    sId: item?.serviceTypeId,


                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (c_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceCategoryID
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeData?.serviceName,
                                    Category: item?.categoryData.name,
                                    Subcategory: item?.subcategoryData.name,
                                    Service: item?.serviceData?.title,
                                    sId: item?.serviceTypeId,


                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else {
                        arr = res?.data?.sort().reverse().map((item, index) => {
                            return {
                                sl: index + 1,
                                Name: item?.title,
                                actualPrice: item?.actualPrice,
                                discountPrice: item?.discountPrice,
                                duration: item?.duration,
                                sId: item?.serviceTypeId,
                                icon: item?.serviceImage,
                                ServiceType: item?.serviceTypeDatas,
                                Category: item?.CategoryDatas,
                                Subcategory: item?.subCategoryDatas,
                                SpecialTags: item?.specialTagArrayDetail,


                                action: (
                                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                                        <svg
                                            onClick={() => onEdit(item)}
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                                cursor: "pointer",
                                                marginRight: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                        </svg>
                                        <svg
                                            onClick={() => onDelete(item?._id)}
                                            style={{
                                                color: "red",
                                                height: "20px",
                                                cursor: "pointer",
                                                width: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-trash3"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>
                                    </div>
                                ),
                            };
                        });
                    }




                    setFilteredData(arr)
                    setAllData(arr);
                }
                console.log("RESPONSE", res);
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add FAQ
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit FAQ
                            </div>
                        )}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Type<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_type_id(e.target.value);
                                    getAllCategoryinService_type(e.target.value);
                                }}
                            // disabled
                            // value={service_type_id}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalService_type?.filter((item) => item._id === "64d60270203bf4c1d37b6a1e").map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == service_type_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setcategory_id(e.target.value);
                                    e.target.value && getAllSubCategory(e.target.value);
                                    e.target.value && onCustomFilter(e.target.value, "", "")
                                }}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalCategory?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == category_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Sub Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setsubcategory_id(e.target.value)
                                e.target.value && getAllServicesByCategory(e.target.value);
                                e.target.value && onCustomFilter(category_id, e.target.value, "")
                            }}>
                                <option value="" >
                                    Choose...
                                </option>
                                {totalSubcategory?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == subcategory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setServiceId(e.target.value)
                                e.target.value && onCustomFilter(category_id, subcategory_id, e.target.value)

                            }}>
                                <option value="" >Choose...</option>
                                {totalServices?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == serviceId ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {/* Multiple FAQ */}

                        <div className="row" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
                            <div className="col-lg-12">
                                {/* <form> */}


                                {faqs?.map((element, index) => (

                                    <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "1em 0", borderRadius: "0.25rem" }} className="_form-inline" key={index}>

                                        <div className="form-group mb-2 mt-1">
                                            <label for="inputEmail4">
                                                Question{index + 1} :
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="question"
                                                placeholder={`Question ${index + 1}`}
                                                value={element.question || ""}
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </div>

                                        <div className="form-group mb-2 mt-1">
                                            <label for="inputEmail4">
                                                Answer{index + 1} :
                                            </label>
                                            <textarea class="form-control"
                                                name="answer"
                                                placeholder={`Answer ${index + 1}`}
                                                value={element.answer || ""}
                                                onChange={(e) => handleChange(index, e)}
                                                rows="3"
                                            ></textarea>

                                        </div>

                                        {/* Image Upload */}
                                        {/* <label for="exampleInputEmail1" style={{ marginTop: "1rem" }}>
                                            Warrenty Icon :
                                        </label>
                                        <input
                                            class="form-control"
                                            onChange={(e) => handleWarrentyImage(index, e)}
                                            type="file"
                                            id="thumbnail"
                                            accept="image/*"
                                        />
                                        {warrentyImageLoader ? (
                                            <>
                                                <ImageLoader />
                                            </>
                                        ) : null}
                                        {element.icon && (
                                            <>
                                                <div>
                                                    <img
                                                        style={{
                                                            height: "10%",
                                                            width: "10%",
                                                            marginTop: "12px",
                                                            borderRadius: "5px",
                                                        }}
                                                        src={element.icon}
                                                    />

                                                </div>
                                            </>
                                        )} */}
                                        {/* //Image Upload */}

                                        {index ? (
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger ml-1"
                                                onClick={() => removeFAQ(index)}
                                            >
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        ) : null}
                                    </div>

                                ))}


                                <div className="button-section my-2">
                                    <button
                                        className="btn btn-sm btn-success"
                                        type="button"
                                        onClick={() => addFAQ()}
                                    >
                                        <i class="fas fa-plus"></i> Question & Answer
                                    </button>

                                </div>
                                {/* </form> */}
                            </div>
                        </div>

                        {/* //Multiple FAQ */}






                        {hide ? (
                            <button class="btn btn-primary" onClick={addData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={updateData}>
                                Update
                            </button>
                        )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            Frequently Asked Question(s)
                        </div>
                        <div class="form-inline my-2">

                            <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                                value={searchCategory}
                                onChange={(e) => {
                                    setSearchCategory(e.target.value)
                                }}
                            />
                            <input class="form-control mr-sm-2" type="search" placeholder="Sub Category" aria-label="Search"
                                value={searchSubCategory}
                                onChange={(e) => {
                                    setSearchSubCategory(e.target.value)
                                }}
                            />

                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div>
                        {
                            loader ? (<Loader />) : (
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    noDataComponent="No Questions are available"
                                />
                            )
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceFAQs;
