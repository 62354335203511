import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import DataTable from "react-data-table-component";
import HttpClientXml from "../../Utils/HttpClientXml";
import Swal from "sweetalert2";
// import "./service.css";
import { IMAMGE_URL } from "../../Utils/HttpClientXml";
import HomeService from "../../Service/HomeService";
import NewHomService from "../../Service/NewHomService";
import ImageLoader from "../Loader/ImageLoader";
import Loader from "../../Component/Loader";

const ManageDistrictWiseServicePrice = () => {
    const [flashoffer, setFlashoffer] = useState("");
    const [imgdata, setimgdata] = useState("");
    const [image, setimage] = useState("");
    const [duration, setduration] = useState("");
    const [price, setPrice] = useState("");
    const [discountPrice, setdiscountPrice] = useState("");
    const [summary, setsummary] = useState("");
    const [category_id, setcategory_id] = useState("");
    const [subcategory_id, setsubcategory_id] = useState("");
    const [serviceId, setServiceId] = useState("")
    const [allData, setAllData] = useState([]);
    const [allState, setAllState] = useState([]);

    const [allDistrict, setAllDistrict] = useState([]);

    const [service_type_id, setservice_type_id] = useState("");
    const [state_id, setstate_id] = useState("");
    const [district_id, setdistrict_id] = useState("");
    const [district_name, setdistrict_name] = useState("");


    const [totalService_type, settotalService_type] = useState([]);
    const [totalCategory, settotalCategory] = useState([]);
    const [totalSubcategory, settotalSubcategory] = useState([]);

    const [singledata, setsingleData] = useState({})
    const [totalServices, setTotalServices] = useState([])

    const [filteredData, setFilteredData] = useState([]);
    const [searchCategory, setSearchCategory] = useState("")
    const [searchSubCategory, setSearchSubCategory] = useState("")

    // console.log("2data", subcategory_id);


    // const [image, setimage] = useState("")

    const [hide, setHide] = useState(true);
    const [id, setId] = useState("");
    console.log(district_name,"asdaeef")
    console.log(allData,"allData")

    useEffect(() => {
        fetchAllState()
        getAllService_type();
        setservice_type_id("64d60270203bf4c1d37b6a1e")
        getAllCategoryinService_type("64d60270203bf4c1d37b6a1e")
        fetchAllService();
    }, []);
    useEffect(() => {
        if (district_id) {
            const data = allDistrict?.find((item,i)=>item._id===district_id)
           
            setdistrict_name(data?.name)
           
        }
       
    }, [district_id]);
    useEffect(() => {
        if (district_name) {
            const data = allData?.filter((item,i)=>item?.District===district_name)
           
            setFilteredData(data)
           
        }
        else{
            setFilteredData(allData)
        }
       
    }, [district_name, allData]);


    const fetchAllState = () => {
        HomeService.ManageState()
            .then((res) => {
                console.log("AllState", res.data);
                if (res && res?.status) {
                    let arr = res?.data;
                    setAllState(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const resetState = () => {

        setservice_type_id("64d60270203bf4c1d37b6a1e")
        getAllCategoryinService_type("64d60270203bf4c1d37b6a1e");
        setcategory_id("");
        getAllSubCategory();

        setsubcategory_id("")
        getAllServicesByCategory();
        setServiceId("")

        setstate_id("")
        fetchAllDistrict()
        setdistrict_id("")
        setPrice("")
    }

    const onEdit = (item) => {

        window.scroll(0, 0)
        // console.log("itemee85", item);

        setservice_type_id(item?.serviceTypeId)
        getAllCategoryinService_type(item?.serviceTypeId);
        setcategory_id(item?.categoryId);
        getAllSubCategory(item?.categoryId);

        setsubcategory_id(item?.subCategoryId)
        getAllServicesByCategory(item?.subCategoryId);
        setServiceId(item?.serviceId)

        setstate_id(item?.stateId)
        fetchAllDistrict(item?.stateId)
        setdistrict_id(item?.districtId)
        setPrice(item?.price)



        setId(item?._id);

        setHide(false);
    };

    const onDelete = (id) => {
        // console.log("ID92", id);
        Swal.fire({
            title: "Are you sure?",
            // text: "You won't  to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                NewHomService.DeleteDistrictWiseServicePrice(id)
                    .then((res) => {
                        console.log("DELETE", res);
                        if (res && res.status) {
                            toast.success("Deleted Successfully");
                            console.log("RESPONSEDELETE", res);
                            fetchAllService();
                        } else {
                            toast.error(res?.message);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    };

    // const organiseData = () => {
    //     let temarr = [...questio_ans]
    //     const tempobj = {
    //         question: question,
    //         answer: ans
    //     }
    //     temarr.push(tempobj);
    //     setquestio_ans(temarr)
    //     setquestion('')
    //     setans('')
    // }

    // const addQuestion = () => {
    //     setfquestions([...fquestions, { question: "", answer: "" }]);
    // };

    // const updateQuestion = (index, field, value) => {
    //     const updatedQuestions = [...fquestions];
    //     updatedQuestions[index][field] = value;
    //     setfquestions(updatedQuestions);
    // };
    // const deleteQuestion = (index) => {
    //     const updatedQuestions = fquestions.filter((_, i) => i !== index);
    //     setfquestions(updatedQuestions);
    // };

    const getAllService_type = async () => {
        HomeService.ManageService()
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalService_type(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getAllCategoryinService_type = async (id) => {
        HomeService.CategoriesviaService_type(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log(res?.data);
                    settotalCategory(res?.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    // const getAllCategory = async () => {
    //   HomeService.ManageCategory()
    //     .then((res) => {
    //       console.log("ResAllBlog", res.data);

    //       if (res && res?.status) {
    //         // setLoader(false)
    //         console.log(res?.data);
    //         settotalCategory(res?.data);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };

    const getAllSubCategory = async (id) => {
        HomeService.SubCategoriesviacategory(id)
            .then((res) => {
                console.log("ResAllBlog", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("sub_cat", res?.data);
                    settotalSubcategory(res?.data);
                } else {
                    settotalSubcategory([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const getAllServicesByCategory = async (id) => {
        console.log("services:", id);
        NewHomService.FetchServicesByCategory(id)
            .then((res) => {
                console.log("FetchServicesByCategory", res.data);

                if (res && res?.status) {
                    // setLoader(false)
                    console.log("services:", res);
                    setTotalServices(res?.data);
                } else {
                    setTotalServices();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchAllService = () => {
        setLoader(true)
        NewHomService.FetchDistrictWiseServicePrice()
            .then((res) => {
                console.log("DATA264", res.data);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = res?.data?.sort().reverse().map((item, index) => {
                        return {
                            sl: index + 1,
                            ServiceType: item?.serviceTypeName,
                            Category: item?.categoryName,
                            Subcategory: item?.subcategoryName,
                            Service: item?.serviceName,
                            State: item?.stateName,
                            District: item?.districtName,
                            Price: item?.price,
                            // sId: item?.serviceTypeId,

                            action: (
                                <div style={{ display: "flex", flexDirection: "coloum" }}>
                                    <svg
                                        onClick={() => onEdit(item)}
                                        style={{
                                            height: "20px",
                                            width: "20px",
                                            cursor: "pointer",
                                            marginRight: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-pencil-square"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                        />
                                    </svg>
                                    <svg
                                        onClick={() => onDelete(item?._id)}
                                        style={{
                                            color: "red",
                                            height: "20px",
                                            cursor: "pointer",
                                            width: "20px",
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-trash3"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                    </svg>
                                </div>
                            ),
                        };
                    });
                    console.log("RESPONSE316", arr);
                    const filteredData = arr?.filter((item,i)=>item?.District===district_name)
                    // setFilteredData(filteredData)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const imageHandler = async (e) => {
        // setImgLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        console.log("imgdata", data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);
        console.log("resImage", res);
        if (res && res.status) {
            toast.success("Image uploaded successfully");
            console.log(IMAMGE_URL + res?.data);
            setimage(IMAMGE_URL + res?.data);
            setimgdata(res?.data);
            // setImgLoader(false);
        } else {
            toast.error("Failed to upload Image");
            // setImgLoader(false);
        }
    };

    const addData = () => {
        let data = {
            stateId: state_id,
            districtId: district_id,
            serviceTypeId: service_type_id,
            categoryId: category_id,
            subCategoryId: subcategory_id,
            serviceId: serviceId,
            price: price,
        };
        console.log("ADDDATA362", data);
        if (service_type_id &&
            category_id &&
            subcategory_id &&
            serviceId &&
            state_id &&
            district_id &&
            price
        ) {


            NewHomService.AddDistrictWiseServicePrice(data)
                .then((res) => {

                    if (res && res.status) {
                        // console.log("ASER", res);
                        toast.success(res.message);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error(res?.message);
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    toast.success("Service warrenty added successfully");
                    fetchAllService();
                    resetState();
                });
        } else {
            if (!service_type_id) {
                return toast.error("Choose Service Type");
            }
            if (!category_id) {
                return toast.error("Choose Category");
            }
            if (!subcategory_id) {
                return toast.error("Choose Sub Category");
            }
            if (!serviceId) {
                return toast.error("Choose Service");
            }
            if (!state_id) {
                return toast.error("Choose State");
            }
            if (!district_id) {
                return toast.error("Choose District");
            }
            if (!price) {
                return toast.error("Choose Price");
            }
        }
    };

    const columns = [
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    SL
                </div>
            ),
            selector: (row) => row.sl,
            width: "75px"
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service Type
                </div>
            ),
            selector: (row) => row.ServiceType,
            width: "150px",
            wrap: true
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Category
                </div>
            ),
            selector: (row) => row.Category,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Sub Category
                </div>
            ),
            selector: (row) => row.Subcategory,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Service
                </div>
            ),
            selector: (row) => row.Service,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    State
                </div>
            ),
            selector: (row) => row.State,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    District
                </div>
            ),
            selector: (row) => row.District,
        },
        {
            name: (
                <div
                    style={{ fontSize: "14px", color: "#495057", fontWeight: "bolder" }}
                >
                    Price
                </div>
            ),
            selector: (row) => row.Price,
        },

        {
            name: (
                <div
                    style={{
                        fontSize: "14px",
                        color: "#495057",
                        marginLeft: "15px",
                        fontWeight: "bolder",
                    }}
                >
                    Action
                </div>
            ),
            selector: (row) => row.action,
        },
    ];

    const updateData = () => {
        let data = {
            stateId: state_id,
            districtId: district_id,
            serviceTypeId: service_type_id,
            categoryId: category_id,
            subCategoryId: subcategory_id,
            serviceId: serviceId,
            price: price,
        };

        if (service_type_id &&
            category_id &&
            subcategory_id &&
            serviceId &&
            state_id &&
            district_id &&
            price
        ) {
            console.log("DATA529", data);
            NewHomService.UpdateDistrictWiseServicePrice(id, data)
                .then((res) => {
                    // console.log("updated res", res);
                    if (res && res.status) {
                        toast.success("Updated Successfully");
                        setHide(true);
                        // console.log("RESPONSEDELETE", res);
                        fetchAllService();
                        resetState();
                    } else {
                        toast.error("Error Occured!");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            if (!service_type_id) {
                return toast.error("Choose Service Type");
            }
            if (!category_id) {
                return toast.error("Choose Category");
            }
            if (!subcategory_id) {
                return toast.error("Choose Sub Category");
            }
            if (!serviceId) {
                return toast.error("Choose Service");
            }
            if (!state_id) {
                return toast.error("Choose State");
            }
            if (!district_id) {
                return toast.error("Choose District");
            }
            if (!price) {
                return toast.error("Choose Price");
            }
        }
    };

    const [onEditing, setOnEditing] = useState(false)
    const initialWarrenty = {
        title: "",
        description: "",
        icon: ""
    };

    const [warrenty, setWarrenty] = useState([initialWarrenty]);
    const handleChange = (i, e) => {
        let newFormValues = [...warrenty];
        newFormValues[i][e.target.name] = e.target.value;
        setWarrenty(newFormValues);
    };


    const [warrentyImageLoader, setWarrentyImageLoader] = useState(false)
    const handleWarrentyImage = async (i, e) => {
        setWarrentyImageLoader(true);
        let file = e.target.files[0];
        let data = new FormData();
        data.append("image", file);
        // console.log('L86:', data);
        let res = await HttpClientXml.fileUplode("upload", "POST", data);

        if (res && res.status) {
            console.log("UploadImageRes", res);
            // setBannerImage(res?.url);
            let newFormValues = [...warrenty];
            newFormValues[i].icon = res?.doc?.url;;
            setWarrenty(newFormValues);
            // console.log("L60: ",warrenty[0].image);
        } else {
            toast.error(res?.message);
        }
        setWarrentyImageLoader(false);
    };
    const addWarrenty = () => {
        // alert(warrenty.length)
        if (warrenty.length < 5000) {
            setWarrenty([...warrenty, initialWarrenty]);
        } else {
            Swal("")
            Swal("Error", "Not more than 5000", "error");
        }
    };

    const removeWarrenty = (i) => {
        let newFormValues = [...warrenty];
        newFormValues.splice(i, 1);
        setWarrenty(newFormValues);
    };

    const [loader, setLoader] = useState(false)
    const onCustomFilter = (s_id, c_id, sc_id, srv_id, state_id, dist_id) => {
        setLoader(true)
        NewHomService.FetchDistrictWiseServicePrice()
            .then((res) => {
                console.log("DATA629", res);
                if (res && res?.status) {
                    setLoader(false)
                    let arr = []

                    if (s_id && c_id && sc_id && srv_id && state_id && dist_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subCategoryId
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.serviceId
                                    .includes(srv_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.stateId
                                    .includes(state_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.districtId
                                    .includes(dist_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id && c_id && sc_id && srv_id && state_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subCategoryId
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.serviceId
                                    .includes(srv_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.stateId
                                    .includes(state_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    }
                    else if (s_id && c_id && sc_id && srv_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subCategoryId
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.serviceId
                                    .includes(srv_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else if (s_id && c_id && sc_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.subCategoryId
                                    .includes(sc_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })

                    } else if (s_id && c_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        }).filter((item) => {
                            if (
                                item?.categoryId
                                    .includes(c_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })

                    } else if (s_id) {
                        arr = res?.data?.filter((item) => {
                            if (
                                item?.serviceTypeId
                                    .includes(s_id)
                            ) {
                                return item;
                            }
                        })
                            .sort().reverse().map((item, index) => {
                                return {
                                    sl: index + 1,
                                    ServiceType: item?.serviceTypeName,
                                    Category: item?.categoryName,
                                    Subcategory: item?.subcategoryName,
                                    Service: item?.serviceName,
                                    State: item?.stateName,
                                    District: item?.districtName,
                                    Price: item?.price,
                                    // sId: item?.serviceTypeId,

                                    action: (
                                        <div style={{ display: "flex", flexDirection: "coloum" }}>
                                            <svg
                                                onClick={() => onEdit(item)}
                                                style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    cursor: "pointer",
                                                    marginRight: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-pencil-square"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                />
                                            </svg>
                                            <svg
                                                onClick={() => onDelete(item?._id)}
                                                style={{
                                                    color: "red",
                                                    height: "20px",
                                                    cursor: "pointer",
                                                    width: "20px",
                                                }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </div>
                                    ),
                                };
                            })
                    } else {
                        arr = res?.data?.sort().reverse().map((item, index) => {
                            return {
                                sl: index + 1,
                                ServiceType: item?.serviceTypeName,
                                Category: item?.categoryName,
                                Subcategory: item?.subcategoryName,
                                Service: item?.serviceName,
                                State: item?.stateName,
                                District: item?.districtName,
                                Price: item?.price,
                                // sId: item?.serviceTypeId,

                                action: (
                                    <div style={{ display: "flex", flexDirection: "coloum" }}>
                                        <svg
                                            onClick={() => onEdit(item)}
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                                cursor: "pointer",
                                                marginRight: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                                fill-rule="evenodd"
                                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                        </svg>
                                        <svg
                                            onClick={() => onDelete(item?._id)}
                                            style={{
                                                color: "red",
                                                height: "20px",
                                                cursor: "pointer",
                                                width: "20px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-trash3"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                        </svg>
                                    </div>
                                ),
                            };
                        })
                    }


                    console.log("RESPONSE", res);
                    setFilteredData(arr)
                    setAllData(arr);
                }

            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const onFilter = () => {
        let searchData = allData;
        console.log("GSGS538", allData);

        //category
        if (searchCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Category?.toLowerCase().includes(searchCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }
        //subcategory
        if (searchSubCategory !== "") {
            searchData = searchData.filter((item) => {
                if (item?.Subcategory?.toLowerCase().includes(searchSubCategory.toLowerCase())) {
                    return item;
                }
            });
            setFilteredData(searchData);
        }

    }

    const viewAllData = () => {
        setSearchCategory("")
        setSearchSubCategory("")
        setFilteredData(allData)
    }

    const fetchAllDistrict = (stateid) => {
        HomeService.ManageDistrict()
            .then((res) => {
                console.log("AllDistrict", res.data);
                if (res && res?.status) {
                    // let arr = res?.data;
                    let arr = res?.data.filter(item => item.stateId === stateid)
                    setAllDistrict(arr);
                }
            })
            .catch((err) => {
                console.log("err", err);
            });
    };

    const checkPrice = (e) => {

        e.target.value < 0 ? (toast.error("Price should not be negative")) : (
            setPrice(e.target.value)
        )
    }


    return (
        <>
            <div component="div" className="TabsAnimation appear-done enter-done">
                <div className="main-card mb-3 card">
                    <div className="card-body">
                        {hide ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Add District Wise Service Price
                            </div>
                        ) : (
                            <div
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    color: "#868e96",
                                    margin: "35px",
                                }}
                                className="card-title"
                            >
                                Edit District Wise Service Price
                            </div>
                        )}

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service Type<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setservice_type_id(e.target.value);
                                    getAllCategoryinService_type(e.target.value);
                                    onCustomFilter(e.target.value, "", "", "", "", "")
                                }}
                            // disabled
                            // value={service_type_id}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalService_type?.filter((item) => item._id === "64d60270203bf4c1d37b6a1e").map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == service_type_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.serviceName}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select
                                onChange={(e) => {
                                    setcategory_id(e.target.value);
                                    getAllSubCategory(e.target.value);
                                    onCustomFilter(service_type_id, e.target.value, "", "", "", "")
                                }}
                            >
                                <option value="" >
                                    Choose...
                                </option>
                                {totalCategory?.map((ele, id) => {
                                    return (
                                        <option
                                            selected={ele._id == category_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Sub Category<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setsubcategory_id(e.target.value)
                                getAllServicesByCategory(e.target.value);
                                onCustomFilter(service_type_id, category_id, e.target.value, "", "", "")
                            }}>
                                <option value="" >
                                    Choose...
                                </option>
                                {totalSubcategory?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == subcategory_id ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">
                                Service<span style={{ color: "red" }}>*</span> :
                            </label>
                            <select onChange={(e) => {
                                setServiceId(e.target.value)
                                onCustomFilter(service_type_id, category_id, subcategory_id, e.target.value, "", "")

                            }}>
                                <option value="" >Choose...</option>
                                {totalServices?.map((ele, id) => {
                                    // console.log("eedata", ele);

                                    return (
                                        <option
                                            selected={ele._id == serviceId ? true : false}
                                            value={ele._id}
                                            key={ele._id}
                                        >
                                            {ele.title}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>



                        <div className="row" data-aos="fade-up" style={{ display: onEditing ? "none" : "block" }}>
                            <div className="col-lg-12">

                                <label for="inputEmail4">
                                    District wise price:
                                </label>



                                <div style={{ border: "solid 1px #ced4da", padding: "1em", margin: "0 0 1em 0", borderRadius: "0.25rem" }} className="_form-inline">

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            State Name<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <select onChange={(e) => {
                                            setstate_id(e.target.value)
                                            e.target.value && fetchAllDistrict(e.target.value)
                                            e.target.value && onCustomFilter(service_type_id, category_id, subcategory_id, serviceId, e.target.value, "")
                                        }}>
                                            <option value="">Select</option>
                                            {allState?.map((ele, id) => {
                                                console.log(ele);
                                                return (
                                                    <option
                                                        selected={ele._id == state_id ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            District Name<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <select onChange={(e) => {
                                            setdistrict_id(e.target.value)
                                            e.target.value && onCustomFilter(service_type_id, category_id, subcategory_id, serviceId, state_id, e.target.value)
                                            }}>
                                            <option value="">Select</option>
                                            {allDistrict?.map((ele, id) => {
                                                console.log(ele);
                                                return (
                                                    <option
                                                        selected={ele._id == district_id ? true : false}
                                                        value={ele._id}
                                                        key={ele._id}
                                                    >
                                                        {ele.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <label for="exampleInputEmail1">
                                            Price<span style={{ color: "red" }}>*</span> :
                                        </label>
                                        <input
                                            type="number"
                                            min={0}
                                            placeholder="0"
                                            class="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            value={price}
                                            onChange={(e) => checkPrice(e)}
                                        />
                                    </div>


                                </div>






                            </div>
                        </div>








                        {hide ? (
                            <button class="btn btn-primary" onClick={addData}>
                                Submit
                            </button>
                        ) : (
                            <button class="btn btn-primary" onClick={updateData}>
                                Update
                            </button>
                        )}

                        <div
                            style={{
                                textAlign: "center",
                                fontSize: "20px",
                                color: "#868e96",
                                margin: "35px",
                            }}
                            className="card-title"
                        >
                            District Wise Service Price
                        </div>
                        {/* <div class="form-inline my-2">

                            <input class="form-control mr-sm-2" type="search" placeholder="Category" aria-label="Search"
                                value={searchCategory}
                                onChange={(e) => {
                                    setSearchCategory(e.target.value)
                                }}
                            />
                            <input class="form-control mr-sm-2" type="search" placeholder="Sub Category" aria-label="Search"
                                value={searchSubCategory}
                                onChange={(e) => {
                                    setSearchSubCategory(e.target.value)
                                }}
                            />

                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0 mr-1" type="submit"
                                onClick={onFilter}
                            >Filter</button>
                            <button class="btn btn-sm btn-outline-primary my-2 my-sm-0" type="submit"
                                onClick={viewAllData}
                            >View All</button>
                        </div> */}
                        {
                            loader ? (<>
                                <Loader />
                            </>) : (<>
                                <DataTable
                                    columns={columns}
                                    data={filteredData}
                                    pagination
                                    noDataComponent="No Warrenty are available"
                                />
                            </>)
                        }


                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageDistrictWiseServicePrice;
